import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-card-events-add-nav',
	templateUrl: './card-events-add-nav.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardEventsAddNavComponent implements OnInit, OnDestroy {
	@Input() oppositeColors: boolean;

	@ViewChild('card') cardRef: ElementRef;

	loading = false;

	constructor(
		private router: Router
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flipCard() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}
