import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { User } from '../models/user.model';

@Injectable()
export class UserTranslationService {
    public userIdsLoading: number[] = [];
    public users: User[] = [];

    public announceUserFound = new Subject<User>();

    public userWithImageFoundSubscription: Subscription;

    constructor(
        private http: HttpClient,
        //  private userService: UserService
    ) {
        // this.subscribeToUserWithImageFound();
    }





    // This is part 1 of the user translation.

    // This is called by various functions that 

    /**
     * Get the user, then trigger the user service to retrieve the image safe url. This is captured
     * in the subscription above and announced back to the comment component.
     * 
     * @param userId 
     */
    // getUserByUserId(userId) {
    //     // Lookup the user in the cache by user id.
    //     let user = this.users.find(d => d.id === userId);
    //     if (!user) {
    //         // No user, need to look it up.  Since this function is referenced within a .html, need to add loading
    //         // so the call isn't performed multiple times when loading comments.
    //         let item = this.userIdsLoading.find(d => d === userId);
    //         if (!item) {
    //             this.userIdsLoading.push(userId);

    //             this.retrieveUser(userId).subscribe(
    //                 response => {
    //                     let user = response.body;

    //                     this.users.push(user);

    //                     this.userService.retrieveUserImageSafeUrl(user);
    //                 }
    //             );
    //         }
    //     } else {
    //         // Found the user in the cache, announce it.
    //         this.announceUserFound.next(user);
    //     }
    // }

    // /**
    //  * 
    //  * @param userId 
    //  * @param photoIndex 
    //  */
    // getUserByUserIdAndPhotoIndex(userId, photoIndex) {
    //     // Lookup the user in the cache by user id.
    //     let user = this.users.find(d => d.id === userId);
    //     if (!user) {
    //         // No user, need to look it up.  Since this function is referenced within a .html, need to add loading
    //         // so the call isn't performed multiple times when loading comments.
    //         let item = this.userIdsLoading.find(d => d === userId);
    //         if (!item) {
    //             this.userIdsLoading.push(userId);

    //             this.retrieveUser(userId).subscribe(
    //                 response => {
    //                     let user = response.body;
    //                     user.photoIndex = photoIndex;

    //                     this.users.push(user);

    //                     this.userService.retrieveUserImageSafeUrl(user);
    //                 }
    //             );
    //         }
    //     } else {
    //         // Have to perform a deep copy, otherwise the sequence somemtimes doesn't work and the index gets overwritten
    //         let userObject = JSON.parse(JSON.stringify(user));

    //         userObject.photoIndex = photoIndex;

    //         // Found the user in the cache, announce it.
    //         this.announceUserFound.next(userObject);
    //     }
    // }

    // // This is part two of the user translation
    // subscribeToUserWithImageFound() {
    //     this.userWithImageFoundSubscription = this.userService.userWithImageRetrieved
    //         .subscribe((retrievedUser: User) => {
    //             let user = this.users.find(d => d.id === retrievedUser.id);
    //             if (user) {
    //                 user.imageSafeUrl = retrievedUser.imageSafeUrl;

    //                 let item = this.userIdsLoading.find(d => d === retrievedUser.id);
    //                 if (item) {
    //                     this.userIdsLoading.splice(this.userIdsLoading.indexOf(item), 1);
    //                 }

    //                 this.announceUserFound.next(user);
    //             }
    //         });
    // }



}
