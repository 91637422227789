import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-widget-photos-connections-graph',
	templateUrl: './widget-photos-connections-graph.component.html',
	styleUrls: ['./widget-photos-connections-graph.component.css']
})
export class WidgetPhotosConnectionsGraphComponent implements OnInit {
	loading = true;

	userPhotoCount: any;
	userPhotoCountChangedSubscription: Subscription;

	pieSliceConnections1Seconds = 0;
	pieSliceConnections2Seconds = 0;

	pieSliceConnections1Degrees = 0;
	pieSliceConnections2Degrees = 0;
	pieSliceConnectionsFillerDegrees = 0;

	constructor(
		private photoService: PhotoService
	) { }

	ngOnInit() {
		this.subscribeToPhotoCountChanged();

		if (this.photoService.loadingUserPhotoCount) {
			// Another component is already loading the count, do nothing and wait for subscription.
		} else {
			// No component is already loading the count, trigger it and wait for subscription.
			this.retrievePhotoCount();
		}
	}

	subscribeToPhotoCountChanged() {
		this.userPhotoCountChangedSubscription = this.photoService.userPhotoCountChanged
			.subscribe((value: any) => {
				this.photoService.loadingUserPhotoCount = false;

				let connectionsPercent = (value.connectionPhotos / value.vaultPhotos);
				if (connectionsPercent > .5) {
					this.pieSliceConnections1Seconds = .5;
					this.pieSliceConnections2Seconds = (connectionsPercent - .5);
				} else {
					this.pieSliceConnections1Seconds = connectionsPercent;
					this.pieSliceConnections2Seconds = 0;
				}

				// Delay settings the CSS specs to make the animatons more consistant. 
				let _this = this;
				setTimeout(function () {
					let connectionsPercent = ((value.connectionPhotos / value.vaultPhotos) * 360);
					if (connectionsPercent > 180) {
						_this.pieSliceConnections1Degrees = 180;
						_this.pieSliceConnections2Degrees = (connectionsPercent - 180);
					} else {
						_this.pieSliceConnections1Degrees = connectionsPercent;
						_this.pieSliceConnections2Degrees = 0;
					}
					_this.pieSliceConnectionsFillerDegrees = 180;
				}, 100);

				this.userPhotoCount = value;
				this.loading = false;
			});
	}

	retrievePhotoCount() {
		this.loading = true;
		this.photoService.loadingUserPhotoCount = true;

		this.photoService.getTotalPhotoCount().subscribe(
			response => {
				if (response.status === 200) {
					this.photoService.setAndAnnounceUserPhotoCount(response.body);
				}
			}
		);
	}
}
