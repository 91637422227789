<div class="message-container" *ngIf="message.action == 'confirm'">
	<div class="message">{{ message.message }}</div>
	<div class="action-icon">
		<i class="fas fa-check"></i> Acknowledge
	</div>
</div>

<div class="message-container" *ngIf="message.action == 'event'">
	<ng-container *ngIf="user?.imageSafeUrl">
		<img [src]="user.imageSafeUrl" class="image" />
	</ng-container>
	<div class="message">{{ user?.firstName }} {{ user?.lastName }} has created an event and you have photos that occur on the same day. Would you like to offer your photos for this event?</div>
	<div class="action-icon">
		<i class="fas fa-check"></i> Review photos
	</div>
	<div class="action-icon">
		<i class="fas fa-times"></i> No thanks
	</div>
</div>