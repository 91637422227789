<div class="outer">
	<div class="center-container">
		<!-- Logo - Always visible -->
		<div class="logo-container"><img src="/assets/images/logo-setupaccount.png" /></div>

		<!-- Load Indicator -->
		<div *ngIf="loading">
			<div class="title">Validating your e-mail</div>
			<div class="subtitle">Please wait while we validate your e-mail address for added security...</div>
			<div class="loading in loading-container">&nbsp;</div>
		</div>

		<!-- Register Form -->
		<div *ngIf="!loading && !validationFailed">
			<div class="row">
				<div class="col-md-4 offset-md-4 form-container">
					<form #f="ngForm" [formGroup]="form" class="form">
						<div class="form-group">
							<label>E-mail Address</label>
							<i class="fas fa-envelope"></i>
							<input type="text" id="email" formControlName="email" class="form-control" [disabled]="disabled" />
						</div>
						<div class="form-group l">
							<label>First Name</label>
							<i class="fas fa-user"></i>
							<div *ngIf="form.controls.firstName.invalid && form.controls.firstName.touched" class="field-alert">
								Enter your first name</div>
							<input type="text" id="firstName" formControlName="firstName" class="form-control" />
						</div>
						<div class="form-group r">
							<label>Last Name</label>
							<i class="fas fa-user"></i>
							<div *ngIf="form.controls.lastName.invalid && form.controls.lastName.touched" class="field-alert">
								Enter your last name</div>
							<input type="text" id="lastName" formControlName="lastName" class="form-control" />
						</div>
						<div class="form-group">
							<label>Password</label>
							<i class="fas fa-passport"></i>
							<div *ngIf="form.controls.password.invalid && form.controls.password.touched" class="field-alert">
								Must be 7 characters</div>
							<input [type]="passwordInputType" id="password" formControlName="password" class="form-control" />
							<div class="show-password-container">
								<a class="password-show" *ngIf="passwordInputType == 'password'" (click)="changePasswordInputType('text')">Show password</a>
								<a class="password-show" *ngIf="passwordInputType == 'text'" (click)="changePasswordInputType('password')">Hide password</a>
							</div>
						</div>
						<!-- <div class="form-group">
							<label>Date of Birth</label>
							<i class="far fa-calendar-alt"></i>
							<div *ngIf="form.controls.birthDate.invalid && form.controls.birthDate.touched" class="field-alert">
								MM-DD-YYYY</div>
							<input type="text" id="birthDate" formControlName="birthDate" class="form-control" maxlength="10" (blur)="verifyAge()" />
							<div *ngIf="userAge > 0 && userAge < 14" class="age-message">Sorry, you must be at least 13 years of age to create a Photonomy account.</div>
						</div> -->
						<div class="form-group">
							<label>Date of Birth</label>
							<div class="date-container">
								<div class="select-container">
									<select formControlName="month" class="month-input" placeholder="Month">
										<option value="">Month</option>
										<option value="0">January</option>
										<option value="1">February</option>
										<option value="2">March</option>
										<option value="3">April</option>
										<option value="4">May</option>
										<option value="5">June</option>
										<option value="6">July</option>
										<option value="7">August</option>
										<option value="8">September</option>
										<option value="9">October</option>
										<option value="10">November</option>
										<option value="11">December</option>
									</select>
								</div>
								<input type="text" id="day" formControlName="day" class="form-control day-input" maxlength="2" placeholder="Day" />
								<input type="text" id="year" formControlName="year" class="form-control year-input" maxlength="4" placeholder="Year" />
							</div>
							<div *ngIf="userAge > 0 && userAge < 14" class="age-message">Sorry, you must be at least 13 years of age to create a Photonomy account.</div>
						</div>

						<input type="hidden" id="userUuid" formControlName="userUuid" class="form-control" />
						<input type="hidden" id="emailVerifyCode" formControlName="emailVerifyCode" class="form-control" />

						<button class="btn btn-success submit register" type="submit" (click)="registerSubmit()" [disabled]="!f.valid || (userAge > 0 && userAge < 14)">Activate Account</button>
						<button class="btn btn-success navigate" (click)="navigate('')">Cancel</button>
					</form>
				</div>
			</div>
		</div>

		<!-- Post Register Submit Messages -->
		<div *ngIf="!loading && validationFailed" class="failed-message">
			<ng-container *ngIf="!registerSuccess">
				<div class="title">Something went wrong</div>
				<div class="subtitle">We're sorry, we were unable to validate your e-mail address</div>
				<!--
						<form #r="ngForm" [formGroup]="formVerify" class="email-form">
							<input type="text" id="email" formControlName="email" placeholder="Enter your e-mail" /><button *ngIf="validationFailedUser.length === 0" type="submit" (click)="emailSubmit()" [disabled]="!r.valid">Create An Account</button><button *ngIf="validationFailedUser.length > 0" type="submit" (click)="emailSubmit()" [disabled]="!r.valid">Re-send Welcome E-mail</button>
						</form>
						-->
			</ng-container>
			<ng-container *ngIf="registerSuccess">
				<div class="title">Registration submitted</div>
				<div class="subtitle">Please check your inbox</div>
				<div class="message">An e-mail has been sent to your inbox with steps to complete your registration</div>
			</ng-container>
		</div>

	</div>
</div>