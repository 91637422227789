<div class="card" #card>
	<div class="face front">
		<i class="fas fa-question" (click)="flip()"></i>
		<i class="fas fa-cloud-upload-alt"></i>
		<div class="text">Import photos from <br /> popular cloud services</div>
		<div class="button-container">
			<div class="button" (click)="navigate('photo/add')">Sync Photos</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Sync Photos</div>
		<div class="help-text">With Photonomy, you can sync photos from external sources such as Facebook and OneDrive. You can choose to import the photo into Photonomy or leave it in the external source.</div>
	</div>
</div>