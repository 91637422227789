<div class="card opposite-colors" #card>
	<div class="face front">
		<div class="welcome-title">
			<img src="/assets/images/hand-gesture.png" class="hand" />
			<div class="title">Welcome to Photonomy</div>
		</div>
		<div class="welcome-columns row">
			<div class="col-md-6">
				<div class="welcome-text">
					<p>In Photonomy, your photos must be stored in either a shared vault or in your private vault.</p>
					<p>Shared vaults can be used to split storage costs as well as set ownership hierarchies
						to ensure long-term ownership of your assets.</p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="welcome-text">
					<p>To use your private vault, a valid <a (click)="navigateToPaymentMethods()">payment method</a> must be associated with
						your account.</p>
				</div>
				<div class="button-container">
					<div class="button start-here" (click)="navigate('vault/create')">Create a Vault</div>
				</div>
				<div class="bold">START HERE</div>
			</div>
		</div>
	</div>
</div>