<div class="header">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div>
		<div class="last-updated">Last updated<br />January 23, 2022</div>
		<p>1. <a href="#agreement-of-terms" *ngIf="showFooter">Agreement to Terms</a><span *ngIf="!showFooter">Agreement to Terms</span></p>
		<p>2. <a href="#ip-rights" *ngIf="showFooter">Intellectual Property Rights</a><span *ngIf="!showFooter">Intellectual Property Rights</span></p>
		<p>3. <a href="#user-representations" *ngIf="showFooter">User Representations</a><span *ngIf="!showFooter">User Representations</span></p>
		<p>4. <a href="#prohibited-activities" *ngIf="showFooter">Prohibited Activities</a><span *ngIf="!showFooter">Prohibited Activities</span></p>
	</div>
	<div>
		<div class="title">Terms and Conditions</div>
		<div class="heading" id="agreement-of-terms">1. Agreement to Terms</div>
		<p>These Terms of Use constitute a legally binding agreement made between you, whether
			personally or on behalf of an entity (“you”) and Photonomy, concerning your access to and use of the
			www.photonomy.com website as well as any other media form, media channel, mobile website or mobile
			application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that
			by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use.
			IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
			SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

		<p>Supplemental terms and conditions or documents that may be posted on the Site from time to time
			are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion,
			to make changes or modifications to these Terms of Use from time to time. We will alert you about
			any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to
			receive specific notice of each such change. Please ensure that you check the applicable Terms every
			time you use our Site so that you understand which Terms apply. You will be subject to, and will be
			deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by
			your continued use of the Site after the date such revised Terms of Use are posted.</p>

		<p>The information provided on the Site is not intended for distribution to or use by any person or entity
			in any jurisdiction or country where such distribution or use would be contrary to law or regulation
			or which would subject us to any registration requirement within such jurisdiction or country.
			Accordingly, those persons who choose to access the Site from other locations do so on their own
			initiative and are solely responsible for compliance with local laws, if and to the extent local laws
			are applicable.</p>

		<div class="heading" id="ip-rights">2. Intellectual Property Rights</div>
		<p>Unless otherwise indicated, the Site is our proprietary
			property and all source code, databases, functionality, software, website
			designs, audio, video, text, photographs, and graphics on the Site
			(collectively, the “Content”) and the trademarks, service marks, and logos
			contained therein (the “Marks”) are owned or controlled by us or licensed to
			us, and are protected by copyright and trademark laws and various other
			intellectual property rights and unfair competition laws of the United States, international copyright laws,
			and international conventions. The Content and the Marks are provided on the
			Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms
			of Use, no part of the Site and no Content or Marks may be copied, reproduced,
			aggregated, republished, uploaded, posted, publicly displayed, encoded,
			translated, transmitted, distributed, sold, licensed, or otherwise exploited
			for any commercial purpose whatsoever, without our express prior written
			permission.</p>

		<div class="heading" id="user-representations">3. User Representations</div>
		<p>
			By using the Site, you represent and warrant that:
			(1) you have the legal capacity and you agree to comply with these Terms of Use;
			(2) you are not a minor in the jurisdiction in which you reside;
			(3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;
			(4) you will not use the Site for any illegal or unauthorized purpose; and
			(5) your use of the Site will not violate any applicable law or regulation.
		</p>

		<p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
			suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
			thereof).</p>

		<div class="heading" id="prohibited-activities">4. Prohibited Activities</div>
		<p>You may not access or use the Site for any purpose other than that for which we make the Site available.
			The Site may not be used in connection with any commercial endeavors except those that are specifically
			endorsed or approved by us.</p>
		<p>As a user of the Site, you agree not to:</p>
		<ul>
			<li>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly,
				a collection, compilation, database, or directory without written permission from us.</li>
			<li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information
				such as user passwords.</li>
			<li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including
				features that prevent or restrict the use or copying of any Content or enforce limitations on
				the use of the Site and/or the Content contained therein.</li>
			<li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
			<li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses
				of users by electronic or other means for the purpose of sending unsolicited email, or creating
				user accounts by automated means or under false pretenses.</li>
			<li>Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any
				revenue-generating endeavor or commercial enterprise.</li>
		</ul>
	</div>
</div>
<app-footer *ngIf="showFooter"></app-footer>