import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../models/user.model';

@Injectable()
export class PaymentService {
	public userPaymentMethods = [];
	public userPaymentMethodLoaded = new Subject();
	public userPaymentMethodAdded = new Subject();
	public userPaymentMethodRemoved = new Subject();

	constructor(
		private http: HttpClient
	) { }

	updateUserPaymentMethod(paymentMethodId: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('paymentMethodId', paymentMethodId);

		const requestUrl = '/api/user/payment-method';

		return this.http.put<User>(requestUrl, '', { observe: 'response', params: queryParameters });
	}

	isPaymentMethodUsed(paymentMethodId: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('paymentMethodId', paymentMethodId);

		const requestUrl = '/api/user/payment-method/used';

		return this.http.get<boolean>(requestUrl, { params: queryParameters });
	}

	deleteUserPaymentMethod(paymentMethodId: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('paymentMethodId', paymentMethodId);

		const requestUrl = '/api/user/payment-method';

		return this.http.delete<User>(requestUrl, { observe: 'response', params: queryParameters });
	}

	// getUserPaymentMethods() {
	// 	const requestUrl = '/api/user/payment-methods';
	// 	return this.http.get<Array<UserPaymentMethod>>(requestUrl);
	// }

	getSetupIntent() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'text/html');

		const requestUrl = '/api/user/setup-intent';

		return this.http.get(requestUrl, { responseType: 'text', observe: 'response', headers: headers });
	}

	getPaymentIntent(amount: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'text/html');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('amount', amount);

		const requestUrl = '/api/user/payment-intent';

		return this.http.get(requestUrl, { responseType: 'text', observe: 'response', headers: headers, params: queryParameters });
	}

	addVaultCredit(vaultId: number, amount: number, transactionId: string, clientId: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('amount', amount);
		queryParameters = queryParameters.set('transactionId', transactionId);
		queryParameters = queryParameters.set('clientId', clientId);

		const requestUrl = '/api/vault/' + vaultId + '/credit';

		return this.http.post<boolean>(requestUrl, '', { observe: 'response', params: queryParameters });
	}

	// announceUserPaymentMethodsLoaded(paymentMethods: UserPaymentMethod[]) {
	// 	this.userPaymentMethods = paymentMethods;
	// 	this.userPaymentMethodLoaded.next(paymentMethods);
	// }

	// announceUserPaymentMethodAdded(paymentMethod: UserPaymentMethod) {
	// 	this.userPaymentMethods.push(paymentMethod);
	// 	this.userPaymentMethodAdded.next(paymentMethod);
	// }

	// announceUserPaymentMethodRemoved(paymentMethod: UserPaymentMethod) {
	// 	this.userPaymentMethods.push(paymentMethod);
	// 	this.userPaymentMethodRemoved.next(paymentMethod);
	// }

}
