<div class="card" #card>
	<div class="face front">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<i class="fas fa-upload"></i>
		<div class="text">Upload photos <br /> from your device</div>
		<div class="button-container">
			<div class="button" (click)="navigate('photo/add/upload')">Upload Photos</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Upload Photos</div>
		<div class="help-text">Photonomy allows you to upload photos from your favorite device into a vault. Vaults are used to secure your photos and allow you to set beneficieries to ensure your photos last forever.</div>
	</div>
</div>