import { NgModule } from '@angular/core';
import { ArrayPipe } from './array.pipe';
import { BytesPipe } from './bytes.pipe';
import { FilterPipe } from './filter.pipe';
import { MinuteSecondsPipe } from './minuteSeconds.pipe';
import { SafePipe } from './safeHtml.pipe';
import { ValuePipe } from './value.pipe';

@NgModule({
	imports: [],
	declarations: [
		ArrayPipe,
		BytesPipe,
		FilterPipe,
		SafePipe,
		MinuteSecondsPipe,
		ValuePipe
	],
	exports: [
		ArrayPipe,
		BytesPipe,
		FilterPipe,
		SafePipe,
		MinuteSecondsPipe,
		ValuePipe
	]
})
export class PipesModule { }
