import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EventPhotoAdd } from '../models/event-photo-add.model';
import { EventPhotoRemove } from '../models/event-photo-remove.model';
import { PhotoUploadQueueNumber } from '../models/photo-upload-queue-number.model';
import { PhotoUpload } from '../models/photo-upload.model';

@Injectable()
export class LayoutService {
	public leftPanelExpandedChanged = new Subject();

	public privateModeEnabledChanged = new Subject();
	private privateModeEnabled: boolean = false;

	public lockDashboardClicked = new Subject();
	public unlockDashboardClicked = new Subject();
	public addWidgetClicked = new Subject();
	public resetWidgetsClicked = new Subject();

	public photoLeftArrowClicked = new Subject();
	public photoRightArrowClicked = new Subject();

	public showUserWelcomeMessageChanged = new Subject();

	// Photo Upload Vars
	public uploadPhoto = new Subject<PhotoUpload>();
	public uploadPhotoSuccess = new Subject<PhotoUpload>();
	public callNextUploadPhotoQueueNumber = new Subject<PhotoUploadQueueNumber>();

	// Event - Add Photo Vars
	public addEventPhoto = new Subject<EventPhotoAdd>();
	public addEventPhotoSuccess = new Subject<EventPhotoAdd>();
	public callNextAddEventPhotoQueueNumber = new Subject<number>();

	// Event - Remove Photo Vars
	public removeEventPhoto = new Subject<EventPhotoRemove>();
	public removeEventPhotoSuccess = new Subject<EventPhotoRemove>();
	public callNextRemoveEventPhotoQueueNumber = new Subject<number>();

	// Event - Delete Vars
	public deleteEvent = new Subject<Event>();

	constructor(
		private http: HttpClient
	) { }

	setLeftPanelExpandedChanged(value: boolean) {
		this.leftPanelExpandedChanged.next(value);
	}

	getPrivateModeEnabled() {
		return this.privateModeEnabled;
	}
	setPrivateModeEnabled(value: boolean) {
		this.privateModeEnabled = value;
		this.privateModeEnabledChanged.next(value);
	}

	announceLockDashboardClicked() {
		this.lockDashboardClicked.next(null);
	}

	announceUnlockDashboardClicked() {
		this.unlockDashboardClicked.next(null);
	}

	announceAddWidgetClicked(type: string) {
		this.addWidgetClicked.next(type);
	}

	announceResetWidgetsClicked() {
		this.resetWidgetsClicked.next(null);
	}

	announcePhotoLeftArrowClicked() {
		this.photoLeftArrowClicked.next(null);
	}
	announcePhotoRightArrowClicked() {
		this.photoRightArrowClicked.next(null);
	}

	announceShowUserWelcomeMessage(value: boolean) {
		this.showUserWelcomeMessageChanged.next(value);
	}

	/** Called when a photo is uploaded from the photo upload component. */
	announceUploadPhoto(photoUpload: PhotoUpload) {
		this.uploadPhoto.next(photoUpload);
	}

	announceUploadPhotoSuccess(photoUpload: PhotoUpload) {
		this.uploadPhotoSuccess.next(photoUpload);
	}

	/** Called by the app component when a new photo queue is ready, to communicate with the upload-photo child components. */
	announceNextUploadPhotoQueueNumber(queueNumber: PhotoUploadQueueNumber) {
		this.callNextUploadPhotoQueueNumber.next(queueNumber);
	}

	announceAddEventPhoto(eventPhoto: EventPhotoAdd) {
		this.addEventPhoto.next(eventPhoto);
	}

	announceAddEventPhotoSuccess(eventPhoto: EventPhotoAdd) {
		this.addEventPhotoSuccess.next(eventPhoto);
	}

	/** Called by the app component when a new photo queue is ready, to communicate with the add-event-photo child components. */
	announceNextAddEventPhotoQueueNumber(queueNumber: number) {
		this.callNextAddEventPhotoQueueNumber.next(queueNumber);
	}

	announceRemoveEventPhoto(eventPhoto: EventPhotoRemove) {
		this.removeEventPhoto.next(eventPhoto);
	}

	announceRemoveEventPhotoSuccess(eventPhoto: EventPhotoRemove) {
		this.removeEventPhotoSuccess.next(eventPhoto);
	}

	/** Called by the app component when a new photo queue is ready, to communicate with the remove-event-photo child components. */
	announceNextRemoveEventPhotoQueueNumber(queueNumber: number) {
		this.callNextRemoveEventPhotoQueueNumber.next(queueNumber);
	}

	announceDeleteEvent(event: Event) {
		this.deleteEvent.next(event);
	}
}
