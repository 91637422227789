import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';

@Component({
	selector: 'app-widget-connections-quick-links',
	templateUrl: './widget-connections-quick-links.component.html',
	styleUrls: ['./widget-connections-quick-links.component.css']
})
export class WidgetConnectionsQuickLinksComponent implements OnInit, OnDestroy {
	loadingCount = true;
	loadingConnections = true;

	connectionsCount = 0;
	userConnectionCountChangedSubscription: Subscription;

	connectionsPending: User[] = [];

	constructor(
		private router: Router,
		public authService: AuthService,
		private connectionService: UserConnectionService
	) { }

	ngOnInit() {
		this.subscribeToActiveConnectionCountChanged();

		this.retrieveConnectionsPending();
	}

	ngOnDestroy() { }

	subscribeToActiveConnectionCountChanged() {
		this.userConnectionCountChangedSubscription = this.connectionService.connectionsCountChanged
			.subscribe((value: any) => {
				this.connectionsCount = value;
				this.loadingCount = false;
			});
		this.retrieveConnectionsCount();
	}

	retrieveConnectionsCount() {
		this.loadingCount = true;
		this.connectionService.getUserConnectionCount().subscribe(
			response => {
				if (response.status === 200) {
					this.connectionService.setAndAnnounceUserConnectionCount(response.body);
					this.loadingCount = false;
				}
			}
		);
	}

	retrieveConnectionsPending() {
		this.loadingConnections = true;
		this.connectionService.getUserConnectionsPending().subscribe(
			response => {
				this.connectionsPending = response;
				this.loadingConnections = false;
			}
		);
	}

	/**
	 * APPROVE PENDING CONNECTION
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	approveConnectionPending(user: User) {
		this.loadingConnections = true;
		this.connectionService.approveUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					// Pending -1
					const itemPending = this.connectionsPending.find(d => d.id === user.id);
					this.connectionsPending.splice(this.connectionsPending.indexOf(itemPending), 1);

					this.loadingConnections = false;
				} else {
					this.loadingConnections = false;
				}
			},
			err => {
				this.loadingConnections = false;
			}
		);
	}

	/**
	 * REJECT CONNECTION
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	rejectConnectionPending(user: User) {
		this.loadingConnections = true;
		this.connectionService.rejectUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					// Pending -1
					const itemPending = this.connectionsPending.find(d => d.id === user.id);
					this.connectionsPending.splice(this.connectionsPending.indexOf(itemPending), 1);

					this.loadingConnections = false;
				} else {
					this.loadingConnections = false;
				}
			},
			err => {
				this.loadingConnections = false;
			}
		);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}
}
