<div class="photo-row">
	<img *ngIf="eventPhotoAdd.photo.imageSafeUrl" [src]="eventPhotoAdd.photo.imageSafeUrl" />
	<div class="details-container">
		<div class="event-name">
			Adding photo to the event
			<br /><strong>{{ eventPhotoAdd.photo.addToEvent?.name }}</strong>
		</div>
	</div>
	<div class="close-container" [ngClass]="{'adding': addingPhoto}">
		<i *ngIf="loading" class="fas fa-circle-notch fa-spin"></i>

		<i *ngIf="!loading && !error" class="fas fa-check" (click)="finish()"></i>
		<i *ngIf="!loading && error" class="fas fa-times"></i>
	</div>
</div>