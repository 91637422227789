import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-card-connection-copy-to-clipboard',
	templateUrl: './card-connection-copy-to-clipboard.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardConnectionCopyToClipboardComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;

	userUuid: string;
	userChangedSubscription: Subscription;

	constructor(
		private router: Router,
		private toastr: ToastrService,
		public userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				this.userUuid = user.uuid;
			});
		if (this.userService.users[0]) {
			this.userUuid = this.userService.users[0].uuid;
		}
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}

	copyToClipboard(item) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
		this.toastr.success('Successfully copied to clipboard');
	}
}
