<div class="background">
	<div class="center-graph" [ngClass]="{'loading in': loading }">
		<div class="pieContainer uploaded" *ngIf="!loading">
			<div class="pieBackground"></div>
			<div id="pieSlice1" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform ' + pieSliceUploaded1Seconds + 's linear .6s' , 'transform' : 'rotate(' + pieSliceUploaded1Degrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice1_filler" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform .5s linear .6s' , 'transform' : 'rotate(' + pieSliceUploadedFillerDegrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice2" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform ' + pieSliceUploaded2Seconds + 's linear 1.1s' , 'transform' : 'rotate(' + pieSliceUploaded2Degrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice2_filler" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform .5s linear 1.1s' , 'transform' : 'rotate(' + pieSliceUploadedFillerDegrees + 'deg)'}"></div>
			</div>
			<div class="innerCircle">
				<div class="content">{{ userPhotoCount?.myUploadedPhotos }}</div>
			</div>
		</div>
		<div class="text" *ngIf="!loading">My Uploaded Photos</div>
	</div>
</div>