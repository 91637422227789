import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Sync } from '../models/sync.model';

@Injectable()
export class SyncService {

	private syncInProgress = false;
	public syncInProgressChanged = new Subject();

	constructor(
		private http: HttpClient
	) { }

	getSyncs() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/syncs';

		return this.http.get<Array<Sync>>(requestUrl, { headers: headers, observe: 'response' });
	}

	enableSync(type: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/sync/enable';

		return this.http.put<Sync>(requestUrl, type, { headers: headers, observe: 'response' });
	}

	disableSync(type: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/sync/disable';

		return this.http.put<Sync>(requestUrl, type, { headers: headers, observe: 'response' });
	}

	getSyncInProgress() {
		return this.syncInProgress;
	}
	setSyncInProgress(status: boolean) {
		this.syncInProgress = status;
		this.syncInProgressChanged.next(status);
	}
}
