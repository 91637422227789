<div class="header">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div>
		<div class="last-updated">Last updated<br />January 23, 2022</div>
		<p>1. <a href="#introduction" *ngIf="showFooter">Introduction</a><span *ngIf="!showFooter">Introduction</span></p>
		<p>2. <a href="#info-collection" *ngIf="showFooter">Information We Collect About You</a><span *ngIf="!showFooter">Information We Collect About You</span></p>
		<p>3. <a href="#info-usage" *ngIf="showFooter">Use of Information Collected</a><span *ngIf="!showFooter">Use of Information Collected</span></p>
		<!--
		<p><a>4. Transfer of Information</a></p>
		<p><a>5. Security of Information</a></p>
		<p><a>6. Children under the age of 13</a></p>
		<p><a>7. Unsubscribe or Opt-Out</a></p>
		<p><a>8. How to Contact Us</a></p>
		-->
	</div>
	<div>
		<div class="title">Privacy Policy</div>
		<div class="heading" id="introduction">1. Introduction</div>

		<p>Photonomy values its users' privacy. This Privacy Policy ("Policy") will help you understand how we
			collect and use personal information from those who visit our website or make use of our online
			facilities and services, and what we will and will not do with the information we collect.</p>

		<p>This Policy applies to Photonomy properties, and it governs any and all data collection and usage
			by us. Through the use of www.photonomy.com, you are therefore consenting to the data collection
			procedures expressed in this Policy.</p>

		<p>Please note that this Policy does not govern the collection and use of information by companies
			that Photonomy does not control, nor by individuals not employed or managed by us. It is highly
			recommended and suggested that you review the privacy policies and statements of any website
			you choose to use or frequent to better understand the way in which websites garner, make use of
			and share the information collected.</p>

		<!--
		<p>Specifically, this Policy will inform you of the following</p>
		<p>1. What personally identifiable information is collected from you through our website;</p>
		<p>2. Why we collect personally identifiable information and the legal basis for such collection;</p>
		<p>3. How we use the collected information and with whom it may be shared;</p>
		<p>4. What choices are available to you regarding the use of your data; and</p>
		<p>5. The security procedures in place to protect the misuse of your information.</p>
		-->

		<br />
		<div class="heading" id="info-collection">2. Information We Collect About You</div>
		<p><u>Your profile data</u> - We collect and store profile data when you first register with the site,
			including your first name, last name, e-mail address, password, and date of birth.</p>

		<p><u>Data extracted from your photos</u> - When uploading a photo, we attempt to extract EXIF data,
			including Datetime, XResolution, YResolution, Camera Make, Camera Model, Lens Make, Lens Model, ISO,
			Shutter Speed, Aperature, Brightness, Digital Zoom, Flash, GPS Latitude, and GPS Longitude.</p>

		<p><u>Manual data about your photos</u> - We store the metadata you enter when uploading and classifying
			your photos, including a digital copy of the photo, people, datetime, locations, and keywords.</p>

		<br />
		<div class="heading" id="info-usage">3. Use of Information Collected</div>
		<p>Your profile data is used to identify you when interacting with other users.</p>
		<p>Your date of birth is used to validate you are over 13 years of age and will be used for a once a year
			communication on your birthdate.</p>
		<p>Your photo metadata is used to allow you to search your photos.</p>
		<p>Your data is not shared with anyone that you have not granted access to your photos.</p>

		<!--
		<br />
		<p><strong>Transfer of Information</strong></p>
		<p>...</p>

		<br />
		<p><strong>Security of Information</strong></p>
		<p>...</p>

		<br />
		<p><strong>Children under the age of 13</strong></p>
		<p>...</p>

		<br />
		<p><strong>Unsubscribe or Opt-Out</strong></p>
		<p>...</p>

		<br />
		<p><strong>How to Contact Us</strong></p>
		<p>...</p>
		-->
	</div>
</div>
<app-footer *ngIf="showFooter"></app-footer>