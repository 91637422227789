import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-user-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.css']
})
export class UserTimelineComponent implements OnInit {
    math = Math;

    error = false;
    loadingEvents = true;
    loadingEventsWait = true; // This is required for the events fade in animation, we delay turning this false after events are loaded
    loadingPhotos = false;

    events: Event[] = [];
    eventsDisplayedOnTimeline: Event[] = [];

    eventActive: Event;
    eventHover: Event;
    eventPhotos = [];

    birthDate: Date;

    year0;
    year1;
    year2;
    year3;
    year4;
    year5;
    year6;
    year7;
    year8;
    year9;

    constructor(
        private domSanitizer: DomSanitizer,
        private router: Router,
        private eventService: EventService,
        private photoService: PhotoService,
        private userService: UserService,
    ) { }

    // 1. Retrieve user
    // 2. Calculate timeline years
    // 3. Retrieve events
    // 4. Calculate timeline events
    // 5. 
    ngOnInit() {
        this.loadingEvents = true;

        if (this.userService.users[0]) {
            let _this = this;
            setTimeout(function () {
                _this.calculateTimelineYears(_this.userService.users[0]);
            }, 100);
        } else {
            this.userService.retrieveCurrentUser().subscribe(
                response => {
                    let user: User = response.body;
                    //this.userService.setUser(user); // I DONT THINK THIS IS NEEDED

                    this.calculateTimelineYears(user);
                },
                err => {
                    this.error = true;
                    this.loadingEvents = false;
                }
            );
        }
    }

    calculateTimelineYears(user) {
        // Birth year
        this.birthDate = user.birthDate;
        let birthYear = new Date(user.birthDate);
        this.year0 = birthYear.getFullYear();

        // Current year
        this.year9 = new Date().getFullYear();

        // Gap years
        let age = this.year9 - this.year0;
        let ageGap = age / 9;
        this.year1 = this.year0 + ageGap;
        this.year2 = this.year1 + ageGap;
        this.year3 = this.year2 + ageGap;
        this.year4 = this.year3 + ageGap;
        this.year5 = this.year4 + ageGap;
        this.year6 = this.year5 + ageGap;
        this.year7 = this.year6 + ageGap;
        this.year8 = this.year7 + ageGap;

        this.retrieveEvents();
    }

    retrieveEvents() {
        this.events = [];
        this.eventsDisplayedOnTimeline = [];

        if (this.eventService.events.length > 0) {
            this.events = this.eventService.events;

            this.calculateTimelineEvents();
        } else {
            this.eventService.getEvents().subscribe(
                response => {
                    if (response.status === 200) {
                        this.eventService.events = response.body;
                        this.events = this.eventService.events;

                        this.calculateTimelineEvents();
                    } else {
                        this.error = true;
                        this.loadingEvents = false;
                    }
                },
                err => {
                    this.error = true;
                    this.loadingEvents = false;
                }
            );
        }
    }

    calculateTimelineEvents() {
        for (let event of this.events) {
            if (event.addToOwnerTimeline && event.owner == this.userService.getLocalUserId(0)) {
                this.eventsDisplayedOnTimeline.push(event);
            }
        }
        this.loadingEvents = false;

        let _this = this;
        setTimeout(function () {
            _this.loadingEventsWait = false;
        }, 100);
    }

    hoverEvent(event) {
        if (!this.eventActive?.id) {
            this.eventHover = event;
        }
    }

    unhoverEvent(event) {
        this.eventHover = null;
    }

    selectEvent(event) {
        this.eventHover = null;
        if (this.eventActive?.id == event.id) {
            this.eventPhotos = [];
            this.eventActive = null;
        } else {
            this.eventActive = event;
            this.retrieveEventPhotos(0, event.shareCode1, event.shareCode2);
        }
    }

    retrieveEventPhotos(startPosition, shareCode1, shareCode2) {
        if (startPosition == 0) {
            this.eventPhotos = [];
        }
        this.loadingPhotos = true;

        //this.lastStartPosition = this.eventPhotosPointer;

        // Retrieve event photos
        this.eventService.retrieveEventPhotosByShareCode1AndShareCode2(startPosition, shareCode1, shareCode2).subscribe(
            response => {
                if (response.status === 200 && response.body.length > 0) {
                    let index = 0;
                    let photos = response.body;
                    let _this = this;

                    (function repeat() {
                        setTimeout(function () {
                            let photo = photos[index];

                            let token = _this.photoService.signedDownloadTokens.find(d => d.eventId === _this.eventActive.id.toString());
                            if (token) {
                                if (!photo.imageSafeUrl) {
                                    let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

                                    let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                    _this.eventPhotos.push(image);
                                } else {
                                    let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                    _this.eventPhotos.push(image);
                                }

                                _this.loadingPhotos = false;

                                index++;
                                if (index == photos.length) {
                                    console.log(_this.eventPhotos);
                                } else {
                                    repeat();
                                }
                            } else {
                                _this.photoService.getSignedDownloadToken(_this.eventActive.id.toString(), 'event').subscribe(
                                    response => {
                                        let responseToken = response.body;
                                        responseToken.eventId = _this.eventActive.id.toString();

                                        _this.photoService.signedDownloadTokens.push(responseToken);

                                        if (!photo.imageSafeUrl) {
                                            let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;

                                            let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                            _this.eventPhotos.push(image);
                                        } else {
                                            let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                            _this.eventPhotos.push(image);
                                        }

                                        _this.loadingPhotos = false;

                                        index++;
                                        if (index == photos.length) {
                                            console.log(_this.eventPhotos);
                                        } else {
                                            repeat();
                                        }
                                    }
                                );
                            }
                        }, 10);
                    })();
                } else {
                    this.loadingPhotos = false;
                }
            });
    }

    calculateLeftPosition(date: Date) {
        let years = this.year9 - this.year0;
        let leftPercent = (this.getAgeFromBirthdate(date) / years) * 100;
        return leftPercent;
    }

    getAgeFromBirthdate(date: Date) {
        const d1 = new Date(date);
        const d2 = new Date(this.birthDate);

        let age = d1.getFullYear() - d2.getFullYear();
        const m = d1.getMonth() - d2.getMonth();
        if (m < 0 || (m === 0 && d1.getDate() < d2.getDate())) {
            age--;
        }
        return age;
    }

    navigate(route: string) {
        this.router.navigate([route]);
    }
}
