<!-- {{ !photoCount }} - {{ loadingPhotos }} - {{ loadingConnections }} - {{ loadingUser }} - {{ loadingShowUserWelcomeMessage }} - {{ loadingShowUserInvitedWelcomeMessage }}
<br /> {{ showUserWelcomeMessage }} - {{ showUserInvitedMessage }} -->

<!-- <div class="row justify-content-center g-0" *ngIf="policiesVerified && (!photoCount || loadingPhotos || loadingConnections || loadingUser || loadingShowUserWelcomeMessage || loadingShowUserInvitedWelcomeMessage)">
	<div class="col-md-11 loading in">
		&nbsp;
	</div>
</div> -->

<!-- LOADING -->
<div class="row justify-content-center g-0" *ngIf="!policiesVerified || !welcomeMessageVerified">
	<div class="col-md-11 loading in">
		&nbsp;
	</div>
</div>

<!-- TERMS AND CONDITIONS MODAL -->
<ng-template #termsDialog>
	<div class="modal-body terms">
		<app-terms-of-service [showFooter]="false"></app-terms-of-service>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-success btn-md" (click)="acknowledgeTermsOfService()">I'm all set</button>
		<button type="button" class="btn btn-light btn-md" data-dismiss="modal" (click)="bypassTermsOfService()">Maybe next time</button>
	</div>
</ng-template>

<!-- PRIVACY POLICY MODAL -->
<ng-template #privacyDialog>
	<div class="modal-body terms">
		<app-privacy-policy [showFooter]="false"></app-privacy-policy>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-success btn-md" (click)="acknowledgePrivacyPolicy()">I'm all set</button>
		<button type="button" class="btn btn-light btn-md" data-dismiss="modal" (click)="bypassPrivacyPolicy()">Maybe next time</button>
	</div>
</ng-template>

<!-- EXPIRING PAYMENT METHOD DIALOG -->
<ng-template #expiringPaymentMethodDialog>
	<div class="modal-header credit-card">
		<h5 class="modal-title"><i class="far fa-credit-card"></i> Expiring Payment Methods</h5>
	</div>
	<div class="modal-body credit-card" [ngClass]="{'loading in': loading }">

		<div *ngIf="!displayManagePaymentMethods">
			<div class="warning">
				Your payment method is about to expire. <a (click)="showPaymentMethodDetails=true" *ngIf="!showPaymentMethodDetails">Learn more.</a>
			</div>
			<div class="warning" *ngIf="showPaymentMethodDetails">
				If your payment method expires, your photos will continue to be accessible only while you have existing funds. Once all funds are used, the photos will be deleted after 60 days unless a valid payment method is added.
			</div>

			<div class="data-row">
				<div>
					<i *ngIf="currentUser?.paymentMethodCcBrand == 'visa'" class="fab fa-cc-visa"></i>
					<i *ngIf="currentUser?.paymentMethodCcBrand == 'mastercard'" class=" fab fa-cc-mastercard"></i>
					<i *ngIf="currentUser?.paymentMethodCcBrand !== 'visa' && currentUser.paymentMethodCcBrand !== 'mastercard'" class="far fa-credit-card"></i>&nbsp;&nbsp;
					<span>**** **** **** {{ currentUser?.paymentMethodCcLastFour }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentUser?.paymentMethodCcExpirationMonth }}/{{ currentUser?.paymentMethodCcExpirationYear }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="navigateToManagePaymentMethod()" [disabled]="loading">Manage Payment Method</button> <button type="button" class="btn btn-success btn-md" (click)="bypassExpiringPaymentMethod()" [disabled]="loading">I'm finished</button>
	</div>
</ng-template>

<!-- NEW USER PANELS WITHOUT INVITATION -->
<div class="scroll" *ngIf="policiesVerified && welcomeMessageVerified && showUserWelcomeMessage && !userWasInvited">
	<div class="welcome-title">
		<div class="title">Welcome to Photonomy</div>
	</div>
	<div class="welcome-message">
		You do not have any photos or connections added to your account.<br />
		To get started, send a connection request or add a payment method below...
	</div>
	<div class="welcome-content">
		<div class="column">
			<div class="icon-cell one">
				<i class="fas fa-users"></i>
			</div>
			<div class="border-cell one">
				<div class="content-cell">
					<div class="content-title">User Connections</div>
					<div class="content-text">
						<p>Create connections with your friends and family to view and help categorize each others photos.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigate('connections')">Create a Connection</div>
					</div>
				</div>
			</div>
		</div>
		<div class="column">
			<div class="icon-cell two">
				<i class="fas fa-images"></i>
			</div>
			<div class="border-cell two">
				<div class="content-cell">
					<div class="content-title">Upload Photos</div>
					<div class="content-text">
						<p *ngIf="!currentUser?.paymentMethodId">Add a payment method to start uploading your photos. Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
						<p *ngIf="currentUser?.paymentMethodId">You have a valid payment method associated with your account. Start uploading photos to start your Photonomy journey.</p>
					</div>
					<div class="button-cell">
						<div *ngIf="!currentUser?.paymentMethodId" class="button" (click)="navigateToManagePaymentMethod()">Add a Payment Method</div>
						<div *ngIf="currentUser?.paymentMethodId" class="button" (click)="navigate('photo/add/upload')">Upload Photos</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- NEW USER PANELS WITH INVITATION -->
<div class="scroll" *ngIf="policiesVerified && welcomeMessageVerified && showUserWelcomeMessage && userWasInvited">
	<div class="welcome-title">
		<div class="title">Welcome to Photonomy</div>
	</div>
	<div class="welcome-message">
		You do not have any photos or connections added to your account.<br />
		To get started, send a connection request or add a payment method to start uploading photos...
	</div>
	<div class="welcome-content invited">
		<div class="column">
			<div class="icon-cell zero">
				<div *ngIf="!invitedUser?.imageSafeUrl" class="initials">{{ invitedUser.initials }}</div>
				<img *ngIf="invitedUser?.imageSafeUrl" [src]="invitedUser.imageSafeUrl" class="image" />
			</div>
			<div class="border-cell zero">
				<div class="content-cell">
					<div class="content-title">You were invited!</div>
					<div class="content-text">
						<p><strong>{{ invitedUser?.firstName }} {{ invitedUser?.lastName }}</strong> invited you to Photonomy!
							Would you like to accept their connection request?</p>
					</div>
					<div class="button-cell">
						<div class="button margin-right accept" (click)="acceptInvitation()">Accept</div>
						<div class="button margin-left reject" (click)="rejectInvitation()">Reject</div>
					</div>
				</div>
			</div>
		</div>
		<div class="column">
			<div class="icon-cell one">
				<i class="fas fa-users"></i>
			</div>
			<div class="border-cell one">
				<div class="content-cell">
					<div class="content-title">User Connections</div>
					<div class="content-text">
						<p>Create connections with your friends and family to view and help categorize each others photos.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigate('connections')">Create a Connection</div>
					</div>
				</div>
			</div>
		</div>
		<div class="column">
			<div class="icon-cell two">
				<i class="fas fa-images"></i>
			</div>
			<div class="border-cell two">
				<div class="content-cell">
					<div class="content-title">Upload Photos</div>
					<div class="content-text">
						<p *ngIf="!currentUser?.paymentMethodId">Add a payment method to start uploading your photos. Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
						<p *ngIf="currentUser?.paymentMethodId">You have a valid payment method associated with your account. Start uploading photos to start your Photonomy journey.</p>
					</div>
					<div class="button-cell">
						<div *ngIf="!currentUser?.paymentMethodId" class="button" (click)="navigateToManagePaymentMethod()">Add a Payment Method</div>
						<div *ngIf="currentUser?.paymentMethodId" class="button" (click)="navigate('photo/add/upload')">Upload Photos</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- DASHBOARD -->
<div class="flex" *ngIf="policiesVerified && welcomeMessageVerified && !showUserWelcomeMessage">
	<div class="dashboard-container" [ngClass]="{'loading in': loading }">
		<gridster #gridster [options]="gridsterOptions" [ngClass]="{'edit': dashboardAction == 'edit' }">
			<gridster-item *ngFor="let item of currentUser?.widgets" [item]="item">
				<app-dashboard-widget [pageAction]="dashboardAction" [widget]="item" (widgetDeleted)="deleteWidget($event)"></app-dashboard-widget>
			</gridster-item>
		</gridster>
	</div>
</div>