import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-blog-07',
	templateUrl: './blog-07.component.html',
	styleUrls: ['./../blog.component.css']
})
export class Blog07Component implements OnInit {
	loading = true;

	constructor(
		private route: ActivatedRoute,
		public router: Router
	) { }

	ngOnInit() {
		/**
		this.route.queryParams.subscribe(params => {
			if (params['footer']) {
				const vaultId = params['vaultId'];
				
			}
		}); */
		this.loading = false;
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
