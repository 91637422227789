<div class="outer">
    <div class="center-container">
        <!-- Logo - Always visible -->
        <div class="logo-container"><img src="/assets/images/logo-forgotpassword.png" /></div>

        <!-- Loading Indicator -->
        <div *ngIf="loading">
            <div class="loading in loading-container">&nbsp;</div>
        </div>

        <!-- Reset Password Form -->
        <div *ngIf="!loading && !resetEmailSuccess">
            <div class="row">
                <div class="col-md-4 offset-md-4 form-container">
                    <form #f="ngForm" [formGroup]="formResetEmail" class="form" *ngIf="!resetEmailSuccess">
                        <div class="form-group">
                            <label>E-mail Address</label>
                            <i class="fas fa-envelope"></i>
                            <input type="text" id="email" formControlName="email" class="form-control" placeholder="E-mail address">
                        </div>
                        <button class="btn btn-success submit register" type="button" (click)="resetEmailSubmit()" [disabled]="!f.valid">Send Reset Email</button>
                        <button class="btn btn-success navigate" (click)="navigate('login')">Cancel</button>
                    </form>
                </div>
            </div>
        </div>

        <!-- Post Form Submit Message -->
        <div *ngIf="!loading && resetEmailSuccess">
            <div class="message">{{ message }}</div>
            <div class="row">
                <div class="col-md-4 offset-md-4 form-container"><button class="btn btn-success navigate" (click)="navigate('login')">Back to Log In</button></div>
            </div>
        </div>

    </div>
</div>