<div class="card opposite-colors" #card>
	<div class="face front">
		<!--
		<i class="fas fa-question" (click)="flip()"></i>
		-->
		<div class="flex-container">
			<div class="title-container">
				<div class="title">My<br />Connections</div>
			</div>
			<div class="dropdown-container">
				<form #f="ngForm" [formGroup]="form" class="form">
					<ng-select #filterSelect class="typeahead" appendTo="body" [items]="filters" [clearable]="false" [multiple]="false" bindLabel="name" bindValue="id" id="filter" formControlName="filter" (change)="filterChanged()"></ng-select>
				</form>
				<div><a href="javascript:void(0)" (click)="selectAddFilter()">Add a Connection</a></div>
			</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">My Connections</div>
		<div class="help-text">...</div>
	</div>
</div>