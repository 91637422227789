<div class="photo-row">
	<img *ngIf="eventPhotoRemove.photo.imageSafeUrl" [src]="eventPhotoRemove.photo.imageSafeUrl" />
	<div class="details-container">
		<div class="event-name">
			Removing photo from the event
			<br /><strong>{{ eventPhotoRemove.photo.addToEvent?.name }}</strong>
		</div>
	</div>
	<div class="close-container" [ngClass]="{'removing': removingPhoto}">
		<i *ngIf="loading" class="fas fa-circle-notch fa-spin"></i>

		<i *ngIf="!loading && !error" class="fas fa-check" (click)="finish()"></i>
		<i *ngIf="!loading && error" class="fas fa-times"></i>
	</div>
</div>