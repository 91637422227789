import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FacebookService } from 'src/app/services/facebook.service';
import { PhotoService } from 'src/app/services/photo.service';
import { AuthService } from '../../../../../services/auth.service';
import { UserService } from '../../../../../services/user.service';

@Component({
	selector: 'app-uncategorized-preview-photo-gallery',
	templateUrl: './uncategorized-preview.component.html',
	styleUrls: ['./uncategorized-preview.component.css']
})
export class UncategorizedPreviewPhotoGalleryComponent implements OnInit {
	//@Input() galleryId: string;
	//@ViewChild('imageViewDialog', { static: true }) imageViewDialog: TemplateRef<any>;

	@ViewChild('slider') slider: NgImageSliderComponent;

	loadingFirst = true;
	loadingLast = true;

	images: Object[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private facebookService: FacebookService,
		private formBuilder: UntypedFormBuilder,
		private modalService: BsModalService,
		private photoService: PhotoService,
		private userService: UserService,
		private domSanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.retrieveUncategorizedPhotos();
	}

	/**
	 * RETRIEVE UNCATEGORIZED PHOTOS
	 * 
	 * 1. Retrieve uncategorized photos.
	 * 2. Loop photos on a timer so they process in succession.
	 * 3. If there is an imageUrl, check if the image exists. Do this because Facebook cache URLs expire.
	 * 4. If the image exists, display the photo.
	 * 5. If the image does not exist, retrieve the photo from Facebook, save the new URL, and dislay the image.
	 * 6. If there is not an imageUrl, check the type of photo.
	 * 7. If facebook, load the image from Facebook, save the new URL, and dislay the image.
	 * 8. If upload, retrieve and dislay the image.
	 */
	retrieveUncategorizedPhotos() {
		this.photoService.getUncategorizedPhotosRandom().subscribe(
			response => {
				let index = 0;
				let photos = response.body;
				let _this = this;

				if (photos.length > 0) {
					(function repeat() {
						setTimeout(function () {
							let photo = photos[index];

							let token = _this.photoService.signedDownloadTokens.find(d => d.userId === photo.userId.toString());
							if (token) {
								if (!photo.imageSafeUrl) {
									let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

									let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
									_this.images.push(image);
								} else {
									let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
									_this.images.push(image);
								}
							} else {
								// TODO: Should I allow public photos?
								let type = 'userPublic';
								if (photo.connectionsCanView == false) {
									type = 'userPrivate';
								}
								_this.photoService.getSignedDownloadToken(photo.userId.toString(), type).subscribe(
									response => {
										if (!photo.imageSafeUrl) {
											let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;

											let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
											_this.images.push(image);
										} else {
											let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
											_this.images.push(image);
										}
									}
								);
							}

							index++;
							_this.loadingFirst = false;
							if (index == photos.length) {
								_this.loadingLast = false;
							} else {
								repeat();
							}

							/** LEGACY FACEBOOK CODE
							// TODO: Check if the image size from the URL is the same before loading the image from facebook or database.
							if (photo.imageUrl) {
								// Check if the imageUrl is still valid or if the cached url has expired.
								_this.imageExists(photo.imageUrl, function (exists) {
									if (exists) {
										let image = { image: photo.imageUrl, thumbImage: photo.imageUrl };
										_this.images.push(image);

										// TODO: For whatever reason ng-image-slider does not display 0 index photos.
										//       Remove whenever this is fixed.
										if (index == 0) {
											_this.images.push(image);
										}

										// First loop stop the page load indicator, last loop stop the uncategorized load indicator
										index++;
										_this.loadingFirst = false;
										if (index == photos.length) {
											_this.loadingLast = false;
										} else {
											repeat();
										}

									} else if (photo.type == 'facebook') {
										// Photo URL is no longer valid, retrieve the new URL.
										// TODO: Move the authentication into the service?
										_this.facebookService.authenticate(function (model) {
											_this.facebookService.setToken(model.authResponse.accessToken);
											_this.facebookService.setUserId(model.authResponse.userID);

											_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
												// TODO: Check to see if this photo status is pending?
												let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
												_this.images.push(image);

												// TODO: For whatever reason ng-image-slider does not display 0 index photos.
												//       Remove whenever this is fixed.
												if (index == 0) {
													_this.images.push(image);
												}

												// Update the URL in the database.
												photo.imageUrl = response2.images[0].source;
												_this.photoService.updatePhoto(photo).subscribe(
													response3 => {
														// Do nothing.  The photo has been updated.
													});

												// First loop stop the page load indicator, last loop stop the uncategorized load indicator
												index++;
												_this.loadingFirst = false;
												if (index == photos.length) {
													_this.loadingLast = false;
												} else {
													repeat();
												}
											});
										});
									}
								});
							} else if (photo.type == 'facebook') {
								_this.facebookService.authenticate(function (model) {
									_this.facebookService.setToken(model.authResponse.accessToken);
									_this.facebookService.setUserId(model.authResponse.userID);

									_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
										let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
										_this.images.push(image);

										// TODO: For whatever reason ng-image-slider does not display 0 index photos.
										//       Remove whenever this is fixed.
										if (index == 0) {
											_this.images.push(image);
										}

										// Update the URL in the database.
										photo.imageUrl = response2.images[0].source;
										_this.photoService.updatePhoto(photo).subscribe(
											response3 => {
												// Do nothing.  The photo has been updated.
											});

										// First loop stop the page load indicator, last loop stop the uncategorized load indicator
										index++;
										_this.loadingFirst = false;
										if (index == photos.length) {
											_this.loadingLast = false;
										} else {
											repeat();
										}
									});
								});
							} else if (photo.type == 'upload') {

								_this.photoService.getPhotoImage(photo.externalIdMed).subscribe(
									response => {
										var reader = new FileReader();
										reader.readAsDataURL(response.body);
										reader.onloadend = function () {
											var base64data = reader.result;
											let image = { image: base64data, thumbImage: base64data };
											_this.images.push(image);

											// TODO: For whatever reason ng-image-slider does not display 0 index photos.
											//       Remove whenever this is fixed.
											if (index == 0) {
												_this.images.push(image);
											}

											// First loop stop the page load indicator, last loop stop the uncategorized load indicator
											index++;
											_this.loadingFirst = false;
											if (index == photos.length) {
												_this.loadingLast = false;
											} else {
												repeat();
											}
										}
									},
									error => {
										// TODO: Error handling
										console.log('An error occurred:');
										console.log(error);
									});

							} */
							//}
						}, 200);
					})();
				} else { // No Photos
					this.loadingFirst = false;
					this.loadingLast = false;
				}
			});
	}

	imageExists(url, callback) {
		var img = new Image();
		img.onload = function () { callback(true); };
		img.onerror = function () { callback(false); };
		img.src = url;
	}

	prevImageClick(event) {
		event.stopPropagation();
		this.slider.prev();
	}

	nextImageClick(event) {
		event.stopPropagation();
		this.slider.next();
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
