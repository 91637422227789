import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InstagramService } from 'src/app/services/instagram.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';
import { SyncService } from 'src/app/services/sync.service';

declare const FB: any;

@Component({
	selector: 'app-photos-add-instagram',
	templateUrl: './instagram.component.html',
	styleUrls: ['./instagram.component.css']
})
export class PhotosAddInstagramComponent implements OnInit, OnDestroy {
	@ViewChild('confirmSyncAllDialog', { static: true }) confirmSyncAllDialog: TemplateRef<any>;
	modalRef: BsModalRef;

	loading = true;
	showHelp = false;
	uploadMessage = "";

	albums: any[] = [];
	photos: any[] = [];

	totalPhotos: number = 0;
	totalPhotosProcessed: number = 0;

	formSingle: UntypedFormGroup;

	constructor(
		private router: Router,
		private instagramService: InstagramService,
		private layoutService: LayoutService,
		private modalService: BsModalService,
		private photoService: PhotoService,
		private syncService: SyncService
	) { }

	ngOnInit() {
		this.loadInstagramPhotos();

		// TODO: Get this from cache.
		//this.loadFacebookAlbums();
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	loadInstagramPhotos() {
		let _this = this;
		// TODO: We shouldn't have to authenticate every time.
		this.instagramService.authenticate(function (model) {
			_this.instagramService.setToken(model.authResponse.accessToken);
			_this.instagramService.setUserId(model.authResponse.userID);

			// TODO: Cache this.
			_this.instagramService.getUser().subscribe(
				response => {
					console.log(response);
				}
			);
		});
	}


	/**
	 * LOAD FACEBOOK ALBUMS
	 
	loadFacebookAlbums() {
	  let _this = this;
	  // TODO: We shouldn't have to authenticate every time.
	  this.facebookService.authenticate(function(model) {
		_this.facebookService.setToken(model.authResponse.accessToken);
		_this.facebookService.setUserId(model.authResponse.userID);
  
		// TODO: Cache this.
		_this.facebookService.retrieveAllAlbums(function(response) {
		  if (response.data) {
			for (const album of response.data) {
			  _this.totalPhotos += album.count;
			  _this.albums.push(album);
			}
			_this.loading = false;
		  } else {
			// TODO: Error occurred.
			_this.loading = false;
		  }
		});
	  }); 
	}*/

	/**
	 * SYNC ALL
	 * 1. Open the confirm sync modal dialog
	 */
	syncAll() {
		this.openModal(this.confirmSyncAllDialog);
	}

	/**
	 * SYNC ALL CONFIRM
	 * 1. Enable sync in progress on the UI
	 * 2. Enable the sync within the database
	 * 3. Check to see if the sync should be stopped (the user pressed the cancel button or we are out of albums/photos to sync)
	 * 4. Loop the albums - TODO: Handle paging
	 * 5. Send array of photos to see if they already exist - TODO: Handle paging
	 * 6. Update the UI with the amount of photos added
	 * TODO: This logic shouldnt even belong here.  It should be within the main component where the "Sync active" is located
	 *       This is so we can manage the sync globally so it doesn't matter where you refresh the page from it will always restart
    
	syncAllConfirm() {
	  this.syncService.setSyncInProgress(true);
  
	  this.syncService.enableSync('facebook').subscribe(
		response => {       
		  let i = 0;
		  let _this = this;
		  (function repeat(pagingNextUrl) {
			if (i > _this.albums.length-1 || !_this.syncService.getSyncInProgress()) {
			  _this.syncService.setSyncInProgress(false);
			  return;
			}
  
			setTimeout(function() {
  
			  if (pagingNextUrl && pagingNextUrl.length > 0) { // paging, load next page
				_this.facebookService.retrieveAlbumPhotosNextPage(pagingNextUrl, function(response) {
				  // TODO: Does this need an else if?  What happens when albums don't load?
				  if (response.data) {
					let photos:Photo[] = [];
					for (const fbPhoto of response.data) {
					  let photo: Photo = new Photo();
					  photo.type = 'facebook';
					  photo.externalId = fbPhoto.id;
					  photo.externalUrl = fbPhoto.link;
					  photo.imageUrl = fbPhoto.images[0].source;
					  photos.push(photo);
					}
    
					_this.photoService.addPhotos(photos).subscribe(
					  addPhotoResponse => {
						let userPhotoCount = _this.layoutService.getUserPhotoCount();
						userPhotoCount = userPhotoCount + addPhotoResponse.body;
						_this.layoutService.setAndAnnounceUserPhotoCount(userPhotoCount);
    
						if (response.paging && response.paging.next) {
						  repeat(response.paging.next);
						} else {
						  // No paging, go to next 
						  i++;
						  repeat('');
						}
					  }
					);
				  }
				});
			  } else { // No paging, load next album
				_this.facebookService.retrieveAlbumPhotos(_this.albums[i].id, function(response) {
				  // TODO: Does this need an else if?  What happens when albums don't load?
				  if (response.data) {
					let photos:Photo[] = [];
					for (const fbPhoto of response.data) {
					  let photo: Photo = new Photo();
					  photo.type = 'facebook';
					  photo.externalId = fbPhoto.id;
					  photo.externalUrl = fbPhoto.link;
					  photo.imageUrl = fbPhoto.images[0].source;
					  photos.push(photo);
					}
    
					_this.photoService.addPhotos(photos).subscribe(
					  addPhotoResponse => {
						let userPhotoCount = _this.layoutService.getUserPhotoCount();
						userPhotoCount = userPhotoCount + addPhotoResponse.body;
						_this.layoutService.setAndAnnounceUserPhotoCount(userPhotoCount);
    
						if (response.paging && response.paging.next) {
						  repeat(response.paging.next);
						} else {
						  // No paging, go to next 
						  i++;
						  repeat('');
						}
					  }
					);
				  }
				});
			  }
			}, 5000);
		  })();
		    
		  this.modalRef.hide();
		},
		err => {
		  this.syncService.setSyncInProgress(false);
		}
	  );
	}
   */

	/**
	 * NAVIGATE
	 * @param path
	 */
	navigate(path) {
		this.router.navigate([path]);
	}

	/**
	 * OPEN MODAL
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'modal-xl' })
		);
	}
}
