<div *ngIf="photoDeleted" class="photo-message-container">
	<div>The photo has been deleted</div>
</div>
<div class="modal-body" [ngClass]="{'loading in': loading}">
	<form #s="ngForm" [formGroup]="classifyForm" class="form">

		<div class="left-container">
			<div class="photo-container">
				<img class="main 2" *ngIf="photo?.imageSafeUrl" [src]="photo?.imageSafeUrl" />
			</div>
			<div class="description-container">
				<div *ngIf="editMode && photo?.userId == userService.getLocalUserId(0)">
					<textarea name="description" value="{{ photo?.description }}" id="description" formControlName="description" class="multiline-text-field" placeholder="Enter a description" rows="4"></textarea>
				</div>
				<div *ngIf="!editMode || photo?.userId !== userService.getLocalUserId(0)">
					{{ photo?.description }}
				</div>
			</div>
			<div *ngIf="deleteConfirm" class="delete-container">
				<div>Delete this photo?</div>
				<div><button class="delete yes" (click)="deletePhotoConfirm()">Yes</button> <button class="delete no" (click)="deleteConfirm=false">No</button></div>
			</div>
		</div>

		<div class="right-container">
			<div>
				<tabset>
					<tab [active]="tab == 'discussTab'" heading="Discuss" id="discussTab" (selectTab)="tabSelect('discussTab')">
						<div class="form-group even comments-container">
							<ng-container *ngIf="photo?.connectionsCanDiscuss || photo?.userId == userService.getLocalUserId(0)">
								<div *ngIf="photoComments.length == 0 && !photo.connectionsCanDiscuss" class="first-comment">
									Discussion is not enabled.
								</div>
								<div *ngIf="photoComments.length == 0 && photo.connectionsCanDiscuss" class="first-comment">
									Be the first to comment.
								</div>
								<ng-container *ngIf="photoComments.length > 0">
									<ng-container *ngFor="let photoComment of photoComments">
										<app-photo-comment [photoComment]="photoComment" [showReplies]="false" [allowReplies]="photo.connectionsCanDiscuss && !photo.lockDiscussion"></app-photo-comment>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!photo.connectionsCanDiscuss && photo.userId !== userService.getLocalUserId(0)">
								<div class="first-comment">
									Discussion is not enabled.
								</div>
							</ng-container>
						</div>
					</tab>
					<tab [active]="tab == 'classifyTab'" heading="Classify" id="classifyTab" (selectTab)="tabSelect('classifyTab')">
						<!-- People -->
						<div class="form-group even view" *ngIf="photo">
							<label for="people">Who is in the photo?</label>
							<div *ngIf="!editMode && photo" class="typeahead" [ngClass]="{'loading in': loadingPeople}">
								<ng-container *ngIf="!loadingPeople">
									<ng-container *ngFor="let person of photoPeople">
										<div *ngIf="person.type == 'photo' || person.type == 'suggestion'" class="ng-value">
											<div class="{{ person.type }} {{ person.action }}">
												{{ person.label }}
												<span *ngIf="person.type == 'suggestion' && person.action == 'add'">(Add)</span>
												<span *ngIf="person.type == 'suggestion' && person.action == 'remove'">(Remove)</span>
												<span *ngIf="person.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)">
													<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(person, 'person')"></i>
													<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(person, 'person')"></i>
												</span>
												<span *ngIf="person.type == 'suggestion' && person.userId == userService.getLocalUserId(0)">
													<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(person, 'person')"></i>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<div *ngIf="selectedPeopleLength === 0" class="ng-value none">
									<div>No data entered</div>
								</div>
							</div>
							<ng-select #peopleSelect [ngClass]="{'hidden': !editMode}" class="typeahead" [items]="photoPeople" [clearable]="photo.userId == userService.getLocalUserId(0)" [multiple]="true" bindLabel="label" bindValue="label" id="people" formControlName="people" (change)="peopleChange($event)" (keyup)="peopleKeyup()" (blur)="peopleBlur($event)" (remove)="peopleUnselect($event)">
								<ng-template ng-label-tmp let-item="item">
									<div class="{{ item.type }}">
										<span *ngIf="item.type !== 'suggestion-new' && item.type !== 'suggestion-remove' && item.type !== 'suggestion'" class="ng-value-icon left" (click)="peopleUnselect(item);">×</span>
										<span class="ng-value-label">
											{{ item.label }}
											<span *ngIf="item.type == 'suggestion-new' || (item.type == 'suggestion' && item.action == 'add')">(Add)</span>
											<span *ngIf="item.type == 'suggestion-remove' || (item.type == 'suggestion' && item.action == 'remove')">(Remove)</span>
											<span *ngIf="(item.type == 'suggestion-new' || item.type == 'suggestion-remove') && item.userId == userService.getLocalUserId(0)">
												<i class="fas fa-undo" title="Cancel suggestion" (click)="peopleUnselect(item);"></i>
											</span>
										</span>
									</div>
								</ng-template>
							</ng-select>
						</div>

						<!-- Date -->
						<div class="form-group even view">
							<label for="date">When was this photo taken?</label>
							<input *ngIf="(!editMode || photo.userId !== userService.getLocalUserId(0) || photo.capturedDateAuto) && photo && photo?.capturedDate" class="form-control date" [value]="photo?.capturedDate | date:'longDate'" disabled>
							<div *ngIf="(!editMode || photo.userId !== userService.getLocalUserId(0)) && photo && !photo?.capturedDate" class="typeahead">
								<div class="ng-value none">
									<div>No data entered</div>
								</div>
							</div>
							<input bsDatepicker [ngClass]="{'hidden': !editMode || photo.userId !== userService.getLocalUserId(0) || photo.capturedDateAuto}" type="text" [value]="photo?.capturedDate | date:'longDate'" id="capturedDate" formControlName="capturedDate" class="form-control date" placement="top middle" [maxDate]="maxDate" autocomplete="off">
						</div>

						<!-- Locations -->
						<div class="form-group odd view" *ngIf="photo">
							<label for="locations">Where was this photo taken?</label>
							<!-- VIEW MODE -->
							<div *ngIf="!editMode && photo" class="typeahead" [ngClass]="{'loading in': loadingLocations}">
								<ng-container *ngIf="!loadingLocations">
									<div *ngFor="let location of photoLocations" class="ng-value">
										<div class="{{ location.type }} {{ location.action }}">
											<i class="fas fa-external-link-alt"></i>
											<a href="https://www.google.com/maps/search/{{ location.label }}" target="_blank">{{ location.label }}</a>
											&nbsp;
											<span *ngIf="location.type == 'suggestion' && location.action == 'add'">(Add)</span>
											<span *ngIf="location.type == 'suggestion' && location.action == 'remove'">(Remove)</span>
											<span *ngIf="location.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)">
												<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(location, 'location')"></i>
												<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(location, 'location')"></i>
											</span>
											<span *ngIf="location.type == 'suggestion' && location.userId == userService.getLocalUserId(0)">
												<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(location, 'location')"></i>
											</span>
										</div>
									</div>
								</ng-container>
								<div *ngIf="selectedLocationsLength === 0" class="ng-value none">
									<div>No data entered</div>
								</div>
							</div>
							<!-- EDIT MODE -->
							<input [ngClass]="{'hidden': !editMode || selectedLocation }" type="text" id="location" formControlName="location" class="form-control" (keyup)="locationKeyup()">
							<div class="location-suggestion-position-container">
								<div class="location-suggestion-container" *ngIf="userLocationSuggestions.length > 0">
									<div *ngFor="let location of userLocationSuggestions" (click)="selectLocation(location)">
										{{ location.label }}
									</div>
									<div (click)="loadMoreSuggestions()">Load more results...</div>
								</div>
								<div class="location-suggestion-container" *ngIf="locationSuggestions.length > 0 || (classifyForm.value.location && classifyForm.value.location.length > 0 && locationShowSuggestions)">
									<div *ngFor="let suggestion of locationSuggestions" (click)="selectLocationSuggestion(suggestion.title)">
										{{ suggestion.title }}
									</div>
									<div (click)="addSuggestion()" *ngIf="(classifyForm.value.location && classifyForm.value.location.length > 0)">Add '{{ classifyForm.value.location }}'...</div>
								</div>
							</div>


							<div *ngIf="editMode && selectedLocation" class="typeahead">
								<div class="ng-value">
									<div class="{{ selectedLocation.type }}">
										<span *ngIf="photo.userId == userService.getLocalUserId(0) && selectedLocation.type !== 'suggestion-new' && selectedLocation.type !== 'suggestion-remove' && selectedLocation.type !== 'suggestion'" class="ng-value-icon left clear" (click)="clearLocation()">×</span>
										<span class="ng-value-label">
											{{ selectedLocation.label }}
											<span *ngIf="selectedLocation.type == 'suggestion-new' || (selectedLocation.type == 'suggestion' && selectedLocation.action == 'add')">(Add)</span>
											<span *ngIf="selectedLocation.type == 'suggestion-remove' || (selectedLocation.type == 'suggestion' && selectedLocation.action == 'remove')">(Remove)</span>
											<span *ngIf="(selectedLocation.type == 'suggestion-new' || selectedLocation.type == 'suggestion-remove') && selectedLocation.userId == userService.getLocalUserId(0)">
												<i class="fas fa-undo" title="Cancel suggestion" (click)="locationsUnselect(selectedLocation);"></i>
											</span>
										</span>
									</div>
								</div>
							</div>


							<!-- <div *ngIf="editMode && selectedLocation !== ''" class="location-selected">{{ selectedLocation }} (<a (click)="clearLocation()" href="javascript:void(0)">clear location</a>)</div> -->



							<!-- Edit MODE
							<ng-select #locationsSelect [ngClass]="{'hidden': !editMode}" class="typeahead" [items]="photoLocations" [clearable]="photo.userId == userService.getLocalUserId(0)" [multiple]="true" bindLabel="label" bindValue="label" id="locations" formControlName="locations" (change)="locationsChange($event)" (keyup)="locationsKeyup()" (blur)="locationsBlur($event)" (remove)="locationsUnselect($event)">
								<ng-template ng-label-tmp let-item="item">
									<div class="{{ item.type }}">
										<span *ngIf="item.type !== 'suggestion-new' && item.type !== 'suggestion-remove' && item.type !== 'suggestion'" class="ng-value-icon left" (click)="locationsUnselect(item);">×</span>
										<span class="ng-value-label">
											{{ item.label }}
											<span *ngIf="item.type == 'suggestion-new' || (item.type == 'suggestion' && item.action == 'add')">(Add)</span>
											<span *ngIf="item.type == 'suggestion-remove' || (item.type == 'suggestion' && item.action == 'remove')">(Remove)</span>
											<span *ngIf="(item.type == 'suggestion-new' || item.type == 'suggestion-remove') && item.userId == userService.getLocalUserId(0)">
												<i class="fas fa-undo" title="Cancel suggestion" (click)="locationsUnselect(item);"></i>
											</span>
										</span>
									</div>
								</ng-template>
							</ng-select> -->
						</div>

						<!-- Keywords -->
						<div class="form-group even view" *ngIf="photo">
							<label for="keywords">Photo keywords:</label>
							<div *ngIf="!editMode && photo" class="typeahead" [ngClass]="{'loading in': loadingKeywords}">
								<ng-container *ngIf="!loadingKeywords">
									<ng-container *ngFor="let keyword of photoKeywords">
										<div *ngIf="keyword.type == 'photo' || keyword.type == 'suggestion'" class="ng-value">
											<div class="{{ keyword.type }} {{ keyword.action }}">
												{{ keyword.label }}
												<span *ngIf="keyword.type == 'suggestion' && keyword.action == 'add'">(Add)</span>
												<span *ngIf="keyword.type == 'suggestion' && keyword.action == 'remove'">(Remove)</span>
												<span *ngIf="keyword.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)">
													<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(keyword, 'keyword')"></i>
													<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(keyword, 'keyword')"></i>
												</span>
												<span *ngIf="keyword.type == 'suggestion' && keyword.userId == userService.getLocalUserId(0)">
													<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(keyword, 'keyword')"></i>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<div *ngIf="selectedKeywordsLength === 0" class="ng-value none">
									<div>No data entered</div>
								</div>
							</div>
							<ng-select #keywordsSelect [ngClass]="{'hidden': !editMode}" class="typeahead" [items]="photoKeywords" [clearable]="photo.userId == userService.getLocalUserId(0)" [multiple]="true" bindLabel="label" bindValue="label" id="keywords" formControlName="keywords" (change)="keywordsChange($event)" (keyup)="keywordsKeyup()" (blur)="keywordsBlur($event)" (remove)="keywordsUnselect($event)">
								<ng-template ng-label-tmp let-item="item">
									<div class="{{ item.type }}">
										<span *ngIf="item.type !== 'suggestion-new' && item.type !== 'suggestion-remove' && item.type !== 'suggestion'" class="ng-value-icon left" (click)="keywordsUnselect(item);">×</span>
										<span class="ng-value-label">
											{{ item.label }}
											<span *ngIf="item.type == 'suggestion-new' || (item.type == 'suggestion' && item.action == 'add')">(Add)</span>
											<span *ngIf="item.type == 'suggestion-remove' || (item.type == 'suggestion' && item.action == 'remove')">(Remove)</span>
											<span *ngIf="(item.type == 'suggestion-new' || item.type == 'suggestion-remove') && item.userId == userService.getLocalUserId(0)">
												<i class="fas fa-undo" title="Cancel suggestion" (click)="keywordsUnselect(item);"></i>
											</span>
										</span>
									</div>
								</ng-template>
							</ng-select>
						</div>
					</tab>

					<tab heading="EXIF Data" id="exifTab" (selectTab)="tabSelect($event)">
						<ng-container *ngIf="photo.connectionsCanSeeExif || photo.userId == userService.getLocalUserId(0)">
							<div class="form-group odd">
								<label>Photo Details</label>
								<div class="details-container-data">
									<div *ngIf="photo?.imageSize">Size: {{ photo?.imageSize / 1024 / 1024 | number : '1.2-2' }} MB</div>
									<div *ngIf="photo?.imageSizeOnDisk">Size on disk: {{ photo?.imageSizeOnDisk / 1024 / 1024 | number : '1.2-2' }} MB<br /></div>
									<div *ngIf="photo?.xresolution"> Resolution: {{ photo?.xresolution }} dpi</div>
									<div *ngIf="photo?.width && photo?.height">Dimensions: {{ photo?.width }}w x {{ photo?.height }}h</div>
								</div>
							</div>
							<div class="form-group even">
								<label>Camera Details</label>
								<div class="details-container-data">
									<div *ngIf="!photo?.cameraMake && !photo?.cameraModel && !photo?.cameraLensMake && !photo?.cameraLensModel">Not Available</div>
									<div *ngIf="photo?.cameraMake">Camera Make: {{ photo?.cameraMake }}</div>
									<div *ngIf="photo?.cameraModel">Camera Model: <a *ngIf="photo?.cameraModel" target="_blank" href="https://www.google.com/search?q={{photo?.cameraModel}}">{{ photo?.cameraModel }}</a></div>
									<div *ngIf="photo?.cameraLensMake">Lens Make: {{ photo?.cameraLensMake }}</div>
									<div *ngIf="photo?.cameraLensModel">Lens Model: {{ photo?.cameraLensModel }}</div>
								</div>
							</div>
							<div class="form-group odd">
								<label>Camera Settings</label>
								<div class="details-container-data">
									<div *ngIf="!photo?.cameraIso && !photo?.cameraShutterSpeed && !photo?.cameraAperature && !photo?.cameraBrightness && !photo?.cameraFlash && !photo?.cameraZoomRatio">Not Available</div>
									<div *ngIf="photo?.cameraIso">ISO: {{ photo?.cameraIso }}</div>
									<div *ngIf="photo?.cameraShutterSpeed">Shutter Speed: {{ photo?.cameraShutterSpeed }}</div>
									<div *ngIf="photo?.cameraAperature">Aperature: {{ photo?.cameraAperature }}</div>
									<div *ngIf="photo?.cameraBrightness">Brightness: {{ photo?.cameraBrightness }}</div>
									<div *ngIf="photo?.cameraFlash">Flash: {{ photo?.cameraFlash }}</div>
									<div *ngIf="photo?.cameraZoomRatio">Zoom Ratio: {{ photo?.cameraZoomRatio }}</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="!photo.connectionsCanSeeExif && photo.userId !== userService.getLocalUserId(0)">
							<div class="first-comment">
								EXIF Data not enabled.
							</div>
						</ng-container>
					</tab>

					<tab heading="History" id="historyTab" *ngIf="photo?.userId == userService.getLocalUserId(0)" (selectTab)="tabSelect($event)">
						<div class="row header">
							<div class="col-md-4">
								<label>Action</label>
							</div>
							<div class="col-md-6">
								<label>Date</label>
							</div>
						</div>
						<div class="row history" *ngFor="let row of photoHistory; let i = index">
							<div class="col-md-4">
								{{ row.action }}
							</div>
							<div class="col-md-6">
								{{ row.date | date:'medium' }}
							</div>
						</div>
					</tab>

					<tab heading="Options" id="optionsTab" *ngIf="photo?.userId == userService.getLocalUserId(0)" (selectTab)="tabSelect($event)">
						<div *ngIf="loadingOptionUpdate" class="loading-box"></div>
						<div class="form-group even">
							<div class="option-column">
								<div>
									<label>Allow connections to view photo</label>
									<div class="slider-container">
										<label class="switch">
											<input id="connectionsCanViewSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanView" (change)="connectionsCanViewSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Allow connections to react to photo</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanReactSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanReact" (change)="connectionsCanReactSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group odd">
							<div class="option-column">
								<div>
									<label>Allow connections to discuss photo</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanDiscussSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanDiscuss" (change)="connectionsCanDiscussSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Lock photo discussion</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="lockDiscussionSwitch" type="checkbox" class="switch-input" formControlName="lockDiscussion" (change)="lockDiscussionSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group even">
							<div class="option-column">
								<div>
									<label>Allow connections to suggest metadata</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSuggestSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSuggest" (change)="connectionsCanSuggestSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Allow connections to see EXIF data</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSeeExifSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSeeExif" (change)="connectionsCanSeeExifSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</tab>
				</tabset>
			</div>

		</div>

	</form>
</div>
<div class="modal-footer no-bg" *ngIf="photo?.userId == userService.getLocalUserId(0) || photo?.connectionsCanSuggest">
	<div class="left">
		<a *ngIf="photo && photo?.type != 'upload' && photo?.userId == userService.getLocalUserId(0)" title="Store in Photonomy" (mouseenter)="updateActionsRolloverText('Store in Photonomy')" (mouseleave)="updateActionsRolloverText('')"><i class="far fa-gem"></i></a>
		<a *ngIf="photo && photo?.type == 'facebook' && photo?.externalUrl && photo?.userId == userService.getLocalUserId(0)" title="View photo in Facebook" [href]="photo?.externalUrl" target="_blank" (mouseenter)="updateActionsRolloverText('View photo in Facebook')" (mouseleave)="updateActionsRolloverText('')" class="facebook"><i class="fab fa-facebook-f"></i>
			<!--<img src="/assets/images/logos/facebook.png" />-->
		</a>
		<!-- TODO: Fix the download action to download the main file. Need to generate a new "download" url
		<a *ngIf="photo && photo?.imageUrl" title="Download image 1" [href]="photo?.imageUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		<a *ngIf="photo && photo?.type == 'upload' && photo?.imageUrl" title="Download image 2" [href]="photo?.imageUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		<a *ngIf="photo && photo?.type == 'upload' && photo?.imageSafeUrl" title="Download image 3" [href]="photo?.imageSafeUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		-->
		<a *ngIf="photo && photo?.type == 'upload' && photo?.userId == userService.getLocalUserId(0)" title="Delete photo" (click)="deleteConfirm=!deleteConfirm" (mouseenter)="updateActionsRolloverText('Delete photo')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-trash"></i></a>

		<!--
        <i class="far fa-smile" (mouseenter)="updateActionsRolloverText('Unset photo as private')" (mouseleave)="updateActionsRolloverText('')"></i>
        <i class="far fa-smile-wink" (mouseenter)="updateActionsRolloverText('Set photo as private')" (mouseleave)="updateActionsRolloverText('')"></i>
        -->
		<span>{{ actionsRolloverText }}</span>
	</div>
	<div class="right">
		<!-- Options Loading Icon -->
		<span *ngIf="loadingOptionUpdate" class="saving-changes">Saving changes</span> <i *ngIf="loadingOptionUpdate" class="fas fa-circle-notch fa-spin"></i>

		<!-- Discuss Reply -->
		<div class="comment-container" [ngClass]="{'hidden': tab !== 'discussTab' || photo.connectionsCanDiscuss == false || photo.lockDiscussion == true}">
			<form #c="ngForm" [formGroup]="commentForm" class="form">
				<i class="fas fa-comment" (click)="addComment()"></i>
				<input id="addComment" type="text" class="form-control comment-input" formControlName="addComment" maxlength="500" placeholder="Submit a comment" />
			</form>
		</div>

		<!-- Classify Buttons -->
		<span>{{ saveRolloverText }}</span>
		<a *ngIf="tab == 'classifyTab' && editMode" title="Cancel changes" (mouseenter)="updateSaveRolloverText('Cancel changes')" (mouseleave)="updateSaveRolloverText('')" (click)="cancelUpdatePhoto()" class="cancel"><i class="fas fa-times"></i></a>
		<a *ngIf="tab == 'classifyTab' && editMode && photo?.userId == userService.getLocalUserId(0)" title="Save changes" (mouseenter)="updateSaveRolloverText('Save changes')" (mouseleave)="updateSaveRolloverText('')" (click)="updatePhoto()" class="save"><i class="fas fa-save"></i></a>
		<a *ngIf="tab == 'classifyTab' && editMode && photo?.userId !== userService.getLocalUserId(0)" title="Save changes" (mouseenter)="updateSaveRolloverText('Save changes')" (mouseleave)="updateSaveRolloverText('')" (click)="updatePhotoForReview()" class="save"><i class="fas fa-save"></i></a>
		<a *ngIf="tab == 'classifyTab' && !editMode && photo?.userId == userService.getLocalUserId(0)" title="Edit photo details" (mouseenter)="updateSaveRolloverText('Edit photo details')" (mouseleave)="updateSaveRolloverText('')" (click)="enterEditMode()" class="edit"><i class="fas fa-edit"></i></a>
		<a *ngIf="tab == 'classifyTab' && !editMode && photo?.userId !== userService.getLocalUserId(0)" title="Edit photo details" (mouseenter)="updateSaveRolloverText('Edit photo details for review')" (mouseleave)="updateSaveRolloverText('')" (click)="enterEditMode()" class="edit"><i class="fas fa-edit"></i></a>
	</div>
</div>