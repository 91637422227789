export class UserMessage {
    public messageId: number;
    public userId: number;
    public message: string;
    public action: string;
    public date: Date;
    public messageUserId: number;
    public messageVaultId: number;
    public messageEventId: number;
}
