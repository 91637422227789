<div class="card" #card>
	<div class="face front">
		<!--
		<i class="fas fa-question" (click)="flip()"></i>
		-->
		<div class="images" [ngClass]="{'hidden': displayDeleteConfirm}">
			<app-pinned-photo-gallery [gallery]="gallery"></app-pinned-photo-gallery>
		</div>
		<div class="images" *ngIf="displayDeleteConfirm">
			<div class="delete-gallery-message">
				Are you sure?
			</div>
		</div>

		<div class="text">
			{{ gallery.name }}<br />
		</div>

		<div class="button-container" *ngIf="!displayDeleteConfirm">
			<div class="button share" (click)="openGallery()"><i class="fas fa-images"></i></div>
			<div class="button share" (click)="search()"><i class="fas fa-search"></i></div>
			<div class="button share" (click)="copyShareLinkToClipboard()"><i class="fas fa-share-alt"></i></div>
			<div class="button delete" (click)="delete()"><em class="fas fa-trash"></em></div>
		</div>
		<div class="button-container" *ngIf="displayDeleteConfirm">
			<div class="button" (click)="displayDeleteConfirm = false">Cancel</div>
			<div class="button delete" (click)="deleteConfirm()">Delete</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Pinned Gallery</div>
		<div class="help-text">
			<!-- TODO: Put gallery stats here -->
			...
		</div>
	</div>
</div>