export class Gallery {
    public id: number;
    public userId: number;
    public name: string;
    public options: string;
    public people: string;
    public locations: string;
    public keywords: string;
    public event: string;
    public date: string;
    public shareCode1: string;
    public shareCode2: string;
    public showOnlyMyPhotos: boolean;
    public showOnlyUncategorizedPhotos: boolean;
}
