<div class="card" #card>
	<div class="face front" [ngClass]="{'loading in': loading }">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<form #f="ngForm" [formGroup]="formEmail" class="form">
			<input type="text" id="email" formControlName="email" class="form-control">
			<div class="text">Enter an e-mail address to invite a<br />new user to Photonomy</div>
			<div class="button-container">
				<button class="button" type="submit" (click)="submitFormEmail()" [disabled]="!f.valid || loading">Send E-mail Invite</button>
			</div>
		</form>
	</div>
</div>