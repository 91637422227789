import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/auth.service';
import { UserConnectionService } from '../../../services/user-connection.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-connections',
	templateUrl: './connections.component.html',
	styleUrls: ['./connections.component.css']
})
export class ConnectionsComponent implements OnInit {
	loading = false;

	userChangedSubscription: Subscription;
	uuid: string;

	connectionUsersAll: User[] = [];
	connectionUsersActive: User[] = [];
	connectionUsersBlocked: User[] = [];
	connectionUsersPending: User[] = [];
	connectionUsersWaiting: User[] = [];

	availableFilters = [];
	selectedFilter = '';

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public connectionService: UserConnectionService,
		private domSanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		this.retrieveConnections();
	}

	ngOnDestroy() { }

	/**
	 * SUBSCRIPTIONS
	 */
	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				this.uuid = user.uuid;
			});
		if (this.userService.users[0]) {
			this.uuid = this.userService.users[0].uuid;
		}
	}

	/**
	 * FUNCTIONS
	 *
	 * 
	 * RETRIEVE CONNECTIONS ACTIVE
	 * Triggered By:  ngOnInit
	 * Outcome:       ...
	 */
	retrieveConnections() {
		this.loading = true;

		this.connectionUsersAll = [];
		this.connectionUsersActive = [];
		this.connectionUsersBlocked = [];
		this.connectionUsersPending = [];
		this.connectionUsersWaiting = [];

		this.availableFilters = [];
		this.selectedFilter = '';

		this.connectionService.getUserConnectionsAll().subscribe(
			response => {
				if (response.status === 200) {
					this.setConnectionArrays(response.body);
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	setConnectionArrays(connections) {
		let users = this.setImageUrls(connections);

		this.connectionUsersAll = users;
		for (let user of users) {
			if (user.connectionType == 'active') {
				this.connectionUsersActive.push(user);
			} else if (user.connectionType == 'pending') {
				this.connectionUsersPending.push(user);
			} else if (user.connectionType == 'waiting') {
				this.connectionUsersWaiting.push(user);
			} else if (user.connectionType == 'blocked') {
				this.connectionUsersBlocked.push(user);
			}
		}

		this.setAvailableFilters();
		this.setSelectedFilter();
	}

	setAvailableFilters() {
		this.availableFilters.push({ id: 'add', name: 'Add a Connection' });
		if (this.connectionUsersAll.length > 0) {
			this.availableFilters.push({ id: 'all', name: 'All' });
		}
		if (this.connectionUsersActive.length > 0) {
			this.availableFilters.push({ id: 'active', name: 'Active' });
		}
		if (this.connectionUsersPending.length > 0) {
			this.availableFilters.push({ id: 'pending', name: 'Pending' });
		}
		if (this.connectionUsersWaiting.length > 0) {
			this.availableFilters.push({ id: 'waiting', name: 'Waiting' });
		}
		if (this.connectionUsersBlocked.length > 0) {
			this.availableFilters.push({ id: 'blocked', name: 'Blocked' });
		}
	}

	setSelectedFilter() {
		if (this.connectionUsersAll.length > 0) {
			this.selectedFilter = 'all';
		} else {
			this.selectedFilter = 'add'
		}

		this.loading = false;
	}

	/**
	 * Emit Handlers
	 */
	handleFilterChanged(filter) {
		this.selectedFilter = filter;
	}

	approveConnectionRequest(user) {
		// All +0
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		itemAll.connectionType = 'active';

		// Active +1
		this.connectionUsersActive.push(itemAll);

		// Pending -1
		const itemPending = this.connectionUsersPending.find(d => d.id === user.id);
		this.connectionUsersPending.splice(this.connectionUsersPending.indexOf(itemPending), 1);
	}

	blockConnection(user) {
		// Blocked +1
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		this.connectionUsersBlocked.push(itemAll);

		// Active -1 ??
		if (itemAll.connectionType == 'active') {
			const itemActive = this.connectionUsersActive.find(d => d.id === user.id);
			this.connectionUsersActive.splice(this.connectionUsersActive.indexOf(itemActive), 1);
		}

		// Pending -1 ??
		if (itemAll.connectionType == 'pending') {
			const itemPending = this.connectionUsersPending.find(d => d.id === user.id);
			this.connectionUsersPending.splice(this.connectionUsersPending.indexOf(itemPending), 1);
		}

		// All +0
		itemAll.connectionType = 'blocked';
	}

	cancelConnectionRequest(user) {
		// All -1
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		this.connectionUsersAll.splice(this.connectionUsersAll.indexOf(itemAll), 1);

		// Waiting -1
		const itemWaiting = this.connectionUsersWaiting.find(d => d.id === user.id);
		this.connectionUsersWaiting.splice(this.connectionUsersWaiting.indexOf(itemWaiting), 1);
	}

	rejectConnectionRequest(user) {
		// All -1
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		this.connectionUsersAll.splice(this.connectionUsersAll.indexOf(itemAll), 1);

		// Pending -1
		const itemPending = this.connectionUsersPending.find(d => d.id === user.id);
		this.connectionUsersPending.splice(this.connectionUsersPending.indexOf(itemPending), 1);
	}

	removeConnectionRequest(user) {
		// All -1
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		this.connectionUsersAll.splice(this.connectionUsersAll.indexOf(itemAll), 1);

		// Active -1
		const itemActive = this.connectionUsersActive.find(d => d.id === user.id);
		this.connectionUsersActive.splice(this.connectionUsersActive.indexOf(itemActive), 1);
	}

	unblockConnection(user) {
		// All -1
		const itemAll = this.connectionUsersAll.find(d => d.id === user.id);
		this.connectionUsersAll.splice(this.connectionUsersAll.indexOf(itemAll), 1);

		// Blocked -1
		const itemBlocked = this.connectionUsersBlocked.find(d => d.id === user.id);
		this.connectionUsersBlocked.splice(this.connectionUsersBlocked.indexOf(itemBlocked), 1);
	}

	requestConnection() {
		this.retrieveConnections();
	}

	/**
	 * HELPER METHODS
	 * 
	 * 
	 * SET IMAGE URLS
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	setImageUrls(users: User[]) {
		/**
		for (let user of users) {
			if (user.image) {
				let tempImageUrl: any;
				tempImageUrl = 'data:image/png;base64,' + user.image;

				user.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(tempImageUrl);
			}
		}
		 */
		return users;
	}
}
