import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private authSerivce: AuthService,
		private router: Router
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const service = req.url.substring(req.url.lastIndexOf('/'));
		// const excludeServices = ['/view', '/classes', '/class', '/comments', '/comment', '/checkin'];
		const excludeServices = [];
		const updateLoading = excludeServices.indexOf(service) === -1;

		if (updateLoading) {
			this.authSerivce.updateLoading(true);
		}

		// Only update the authorization header for local requests.
		if (req.url.includes('backblaze.com')) {
			// B2 requests
			return next.handle(req).pipe(map((response: any) => {
				return response;
			}));

		} else if (req.url.includes('autocomplete.search.hereapi.com')) {
			// Here requests
			return next.handle(req).pipe(map((response: any) => {
				return response;
			}));
		} else {
			// Normal requests
			const authReq = req.clone({
				headers: req.headers
					.set('Authorization', this.authSerivce.getToken('jwt')),
			});

			return next.handle(authReq).pipe(map((response: any) => {
				if (response.status === 200) {
					if (response.headers.get('Authorization')) {
						this.authSerivce.setToken('jwt', response.headers.get('Authorization'));
					}
					if (updateLoading) {
						this.authSerivce.updateLoading(false);
					}
				}
				return response;
			}), catchError((response: any) => {
				if (response instanceof HttpErrorResponse) {
					if (response.status === 401) {
						this.authSerivce.signOutUser();
						if (this.router.url !== '/login') {
							const navigationExtras: NavigationExtras = {
								queryParams: {
									timeout: true
								}
							};
							this.router.navigate(['/login'], navigationExtras);
						}
					}
				}
				if (updateLoading) {
					this.authSerivce.updateLoading(false);
				}
				//this.authSerivce.doLoginFailed();
				return throwError(response);
			}));

		}
	}
}
