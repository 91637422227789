import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Gallery } from 'src/app/models/gallery.model';
import { AuthService } from 'src/app/services/auth.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
	selector: 'app-card-gallery-pin',
	templateUrl: './card-gallery-pin.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardGalleryPinComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;

	modalRef: BsModalRef;
	loading = false;

	displayAddGalleryDialog = false;

	galleriesPinned: Array<Gallery> = [];
	galleriesUnpinned: Array<Gallery> = [];

	constructor(
		private router: Router,
		public authService: AuthService,
		private modalService: BsModalService,
		private galleryService: GalleryService
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}

	/**
	 * SHOW ADD GALLERY DIALOG
	 * 
	 * ...
	 * 
	 * 1.
	 */
	showAddGalleryDialog() {
		this.loading = true;

		this.displayAddGalleryDialog = true;
		this.galleriesUnpinned = [];

		let galleriesPinned = this.galleryService.getGalleries();
		if (galleriesPinned) {
			for (const gallery of galleriesPinned) {
				const item = this.galleriesPinned.find(d => d.id === gallery.id);
				if (!item) {
					this.galleriesUnpinned.push(gallery);
				}
			}

			this.loading = false;
		} else {
			this.galleryService.retrieveAllGalleries().subscribe(
				response => {
					if (response.status === 200) {
						for (const gallery of response.body) {
							const item = this.galleriesPinned.find(d => d.id === gallery.id);
							if (!item) {
								this.galleriesUnpinned.push(gallery);
							}
						}

						this.loading = false;
					}
				}
			);
		}
	}

	/**
	 * PIN GALLERY
	 * 
	 * ...
	 * 
	 * 1.
	 * 
	 * @param gallery 
	 */
	pinGallery(gallery: Gallery) {
		this.loading = true;

		this.galleryService.pinGallery(gallery.id, 'web').subscribe(
			response => {
				if (response) {
					this.galleriesPinned.push(gallery);

					this.galleryService.setGalleriesPinned(this.galleriesPinned);

					this.displayAddGalleryDialog = false;
					this.loading = false;
				} else {
					this.displayAddGalleryDialog = false;
					this.loading = false;
				}
			}
		);
	}

	/**
	 * UNPIN GALLERY
	 * 
	 * ...
	 * 
	 * 1. 
	 * 
	 * @param gallery
	 */
	unpinGallery(gallery: Gallery) {
		this.loading = true;

		this.galleryService.unpinGallery(gallery.id, 'web').subscribe(
			response => {
				if (response) {
					const item = this.galleriesPinned.find(d => d.id === gallery.id);
					if (item) {
						this.galleriesPinned.splice(this.galleriesPinned.indexOf(item), 1);
					}

					this.galleryService.setGalleriesPinned(this.galleriesPinned);

					this.displayAddGalleryDialog = false;

					this.loading = false;
				} else {
					this.loading = false;
				}
			}
		);
	}
}
