import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-widget-photos-my-uploaded-graph',
	templateUrl: './widget-photos-my-uploaded-graph.component.html',
	styleUrls: ['./widget-photos-my-uploaded-graph.component.css']
})
export class WidgetPhotosMyUploadedGraphComponent implements OnInit {
	loading = true;

	userPhotoCount: any;
	userPhotoCountChangedSubscription: Subscription;

	pieSliceUploaded1Seconds = 0;
	pieSliceUploaded2Seconds = 0;

	pieSliceUploaded1Degrees = 0;
	pieSliceUploaded2Degrees = 0;
	pieSliceUploadedFillerDegrees = 0;

	constructor(
		private photoService: PhotoService
	) { }

	ngOnInit() {
		this.subscribeToPhotoCountChanged();

		if (this.photoService.loadingUserPhotoCount) {
			// Another component is already loading the count, do nothing and wait for subscription.
		} else {
			// No component is already loading the count, trigger it and wait for subscription.
			this.retrievePhotoCount();
		}
	}

	subscribeToPhotoCountChanged() {
		this.userPhotoCountChangedSubscription = this.photoService.userPhotoCountChanged
			.subscribe((value: any) => {
				this.photoService.loadingUserPhotoCount = false;

				let uploadedPercent = (value.myUploadedPhotos / value.vaultPhotos);
				if (uploadedPercent > .5) {
					this.pieSliceUploaded1Seconds = .5;
					this.pieSliceUploaded2Seconds = (uploadedPercent - .5);
				} else {
					this.pieSliceUploaded1Seconds = uploadedPercent;
					this.pieSliceUploaded2Seconds = 0;
				}

				// Delay settings the CSS specs to make the animatons more consistant. 
				let _this = this;
				setTimeout(function () {
					let uploadedPercent = ((value.myUploadedPhotos / value.vaultPhotos) * 360);
					if (uploadedPercent > 180) {
						_this.pieSliceUploaded1Degrees = 180;
						_this.pieSliceUploaded2Degrees = (uploadedPercent - 180);
					} else {
						_this.pieSliceUploaded1Degrees = uploadedPercent;
						_this.pieSliceUploaded2Degrees = 0;
					}
					_this.pieSliceUploadedFillerDegrees = 180;
				}, 100);

				this.userPhotoCount = value;
				this.loading = false;
			});
	}

	retrievePhotoCount() {
		this.loading = true;
		this.photoService.loadingUserPhotoCount = true;

		this.photoService.getTotalPhotoCount().subscribe(
			response => {
				if (response.status === 200) {
					this.photoService.setAndAnnounceUserPhotoCount(response.body);
				}
			}
		);
	}
}
