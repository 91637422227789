import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-card-gallery-uncategorized-photos',
	templateUrl: './card-gallery-uncategorized-photos.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardGalleryUncategorizedPhotosComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;

	modalRef: BsModalRef;
	loading = false;

	constructor(
		private router: Router,
		public authService: AuthService,
		private modalService: BsModalService
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}

	viewGallery() {
		this.router.navigate(['/explore/'], { queryParams: { uncategorized: 'true' } });
	}
}
