import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Event } from '../../../models/event.model';
import { AuthService } from '../../../services/auth.service';
import { UserConnectionService } from '../../../services/user-connection.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
	loadingEvents = true;
	loadingUserPaymentMethods = true;

	error = false;

	paymentMethodsExist = false;
	paymentMethods: any[] = [];
	user: User;
	userChangedSubscription: Subscription;

	eventsAll: Event[] = [];
	eventsPast: Event[] = [];
	eventsFuture: Event[] = [];

	availableFilters = [];
	selectedFilter = '';

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public connectionService: UserConnectionService,
		public router: Router,
		private paymentService: PaymentService,
		private eventService: EventService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		if (this.userService.users[0]) {
			this.user = this.userService.users[0];

			this.checkUserPaymentMethod();

			this.retrieveEvents();
		}
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user = user;

					this.checkUserPaymentMethod();

					this.retrieveEvents();
				}
			}
		);
	}

	retrieveEvents() {
		this.loadingEvents = true;

		this.eventsAll = [];
		this.eventsPast = [];
		this.eventsFuture = [];

		this.availableFilters = [];
		this.selectedFilter = '';

		if (this.eventService.events.length > 0) {
			this.setEventArrays(this.eventService.events);
		} else {
			this.eventService.getEvents().subscribe(
				response => {
					if (response.status === 200) {
						this.eventService.events = response.body;
						this.setEventArrays(response.body);
					} else {
						this.error = true;
						this.loadingEvents = false;
					}
				},
				err => {
					this.error = true;
					this.loadingEvents = false;
				}
			);
		}
	}

	checkUserPaymentMethod() {
		if (this.user.paymentMethodId) {
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.user.paymentMethodCcExpirationYear, this.user.paymentMethodCcExpirationMonth, 0);

			if (expireDate > now) {
				this.paymentMethodsExist = true;
			}

			this.loadingUserPaymentMethods = false;
		} else {
			this.loadingUserPaymentMethods = false;
		}
	}

	setEventArrays(events) {
		let currentDate = new Date();
		for (let event of events) {
			this.eventsAll.push(event);
			let eventDate = new Date(event.startDateTime);

			if (eventDate > currentDate) {
				this.eventsFuture.push(event);
			} else {
				this.eventsPast.push(event);
			}
		}

		this.setAvailableFilters();

		this.eventsAll.sort((a, b) => -a.startDateTime.toString().localeCompare(b.startDateTime.toString()))

	}

	setAvailableFilters() {
		this.availableFilters.push({ id: 'add', name: 'Add an Event' });
		if (this.eventsAll.length > 0) {
			this.availableFilters.push({ id: 'all', name: 'All' });
		}
		if (this.eventsFuture.length > 0) {
			this.availableFilters.push({ id: 'future', name: 'Future' });
		}
		if (this.eventsPast.length > 0) {
			this.availableFilters.push({ id: 'past', name: 'Past' });
		}

		this.setSelectedFilter();
	}

	setSelectedFilter() {
		if (this.eventsAll.length > 0) {
			this.selectedFilter = 'all';
		} else {
			this.selectedFilter = 'add'
		}

		this.loadingEvents = false;
	}

	/**
	 * Emit Handlers
	 */
	handleFilterChanged(filter) {
		this.selectedFilter = filter;
	}

	// acceptEventInvite(event) {
	// 	// TODO: Remove this after I figure out why the below (All +0) is not working.
	// 	this.router.navigate(['/explore'], { queryParams: { eventId: event.id } });

	// 	// All +0
	// 	const itemAll = this.eventsAll.find(d => d.id === event.id);
	// 	//itemAll.status = 'active';

	// 	// Member +1
	// 	this.eventsMember.push(itemAll);

	// 	// Pending -1
	// 	const itemPending = this.eventsPending.find(d => d.id === event.id);
	// 	this.eventsPending.splice(this.eventsPending.indexOf(itemPending), 1);
	// }

	// declineEventInvite(event) {
	// 	// All -1
	// 	const itemAll = this.eventsAll.find(d => d.id === event.id);
	// 	this.eventsAll.splice(this.eventsAll.indexOf(itemAll), 1);

	// 	// Pending -1
	// 	const itemPending = this.eventsPending.find(d => d.id === event.id);
	// 	this.eventsPending.splice(this.eventsPending.indexOf(itemPending), 1);
	// }

	deleteEvent(event) {
		// All -1
		const itemAll = this.eventsAll.find(d => d.id === event.id);
		this.eventsAll.splice(this.eventsAll.indexOf(itemAll), 1);

		let currentDate = new Date();
		if (event.startDateTime > currentDate) {
			// Future -1
			const itemFuture = this.eventsFuture.find(d => d.id === event.id);
			this.eventsFuture.splice(this.eventsFuture.indexOf(itemFuture), 1);
		} else {
			// Past -1
			const itemPast = this.eventsPast.find(d => d.id === event.id);
			this.eventsPast.splice(this.eventsPast.indexOf(itemPast), 1);
		}

		// If this is the last event, display the Add Events panel again
		if (this.eventsAll.length == 0) {
			this.selectedFilter = 'add'
		}
	}

	// leaveEvent(event) {

	// }

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}


}
