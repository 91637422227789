import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PhotoCount } from '../models/photo-count.model';
import { PhotoHistory } from '../models/photo-history.model';
import { PhotoStorage } from '../models/photo-storage.model';
import { PhotoSuggestion } from '../models/photo-suggestion.model';
import { Photo } from '../models/photo.model';
import { SignedDownloadToken } from '../models/signed-download-token.model';

@Injectable()
export class PhotoService {
	public loadingSignedDownloadTokens: any[] = [];
	public signedDownloadTokens: SignedDownloadToken[] = [];
	public signedUploadUrl1: any;
	public signedUploadUrl2: any;
	public signedUploadUrl3: any;
	public signedUploadUrl4: any;
	public signedUploadUrl5: any;

	public lastUsedSignedUploadUrl: number;

	public activePhotoChanged = new Subject();
	public photoCategorized = new Subject();
	public photoDeleted = new Subject();
	public nextPhotoSelected = new Subject();
	public previousPhotoSelected = new Subject();
	public modalClosed = new Subject();

	public userPhotoCountChanged = new Subject();
	public userPhotoCount: any;
	public loadingUserPhotoCount = false;

	public userImageSizeChanged = new Subject();
	private userImageSize: PhotoStorage;

	activePhoto: Photo;
	activePhotoUrl: string;
	activePhotoExternalId: string;

	constructor(
		private http: HttpClient
	) { }

	/** 
	 * UPLOAD SERVICES
	 */
	getSignedUploadUrl() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/image/upload-url';

		return this.http.get<string>(requestUrl, { headers: headers, observe: 'response' });
	}

	addPhoto(type: string, checksum: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('type', type);
		queryParameters = queryParameters.set('checksum', checksum);

		const requestUrl = '/api/photo';

		return this.http.post<Photo>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	addPhotoFinish(photo: Photo) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/finish';

		return this.http.put<Photo>(requestUrl, photo, { headers: headers, observe: 'response' });
	}

	addPhotoAbort(photoId: number, imageUrl: string, imageUrlMed: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);
		queryParameters = queryParameters.set('imageUrl', imageUrl);
		queryParameters = queryParameters.set('imageUrlMed', imageUrlMed);

		const requestUrl = '/api/photo/abort';

		return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	addImageBySignedUploadUrl(image: File, signedUploadUrl: string, authToken: string, contentLength: string, fileName: string, checksum: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'b2/x-auto');
		headers = headers.set('Authorization', authToken); // Token from getSignedUploadUrl()
		headers = headers.set('Content-Length', contentLength.toString()); // The number of bytes in the file being uploaded.
		headers = headers.set('X-Bz-File-Name', fileName);
		headers = headers.set('X-Bz-Content-Sha1', checksum); // The SHA1 checksum of the content of the file.

		return this.http.post<any>(signedUploadUrl, image, { reportProgress: true, observe: 'events', headers: headers });
	}

	/** 
	 * DOWNLOAD SERVICES
	 */

	getSignedDownloadToken(scope: string, type: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('scope', scope);
		queryParameters = queryParameters.set('type', type);

		const requestUrl = '/api/photo/image/download-token';

		return this.http.get<SignedDownloadToken>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	/** 
	 * LEGACY SERVICES 
	 */

	addPhotos(photos: Photo[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos';

		return this.http.post<number>(requestUrl, photos, { headers: headers, observe: 'response' });
	}

	addPhotoWithImage(image: File, vaultId: number, allowConnectionsToEdit: boolean) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('vaultId', vaultId);
		queryParameters = queryParameters.set('allowConnectionsToEdit', allowConnectionsToEdit);

		const formData = new FormData();
		formData.append('file', image);

		const requestUrl = '/api/photo/image';

		return this.http.post<any>(requestUrl, formData, { reportProgress: true, observe: 'events', params: queryParameters });
	}

	getPhotoImage(externalId: string): Observable<any> {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('externalId', externalId);

		const requestUrl = '/api/photo/image';

		return this.http.get<any>(requestUrl,
			{
				responseType: 'blob' as 'json',
				observe: 'response',
				params: queryParameters
			});
	}

	getPhoto(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId.toString());

		const requestUrl = '/api/photo';

		return this.http.get<Photo>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	getPhotos(startPosition: number, sortBy: string, ownerUserIds: string, people: string, capturedDate: string, location: string, keywords: string, event: string, showOnlyUncategorizedPhotos: boolean) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('startPosition', startPosition.toString());
		queryParameters = queryParameters.set('sortBy', sortBy);
		queryParameters = queryParameters.set('ownerUserIds', ownerUserIds);
		queryParameters = queryParameters.set('people', people);
		queryParameters = queryParameters.set('capturedDate', capturedDate);
		queryParameters = queryParameters.set('location', location);
		queryParameters = queryParameters.set('keywords', keywords);
		queryParameters = queryParameters.set('event', event);
		queryParameters = queryParameters.set('showOnlyUncategorizedPhotos', showOnlyUncategorizedPhotos);

		const requestUrl = '/api/photos';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	getPhotosByFacebookAlbumId(albumId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos/facebook/album/' + albumId;

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getPhotosByGallery(galleryId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('galleryId', galleryId);

		const requestUrl = '/api/photosByGallery';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	getUncategorizedPhotos(startPosition: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('startPosition', startPosition.toString());

		const requestUrl = '/api/photos/uncategorized';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	getUncategorizedPhotosRandom() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos/uncategorized/random';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getPublicPhotos() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos/public';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getTotalPhotoCount() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos/count';

		return this.http.get<PhotoCount>(requestUrl, { headers: headers, observe: 'response' });
	}

	getTotalPhotoSize() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photos/size';

		return this.http.get<PhotoStorage>(requestUrl, { headers: headers, observe: 'response' });
	}

	getPhotoHistory(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/history';

		return this.http.get<Array<PhotoHistory>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getPhotoSuggestions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/suggestions';

		return this.http.get<Array<PhotoSuggestion>>(requestUrl, { headers: headers, observe: 'response' });
	}

	updatePhoto(photo: Photo, updateHistory: boolean) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('updateHistory', updateHistory);

		const requestUrl = '/api/photo';

		return this.http.put<Photo>(requestUrl, photo, { headers: headers, observe: 'response', params: queryParameters });
	}

	createPhotoSuggestion(photoSuggestion: PhotoSuggestion) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/suggestion';

		return this.http.post<PhotoSuggestion>(requestUrl, photoSuggestion, { headers: headers, observe: 'response' });
	}

	cancelPhotoSuggestion(photoSuggestionId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/suggestion/' + photoSuggestionId + '/cancel';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	acceptPhotoSuggestion(photoSuggestionId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/suggestion/' + photoSuggestionId + '/accept';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	declinePhotoSuggestion(photoSuggestionId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/suggestion/' + photoSuggestionId + '/decline';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	disablePublicPhotoReactions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/reactions/public/disable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	enablePublicPhotoReactions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/reactions/public/enable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	disableConnectionPhotoReactions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/reactions/connection/disable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	enableConnectionPhotoReactions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/reactions/connection/enable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	disablePhotoSuggestions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/suggestions/disable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	enablePhotoSuggestions(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId + '/suggestions/enable';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Delete an uploaded photo
	deleteUploadedPhoto(photoId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/photo/' + photoId;

		return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}


	getUserPhotoCount() {
		return this.userPhotoCount;
	}

	setAndAnnounceUserPhotoCount(value: any) {
		this.userPhotoCount = value;
		this.userPhotoCountChanged.next(value);
	}

	getUserImageSize() {
		return this.userImageSize;
	}
	setUserImageSize(photoStorage: PhotoStorage) {
		// Data comes in as MB
		// TODO: Convert to either MB or GB based on size

		// Convert to MB for now.
		if (photoStorage.totalSize) {
			photoStorage.totalSize = (photoStorage.totalSize / 1000000);
		}
		if (photoStorage.sharedSize) {
			photoStorage.sharedSize = (photoStorage.sharedSize / 1000000);
		}
		if (photoStorage.privateSize) {
			photoStorage.privateSize = (photoStorage.privateSize / 1000000);
		}

		this.userImageSize = photoStorage;
		this.userImageSizeChanged.next(photoStorage);
	}

	setActivePhoto(photo: Photo) {
		this.activePhoto = photo;
		this.activePhotoChanged.next(photo);
	}
	getActivePhoto() {
		return this.activePhoto;
	}

	setActivePhotoUrl(url: string) {
		this.activePhotoUrl = url;
	}
	getActivePhotoUrl() {
		return this.activePhotoUrl;
	}

	setActivePhotoExternalId(id: string) {
		this.activePhotoExternalId = id;
	}
	getActivePhotoExternalId() {
		return this.activePhotoExternalId;
	}

	setPhotoCategorized(photo: Photo) {
		this.photoCategorized.next(photo);
	}

	triggerPreviousPhoto() {
		this.previousPhotoSelected.next(null);
	}
	triggerNextPhoto() {
		this.nextPhotoSelected.next(null);
	}
	triggerModalClosed() {
		this.modalClosed.next(null);
	}

	announcePhotoDeleted(photoId) {
		this.photoDeleted.next(photoId);
	}

	/**
	 * REACTIONS
	 */
	angryPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/angry';

		return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
	}

	unangryPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/angry';

		return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
	}

	laughPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/laugh';

		return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
	}

	unlaughPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/laugh';

		return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
	}

	likePhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/like';

		return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
	}

	unlikePhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/like';

		return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
	}

	lovePhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/love';

		return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
	}

	unlovePhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/love';

		return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
	}

	sadPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/sad';

		return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
	}

	unsadPhoto(photoId: number) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('photoId', photoId);

		const requestUrl = '/api/photo/sad';

		return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
	}
}
