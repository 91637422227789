<div class="card" [ngClass]="user.connectionType ? user.connectionType : 'pending'" #card>
	<div class="face front" [ngClass]="{'loading in': loading }">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<img *ngIf="user.imageSafeUrl" [src]="user.imageSafeUrl" class="user-image-icon" [title]="user.name" />
		<i *ngIf="!user.imageSafeUrl" class="fas fa-user"></i>
		<div class="text">
			<ng-container *ngIf="(user.firstName?.length > 0) || (user.lastName?.length > 0)">
				{{ user.firstName }} {{ user.lastName }}
			</ng-container>
			<ng-container *ngIf="!(user.firstName?.length > 0) && !(user.lastName?.length > 0)">
				{{ user.email }}
			</ng-container>
			<br />
			<!-- Active -->
			<span *ngIf="user.connectionType == 'active'">Connection since {{ user.date | date:'longDate' }}</span>

			<!-- Pending -->
			<span *ngIf="user.connectionType == 'pending'">Requested on {{ user.date | date:'longDate' }}</span>

			<!-- Waiting -->
			<span *ngIf="user.connectionType == 'waiting'">Requested on {{ user.date | date:'longDate' }}</span>

			<!-- Blocked -->
			<span *ngIf="user.connectionType == 'blocked'">Blocked on {{ user.date | date:'longDate' }}</span>
		</div>

		<div class="button-container">
			<!-- Active -->
			<div *ngIf="user.connectionType == 'active'" class="button first" (click)="removeConnectionActive(user)" title="Remove connection">Remove</div>
			<div *ngIf="user.connectionType == 'active'" class="button last" (click)="blockConnectionActiveConfirm(user)" title="Block connection">Block</div>

			<!-- Pending -->
			<div *ngIf="user.connectionType == 'pending'" class="button first btn-success" (click)="approveConnectionPending(user)" title="Approve connection">Approve</div>
			<div *ngIf="user.connectionType == 'pending'" class="button middle btn-danger" (click)="rejectConnectionPending(user)" title="Reject connection">Reject</div>
			<div *ngIf="user.connectionType == 'pending'" class="button last btn-danger" (click)="blockConnectionPending(user)" title="Block connection">Block</div>

			<!-- Waiting -->
			<div *ngIf="user.connectionType == 'waiting'" class="button" (click)="cancelConnectionWaiting(user)" title="Cancel connection">Cancel</div>

			<!-- Blocked -->
			<div *ngIf="user.connectionType == 'blocked'" class="button" (click)="unblockConnection(user)" title="Unblock connection">Unblock</div>
		</div>
	</div>
	<div class="face back" [ngClass]="{'loading in': loading }">
		<ng-container *ngIf="!blockConfirm">
			<i class="fas fa-reply" (click)="flip()"></i>
			<div class="help-title">{{ user.firstName }} {{ user.lastName }}</div>
			<div class="help-text">...</div>
		</ng-container>
		<ng-container *ngIf="blockConfirm">
			<i class="fas fa-reply" (click)="flip()"></i>
			<div class="help-title">{{ user.firstName }} {{ user.lastName }}</div>
			<div class="help-text">You are about to block the following user. Continue?</div>
			<div class="button-container">
				<div class="button" (click)="blockConnectionActive()">Block</div>
				<div class="button" data-dismiss="modal" (click)="cancelBlockConnection()">Cancel</div>
			</div>
		</ng-container>
	</div>
</div>