<div class="card opposite-colors" #card>
	<div class="face front">
		<i class="fas fa-question" (click)="flip()"></i>
		<div class="title-container"><div class="title">My<br />Galleries</div></div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">My Galleries</div>
		<div class="help-text">...</div>
	</div>
</div>