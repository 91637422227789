<div class="gallery-container" [ngClass]="{'loading in': loading }">
	<div class="controls" *ngIf="!loading && images && images.length > 1">
		<button (click)="prevImageClick()" class="prev"><i class="fas fa-caret-left"></i></button>
		<button (click)="nextImageClick()" class="next"><i class="fas fa-caret-right"></i></button>
	</div>
	<div *ngIf="!loading && images && images.length > 0" class="image-container">
		<ng-container *ngFor="let image of images; let i = index">
			<img [src]="image.image" *ngIf="i == imageIndex" />
		</ng-container>
	</div>
	<div *ngIf="!loading && images && images.length > 0" class="uncategorized">
		<a href="javascript:void(0)" (click)="navigateToUncategorized()">Explore uncategorized photos</a>
	</div>
	<div class="no-photos-message" *ngIf="!loading && images && images.length == 0">
		<div class="text">No uncategorized photos</div>
	</div>
</div>