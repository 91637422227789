<div class="row justify-content-center g-0">
	<div class="col-md-11">
		<!--<div><img src="/assets/images/photo-albums.png" class="page-icon" /></div>-->
		<!--<div><i class="fab fa-facebook-f page-icon"></i></div>-->
		<div class="page-title welcome">Sync Facebook Photos</div>
		<!--
<div *ngIf="showHelp" class="col-md-8 offset-md-2 help-text">
    Syncing your photos to Photonomy allows you to view, classify, search, and secure your photos. To link your photos, press the <span class="bold">Start Sync</span> button. After the full sync is completed, you can browse within the Facebook albums and un-snyc photos as wanted. Press the <span class="bold">Re-Sync</span> button to sync any photos that were added since your last full sync.
</div>
-->
		<div class="col-md-12 album-table" [ngClass]="{'loading in': loading }">
			<div class="row">
				<div class="col-md-8">
					<div class="nav"><a (click)="navigate('photo/add')">Add Photos</a><i class="fas fa-angle-double-right"></i><a (click)="album=null">Facebook Albums</a> <span *ngIf="album"><i class="fas fa-angle-double-right"></i>{{ album.name }}</span></div>
				</div>
				<div class="col-md-4 right">
					<span *ngIf="!album" class="button" (click)="syncAll()"><i class="fas fa-sync-alt" title="Sync"></i> Start Full Sync</span>
					<span *ngIf="album" class="button" (click)="syncAlbum()"><i class="fas fa-sync-alt" title="Sync Album"></i> Start Album Sync</span>
				</div>
			</div>

			<div *ngIf="!album">
				<div class="row album-row">
					<div class="col-md-6 title">
						<div class="text">Album</div>
					</div>
					<div class="col-md-3 title">
						<div class="text">Photos</div>
					</div>
					<div class="col-md-3 title right">
						<div class="text">Created</div>
					</div>
				</div>
				<div *ngFor="let album of albums" class="row album-row">
					<div class="col-md-6"><a (click)="loadFacebookAlbum( album )">
							<div class="text">{{ album.name }}</div>
						</a></div>
					<div class="col-md-3">
						<div class="text">{{ album.count }}</div>
					</div>
					<div class="col-md-3 right">
						<div class="text">{{ album.created_time | date:'longDate' }}</div>
					</div>
				</div>
				<div class="row album-row last">
					<div class="col-md-6">&nbsp;</div>
					<div class="col-md-3 title">
						<div class="text">{{ totalPhotos }} total</div>
					</div>
					<div class="col-md-3">&nbsp;</div>
				</div>
			</div>

			<div *ngIf="album">
				<div class="row album-row">
					<div class="col-md-6 title">
						<div class="text">Photo</div>
					</div>
					<div class="col-md-6 title right">
						<div class="text">Status</div>
					</div>
				</div>
				<div *ngFor="let photo of photos; let i = index" class="row album-row">
					<div class="col-md-6"><input *ngIf="albumSynced && photo && photo.photoId" class="image-checkbox" type="checkbox" [id]="photo.id" [value]="photo.photoId" (click)="handlePhotoSyncEvent($event)" [checked]="photo && photo.status && photo.status == 'Synced'" />
						<div class="image" [style.background-image]="'url(' + photo.images[0].source + ')'"></div>
					</div>
					<div class="col-md-6 right">
						<div class="image-text">
							<span class="bold orange" [ngClass]="{'hidden': albumSynced}">Not synced</span>
							<span class="bold green" [ngClass]="{'hidden': !albumSynced}"><span *ngIf="photo && photo.status && photo.status == 'Synced'">Synced</span></span>
							<span class="bold red" [ngClass]="{'hidden': !albumSynced}"><span *ngIf="photo && photo.status && photo.status == 'Do not sync'">Do not sync</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #confirmSyncAllDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body">
		<p class="center bold">Do not close the browser while the photos are syncing!</p>
		<p class="justify"><span class="bold">TIP:</span> The time it takes to complete a full sync depends on the number of photos stored within Facebook. Photos will become available to start updating as soon as they are imported.</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="syncAllConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>


<ng-template #confirmSyncAlbumDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body">
		<p class="center bold">Do not close the browser while the photos are syncing!</p>
		<p class="justify"><span class="bold">TIP:</span> The time it takes to complete a sync depends on the number of photos stored within Facebook. Photos will become available to start updating as soon as they are imported.</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="syncAlbumConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>


<ng-template #confirmUnsyncPhotoDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body">
		<p class="center">Unsyncing a photo will remove the photo.</p>
		<p class="center">Continue?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="unsyncPhotoConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="unsyncPhotoCancel()">Cancel</button>
	</div>
</ng-template>