import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { Widget } from '../../models/widget.model';

@Component({
	selector: 'app-dashboard-widget',
	templateUrl: './widget.component.html',
	styleUrls: ['./widget.component.css']
})
export class DashboardWidgetComponent implements OnInit {
	@ViewChild('widgetModeContainer') widgetModeContainer: ElementRef;

	@Input() widget: any; // I would much rather pass the entire widget object like this

	//@Input() widgetType: string;
	//@Input() widgetTitle: string;
	//@Input() widgetShowTitle: boolean;
	//@Input() widgetOwnerId: string;
	//@Input() widgetId: string;
	//@Input() widgetData: any;         // This is referencing a property of a widget from pageModified.widgets in the Page component. Any changes to widgetData will also be changed in pageModified.widgets[i].
	//@Input() widgetSettings: any;     // This is referencing a property of a widget from pageModified.widgets in the Page component. Any changes to widgetData will also be changed in pageModified.widgets[i].

	@Input() pageAction: string;

	@Output() widgetDeleted = new EventEmitter<Widget>();

	loading = true;
	iconsRolloverText: string;

	editMode = false;
	editSaved = false;
	widgetModified = false;

	constructor(
		public router: Router,
		public layoutService: LayoutService
	) { }

	ngOnInit() {
		/**
		this.widget.type = this.widgetType;
		this.widget.title = this.widgetTitle;
		this.widget.showTitle = this.widgetShowTitle;
		this.widget.ownerId = this.widgetOwnerId;
		this.widget.id = this.widgetId;
		this.widget.data = this.widgetData;           // this.widget.data is being set to a reference of this.widgetData
		this.widget.settings = this.widgetSettings;   // this.widget.settings is being set to a reference of this.widgetSettings
		this.widget.editMode = this.editMode;
		 */

		this.loading = false;
	}

	updateIconText(text: string) {
		this.iconsRolloverText = text;
	}

	/**
	 * This is executed when the delete icon is pressed within a widget.  This emits to the page component which removes
	 * the widget from the gridster array.
	 */
	deleteWidget() {
		this.widgetDeleted.emit(this.widget);
	}
}
