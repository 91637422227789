import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	@Input() uuid: string;
	@Input() emailVerifyCode: string;

	modalRef: BsModalRef;
	@ViewChild('credentialsDialog', { static: true }) credentialsDialog: TemplateRef<any>;

	formResetEmail: UntypedFormGroup;
	formResetConfirm: UntypedFormGroup;

	loading = true;

	resetEmailSuccess = false;
	resetEmail: string;
	resetPasswordCode: string;
	resetPasswordValidateSuccess = false;
	resetConfirmSuccess = false;

	message: string = 'Sign in to your account';

	paramSubscription: any;

	user: User;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private domSanitizer: DomSanitizer,
		public authService: AuthService,
		private route: ActivatedRoute,
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private userService: UserService
	) { }

	ngOnInit() {
		if (this.authService.isAuthenticated()) {
			this.authService.signOutUser();
		}

		this.initResetEmailForm();
		this.initResetConfirmForm();

		this.route.params.subscribe(params => {
			// check for password reset codes
			if (params['resetEmail']) {
				this.resetEmail = params['resetEmail'];
			}
			if (params['resetPasswordCode']) {
				this.resetPasswordCode = params['resetPasswordCode'];
			}
			if (this.resetEmail && this.resetPasswordCode) {
				this.validatePasswordReset();
			}
		});

		this.loading = false;
	}

	ngOnDestroy() {
		if (this.paramSubscription) {
			this.paramSubscription.unsubscribe();
		}
	}

	/**
	 * INIT RESET FORM EMAIL
	 */
	initResetEmailForm() {
		this.formResetEmail = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required,
			])
		});
	}

	/**
	 * INIT RESET CONFIRM FORM
	 */
	initResetConfirmForm() {
		this.formResetConfirm = this.formBuilder.group({
			'password': new UntypedFormControl(null, [
				Validators.required,
				Validators.minLength(7)
			]),
			'confirmPassword': new UntypedFormControl(null, [
				Validators.required
			])
		}, { validator: this.matchingPasswords('password', 'confirmPassword') });
	}

	/**
	 * VALIDATE PASSWORD RESET
	 */
	validatePasswordReset() {
		this.loading = true;

		this.authService.validateRequestPasswordReset(this.resetEmail, this.resetPasswordCode).subscribe(
			response => {
				if (response.status === 200) {
					this.message = "Enter a new password.";
					this.resetPasswordValidateSuccess = true;

					this.loading = false;
				} else {
					this.message = "An error occurred.";
					this.resetPasswordValidateSuccess = false;
					this.loading = false;
				}
			},
			err => {
				this.message = "Invalid password reset code.";
				this.resetPasswordValidateSuccess = false;
				this.loading = false;
			}
		);

	}

	/**
	 * ROUTE TO HOME
	 * 1. Set the global user.  Since we set this after the Terms and Privacy screens, they cannot bypass the agreements
	 * 2. Route to the photos route
	 
	routeToHome() {
		this.authService.setUserData(this.user);

		this.router.navigate(['/home']);
	}*/

	/**
	 * RESET EMAIL SUBMIT
	 */
	resetEmailSubmit() {
		this.loading = true;
		const email = this.formResetEmail.get('email').value;

		this.authService.requestPasswordReset(email).subscribe(
			response => {
				if (response.status === 200) {
					this.resetEmailSuccess = true;
					this.message = "If this account exists, an email will be sent with password reset instructions.";
					this.loading = false;
				} else {
					this.resetEmailSuccess = true;
					this.message = "An error occurred."
					this.loading = false;
				}
			},
			err => {
				this.resetEmailSuccess = true;
				this.message = "An error occurred."
				this.loading = false;
			}
		);
	}

	/**
	 * RESET CONFIRM SUBMIT
	 */
	resetConfirmSubmit() {
		this.loading = true;
		const password = this.formResetConfirm.value.password;

		this.authService.updatePassword(this.resetEmail, this.resetPasswordCode, password).subscribe(
			response => {
				if (response.status === 200) {
					this.resetConfirmSuccess = true;
					this.message = "Your password has been updated.";
					this.loading = false;
				} else {
					this.resetConfirmSuccess = true;
					this.message = "An error occurred."
					this.loading = false;
				}
			},
			err => {
				this.resetConfirmSuccess = true;
				this.message = "The reset password code has expired.  You must request a new password reset code."
				this.loading = false;
			}
		);
	}

	/** 
	 * UTILS 
	 * */
	resetPage() {
		this.user = null;
		this.resetEmailSuccess = false;
		this.initResetEmailForm();
	}

	matchingPasswords(passwordKey: string, password2Key: string) {
		// Form validation functions are passed the entire FormGroup and return a validation message formatted as {String: object}
		return (group: UntypedFormGroup): { [key: string]: any } => {
			// get the control objects for the two password fields
			const password = group.controls[passwordKey];
			const password2 = group.controls[password2Key];

			// compare the value of the two password fields
			if (password.value !== password2.value) {
				// if they're different, set a validation error on password2
				password2.setErrors(Validators);
				// return a validation property for the form
				return {
					mismatchedPasswords: true
				};
			} else {
				// if they're the same, remove any validation errors on password2
				password2.setErrors(null);
			}
		};
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
