import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
	loading = true;

	form: UntypedFormGroup;
	sessionTimeout = false;
	loginSuccess = false;

	userChangedSubscription: Subscription;

	eventCode1;
	eventCode2;

	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private toastr: ToastrService,
		private photoService: PhotoService,
		private userService: UserService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (this.authService.isAuthenticated()) {
			this.authService.signOutUser();
		}

		this.initLoginForm();

		this.route.queryParams.subscribe(params => {
			if (params.eventCode1) {
				this.eventCode1 = params.eventCode1;
			}
			if (params.eventCode2) {
				this.eventCode2 = params.eventCode2;
			}
		});

		this.loading = false;
	}

	ngOnDestroy() { }

	/**
	 * INIT LOGIN FORM
	 */
	initLoginForm() {
		this.form = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required
			]),
			'password': new UntypedFormControl(null, [
				Validators.required
			])
		});
	}

	/**
	 * LOGIN SUBMIT
	 * 1. Get the values from the login form
	 * 2. Reset global session variables
	 * 3. Authenticate the user
	 * 4. If success, store the user object and check for a new terms of service
	 * 5. If failure, show error message
	 */
	loginSubmit() {
		this.loading = true;
		this.sessionTimeout = false;

		const email = this.form.value.email;
		const password = this.form.value.password;

		this.authService.signInUser(email, password).subscribe(
			response => {
				if (response.status === 200) {
					const user: User = response.body;

					// This clears the cache and then sets the user data.
					this.authService.setUserData(user);

					// Retrieve download tokens so the explore page is quicker to load.
					this.retrieveDownloadTokens(user.id);

					// This starts the animation
					this.loginSuccess = true;

					// Delay the navigation by two seconds to allow the animation to finish.
					let _this = this;
					setTimeout(function () {
						if (_this.eventCode1 && _this.eventCode2) {
							_this.navigate('/event/' + _this.eventCode1 + '/' + _this.eventCode2);
						} else {
							_this.navigate('/dashboard');
						}
					}, 2000);
				} else {
					this.toastr.error('Invalid username or password');
					this.loading = false;
				}
			},
			err => {
				this.toastr.error('Invalid username or password');
				this.loading = false;
			}
		);
	}

	retrieveDownloadTokens(userId) {
		this.photoService.getSignedDownloadToken(userId, 'userPublic').subscribe(
			response => {
				let responseToken = response.body;
				responseToken.userId = userId;

				// Make sure token doesn't already exist.
				let token2;
				for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
					if (signedDownloadToken.userId == userId && signedDownloadToken.type == 'userPublic') {
						token2 = signedDownloadToken;
					}
				}
				if (!token2) {
					this.photoService.signedDownloadTokens.push(responseToken);
				}
			}
		);
		this.photoService.getSignedDownloadToken(userId, 'userPrivate').subscribe(
			response => {
				let responseToken = response.body;
				responseToken.userId = userId;

				// Make sure token doesn't already exist.
				let token2;
				for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
					if (signedDownloadToken.userId == userId && signedDownloadToken.type == 'userPrivate') {
						token2 = signedDownloadToken;
					}
				}
				if (!token2) {
					this.photoService.signedDownloadTokens.push(responseToken);
				}
			}
		);
		this.photoService.getSignedDownloadToken('profile', 'profile').subscribe(
			response => {
				let responseToken = response.body;

				// Make sure token doesn't already exist.
				let token2;
				for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
					if (signedDownloadToken.type == 'profile') {
						token2 = signedDownloadToken;
					}
				}
				if (!token2) {
					this.photoService.signedDownloadTokens.push(responseToken);
				}
			}
		);
	}

	/**
	 * NAVIGATE
	 * @param path
	 */
	navigate(path) {
		this.router.navigate([path]);
	}
}
