import { EventCommentReactionAngry } from "./event-comment-reaction-angry.model";
import { EventCommentReactionLaugh } from "./event-comment-reaction-laugh.model";
import { EventCommentReactionLike } from "./event-comment-reaction-like.model";
import { EventCommentReactionLove } from "./event-comment-reaction-love.model";
import { EventCommentReactionSad } from "./event-comment-reaction-sad.model";

export class EventComment {
    public commentId: number;
    public parentCommentId: number;
    public eventId: number;
    public userId: number;
    public comment: string;
    public date: Date;
    public modified: boolean;
    public lastModifiedDate: Date;
    public removed: boolean;
    public removedDate: Date;
    public removedByUserId: number;

    public reactionsAngry: EventCommentReactionAngry[] = [];
    public reactionsLaugh: EventCommentReactionLaugh[] = [];
    public reactionsLike: EventCommentReactionLike[] = [];
    public reactionsLove: EventCommentReactionLove[] = [];
    public reactionsSad: EventCommentReactionSad[] = [];

    public replies: EventComment[] = [];
}