<!-- Loading -->
<div [ngClass]="{'loading in': loading }" *ngIf="loading">
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
</div>

<!-- No payment method -->
<div class="center" *ngIf="!user?.paymentMethodId && !showAddPaymentMethod && !loading" [ngClass]="{'loading in': loading }">
	<div class="margin-auto">
		<div class="no-message">You have no payment method</div>
		<div class="button offset" (click)="showAddPayment()">Add Payment Method</div>
	</div>
</div>

<!-- Payment method -->
<div *ngIf="user?.paymentMethodId && !showAddPaymentMethod && !loading" [ngClass]="{'loading in': loading }">
	<div *ngIf="paymentMethodExpiring" class="warning">
		WARNING: Your payment method will expire within the next 60 days. Make sure to update your payment method so your account remains active.
	</div>
	<div class="warning">
		<div class="row">
			<div class="col-md-8">
				<i *ngIf="user?.paymentMethodCcBrand == 'visa'" class="fab fa-cc-visa"></i>
				<i *ngIf="user?.paymentMethodCcBrand == 'mastercard'" class=" fab fa-cc-mastercard"></i>
				<i *ngIf="user?.paymentMethodCcBrand !== 'visa' && user.paymentMethodCcBrand !== 'mastercard'" class="far fa-credit-card"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<span>Credit card ending in &middot;&middot;&middot;&middot; {{ user?.paymentMethodCcLastFour }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<span>Expires {{ user?.paymentMethodCcExpirationMonth }}/{{ user?.paymentMethodCcExpirationYear }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			</div>
			<div class="col-md-4 right data"><i class="far fa-times-circle" (click)="showDeletePaymentMethodDialog()"></i></div>
		</div>
	</div>
	<div class="button" (click)="showAddPayment()">Update Payment Method</div>
</div>

<!-- Add payment method -->
<div class="container" [ngClass]="{'hidden': !showAddPaymentMethod, 'loading in': loading }">
	<form #checkout="ngForm" (ngSubmit)="createSetupIntent()" class="checkout">
		<div [ngClass]="{'hidden': loading }">
			<div class="card-container">
				<div id="card-info" #cardInfo></div>
			</div>
			<div id="card-errors" role="alert" *ngIf="error" class="card-error">{{ error }}</div>
			<div class="button-container">
				<button class="button" type="reset" (click)="cancelAddPayment()">Cancel</button>
				<button class="button" type="submit">Submit</button>
			</div>
		</div>
	</form>
</div>

<!-- Delete confirm dialog -->
<ng-template #deletePaymentMethodDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-credit-card"></em> Delete Payment Method</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<div *ngIf="canDeletePaymentMethod && !loading">Are you sure you want to delete the following payment method?</div>
		<div *ngIf="!canDeletePaymentMethod && !loading">You cannot delete a payment method that is currently associated with a vault.</div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-danger btn-md" data-dismiss="modal" (click)="deletePaymentMethodConfirm()" *ngIf="canDeletePaymentMethod">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>