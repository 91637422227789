import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-widget-photos-my-uncategorized-graph',
	templateUrl: './widget-photos-my-uncategorized-graph.component.html',
	styleUrls: ['./widget-photos-my-uncategorized-graph.component.css']
})
export class WidgetPhotosMyUncategorizedGraphComponent implements OnInit {
	loading = true;

	userPhotoCount: any;
	userPhotoCountChangedSubscription: Subscription;

	pieSliceUncategorized1Seconds = 0;
	pieSliceUncategorized2Seconds = 0;

	pieSliceUncategorized1Degrees = 0;
	pieSliceUncategorized2Degrees = 0;
	pieSliceUncategorizedFillerDegrees = 0;

	constructor(
		private photoService: PhotoService
	) { }

	ngOnInit() {
		this.subscribeToPhotoCountChanged();

		if (this.photoService.loadingUserPhotoCount) {
			// Another component is already loading the count, do nothing and wait for subscription.
		} else {
			// No component is already loading the count, trigger it and wait for subscription.
			this.retrievePhotoCount();
		}
	}

	subscribeToPhotoCountChanged() {
		this.userPhotoCountChangedSubscription = this.photoService.userPhotoCountChanged
			.subscribe((value: any) => {
				this.photoService.loadingUserPhotoCount = false;

				let uncategorizedPercent = ((value.myUncategorizedPhotos) / (value.vaultPhotos - value.connectionPhotos));
				if (uncategorizedPercent > .5) {
					this.pieSliceUncategorized1Seconds = .5;
					this.pieSliceUncategorized2Seconds = (uncategorizedPercent - .5);
				} else {
					this.pieSliceUncategorized1Seconds = uncategorizedPercent;
					this.pieSliceUncategorized2Seconds = 0;
				}

				// Delay setting the CSS specs to make the animatons more consistant.
				let _this = this;
				setTimeout(function () {
					let uncategorizedPercent = (((value.myUncategorizedPhotos) / (value.vaultPhotos - value.connectionPhotos)) * 360);
					if (uncategorizedPercent > 180) {
						_this.pieSliceUncategorized1Degrees = 180;
						_this.pieSliceUncategorized2Degrees = (uncategorizedPercent - 180);
					} else {
						_this.pieSliceUncategorized1Degrees = uncategorizedPercent;
						_this.pieSliceUncategorized2Degrees = 0;
					}
					_this.pieSliceUncategorizedFillerDegrees = 180;
				}, 100);

				this.userPhotoCount = value;
				this.loading = false;
			});
	}

	retrievePhotoCount() {
		this.loading = true;
		this.photoService.loadingUserPhotoCount = true;

		this.photoService.getTotalPhotoCount().subscribe(
			response => {
				if (response.status === 200) {
					this.photoService.setAndAnnounceUserPhotoCount(response.body);
				}
			}
		);
	}
}
