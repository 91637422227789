import { Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, OnDestroy {
	@ViewChild('registerCodeDialog', { static: true }) registerCodeDialog: TemplateRef<any>;

	modalRef: BsModalRef;
	form: UntypedFormGroup;
	formRegisterCode: UntypedFormGroup;

	startAnimation1 = false;
	startAnimation2 = false

	loading = false;
	loadingRegister = false;

	constructor(
		private authService: AuthService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private toastr: ToastrService,
		private modalService: BsModalService,
		private renderer: Renderer2
	) { }

	ngOnInit() {
		this.initForm();

		let _this = this;
		setTimeout(function () {
			_this.startAnimation1 = true;
		}, 100);

		setTimeout(function () {
			_this.startAnimation2 = true;
		}, 300);

	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	/**
	 * INIT REGISTER FORM
	 */
	initForm() {
		this.form = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required,
				Validators.email
			])
		});
	}

	initFormRegisterCode() {
		this.formRegisterCode = this.formBuilder.group({
			'reg1': new UntypedFormControl(null, [Validators.required]),
			'reg2': new UntypedFormControl(null, [Validators.required]),
			'reg3': new UntypedFormControl(null, [Validators.required]),
			'reg4': new UntypedFormControl(null, [Validators.required]),
			'reg5': new UntypedFormControl(null, [Validators.required])
		});

		// Auto-select the first input
		const element = this.renderer.selectRootElement('#reg1');
		setTimeout(() => element.focus(), 0);
	}

	showRegisterSubmitConfirm() {
		this.modalRef = this.modalService.show(
			this.registerCodeDialog,
			Object.assign({}, {}, { backdrop: true, ignoreBackdropClick: true })
		);

		this.initFormRegisterCode();
	}

	registerCodeEntered(input: number) {
		if (input == 1) {
			// Auto-select the input
			const element = this.renderer.selectRootElement('#reg2');
			setTimeout(() => element.focus(), 0);
		} else if (input == 2) {
			const element = this.renderer.selectRootElement('#reg3');
			setTimeout(() => element.focus(), 0);
		} else if (input == 3) {
			const element = this.renderer.selectRootElement('#reg4');
			setTimeout(() => element.focus(), 0);
		} else if (input == 4) {
			const element = this.renderer.selectRootElement('#reg5');
			setTimeout(() => element.focus(), 0);
		} else if (input == 5) {
			let _this = this;
			setTimeout(function () {
				if (_this.formRegisterCode.value.reg1?.length > 0 && _this.formRegisterCode.value.reg2?.length > 0 && _this.formRegisterCode.value.reg3?.length > 0 && _this.formRegisterCode.value.reg4?.length > 0 && _this.formRegisterCode.value.reg5?.length > 0) {
					_this.registerSubmit(_this.formRegisterCode.value.reg1 + _this.formRegisterCode.value.reg2 + _this.formRegisterCode.value.reg3 + _this.formRegisterCode.value.reg4 + _this.formRegisterCode.value.reg5);
				}
			}, 0);
		}
	}

	/**
	 * REGISTER SUBMIT
	 */
	registerSubmit(registerCode: string) {
		this.loadingRegister = true;
		this.authService.registerEmail(this.form.value.email, registerCode).subscribe(
			response => {
				if (response.status === 200) {
					this.toastr.success('Success. Please check your inbox.');
				} else {
					this.toastr.error('An eror occurred 1.');
				}

				this.modalRef.hide();
				this.loadingRegister = false;
			},
			err => {
				if (err.status === 418) {
					this.toastr.error('Invalid registration code.');
				} else if (err.status === 500) {
					this.toastr.error(err.error);
				} else {
					this.toastr.error('An eror occurred 2.');
				}

				this.modalRef.hide();
				this.loadingRegister = false;
			}
		);
	}

	navigate(path,) {
		this.router.navigate([path]);
	}

}
