<div class="row justify-content-center g-0">
	<div class="col-md-11">
		<div class="quick-links row">
			<!-- Filter / Title Card -->
			<div class="container col-12  col-md-4 col-lg-3" *ngIf="!loading">
				<app-card-user-settings-title [selectedFilter]="selectedFilter" [filters]="availableFilters" (emitFilterChanged)="handleFilterChanged($event)"></app-card-user-settings-title>
			</div>

			<div class="container col-12 col-md-8 col-lg-9">
				<!-- General -->
				<div class="content-container" *ngIf="selectedFilter == 'general'" [ngClass]="{'loading in': loading }">
					<div class="row">
						<div class="col-md-4">
							<label for="firstName">First Name</label>
						</div>
						<div class="col-md-8" [ngClass]="{'loading in': loadingFirstNameSave }">
							<form #fn="ngForm" [formGroup]="formFirstName" class="form">
								<input id="name" type="text" class="form-control" [ngClass]="{'success': editFirstNameSuccess }" formControlName="firstName" [value]="user?.firstName" [attr.disabled]="!editFirstName ? '' : null" />
								<i *ngIf="!editFirstName" class="fas fa-edit edit-icon" title="Edit First Name" (click)="editFirstNameStart()"></i>
								<i *ngIf="editFirstName" class="fas fa-check green edit-icon" title="Save First Name" (click)="editFirstNameSave()"></i>
								<i *ngIf="editFirstName" class="fas fa-times red edit-icon cancel" title="Cancel" (click)="editFirstNameCancel()"></i>
							</form>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<label for="lastName">Last Name</label>
						</div>
						<div class="col-md-8" [ngClass]="{'loading in': loadingLastNameSave }">
							<form #ln="ngForm" [formGroup]="formLastName" class="form">
								<input id="lastName" type="text" class="form-control" [ngClass]="{'success': editLastNameSuccess }" formControlName="lastName" [value]="user?.lastName" [attr.disabled]="!editLastName ? '' : null" />
								<i *ngIf="!editLastName" class="fas fa-edit edit-icon" title="Edit Last Name" (click)="editLastNameStart()"></i>
								<i *ngIf="editLastName" class="fas fa-check green edit-icon" title="Save Last Name" (click)="editLastNameSave()"></i>
								<i *ngIf="editLastName" class="fas fa-times red edit-icon cancel" title="Cancel" (click)="editLastNameCancel()"></i>
							</form>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<label for="email">E-Mail Address</label>
						</div>
						<div class="col-md-8">
							<input *ngIf="user" id="email" type="text" class="form-control" [value]="user.email" disabled />
							<!--<i class="fas fa-edit edit-icon" title="Edit Name"></i>-->
						</div>
					</div>
					<!-- TODO: Make this into an Under 18 checkbox instead of showing their date.
					<div class="row">
						<div class="col-md-4">
							<label for="birthDate">Date of Birth</label>
						</div>
						<div class="col-md-8">
							<input *ngIf="user" id="birthDate" type="text" class="form-control" [value]="user.birthDate | date:'longDate'" disabled />
						</div>
					</div>
					-->
					<div class="row">
						<div class="col-md-4">
							<label for="uuid">Connection Code</label>
						</div>
						<div class="col-md-8">
							<input *ngIf="user" id="uuid" type="text" class="form-control" [value]="user.uuid" disabled />
						</div>
					</div>

					<div class="row" [ngClass]="{'hidden': editPassword, 'loading in': loadingPasswordSave }">
						<div class="col-md-4">
							<label for="currentPassword">Password</label>
						</div>
						<div class="col-md-8">
							<div class="password-cell">**********</div>
							<i class="fas fa-edit edit-icon" title="Edit Password" (click)="editPasswordStart()"></i>
						</div>
					</div>

					<form #p="ngForm" [formGroup]="formPassword" class="form">
						<div class="row" [ngClass]="{'hidden': !editPassword }">
							<div class="col-md-4">
								<label for="currentPassword">Current Password</label>
							</div>
							<div class="col-md-8">
								<input id="currentPassword" type="password" class="form-control" [ngClass]="{'success': editPasswordSuccess, 'fail': editPasswordFailure }" formControlName="currentPassword" [attr.disabled]="!editPassword ? '' : null" />

							</div>
						</div>
						<div class="row" [ngClass]="{'hidden': !editPassword }">
							<div class="col-md-4">
								<label for="password">New Password</label>
							</div>
							<div class="col-md-8">
								<input id="password" type="password" class="form-control" [ngClass]="{'success': editPasswordSuccess, 'fail': editPasswordFailure }" formControlName="password" [attr.disabled]="!editPassword ? '' : null" />
							</div>
						</div>
						<div class="row" [ngClass]="{'hidden': !editPassword }">
							<div class="col-md-4">
								<label for="password2">Repeat New Password</label>
							</div>
							<div class="col-md-8">
								<input id="password2" type="password" class="form-control" [ngClass]="{'success': editPasswordSuccess, 'fail': editPasswordFailure }" formControlName="password2" [attr.disabled]="!editPassword ? '' : null" />
								<i *ngIf="editPassword" class="fas fa-check green edit-icon" title="Save Password" (click)="editPasswordSave()"></i>
								<i *ngIf="editPassword" class="fas fa-times red edit-icon cancel" title="Cancel" (click)="editPasswordCancel()"></i>
							</div>
						</div>
					</form>
					<div class="row last">
						<div class="col-md-4">
							<label for="userImage">Profile Image</label>
						</div>
						<div class="col-md-8">
							<i *ngIf="loadingImage" class="fas fa-circle-notch fa-spin image-loading-indicator"></i>
							<div *ngIf="!user?.imageSafeUrl && !loadingImage" class="user-image-cell">
								<input id="file-userImage" class="hidden" type="file" (change)="uploadProfileImageStep1($event)" />
								<label for="file-userImage" class="btn btn-success clear">Choose a file...</label>
							</div>
							<div *ngIf="user?.imageSafeUrl && !loadingImage" class="user-image-cell">
								<img [src]="user.imageSafeUrl" class="user-image" />
								<button type="button" class="btn btn-danger clear" (click)="clearImage()">Clear Image</button>
							</div>
						</div>
					</div>
				</div>

				<!-- Default Settings -->
				<div class="content-container" *ngIf="selectedFilter == 'defaults'" [ngClass]="{'loading in': loading }">
					<ng-container *ngIf="!loading">
						<div class="settings-text">Choose which connections photos will be displayed by default when exploring photos:</div>
						<span class="connection-select user current" tooltip="Your user will always be selected by default" placement="bottom">
							<div *ngIf="user?.imageSafeUrl"><img [src]="user?.imageSafeUrl" class="initials image" /></div>
							<div *ngIf="!user?.imageSafeUrl">{{ user?.firstName.charAt(0) }}{{ user?.lastName.charAt(0)}}</div>
						</span>

						<ng-container *ngFor="let user of connectionsActive">
							<app-profile-connection-icon [user]="user" [defaultPhotoOwnerUserIdsArray]="defaultPhotoOwnerUserIdsArray"></app-profile-connection-icon>
						</ng-container>

						<div class="settings-text">Choose the default settings that will be used when uploading photos:</div>
						<form #ps="ngForm" [formGroup]="formPhotoSettings" class="form">
							<div class="row">
								<div class="col-md-6">
									<label for="connectionsCanView">Allow connections to view photos</label>
								</div>
								<div class="col-md-6 right">
									<div class="slider-container">
										<label class="switch">
											<input id="connectionsCanViewSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanView" (change)="connectionsCanViewSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<label for="connectionsCanReact">Allow connections to react to photos</label>
								</div>
								<div class="col-md-6 right">
									<div class="slider-container" [ngClass]="{'hidden': formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanReactSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanReact" (change)="connectionsCanReactSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<label for="connectionsCanDiscuss">Allow connections to discuss photos</label>
								</div>
								<div class="col-md-6 right">
									<div class="slider-container" [ngClass]="{'hidden': formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanDiscussSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanDiscuss" (change)="connectionsCanDiscussSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<label for="connectionsCanSuggest">Allow connections to suggest metadata</label>
								</div>
								<div class="col-md-6 right">
									<div class="slider-container" [ngClass]="{'hidden': formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSuggestSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSuggest" (change)="connectionsCanSuggestSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
							<div class="row last">
								<div class="col-md-6">
									<label for="connectionsCanSeeExif">Allow connections to see EXIF data</label>
								</div>
								<div class="col-md-6 right">
									<div class="slider-container" [ngClass]="{'hidden': formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !formPhotoSettings.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSeeExifSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSeeExif" (change)="connectionsCanSeeExifSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</form>
					</ng-container>
				</div>

				<!-- Account Statistics -->
				<div class="content-container" *ngIf="selectedFilter == 'statistics'" [ngClass]="{'loading in': loading || loadingLocations }">
					<ng-container *ngIf="!loading && !loadingLocations">
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfPhotos">Number of photos</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data">{{ userPhotoCount?.vaultPhotos || 0 }}</div>
							</div>
						</div>
						<!--
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfFacebookPhotos">Number of Facebook Photos</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data">{{ userPhotoCount?.facebookPhotos || 0 }}</div>
							</div>
						</div>
						
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfUploadedPhotos">Number of Uploaded Photos</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data">{{ userPhotoCount?.uploadedPhotos || 0 }}</div>
							</div>
						</div>
						-->
						<!--
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfActiveConnections">Number of Active Connections</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data"></div>
							</div>
						</div>
						-->
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfLogins">Number of custom keywords</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="keywords" class="data">{{ keywords.length }}</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label for="numerOfLogins">Number of custom locations</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="locations" class="data">{{ locations.length }}</div>
							</div>
						</div>
						<div class="row last">
							<div class="col-md-6">
								<label for="numerOfLogins">Number of total logins</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data">{{ user.loginCount || 1 }}</div>
							</div>
						</div>
						<!--
						<div class="row last">
							<div class="col-md-6">
								<label for="numerOfLogins">Total Size of Photos (MB)</label>
							</div>
							<div class="col-md-6">
								<div *ngIf="user" class="data">{{ userPhotoSize }}</div>
							</div>
						</div>
						-->
					</ng-container>
				</div>

				<!-- Manage Payment Method -->
				<div class="content-container" *ngIf="selectedFilter == 'payment'">
					<app-payment-method-manage></app-payment-method-manage>
				</div>

				<!-- Manage Keywords -->
				<div class="content-container" *ngIf="selectedFilter == 'keywords'" [ngClass]="{'loading in': loadingKeywords }">
					<div class="center no-message" *ngIf="keywords && keywords.length == 0 && !loadingKeywords">You have no user defined keywords</div>
					<div class="row" *ngFor="let keyword of keywords; let i = index" [ngClass]="{ 'last': i == keywords.length-1 }">
						<div class="col-md-6">
							<label><a (click)="searchKeyword( keyword.name )">{{ keyword.name }}</a></label>
						</div>
						<div class="col-md-6">
							<div class="data"><i class="far fa-times-circle" (click)="deleteKeyword(keyword.name)"></i></div>
						</div>
					</div>
				</div>

				<!-- Manage Locations -->
				<div class="content-container" *ngIf="selectedFilter == 'locations'" [ngClass]="{'loading in':  loadingLocations }">
					<div class="center no-message" *ngIf="locations && locations.length == 0 && !loadingLocations">You have no user defined locations</div>
					<div class="row" *ngFor="let location of locations; let j = index" [ngClass]="{ 'last': j == locations.length-1 }">
						<div class="col-md-6">
							<label><a (click)="searchLocation( location.name )">{{ location.name }}</a></label>
						</div>
						<div class="col-md-6">
							<div class="data"><i class="far fa-times-circle" (click)="deleteLocation(location.name)"></i></div>
						</div>
					</div>
				</div>

				<!-- Manage People -->
				<div class="content-container" *ngIf="selectedFilter == 'people'" [ngClass]="{'loading in':  loadingPeople }">
					<div class="center no-message" *ngIf="people && people.length == 0 && !loadingPeople">You have no user defined people</div>
					<div class="row" *ngFor="let person of people; let k = index" [ngClass]="{ 'last': k == people.length-1 }">
						<div class="col-md-6">
							<label><a (click)="searchPerson( person.name )">{{ person.name }}</a></label>
						</div>
						<div class="col-md-6">
							<div class="data"><i class="far fa-times-circle" (click)="deletePerson(person.name)"></i></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #confirmDeleteLocationDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<p class="center">You are about to delete the following location. Continue?</p>
		<p class="center bold">{{ locationDelete }}</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deleteLocationConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #confirmDeleteKeywordDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<p class="center">You are about to delete the following keyword. Continue?</p>
		<p class="center bold">{{ keywordDelete }}</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deleteKeywordConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #confirmDeletePersonDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<p class="center">You are about to delete the following person. Continue?</p>
		<p class="center bold">{{ personDelete }}</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deletePersonConfirm()">Confirm</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>