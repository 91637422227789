<div class="row justify-content-center g-0">
	<div class="col-md-11" [ngClass]="{'loading in': loading }">
		<div class="quick-links row">
			<!-- Filter / Title Card -->
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-connections-title [selectedFilter]="selectedFilter" [filters]="availableFilters" (emitFilterChanged)="handleFilterChanged($event)"></app-card-connections-title>
			</div>

			<!-- Add -->
			<ng-container *ngIf="selectedFilter == 'add'">
				<div class="container col-sm-6 col-lg-4 col-xl-3">
					<app-card-connection-copy-to-clipboard></app-card-connection-copy-to-clipboard>
				</div>
				<div class="container col-sm-6 col-lg-4 col-xl-3">
					<app-card-connection-send-request></app-card-connection-send-request>
				</div>
				<div class="container col-sm-6 col-lg-4 col-xl-3">
					<app-card-connection-invite></app-card-connection-invite>
				</div>
			</ng-container>

			<!-- All -->
			<ng-container *ngIf="selectedFilter == 'all'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of connectionUsersAll">
					<app-card-connection-view [user]="user" (emitConnectionApproved)="approveConnectionRequest($event)" (emitConnectionRejected)="rejectConnectionRequest($event)" (emitConnectionCancelled)="cancelConnectionRequest($event)" (emitConnectionBlocked)="blockConnection($event)" (emitConnectionUnblocked)="unblockConnection($event)" (emitConnectionRemoved)="removeConnectionRequest($event)"></app-card-connection-view>
				</div>
			</ng-container>

			<!-- Active -->
			<ng-container *ngIf="selectedFilter == 'active'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of connectionUsersActive">
					<app-card-connection-view [user]="user" (emitConnectionApproved)="approveConnectionRequest($event)" (emitConnectionRejected)="rejectConnectionRequest($event)" (emitConnectionCancelled)="cancelConnectionRequest($event)" (emitConnectionBlocked)="blockConnection($event)" (emitConnectionUnblocked)="unblockConnection($event)" (emitConnectionRemoved)="removeConnectionRequest($event)"></app-card-connection-view>
				</div>
			</ng-container>

			<!-- Pending -->
			<ng-container *ngIf="selectedFilter == 'pending'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of connectionUsersPending">
					<app-card-connection-view [user]="user" (emitConnectionApproved)="approveConnectionRequest($event)" (emitConnectionRejected)="rejectConnectionRequest($event)" (emitConnectionCancelled)="cancelConnectionRequest($event)" (emitConnectionBlocked)="blockConnection($event)" (emitConnectionUnblocked)="unblockConnection($event)" (emitConnectionRemoved)="removeConnectionRequest($event)"></app-card-connection-view>
				</div>
			</ng-container>

			<!-- Waiting -->
			<ng-container *ngIf="selectedFilter == 'waiting'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of connectionUsersWaiting">
					<app-card-connection-view [user]="user" (emitConnectionApproved)="approveConnectionRequest($event)" (emitConnectionRejected)="rejectConnectionRequest($event)" (emitConnectionCancelled)="cancelConnectionRequest($event)" (emitConnectionBlocked)="blockConnection($event)" (emitConnectionUnblocked)="unblockConnection($event)" (emitConnectionRemoved)="removeConnectionRequest($event)"></app-card-connection-view>
				</div>
			</ng-container>

			<!-- Blocked -->
			<ng-container *ngIf="selectedFilter == 'blocked'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of connectionUsersBlocked">
					<app-card-connection-view [user]="user" (emitConnectionApproved)="approveConnectionRequest($event)" (emitConnectionRejected)="rejectConnectionRequest($event)" (emitConnectionCancelled)="cancelConnectionRequest($event)" (emitConnectionBlocked)="blockConnection($event)" (emitConnectionUnblocked)="unblockConnection($event)" (emitConnectionRemoved)="removeConnectionRequest($event)"></app-card-connection-view>
				</div>
			</ng-container>
		</div>
	</div>
</div>