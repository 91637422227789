import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'typeFilter',
	pure: false
})
@Injectable()
export class FilterPipe implements PipeTransform {
	transform(value: any, filterBy: string): any[] {
		return value.filter((item: any) => item.type === filterBy);
	}
}
