<div class="card" #card>
	<div class="face front">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<i class="fas fa-barcode"></i>
		<div class="text">Send users your code to initiate a request<br />{{ userUuid }}</div>
		<div class="button-container">
			<div class="button" (click)="copyToClipboard( userUuid )">Copy to Clipboard</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Connection Codes</div>
		<div class="help-text">With Photonomy, you can sync photos from external sources such as Facebook and OneDrive. You can choose to import the photo into Photonomy or leave it in the external source.</div>
	</div>
</div>