<div class="comment-container">
	<div class="column">
		<div class="name">
			<i class="fas fa-circle-notch fa-spin" *ngIf="loadingUser"></i>
			<ng-container *ngIf="user?.imageSafeUrl && !loadingUser">
				<img [src]="user.imageSafeUrl" class="image" />
			</ng-container>
			<ng-container *ngIf="user?.firstName && !loadingUser">
				{{ user.firstName }} {{ user.lastName }}
			</ng-container>
			<span class="time" [title]="photoComment.date | date:'full'">{{ timeInPast }}</span>
		</div>
		<div class="reactions-container">
			<span *ngIf="photoComment.reactionsLike.length > 0" class="reaction-container" [ngClass]="{'active': hasLikeReaction }">
				<i class="fas fa-thumbs-up"></i> <span class="number">{{ photoComment.reactionsLike.length }}</span>
			</span>
			<span *ngIf="photoComment.reactionsLove.length > 0" class="reaction-container" [ngClass]="{'active': hasLoveReaction }">
				<i class="fas fa-heart"></i> <span class="number">{{ photoComment.reactionsLove.length }}</span>
			</span>
			<span *ngIf="photoComment.reactionsSad.length > 0" class="reaction-container" [ngClass]="{'active': hasSadReaction }">
				<i class="fas fa-heart-broken"></i> <span class="number">{{ photoComment.reactionsSad.length }}</span>
			</span>
			<span *ngIf="photoComment.reactionsLaugh.length > 0" class="reaction-container" [ngClass]="{'active': hasLaughReaction }">
				<i class="fas fa-laugh-beam"></i> <span class="number">{{ photoComment.reactionsLaugh.length }}</span>
			</span>
			<span *ngIf="photoComment.reactionsAngry.length > 0" class="reaction-container" [ngClass]="{'active': hasAngryReaction }">
				<i class="fas fa-angry"></i> <span class="number">{{ photoComment.reactionsAngry.length }}</span>
			</span>
		</div>
	</div>
	<div class="comment">{{ photoComment.comment }}</div>
	<div class="actions-container">
		<div *ngIf="photoComment.replies.length > 0 && !showReplies" class="show-conversation-container">
			<a (click)="showReplies = true;">Show replies...</a>
		</div>
		<div class="reaction-select-container" *ngIf="showReactionContainer">
			<a class="like" (click)="showReactionContainer = false">Cancel</a>
			<span class="reaction"><i class="fas fa-thumbs-up" (click)="addReactionLike()"></i></span>
			<span class="reaction"><i class="fas fa-heart" (click)="addReactionLove()"></i></span>
			<span class="reaction"><i class="fas fa-heart-broken" (click)="addReactionSad()"></i></span>
			<span class="reaction"><i class="fas fa-laugh-beam" (click)="addReactionLaugh()"></i></span>
			<span class="reaction"><i class="fas fa-angry" (click)="addReactionAngry()"></i></span>
		</div>
		<div *ngIf="!showReactionContainer && allowReplies">
			<a class="like" *ngIf="!hasLikeReaction && !hasLoveReaction && !hasAngryReaction && !hasSadReaction && !hasLaughReaction" (click)="addReaction()">Like</a>

			<a class="like" *ngIf="hasLikeReaction" (click)="removeReactionLike()">Unlike</a>
			<a class="like" *ngIf="hasLoveReaction" (click)="removeReactionLove()">Unlike</a>
			<a class="like" *ngIf="hasAngryReaction" (click)="removeReactionAngry()">Unlike</a>
			<a class="like" *ngIf="hasSadReaction" (click)="removeReactionSad()">Unlike</a>
			<a class="like" *ngIf="hasLaughReaction" (click)="removeReactionLaugh()">Unlike</a>

			<a class="reply" *ngIf="!showReplyContainer" (click)="addReply()">Reply</a>
			<a class="reply" *ngIf="showReplyContainer" (click)="cancelReply()">Cancel Reply</a>
			<a class="edit" (click)="editComment()">Edit</a>
		</div>
	</div>
	<div class="reply-container" [ngClass]="{'hidden': !showReplyContainer}">
		<form #f="ngForm" [formGroup]="replyForm" class="form">
			<i class="fas fa-comment comment-button" (click)="addReplySubmit()"></i>
			<input id="reply" type="text" class="form-control comment-input" maxlength="500" formControlName="reply" placeholder="Submit a reply" />
		</form>
	</div>
	<div class="replies-container" *ngIf="photoComment.replies.length > 0 && showReplies">
		<ng-container *ngFor="let reply of photoComment.replies">
			<app-photo-comment [photoComment]="reply" [showReplies]="showReplies" [allowReplies]="allowReplies"></app-photo-comment>
		</ng-container>
	</div>
</div>