import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Photo } from 'src/app/models/photo.model';
import { User } from 'src/app/models/user.model';
import { PhotoService } from 'src/app/services/photo.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-photo-action-container',
	templateUrl: './photo-action-container.component.html',
	styleUrls: ['./photo-action-container.component.css']
})
export class PhotoActionContainerComponent implements OnInit {
	@Input() photo: Photo;
	@Input() i: number;

	@Output() emitSetSelectedPhotoPointer = new EventEmitter<number>();
	@Output() emitOpenPhotoModalClassify = new EventEmitter<Photo>();
	@Output() emitOpenPhotoModalDiscuss = new EventEmitter<Photo>();

	myUserId: number;

	photoOwner: User;
	photoOwnerIsConnection = false;
	photoOwnerConnectionStatus = ''; // active,pending,waiting

	userChangedSubscription: Subscription;

	showEditPhotoDetailsMessage = false;
	showViewPhotoDetailsMessage = false;
	showDiscussPhotoMessage = false;
	showReactToPhotoMessage = false;
	showUnreactToPhotoMessage = false;
	showSendRequestMessage = false;

	showActionsContainer = false;
	showUserActionsContainer = false;
	showReactionIcons = false;

	hasLikeReaction = false;
	hasLoveReaction = false;
	hasSadReaction = false;
	hasAngryReaction = false;
	hasLaughReaction = false;

	loading = true;

	constructor(
		private photoService: PhotoService,
		public userService: UserService,
		private userConnectionService: UserConnectionService
	) { }

	ngOnInit() {
		this.myUserId = this.userService.getLocalUserId(0);

		this.photoOwner = new User();
		this.photoOwner.id = this.photo.userId;

		this.setReactionFlags();

		// Figure out why I was looking for initial length
		//if (this.photoOwner.initials.length == 0 && this.photo.userId !== this.myUserId) {
		if (this.photo.userId !== this.myUserId) {
			this.subscribeToUserChanged();
			this.userService.getUserByUserId(this.photo.userId, this.i);
		} else {
			this.loading = false;
		}
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				// console.log(this.i + ' == ' + user.photoIndex);
				if (this.i == user.photoIndex) {
					this.photoOwner = user;
					if (user.firstName && user.lastName) {
						this.photoOwner.initials = user.firstName.charAt(0) + user.lastName.charAt(0);
					}

					// TODO: Use this to determine if the current user is a connection to the photo
					//		 owner and if so, what is the status of the connection
					//this.setPhotoOwnerIsConnection();
					this.loading = false;
				}
			}
		);
	}

	setPhotoOwnerIsConnection() {
		if (this.userConnectionService.connections.length == 0) {

		} else {
			for (let connection of this.userConnectionService.connections) {
				if (connection.userUuid1 == this.photoOwner.id || connection.userUuid2 == this.photoOwner.id) {
					this.photoOwnerIsConnection = true;
				}
			}
		}

		this.loading = false;
	}

	sendConnectionRequest() {

	}

	openPhotoDetailsModalClassify(photo) {
		this.showEditPhotoDetailsMessage = false;
		this.showViewPhotoDetailsMessage = false;
		this.showActionsContainer = false;
		this.showReactionIcons = false;

		this.emitOpenPhotoModalClassify.emit(photo);
		this.emitSetSelectedPhotoPointer.emit(this.i);
	}

	openPhotoDetailsModalDiscuss(photo) {
		this.showEditPhotoDetailsMessage = false;
		this.showViewPhotoDetailsMessage = false;
		this.showActionsContainer = false;
		this.showReactionIcons = false;

		this.emitOpenPhotoModalDiscuss.emit(photo);
		this.emitSetSelectedPhotoPointer.emit(this.i);
	}

	/**
	 * REACTIONS
	 */

	setReactionFlags() {
		if (this.photo.reactionsLike.length > 0) {
			for (let photoReactionCommentLike of this.photo.reactionsLike) {
				if (photoReactionCommentLike.userId == this.userService.getLocalUserId(0)) {
					this.hasLikeReaction = true;
					break;
				}
			}
		}
		if (this.photo.reactionsLove.length > 0) {
			for (let photoReactionCommentLove of this.photo.reactionsLove) {
				if (photoReactionCommentLove.userId == this.userService.getLocalUserId(0)) {
					this.hasLoveReaction = true;
					break;
				}
			}
		}
		if (this.photo.reactionsAngry.length > 0) {
			for (let photoReactionCommentAngry of this.photo.reactionsAngry) {
				if (photoReactionCommentAngry.userId == this.userService.getLocalUserId(0)) {
					this.hasAngryReaction = true;
					break;
				}
			}
		}
		if (this.photo.reactionsSad.length > 0) {
			for (let photoReactionCommentSad of this.photo.reactionsSad) {
				if (photoReactionCommentSad.userId == this.userService.getLocalUserId(0)) {
					this.hasSadReaction = true;
					break;
				}
			}
		}
		if (this.photo.reactionsLaugh.length > 0) {
			for (let photoReactionCommentLaugh of this.photo.reactionsLaugh) {
				if (photoReactionCommentLaugh.userId == this.userService.getLocalUserId(0)) {
					this.hasLaughReaction = true;
					break;
				}
			}
		}
	}

	addReactionLike() {
		this.photoService.likePhoto(this.photo.id).subscribe(
			response => {
				this.hasLikeReaction = true;

				let item = { photoId: this.photo.id, userId: this.userService.getLocalUserId(0) };
				this.photo.reactionsLike.push(item);

				this.showActionsContainer = false;
				this.showReactionIcons = false;
			}
		);
	}

	addReactionLove() {
		this.photoService.lovePhoto(this.photo.id).subscribe(
			response => {
				this.hasLoveReaction = true;

				let item = { photoId: this.photo.id, userId: this.userService.getLocalUserId(0) };
				this.photo.reactionsLove.push(item);

				this.showActionsContainer = false;
				this.showReactionIcons = false;
			}
		);
	}

	addReactionSad() {
		this.photoService.sadPhoto(this.photo.id).subscribe(
			response => {
				this.hasSadReaction = true;

				let item = { photoId: this.photo.id, userId: this.userService.getLocalUserId(0) };
				this.photo.reactionsSad.push(item);

				this.showActionsContainer = false;
				this.showReactionIcons = false;
			}
		);
	}

	addReactionLaugh() {
		this.photoService.laughPhoto(this.photo.id).subscribe(
			response => {
				this.hasLaughReaction = true;

				let item = { photoId: this.photo.id, userId: this.userService.getLocalUserId(0) };
				this.photo.reactionsLaugh.push(item);

				this.showActionsContainer = false;
				this.showReactionIcons = false;
			}
		);
	}

	addReactionAngry() {
		this.photoService.angryPhoto(this.photo.id).subscribe(
			response => {
				this.hasAngryReaction = true;

				let item = { photoId: this.photo.id, userId: this.userService.getLocalUserId(0) };
				this.photo.reactionsAngry.push(item);

				this.showActionsContainer = false;
				this.showReactionIcons = false;
			}
		);
	}

	removeReactionLike() {
		this.photoService.unlikePhoto(this.photo.id).subscribe(
			response => {
				this.hasLikeReaction = false;

				const item = this.photo.reactionsLike.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photo.reactionsLike.splice(this.photo.reactionsLike.indexOf(item), 1);

				this.showUnreactToPhotoMessage = false;
				this.showActionsContainer = false;
			}
		);
	}

	removeReactionLove() {
		this.photoService.unlovePhoto(this.photo.id).subscribe(
			response => {
				this.hasLoveReaction = false;

				const item = this.photo.reactionsLove.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photo.reactionsLove.splice(this.photo.reactionsLove.indexOf(item), 1);

				this.showUnreactToPhotoMessage = false;
				this.showActionsContainer = false;
			}
		);
	}

	removeReactionAngry() {
		this.photoService.unangryPhoto(this.photo.id).subscribe(
			response => {
				this.hasAngryReaction = false;

				const item = this.photo.reactionsAngry.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photo.reactionsAngry.splice(this.photo.reactionsAngry.indexOf(item), 1);

				this.showUnreactToPhotoMessage = false;
				this.showActionsContainer = false;
			}
		);
	}

	removeReactionSad() {
		this.photoService.unsadPhoto(this.photo.id).subscribe(
			response => {
				this.hasSadReaction = false;

				const item = this.photo.reactionsSad.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photo.reactionsSad.splice(this.photo.reactionsSad.indexOf(item), 1);

				this.showUnreactToPhotoMessage = false;
				this.showActionsContainer = false;
			}
		);
	}

	removeReactionLaugh() {
		this.photoService.unlaughPhoto(this.photo.id).subscribe(
			response => {
				this.hasLaughReaction = false;

				const item = this.photo.reactionsLaugh.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photo.reactionsLaugh.splice(this.photo.reactionsLaugh.indexOf(item), 1);

				this.showUnreactToPhotoMessage = false;
				this.showActionsContainer = false;
			}
		);
	}
}
