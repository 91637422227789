<div class="card" [ngClass]="{'opposite-colors': oppositeColors}" #card>
	<div class="face front">
		<i class="fas fa-calendar-alt"></i>
		<div class="text">Create an event for a<br /> special occasion or your timeline</div>
		<div class="button-container">
			<div class="button" (click)="navigate('event/create')">Create</div>
		</div>
	</div>
	<div class="face back" [ngClass]="{'loading in': loading}">
		<i class="fas fa-reply" (click)="flipCard()"></i>
		<div class="help-title">Add Event</div>
		<div class="help-text tall">
			<p>Vaults serve the purpose of ensuring long-term ownership of photo assets by setting ownership and beneficiery heirarchies.</p>
			<p>All users with access to a vault can split maintenance costs or even pre-pay costs for future generations.</p>
		</div>
	</div>
</div>