<img class="logo default" src="/assets/images/logo.jpg" (click)="navigate($event, '/photos')">
<i *ngIf="galleryPhotos.length > 1 && galleryType === 'wall'" class="far fa-image type-selector" (click)="galleryType = 'slide'"></i>
<i *ngIf="galleryPhotos.length > 1 && galleryType === 'slide'" class="far fa-images type-selector" (click)="galleryType = 'wall'"></i>

<div *ngIf="error" class="error">An error occurred.</div>

<div *ngIf="!error && !loadingGallery && loadingPhotos || galleryPhotos.length == 0" class="loading-title">
    {{ gallery?.name }}
</div>

<div *ngIf="!error && !loadingGallery && !loadingPhotos && galleryPhotos.length == 0" class="no-photos-message">
    No photos in this gallery
</div>

<div *ngIf="!error && loadingPhotos && (!loadingGallery && galleryPhotos.length == 0)" class="loading in"></div>

<div *ngIf="!error && !loadingGallery && !loadingPhotos && galleryPhotos.length > 0 && galleryType == 'slide'" class="main-container">
    <i *ngIf="galleryPhotos.length > 1 && selectedPhotoPointer !== 0" class="fas fa-angle-left previous" (click)="navigatePreviousPhoto($event)"></i>
    <div class="photo-container">
        <div class="flex-container">
            <img *ngIf="selectedPhoto.imageUrl" [src]="selectedPhoto.imageUrl" />
            <img *ngIf="selectedPhoto.imageSafeUrl" [src]="selectedPhoto.imageSafeUrl" />
            <div class="caption" *ngIf="selectedPhoto?.description">{{ selectedPhoto?.description }}</div>
        </div>
    </div>
    <i *ngIf="galleryPhotos.length > 1 && selectedPhotoPointer !== (galleryPhotos.length - 1)" class="fas fa-angle-right next" (click)="navigateNextPhoto($event)"></i>
</div>

<div [ngClass]="{'hidden': error || loadingGallery || loadingPhotos || galleryPhotos.length == 0 || galleryType !== 'wall'}" class="main-container scroll">
    <infinite-scroll (scrolled)="onScroll()">
        <ngx-masonry>
            <div ngxMasonryItem class="masonry-item" *ngFor="let photo of galleryPhotos; let i = index">
                <img *ngIf="photo.imageUrl" [src]="photo.imageUrl" />
                <img *ngIf="photo.imageSafeUrl" [src]="photo.imageSafeUrl" />
            </div>
        </ngx-masonry>
    </infinite-scroll>
</div>