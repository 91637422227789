<div class="banner">
	<div class="header">
		<div class="left"><img src="assets/images/logo-white.png" /></div>
		<div class="right">
			<!--
			<a>Contact Us</a>
			-->
			<button (click)="navigate('login')">Log In</button>
		</div>
	</div>
	<div class="content">
		<div class="center">
			<h2 class="discover" [ngClass]="{ 'fade': startAnimation1, 'lines': startAnimation2 }">Photonomy</h2>
			<div class="tagline">Protect your photos and keep your memories safe for generations</div>
			<!--
			<div class="info">
				Photonomy allows you to create private or shared photo vaults, which contain a set of distribution
				instructions for your photo assets in the event that you are no longer able to manage your photos. This
				allows you to legally pass down rights to your photos instead of them entering the public domain.
			</div>
			-->
			<div class="form" *ngIf="!loading">
				<form #f="ngForm" [formGroup]="form">
					<input type="text" id="email" formControlName="email" placeholder="Enter your e-mail" /><button type="submit" (click)="showRegisterSubmitConfirm()" [disabled]="!f.valid">Sign Up</button>
				</form>
			</div>
			<div *ngIf="loading" class="loading in"></div>
		</div>
	</div>
</div>
<div class="container1">
	<div class="info">
		Photonomy allows you to upload, classify, and organize your photos. Photos can be set to private or shared with friends and family. Create events or galleries to showcase your photos in various ways.
		<!--					create private or shared photo vaults, which contain a set of distribution instructions in the event that you are no longer able to manage your photos. This allows you to legally pass down rights to your photos instead of them entering the public domain.-->
	</div>
</div>
<div class="container2">
	<div class="icon-cell">
		<span class="icon"><i class="fas fa-lock i1"></i></span>
		<div class="title">Privacy First</div>
		<div class="description">Photonomy is designed to protect your privacy. We do not sell our users data.</div>
	</div>
	<div class="icon-cell">
		<span class="icon"><i class="fas fa-cloud-upload-alt i2"></i></span>
		<div class="title">Import or upload</div>
		<div class="description">You can add images from services you already use or upload them manually from your devices.</div>
	</div>
	<div class="icon-cell">
		<span class="icon"><i class="fas fa-sitemap i3"></i></span>
		<div class="title">Organize photos</div>
		<div class="description">Classify your images with tags with the help of friends and family.</div>
	</div>
	<div class="icon-cell">
		<span class="icon"><i class="fas fa-user-tag i4"></i></span>
		<div class="title">Sharing controls</div>
		<div class="description">You decide what remains private and what you want to share and with who you would share it.</div>
	</div>
</div>
<div class="container3">
	<div>How Photonomy works...</div>
</div>
<div class="container4">
	<div>
		<div class="center">
			<div class="title">Your memories, your way</div>
			<div class="tagline">A private home for your digital memories</div>
			<div class="description">Photonomy is your own. A personal, safe photo vault where your logs are not recorded, your content is not viewed, or shared by us in any way. Yours, entirely.</div>
		</div>
	</div>
	<div><img src="assets/images/laptop_dashboard.png" /></div>
</div>
<div class="container5">
	<div><img src="assets/images/laptop_explore.png" /></div>
	<div>
		<div class="center">
			<div class="title">Adding content</div>
			<div class="tagline">Controlled import from popular services and manual uploads</div>
			<div class="description">Upload images from your device or import them from your favorite social media platforms, including FaceBook, Instagram, Google Photos, iCloud, and OneDrive.</div>
		</div>
	</div>
</div>
<div class="container6">
	<div>
		<div class="center">
			<div class="title">Complete control</div>
			<div class="tagline">Organize, classify, protect, and share content on your terms</div>
			<div class="description">Organize and classify your image using custom tags and keywords. You can search and filter shared images from other users in your vaults.</div>
		</div>
	</div>
	<div><img src="assets/images/laptop_gallery.png" /></div>
</div>

<!--
<div class="container7">
	<div class="center">
		<div class="section-title">Pricing Plans</div>
		<div class="pricing-container">
			<div class="starter">
				<div class="container">
					<div class="title">Photonomy Starter</div>
					<div class="description">Perfect for new users to explore and get familiar with the interface</div>
					<div class="price-container">
						<div class="price">FREE</div>
						<div class="price-description">Forever</div>
					</div>
					<div class="list-item"><i class="fas fa-check"></i> Add and accept Connections</div>
					<div class="list-item"><i class="fas fa-check"></i> View photos from friends and family</div>
					<div class="list-item"><i class="fas fa-check"></i> 15 free Reactions</div>
					<button>Get Started</button>
				</div>
			</div>
			<div class="premium">
				<div class="container">
					<div class="title">Photonomy Premium</div>
					<div class="description">Full access to all Photonomy features, services, and updates</div>
					<div class="price-container">
						<div class="price">$.05/GB</div>
						<div class="price-description">Per month billed monthly</div>
					</div>
					<div class="list-item"><i class="fas fa-check"></i> Add and accept Connections</div>
					<div class="list-item"><i class="fas fa-check"></i> View photos from friends and family</div>
					<div class="list-item"><i class="fas fa-check"></i> Share photos with friends and family</div>
					<div class="list-item"><i class="fas fa-check"></i> 15 free Reactions</div>
					<div class="list-item"><i class="fas fa-check"></i> Upload unlimited images</div>
					<button>Get Started</button>
				</div>
			</div>
		</div>
	</div>
</div>
-->
<!--
<div class="container8">
    <div>Ask us anything</div>
</div>
-->
<app-footer></app-footer>

<ng-template #registerCodeDialog>
	<div class="modal-header">
		<h5 class="modal-title">Enter registration code</h5>
	</div>
	<div class="modal-body register-code" [ngClass]="{'loading in': loadingRegister }">
		<form #g="ngForm" [formGroup]="formRegisterCode">
			<input #reg1 [ngClass]="{'hidden': loadingRegister}" type="password" maxlength="1" id="reg1" formControlName="reg1" (keypress)="registerCodeEntered(1)" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			<input #reg2 [ngClass]="{'hidden': loadingRegister}" type="password" maxlength="1" id="reg2" formControlName="reg2" (keypress)="registerCodeEntered(2)" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			<input #reg3 [ngClass]="{'hidden': loadingRegister}" type="password" maxlength="1" id="reg3" formControlName="reg3" (keypress)="registerCodeEntered(3)" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			<input #reg4 [ngClass]="{'hidden': loadingRegister}" type="password" maxlength="1" id="reg4" formControlName="reg4" (keypress)="registerCodeEntered(4)" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			<input #reg5 [ngClass]="{'hidden': loadingRegister}" type="password" maxlength="1" id="reg5" formControlName="reg5" (keypress)="registerCodeEntered(5)" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
		</form>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-danger btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-success btn-md" (click)="registerCodeEntered(5)">Confirm</button>
	</div>
</ng-template>