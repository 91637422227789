<p><strong>ONE DRIVE AUTHENTICATION</strong></p> 

<button mat-raised-button *ngIf="!isAuthenticated" (click)="login()">Login</button>

<p *ngIf="isAuthenticated">Logged in</p>

<button mat-raised-button *ngIf="isAuthenticated" (click)="getToken()">Get Token</button>

<p *ngIf="isAuthenticated">{{ token }}</p>

<button mat-raised-button *ngIf="isAuthenticated" (click)="getUsername()">Get Username</button>
<p *ngIf="isAuthenticated">{{ username }}</p>
<button mat-raised-button *ngIf="isAuthenticated" (click)="getProfile()">Get Profile</button>
<button mat-raised-button *ngIf="isAuthenticated" (click)="getDrives()">Get Drives</button>
