import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from '../models/event.model';
import { Photo } from '../models/photo.model';
import { UserService } from './user.service';

@Injectable()
export class EventService {
    public timeline;
    public events = [];

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    getTimeline() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/events/timeline';

        return this.http.get<string>(requestUrl, { headers: headers, observe: 'response' });
    }

    getEvents() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/events';

        return this.http.get<Array<Event>>(requestUrl, { headers: headers, observe: 'response' });
    }

    getEvent(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId;

        return this.http.get<Event>(requestUrl, { headers: headers, observe: 'response' });
    }

    retrieveEventByShareCode1AndShareCode2(shareCode1: string, shareCode2: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + shareCode1 + '/' + shareCode2;

        return this.http.get<Event>(requestUrl, { headers: headers, observe: 'response' });
    }

    retrieveEventPhotosByShareCode1AndShareCode2(startPosition: number, shareCode1: string, shareCode2: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('startPosition', startPosition.toString());

        const requestUrl = '/api/event/' + shareCode1 + '/' + shareCode2 + '/photos';

        return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
    }

    retrieveAllEventPhotosByShareCode1AndShareCode2(startPosition: number, shareCode1: string, shareCode2: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('startPosition', startPosition.toString());

        const requestUrl = '/api/event/' + shareCode1 + '/' + shareCode2 + '/photos/all';

        return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
    }

    retrieveEventImageByExternalIdShareCode1AndShareCode2(externalId: string, shareCode1: string, shareCode2: string): Observable<any> {
        const requestUrl = '/api/event/' + shareCode1 + '/' + shareCode2 + '/' + externalId + '/image';

        return this.http.get<any>(requestUrl,
            {
                responseType: 'blob' as 'json',
                observe: 'response'
            });
    }

    createEvent(event: Event) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        event.owner = this.userService.getLocalUserId(0);

        const requestUrl = '/api/event';

        return this.http.post<Event>(requestUrl, event, { headers: headers, observe: 'response' });
    }

    updateEvent(event: Event) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event';

        return this.http.put<Event>(requestUrl, event, { headers: headers, observe: 'response' });
    }

    deleteEvent(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId;

        return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response' });
    }

    getMyPreexistingPhotos(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId + '/photos/preexisting';

        return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response' });
    }

    getConnectionsPreexistingPhotos(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId + '/photos/preexisting/connections';

        return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response' });
    }

    addEventPhoto(eventId: number, photoId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId + '/photo';

        return this.http.post<Photo>(requestUrl, photoId, { headers: headers, observe: 'response' });
    }

    removeEventPhoto(eventId: number, photoId: number, setToPrivate: boolean) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('setToPrivate', setToPrivate);

        const requestUrl = '/api/event/' + eventId + '/photo';

        return this.http.delete<Photo>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
    }

    deleteEventPhotos(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/' + eventId + '/photos';

        return this.http.delete<Photo>(requestUrl, { headers: headers, observe: 'response' });
    }

    deleteEventBannerImage(eventId: number) {
        const requestUrl = '/api/event/' + eventId + '/image';

        return this.http.delete<boolean>(requestUrl, { observe: 'response' });
    }
}