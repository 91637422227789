import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery } from 'src/app/models/gallery.model';
import { Photo } from 'src/app/models/photo.model';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
    selector: 'app-gallery-share',
    templateUrl: './gallery-share.component.html',
    styleUrls: ['./gallery-share.component.css']
})
export class GalleryShareComponent implements OnInit {
    @Input() shareCode1: string;
    @Input() shareCode2: string;


    error = false;

    loadingGallery = true;
    loadingPhotos = true;

    firstGalleryLoaded = false;

    gallery: Gallery;
    galleryPhotos: Array<Photo> = [];
    galleryPhotosPointer = 0;
    lastStartPosition = 0;

    selectedPhotoPointer = 0;
    selectedPhoto: Photo;

    galleryType = 'slide'; // slide or wall

    constructor(
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        public router: Router,
        private galleryService: GalleryService
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['shareCode1']) {
                this.shareCode1 = params['shareCode1'];
            }
            if (params['shareCode2']) {
                this.shareCode2 = params['shareCode2'];
            }

            this.retrieveGallery(this.shareCode1, this.shareCode2);
        });
    }



    onScroll() {
        console.log('onscroll');
        // This keeps duplicate results from loading.  Wait until all results are loaded before we load the next bunch.

        const nextPositionPointer = this.lastStartPosition + 15;
        console.log(nextPositionPointer + ' == ' + this.galleryPhotosPointer);

        if (this.galleryPhotos.length > 14 && nextPositionPointer == this.galleryPhotosPointer) {
            console.log('scroll');
            this.retrieveGalleryPhotosWall(nextPositionPointer, this.shareCode1, this.shareCode2);
        } else {
            console.log('dont scroll yet');
        }
    }

    retrieveGallery(shareCode1, shareCode2) {
        this.galleryService.retrieveGalleryByShareCode1AndShareCode2(shareCode1, shareCode2).subscribe(
            response => {
                this.gallery = response.body;
                this.loadingGallery = false;

                this.retrieveGalleryPhotosSlide(0, shareCode1, shareCode2);
            });
    }

    retrieveGalleryPhotosSlide(startPosition, shareCode1, shareCode2) {
        // Is this right?
        this.lastStartPosition = this.galleryPhotosPointer;

        console.log('retrieveGalleryPhotos');
        this.galleryService.retrieveGalleryPhotosByShareCode1AndShareCode2(startPosition, shareCode1, shareCode2).subscribe(
            response => {
                if (response.status === 200 && response.body.length > 0) {
                    let photos = response.body;

                    if (photos.length > 0) {
                        let index = 0;
                        let _this = this;
                        (function repeat() {
                            setTimeout(function () {
                                if (index == 0 && !this.firstGalleryLoaded) {
                                    _this.selectedPhoto = photos[index];
                                    _this.firstGalleryLoaded = true;
                                }
                                // CDN: REPLACE THIS!
                                _this.galleryService.retrieveGalleryImageByExternalIdShareCode1AndShareCode2(photos[index].externalIdMed, shareCode1, shareCode2).subscribe(
                                    response => {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(response.body);
                                        reader.onloadend = function () {
                                            photos[index].imageSafeUrl = _this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());

                                            // Is this right?
                                            _this.galleryPhotosPointer++;

                                            _this.galleryPhotos.push(photos[index]);
                                            _this.loadingPhotos = false;

                                            index++;
                                            if (index == photos.length) {
                                                //_this.loadingLast = false;
                                            } else {
                                                repeat();
                                            }
                                        }
                                    },
                                    error => {
                                        // TODO: Error handling
                                        console.log('An error occurred:');
                                        console.log(error);

                                        index++;
                                        if (index == photos.length) {
                                            //_this.loadingLast = false;
                                        } else {
                                            repeat();
                                        }
                                    });
                            }, 10);
                        })();
                    }
                } else {
                    this.loadingPhotos = false;
                }
            });
    }

    retrieveGalleryPhotosWall(startPosition, shareCode1, shareCode2) {
        // Is this right?
        this.lastStartPosition = this.galleryPhotosPointer;

        console.log('retrieveGalleryPhotos');
        this.galleryService.retrieveGalleryPhotosByShareCode1AndShareCode2(startPosition, shareCode1, shareCode2).subscribe(
            response => {
                if (response.status === 200 && response.body.length > 0) {
                    let photos = response.body;

                    if (photos.length > 0) {
                        let index = 0;
                        let _this = this;
                        (function repeat() {
                            setTimeout(function () {
                                if (index == 0 && !this.firstGalleryLoaded) {
                                    _this.selectedPhoto = photos[index];
                                    _this.firstGalleryLoaded = true;
                                }
                                // CDN: REPLACE THIS!
                                _this.galleryService.retrieveGalleryImageByExternalIdShareCode1AndShareCode2(photos[index].externalIdMed, shareCode1, shareCode2).subscribe(
                                    response => {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(response.body);
                                        reader.onloadend = function () {
                                            photos[index].imageSafeUrl = _this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());

                                            // Is this right?
                                            _this.galleryPhotosPointer++;

                                            _this.galleryPhotos.push(photos[index]);
                                            _this.loadingPhotos = false;

                                            index++;
                                            if (index == photos.length) {
                                                //_this.loadingLast = false;
                                            } else {
                                                repeat();
                                            }
                                        }
                                    },
                                    error => {
                                        // TODO: Error handling
                                        console.log('An error occurred:');
                                        console.log(error);

                                        index++;
                                        if (index == photos.length) {
                                            //_this.loadingLast = false;
                                        } else {
                                            repeat();
                                        }
                                    });
                            }, 10);
                        })();
                    }
                } else {
                    this.loadingPhotos = false;
                }
            });
    }

    navigatePreviousPhoto(event: Event) {
        let prevPointer = this.selectedPhotoPointer;
        if (prevPointer == 0) {
            prevPointer = this.galleryPhotos.length - 1;
        } else {
            prevPointer--;
        }
        this.selectedPhotoPointer = prevPointer;
        this.selectedPhoto = this.galleryPhotos[prevPointer];
    }

    navigateNextPhoto(event: Event) {
        let nextPointer = this.selectedPhotoPointer;
        if (nextPointer == (this.galleryPhotos.length - 1)) {
            nextPointer = 0;
        } else if (nextPointer == (this.galleryPhotos.length - 3)) {
            // Start pre-loading next images
            this.retrieveGalleryPhotosSlide(this.galleryPhotos.length, this.shareCode1, this.shareCode2);

            nextPointer++;
        } else {
            nextPointer++;
        }

        this.selectedPhotoPointer = nextPointer;
        this.selectedPhoto = this.galleryPhotos[nextPointer];
    }

    navigate(event: Event, path: string) {
        this.router.navigate([path]);
    }
}
