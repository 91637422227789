import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhotoStorage } from 'src/app/models/photo-storage.model';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-widget-photo-storage-graph',
	templateUrl: './widget-photo-storage-graph.component.html',
	styleUrls: ['./widget-photo-storage-graph.component.css']
})
export class WidgetPhotoStorageGraphComponent implements OnInit {
	loading = true;

	userImageSize: PhotoStorage;
	userImageSizeChangedSubscription: Subscription;

	privateWidth = 0;
	sharedWidth = 0;
	privateWidthSeconds = 0;

	constructor(
		private photoService: PhotoService
	) { }

	ngOnInit() {
		this.subscribeToUserImageSizeChanged();
		this.retrieveImageSize();
	}

	subscribeToUserImageSizeChanged() {
		this.userImageSizeChangedSubscription = this.photoService.userImageSizeChanged
			.subscribe((photoStorage: PhotoStorage) => {
				this.userImageSize = photoStorage;

				// Calculate width of private vs shared container

				let totalSize = photoStorage.totalSize;

				if (totalSize > 0) {
					let privateSize = photoStorage.privateSize;
					this.privateWidthSeconds = (privateSize / totalSize);

					// Delay settings the CSS specs to make the animatons more consistant. 
					let _this = this;
					setTimeout(function () {
						_this.privateWidth = (_this.privateWidthSeconds * 100);
						_this.sharedWidth = 100;
					}, 100);

				}
				this.loading = false;
			});

	}

	retrieveImageSize() {
		this.loading = true;
		this.photoService.getTotalPhotoSize().subscribe(
			response => {
				if (response.status === 200) {
					this.photoService.setUserImageSize(response.body);
					this.loading = false;
				}
			}
		);
	}
}
