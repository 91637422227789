<div class="card" #card>
	<div class="face front">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<!--
		<i class="fas fa-images"></i>
		-->
		<div class="images">
			<app-uncategorized-preview-photo-gallery></app-uncategorized-preview-photo-gallery>
		</div>
		<div class="text">
			Uncategorized Photos<br />
		</div>

		<div class="button-container">
			<div class="button" (click)="viewGallery()">View</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Uncategorized Photos</div>
		<div class="help-text">
			...
		</div>
	</div>
</div>