import { SafeUrl } from "@angular/platform-browser";

export class Event {
	public id: number;
	public owner: number;
	public name: string;
	public details: string;
	public startDateTime: Date;
	public startDateIncludeTime: boolean;
	public endDateTime: Date;
	public endDateIncludeTime: boolean;
	public canConnectionsView: boolean;
	public canConnectionsContribute: boolean;
	public canConnectionsDiscuss: boolean;
	public canConnectionsShare: boolean;
	public canPublicView: boolean;
	public canPublicContribute: boolean;
	public canPublicDiscuss: boolean;
	public canPublicShare: boolean;
	public addToOwnerTimeline: boolean;
	public shareCode1: string;
	public shareCode2: string;
	public bannerImageExternalId: string;
	public bannerImageExternalUrl: string;
	public bannerImageSafeUrl: SafeUrl;
	public bannerSubtitle: string;
	public bannerSubtitleDisplay: string;
	public bannerSize: string;
	public lockDiscussion: boolean;
	public location: string;
	public locationShowMap: boolean;

	public photosCount: number;
}
