import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InvoiceService {

	constructor(
		private http: HttpClient
	) { }

}
