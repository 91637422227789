import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserConnection } from '../models/user-connection.model';
import { User } from '../models/user.model';

@Injectable()
export class UserConnectionService {
	public connections = [];
	public connectionsCount: any;
	public connectionsCountChanged = new Subject();

	constructor(
		private http: HttpClient
	) { }

	getUserConnectionCount() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections/count';

		return this.http.get<number>(requestUrl, { headers: headers, observe: 'response' });
	}

	getUserConnectionsAll() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections';

		return this.http.get<Array<User>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getUserConnectionsActive() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections/active';

		return this.http.get<Array<User>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getUserConnectionsPending() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections/pending';

		return this.http.get<Array<User>>(requestUrl, { headers: headers });
	}

	getUserConnectionsWaiting() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections/waiting';

		return this.http.get<Array<User>>(requestUrl, { headers: headers, observe: 'response' });
	}

	getUserConnectionsBlocked() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connections/blocked';

		return this.http.get<Array<User>>(requestUrl, { headers: headers, observe: 'response' });
	}

	addUserConnection(connection: UserConnection) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/connection';

		return this.http.post<UserConnection>(requestUrl, JSON.stringify(connection), { headers: headers, observe: 'response' });
	}

	approveUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/approve';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	rejectUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/reject';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	blockUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/block';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	unblockUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/unblock';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	removeUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/remove';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	cancelUserConnection(userUuid: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);

		const requestUrl = '/api/connection/cancel';

		return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	inviteUserConnection(email: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('email', email);

		const requestUrl = '/api/connection/invite';

		return this.http.post<User>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	setAndAnnounceUserConnectionCount(value: any) {
		this.connectionsCount = value;
		this.connectionsCountChanged.next(value);
	}
}
