import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/auth.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
	@Input() userUuid: string;
	@Input() emailVerifyCode: string;

	loading = true;

	paramSubscription: any;
	user: User;
	userAge = 0;

	form: UntypedFormGroup;
	formVerify: UntypedFormGroup;

	passwordInputType = 'password';

	validationFailed = false;
	validationFailedUser = '';

	registerSuccess = false;

	constructor(
		private formBuilder: UntypedFormBuilder,
		public authService: AuthService,
		private route: ActivatedRoute,
		public router: Router,
		private toastr: ToastrService,
		private userService: UserService
	) { }

	ngOnInit() {
		if (this.authService.isAuthenticated()) {
			this.authService.signOutUser();
		}

		this.initForm();
		this.initVerifyForm();

		// Check for email validation codes
		this.route.params.subscribe(params => {
			let userUuid;
			if (params['userUuid']) {
				userUuid = params['userUuid'];
			}
			let emailVerifyCode;
			if (params['emailVerifyCode']) {
				emailVerifyCode = params['emailVerifyCode'];
			}
			if (userUuid && emailVerifyCode) {
				this.validateCodes(userUuid, emailVerifyCode);
			}
		});
	}

	ngOnDestroy() {
		if (this.paramSubscription) {
			this.paramSubscription.unsubscribe();
		}
	}

	/**
	 * INIT REGISTER FORM
	 */
	initForm() {
		this.form = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required,
				Validators.email
			]),
			'firstName': new UntypedFormControl(null, [
				Validators.required,
			]),
			'lastName': new UntypedFormControl(null, [
				Validators.required,
			]),
			'month': new UntypedFormControl("", [
				Validators.required,
			]),
			'day': new UntypedFormControl(null, [
				Validators.required,
			]),
			'year': new UntypedFormControl(null, [
				Validators.required,
			]),
			'password': new UntypedFormControl(null, [
				Validators.required,
				Validators.minLength(7)
			]),
			'userUuid': new UntypedFormControl(null, [
				Validators.required
			]),
			'emailVerifyCode': new UntypedFormControl(null, [
				Validators.required
			])
		});
	}

	initVerifyForm() {
		this.formVerify = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required,
				Validators.email
			])
		});
	}

	/**
	 * VALIDATE CODES
	 */
	validateCodes(userUuid, emailVerifyCode) {
		this.validationFailed = false;
		this.validationFailedUser = '';
		this.registerSuccess = false;

		this.authService.verifyRegisterEmail(userUuid, emailVerifyCode).subscribe(
			response => {
				if (response.status === 200) {
					this.form.patchValue({ userUuid: userUuid });
					this.form.patchValue({ emailVerifyCode: emailVerifyCode });
					this.form.patchValue({ email: response.body });
					this.form.get('email').disable();

					this.loading = false;
					this.toastr.success("Your e-mail has been verified");
				} else {
					this.validationFailed = true;
					this.loading = false;
					this.toastr.error('An error occurred');
				}
			},
			err => {
				this.validationFailed = true;

				if (err.error?.length > 0) {
					this.validationFailedUser = err.error;
					this.toastr.error('E-mail validation failed');

					this.formVerify.patchValue({ email: this.validationFailedUser });
				} else {
					this.toastr.error('E-mail validation failed');
				}

				this.loading = false;
			}
		);
	}

	/**
	 * REGISTER SUBMIT
	 */
	registerSubmit() {
		this.loading = true;

		// TODO: AGE CHECK
		const today = new Date();
		const month = this.form.get('month').value;
		const day = this.form.get('day').value;
		const year = this.form.get('year').value;
		const birthdate = new Date(year, month, day);
		let age = today.getFullYear() - birthdate.getFullYear();
		const m = today.getMonth() - birthdate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
			age--;
		}
		this.userAge = age;

		if (age > 12) {
			let user = new User();
			user.firstName = this.form.get('firstName').value;
			user.lastName = this.form.get('lastName').value;
			user.email = this.form.get('email').value;
			user.password = this.form.get('password').value;
			user.uuid = this.form.get('userUuid').value;
			user.emailVerifyCode = this.form.get('emailVerifyCode').value;
			user.birthDate = birthdate;

			this.authService.registerUser(user).subscribe(
				response => {
					if (response.status === 200) {
						this.user = response.body;
						this.userService.policiesVerified = false;
						this.routeToHome();
					} else {
						this.loading = false;
					}
				},
				err => {
					this.loading = false;
				}
			);
		} else {
			this.loading = false;
		}
	}

	/** 
	 * UTILS 
	 */

	/** 
	 * MATCHING PASSWORDS 
	 */
	matchingPasswords(passwordKey: string, password2Key: string) {
		// Form validation functions are passed the entire FormGroup and return a validation message formatted as {String: object}
		return (group: UntypedFormGroup): { [key: string]: any } => {
			// get the control objects for the two password fields
			const password = group.controls[passwordKey];
			const password2 = group.controls[password2Key];

			// compare the value of the two password fields
			if (password.value !== password2.value) {
				// if they're different, set a validation error on password2
				password2.setErrors(Validators);
				// return a validation property for the form
				return {
					mismatchedPasswords: true
				};
			} else {
				// if they're the same, remove any validation errors on password2
				password2.setErrors(null);
			}
		};
	}

	changePasswordInputType(type: string) {
		this.passwordInputType = type;
	}

	// verifyAge() {
	// 	const today = new Date();

	// 	const dateArray = this.form.get('birthDate').value.split("-");
	// 	const birthdate = new Date(dateArray[2], dateArray[0] - 1, dateArray[1]);

	// 	var age = today.getFullYear() - birthdate.getFullYear();
	// 	var m = today.getMonth() - birthdate.getMonth();
	// 	if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
	// 		age--;
	// 	}

	// 	this.userAge = age;
	// }

	/**
	 * ROUTE TO HOME
	 * 1. Set the global user.  Since we set this after the Terms and Privacy screens, they cannot bypass the agreements
	 * 2. Route to the photos route
	 */
	routeToHome() {
		this.authService.setUserData(this.user);

		this.router.navigate(['/dashboard']);
	}

	/**
	 * NAVIGATE
	 * @param path 
	 */
	navigate(path) {
		this.router.navigate([path]);
	}
}
