<div class="background" [ngClass]="{'loading in': loadingEvents || loadingPhotos }">
    <div class="no-events-message-container" *ngIf="!loadingEvents && eventsDisplayedOnTimeline.length == 0">
        <div>Welcome to your timeline. </div>
        <div>Add at least one <a (click)="navigate('/events')">event</a> to get started.</div>
    </div>
    <div class="event-container" *ngIf="!loadingEvents && eventsDisplayedOnTimeline.length > 0">
        <div class="title" [ngClass]="{'hidden': !eventActive }">{{ eventActive?.name }}</div>
        <div class="title" [ngClass]="{'hidden': !eventHover }">{{ eventHover?.name }}</div>
        <div class="sub-title" [ngClass]="{'hidden': !eventActive }">{{ eventActive?.date | date:'longDate' }}</div>
        <div class="sub-title" [ngClass]="{'hidden': !eventHover }">{{ eventHover?.date | date:'longDate' }}</div>
        <div class="image-container" *ngIf="eventActive" [ngClass]="{'wait': loadingPhotos}">
            <ng-container *ngFor="let photo of eventPhotos">
                <img *ngIf="photo.image" [src]="photo.image" />
            </ng-container>
        </div>
    </div>
</div>
<div class="timeline" [ngClass]="{'wait': loadingEvents, 'not-empty': eventsDisplayedOnTimeline.length > 0 }">
    <div class="date date-0">
        <div class="focus">
            <div>{{ year0 }}</div>
        </div>
    </div>
    <div class="date date-1">
        <div class="focus">
            <div>{{ math.trunc(year1) }}</div>
        </div>
    </div>
    <div class="date date-2">
        <div class="focus">
            <div>{{ math.trunc(year2) }}</div>
        </div>
    </div>
    <div class="date date-3">
        <div class="focus">
            <div>{{ math.trunc(year3) }}</div>
        </div>
    </div>
    <div class="date date-4">
        <div class="focus">
            <div>{{ math.trunc(year4) }}</div>
        </div>
    </div>
    <div class="date date-5">
        <div class="focus">
            <div>{{ math.trunc(year5) }}</div>
        </div>
    </div>
    <div class="date date-6">
        <div class="focus">
            <div>{{ math.trunc(year6) }}</div>
        </div>
    </div>
    <div class="date date-7">
        <div class="focus">
            <div>{{ math.trunc(year7) }}</div>
        </div>
    </div>
    <div class="date date-8">
        <div class="focus">
            <div>{{ math.trunc(year8) }}</div>
        </div>
    </div>
    <div class="date date-9">
        <div class="focus">
            <div>{{ year9 }}</div>
        </div>
    </div>
    <ng-container *ngFor="let event of eventsDisplayedOnTimeline">
        <div class="event" [ngClass]="{'wait': loadingEventsWait}" [ngStyle]="{'left': calculateLeftPosition(event.startDateTime) + '%'}" (click)="selectEvent(event)" (mouseover)="hoverEvent(event)" (mouseout)="unhoverEvent(event)">
            <!--<div class="timeline-title">{{ event.name }}</div>-->
            <div class="focus">
                <div></div>
            </div>
        </div>
    </ng-container>
</div>