import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import CryptoJS from 'crypto-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EventComment } from 'src/app/models/event-comment.model';
import { EventPhotoAdd } from 'src/app/models/event-photo-add.model';
import { EventPhotoRemove } from 'src/app/models/event-photo-remove.model';
import { Event } from 'src/app/models/event.model';
import { PhotoUpload } from 'src/app/models/photo-upload.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { EventCommentService } from 'src/app/services/event-comment.service';
import { EventService } from 'src/app/services/event.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

const EXIF = require('exif-js');

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
    @Input() shareCode1: string;
    @Input() shareCode2: string;

    @ViewChild('uploadDialog', { static: true }) uploadDialog: TemplateRef<any>;
    @ViewChild('findDialog', { static: true }) findDialog: TemplateRef<any>;
    @ViewChild('settingsDialog', { static: true }) settingsDialog: TemplateRef<any>;
    @ViewChild('shareDialog', { static: true }) shareDialog: TemplateRef<any>;
    @ViewChild('approvalDialog', { static: true }) approvalDialog: TemplateRef<any>;

    modalRef: BsModalRef;

    error = false;

    // Layout Vars
    compressHeader = false;
    showUserMenu = false;
    showScrollBar = false;

    // Loading Vars
    loadingUser = true;
    loadingHost = true;
    loadingEvent = true;
    loadingUpdateEvent = false;
    loadingPhotos = true;
    loadingFindPhotos = false;
    loadingDiscussion = true;
    loadingBannerImageUpload = true;
    loadingDeleteEvent = false;
    loadingRemovePhotos = false;

    firstEventPhotoLoaded = false;

    // User Vars
    userFoundSubscription: Subscription;

    currentUser: User;
    currentUserType: string; // owner/connection/public
    currentUserAuthenticated: boolean;

    hostUser: User;

    // Event Vars
    event: Event;
    eventComments: EventComment[] = [];
    eventPhotos: Array<any> = [];
    eventSignedDownloadToken: any;
    eventIsInFuture = false;

    // Event Banner Image Vars
    bannerImageLocked = true;
    bannerImageHoverText = '';
    bannerImageShowSubtitleSelectors = false;
    bannerImageShowUploadSelectors = false;
    bannerImageShowSizeSelectors = false;

    bannerSubtitleDisplayTemp = '';
    bannerSizeTemp = '';

    // Event Comments Vars
    commentForm: UntypedFormGroup;

    // Upload Vars
    filesToUpload: any[] = [];
    showInvalidDateMessage = false;

    // Event Photos Vars
    eventPhotosPointer = 0;
    lastStartPosition = 0;

    myPreExistingPhotos = [];
    connectionsPreExistingPhotos = [];

    uploadPhotoSuccessSubscription: Subscription;
    addEventPhotoSuccessSubscription: Subscription;
    removeEventPhotoSuccessSubscription: Subscription;

    // Event Location Vars
    locationEmbedUrl;

    // Event Settings Vars
    settingsForm: FormGroup;
    showStartTime = false;
    showEndDate = false;
    showEndTime = false;

    newLocation = '';
    newLocationEmbedUrl = '';
    locationSuggestions = [];

    // Event Delete Vars
    displayDeleteEventConfirm = false;

    // Event QR Code Vars
    qrEventPageURL;
    qrEmail;

    // Event Action Vars


    // selectedPhotoPointer = 0;
    // selectedPhoto: Photo;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private eventService: EventService,
        private eventCommentService: EventCommentService,
        private layoutService: LayoutService,
        private modalService: BsModalService,
        private photoService: PhotoService,
        private userService: UserService
    ) { }

    /**
     * 1. Retrieve the event
     * 2. Determine the user type + permissions
     * 
     * 3. Retrieve the event download token
     * 4. Retrieve the event discussion
     */
    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['shareCode1']) {
                this.shareCode1 = params['shareCode1'];
            }
            if (params['shareCode2']) {
                this.shareCode2 = params['shareCode2'];
            }

            this.initCommentForm();

            this.retrieveEvent(this.shareCode1, this.shareCode2);
        });
    }

    ngOnDestroy() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    initCommentForm() {
        this.commentForm = this.formBuilder.group({
            'comment': new UntypedFormControl(null, [
                Validators.required,
            ]),
        });
    }

    initSettingsForm() {
        // TODO: Extract the time from the date

        let startDate: Date = new Date(this.event.startDateTime);
        // let startDateOnly = formatDate(startDate, 'EEEE, MMMM d, y', 'en_US');
        let startDateOnly = startDate;

        console.log(startDateOnly);



        let startTimeHour = '01'; // Default values, which we will override if they are set
        let startTimeMinute = '00';
        let startTimeMeridiem = 'PM';

        if (this.event.startDateIncludeTime) {
            if (startDate.getHours() > 12) {
                startTimeHour = (startDate.getHours() - 12).toString();
            } else {
                startTimeHour = startDate.getHours().toString();
            }
            if (startTimeHour.length == 1) {
                startTimeHour = '0' + startTimeHour;
            }
            console.log('startTimeHour: ' + startTimeHour);

            startTimeMinute = startDate.getMinutes().toString();
            if (startTimeMinute.length == 1) {
                startTimeMinute = '0' + startTimeMinute;
            }
            console.log('startTimeMinute: ' + startTimeMinute);

            if (startDate.getHours() < 12) {
                startTimeMeridiem = 'AM';
                console.log('startTimeMeridiem: ' + startTimeMeridiem);
            }
        }

        let endDateOnly = null; // Default values, which we will override if they are set
        let endTimeHour = '03';
        let endTimeMinute = '00';
        let endTimeMeridiem = 'PM';

        if (this.event.endDateTime) {
            let endDate: Date = new Date(this.event.endDateTime);
            endDateOnly = endDate.getDate();

            if (this.event.endDateIncludeTime) {
                if (endDate.getHours() > 12) {
                    endTimeHour = (endDate.getHours() - 12).toString();
                } else {
                    endTimeHour = endDate.getHours().toString();
                }
                if (endTimeHour.length == 1) {
                    endTimeHour = '0' + endTimeHour;
                }

                endTimeMinute = endDate.getMinutes().toString();
                if (endTimeMinute.length == 1) {
                    endTimeMinute = '0' + endTimeMinute;
                }

                if (endDate.getHours() < 12) {
                    endTimeMeridiem = 'AM';
                }
            }
        }



        this.settingsForm = this.formBuilder.group({
            'name': new UntypedFormControl(this.event.name, [
                Validators.required,
            ]),
            'details': new UntypedFormControl(this.event.details, []),
            'startDate': new UntypedFormControl(startDateOnly, [ // TODO
                Validators.required,
            ]),
            'startTimeHour': new UntypedFormControl(startTimeHour, []), // TODO
            'startTimeMinute': new UntypedFormControl(startTimeMinute, []), // TODO
            'startTimeMeridiem': new UntypedFormControl(startTimeMeridiem, []), // TODO
            'endDate': new UntypedFormControl(endDateOnly, []), // TODO
            'endTimeHour': new UntypedFormControl(endTimeHour, []), // TODO
            'endTimeMinute': new UntypedFormControl(endTimeMinute, []), // TODO
            'endTimeMeridiem': new UntypedFormControl(endTimeMeridiem, []), // TODO
            'location': new UntypedFormControl(this.event.location, []),
            'locationShowMap': new UntypedFormControl(this.event.locationShowMap, []),
            'canConnectionsView': new UntypedFormControl(this.event.canConnectionsView, [
                Validators.required,
            ]),
            'canConnectionsContribute': new UntypedFormControl(this.event.canConnectionsContribute, [
                Validators.required,
            ]),
            'canConnectionsDiscuss': new UntypedFormControl(this.event.canConnectionsDiscuss, [
                Validators.required,
            ]),
            'canConnectionsShare': new UntypedFormControl(this.event.canConnectionsShare, [
                Validators.required,
            ]),
            'canPublicView': new UntypedFormControl(this.event.canPublicView, [
                Validators.required,
            ]),
            'canPublicContribute': new UntypedFormControl(this.event.canPublicContribute, [
                Validators.required,
            ]),
            // 'canPublicDiscuss': new UntypedFormControl(this.event.canPublicDiscuss, [
            //     Validators.required,
            // ]),
            'canPublicShare': new UntypedFormControl(this.event.canPublicShare, [
                Validators.required,
            ]),
            'addToMyTimeline': new UntypedFormControl(this.event.addToOwnerTimeline, [
                Validators.required,
            ])
        });

        if (this.event.location) {
            this.selectNewLocation(this.event.location);
        }
    }

    retrieveEvent(shareCode1, shareCode2) {
        this.loadingEvent = true;
        this.eventService.retrieveEventByShareCode1AndShareCode2(shareCode1, shareCode2).subscribe(
            response => {
                let event = response.body;

                if (event.id == null) {
                    this.error = true;
                } else {
                    this.event = response.body;

                    if (this.event.location && this.event.locationShowMap) {
                        let url = 'https://www.google.com/maps/embed/v1/place?&q=' + this.event.location + '&key=AIzaSyBaIc0xglS3_DQ0S5h8B-nnnm1ohED0BsI'
                        url = url.replace(/\s/g, "+");

                        this.locationEmbedUrl = url;
                    }

                    if (this.event.startDateIncludeTime) {
                        this.showStartTime = true;
                    }
                    if (this.event.endDateTime) {
                        this.showEndDate = true;
                    }
                    if (this.event.endDateIncludeTime) {
                        this.showEndTime = true;
                    }

                    let eventDate = new Date(this.event.startDateTime);
                    let currentDate = new Date();
                    if (eventDate > currentDate) {
                        this.eventIsInFuture = true;
                    }

                    this.qrEventPageURL = 'https://www.photonomy.com/#/event/' + this.event.shareCode1 + '/' + this.event.shareCode2;
                }
                this.loadingEvent = false;

                this.retrieveCurrentUser();

                this.subscribeToAddEventPhotoSuccess();
                this.subscribeToRemoveEventPhotoSuccess();
                this.subscribeToUploadPhotoSuccess();
            });
    }


    subscribeToAddEventPhotoSuccess() {
        this.addEventPhotoSuccessSubscription = this.layoutService.addEventPhotoSuccess
            .subscribe((eventPhotoAdd: EventPhotoAdd) => {
                if (this.event.id == eventPhotoAdd.photo.addToEvent.id) {
                    eventPhotoAdd.photo.imageSafeUrl = null;
                    this.setImageSafeUrl(eventPhotoAdd.photo);
                    this.event.photosCount++;
                }
            });
    }

    subscribeToRemoveEventPhotoSuccess() {
        this.removeEventPhotoSuccessSubscription = this.layoutService.removeEventPhotoSuccess
            .subscribe((eventPhotoRemove: EventPhotoRemove) => {
                if (this.event.id == eventPhotoRemove.photo.addToEvent.id) {
                    this.event.photosCount--;
                    if (this.event.photosCount == 0) {
                        if (this.loadingRemovePhotos) {
                            this.deleteEventConfirm();
                        }
                    }
                }
            });
    }

    subscribeToUploadPhotoSuccess() {
        this.uploadPhotoSuccessSubscription = this.layoutService.uploadPhotoSuccess
            .subscribe((photoUpload: PhotoUpload) => {
                if (photoUpload.event?.id == this.event.id) {
                    photoUpload.photo.imageSafeUrl = null;
                    this.setImageSafeUrl(photoUpload.photo);
                    this.event.photosCount++;
                }
            });
    }

    subscribeToUserChanged() {
        this.userFoundSubscription = this.userService.userChanged.subscribe(
            user => {
                if (user.id == this.event.owner) {
                    this.hostUser = user;

                    this.loadingHost = false;
                }
            }
        );
    }

    retrieveCurrentUser() {
        if (this.userService.users[0]) {
            this.currentUser = this.userService.users[0];
            this.currentUser.initials = this.currentUser.firstName.charAt(0) + this.currentUser.lastName.charAt(0);
            if (this.event) {
                this.determineUserPermissions();
            }
        } else {
            this.userService.retrieveCurrentUser().subscribe(
                response => {
                    console.log('retrieve user');
                    let user = response.body;

                    if (user == null || user.id == null) {
                        // not logged in
                    } else {
                        // logged in
                        if (!this.userService.users[0]) {
                            this.authService.setUserData(user);
                        }
                        this.currentUser = response.body;
                        this.currentUser.initials = this.currentUser.firstName.charAt(0) + this.currentUser.lastName.charAt(0);

                        this.retrieveEventDiscussion();
                    }
                    if (this.event) {
                        this.determineUserPermissions();
                    }
                }
            );
        }
    }

    determineUserPermissions() {
        if (this.currentUser) {
            this.currentUserAuthenticated = true;

            if (this.event.owner == this.currentUser.id) {
                this.currentUserType = 'owner';
            } else {
                // TODO: THIS SHOULD LOOK UP IF THEY ARE A CONNECTION OR NOT
                this.currentUserType = 'connection';

                if (!this.event.canConnectionsView) {
                    this.error = true;
                }
            }

            // Determine if we are the host.
            if (this.currentUser.id == this.event.owner) {
                // Host
                this.hostUser = this.currentUser;

                this.loadingHost = false;
            } else {
                // Not the host, load the host details.
                this.subscribeToUserChanged();

                this.userService.getUserByUserId(this.event.owner, null);
            }

        } else {
            this.currentUserAuthenticated = false;
            this.currentUserType = 'public';

            if (!this.event.canPublicView) {
                this.error = true;
            }

            this.subscribeToUserChanged();

            this.userService.getUserByUserId(this.event.owner, null);
        }

        if (!this.error) {
            // this.loadDiscussion(shareCode1, shareCode2);
            this.retrieveEventSignedDownloadToken(this.event.id);
        }

        this.loadingUser = false;


    }

    retrieveEventDiscussion() {
        this.loadingDiscussion = true;
        this.eventCommentService.retrieveComments(this.event.id).subscribe(
            response => {
                this.eventComments = response.body;
                this.loadingDiscussion = false;
            }
        );
    }

    retrieveEventSignedDownloadToken(eventId) {
        // TODO: LOOK FOR THIS IN THE CACHE BEFORE RETRIEVING IT
        this.photoService.getSignedDownloadToken(eventId, 'event').subscribe(
            response => {
                let responseToken = response.body;
                responseToken.eventId = eventId;

                // Ensure the token doesn't exist before adding to cache
                let token2;
                for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
                    if (signedDownloadToken.eventId == eventId) {
                        token2 = signedDownloadToken;
                    }
                }

                if (!token2) {
                    this.photoService.signedDownloadTokens.push(responseToken);
                    this.eventSignedDownloadToken = responseToken;
                } else {
                    this.eventSignedDownloadToken = token2;
                }

                this.retrieveEventPhotos(0, this.shareCode1, this.shareCode2);

                if (this.event.bannerImageExternalId) {
                    this.setBannerImageSafeUrl(this.event);
                } else {
                    this.loadingBannerImageUpload = false;
                }
            }
        );
    }

    retrieveEventPhotos(startPosition, shareCode1, shareCode2) {
        this.loadingPhotos = true;

        this.lastStartPosition = this.eventPhotosPointer;

        this.eventService.retrieveEventPhotosByShareCode1AndShareCode2(startPosition, shareCode1, shareCode2).subscribe(
            response => {
                if (response.status === 200 && response.body.length > 0) {
                    let photos = response.body;

                    if (photos.length > 0) {
                        let index = 0;
                        let _this = this;
                        (function repeat() {
                            setTimeout(function () {
                                _this.firstEventPhotoLoaded = true;

                                if (!photos[index].imageSafeUrl) {
                                    _this.setImageSafeUrl(photos[index]);
                                }

                                _this.eventPhotosPointer++;

                                index++;
                                if (index == photos.length) {
                                    // _this.loadingPhotos = false; // I dont think i want to do this yet
                                } else {
                                    repeat();
                                }
                            }, 10);
                        })();
                    }
                } else {
                    this.loadingPhotos = false;
                }

                this.showScrollBar = false;
            });
    }

    // Make a call to detect if there are photos from the event's timeframe that already exist.
    // If yes, allow the user to add them to the event.
    findEventPhotos() {
        this.loadingFindPhotos = true;
        this.myPreExistingPhotos = [];
        this.connectionsPreExistingPhotos = [];

        this.openFindModal();

        this.eventService.getMyPreexistingPhotos(this.event.id).subscribe(
            response => {
                let myPreExistingPhotos = response.body;
                for (let photo of myPreExistingPhotos) {
                    this.retrieveMyFoundImage(photo);
                }

                this.eventService.getConnectionsPreexistingPhotos(this.event.id).subscribe(
                    response => {
                        let connectionsPreExistingPhotos = response.body;
                        for (let photo of connectionsPreExistingPhotos) {
                            this.retrieveConnectionsFoundImage(photo);
                        }

                        this.loadingFindPhotos = false;
                    },
                    err => {
                        console.log('Error: An error occurred while finding pre-existing photos...');
                        console.log(err);
                        this.loadingFindPhotos = false;
                    }
                );
            },
            err => {
                console.log('Error: An error occurred while finding pre-existing photos...');
                console.log(err);
                this.loadingFindPhotos = false;
            }
        );
    }

    selectAllPhotos() {
        for (let i = 0; i < this.myPreExistingPhotos.length; i++) {
            (<HTMLInputElement>document.getElementById('preExistingPhoto' + i)).checked = true;
        }
    }

    unSelectAllPhotos() {
        for (let i = 0; i < this.myPreExistingPhotos.length; i++) {
            (<HTMLInputElement>document.getElementById('preExistingPhoto' + i)).checked = false;
        }
    }

    addSelectedPhotos() {
        let photoIds = [];
        console.log(this.myPreExistingPhotos);
        if (this.myPreExistingPhotos.length > 0) {
            for (let i = 0; i < this.myPreExistingPhotos.length; i++) {
                let addPhoto = (<HTMLInputElement>document.getElementById('preExistingPhoto' + i)).checked;
                if (addPhoto) {
                    // Sets the event id before announcing the photo
                    this.myPreExistingPhotos[i].addToEvent = this.event;

                    let eventPhotoAdd: EventPhotoAdd = new EventPhotoAdd();
                    eventPhotoAdd.photo = this.myPreExistingPhotos[i];

                    this.layoutService.announceAddEventPhoto(eventPhotoAdd);
                }

                if (i == (this.myPreExistingPhotos.length - 1)) {
                    this.modalRef.hide();
                }
            }
        } else {
            this.modalRef.hide();
        }
    }

    retrieveConnectionsFoundImage(photo) {
        let token = this.photoService.signedDownloadTokens.find(d => d.userId === photo.userId.toString());
        if (token) {
            if (!photo.imageSafeUrl) {
                let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;
                photo.imageSafeUrl = imageSafeUrl;

                this.connectionsPreExistingPhotos.push(photo);
            } else {
                this.connectionsPreExistingPhotos.push(photo);
            }
        } else {
            let type = 'userPublic';
            if (photo.connectionsCanView == false) {
                type = 'userPrivate';
            }
            this.photoService.getSignedDownloadToken(photo.userId.toString(), type).subscribe(
                response => {
                    if (!photo.imageSafeUrl) {
                        let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;
                        photo.imageSafeUrl = imageSafeUrl;

                        this.connectionsPreExistingPhotos.push(photo);
                    } else {
                        this.connectionsPreExistingPhotos.push(photo);
                    }
                }
            );
        }
    }

    retrieveMyFoundImage(photo) {
        let token = this.photoService.signedDownloadTokens.find(d => d.userId === photo.userId.toString());
        if (token) {
            if (!photo.imageSafeUrl) {
                let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;
                photo.imageSafeUrl = imageSafeUrl;

                this.myPreExistingPhotos.push(photo);
            } else {
                this.myPreExistingPhotos.push(photo);
            }
        } else {
            let type = 'userPublic';
            if (photo.connectionsCanView == false) {
                type = 'userPrivate';
            }
            this.photoService.getSignedDownloadToken(photo.userId.toString(), type).subscribe(
                response => {
                    if (!photo.imageSafeUrl) {
                        let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;
                        photo.imageSafeUrl = imageSafeUrl;

                        this.myPreExistingPhotos.push(photo);
                    } else {
                        this.myPreExistingPhotos.push(photo);
                    }
                }
            );
        }
    }

    setImageSafeUrl(photo) {
        let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + this.eventSignedDownloadToken.token;
        photo.imageSafeUrl = url;

        this.eventPhotos.push(photo);

        this.loadingPhotos = false;
    }

    onScroll() {
        this.showScrollBar = true;
        console.log('onscroll');
        // This keeps duplicate results from loading.  Wait until all results are loaded before we load the next bunch.

        const nextPositionPointer = this.lastStartPosition + 15;
        console.log(this.eventPhotos.length + " >  14 && " + nextPositionPointer + ' == ' + this.eventPhotosPointer);

        if (this.eventPhotos.length > 14 && nextPositionPointer == this.eventPhotosPointer) {
            console.log('scroll');
            this.retrieveEventPhotos(nextPositionPointer, this.shareCode1, this.shareCode2);
        } else {
            console.log('dont scroll yet');
            this.showScrollBar = false;
        }
    }

    addDiscussionComment() {
        console.log(this.commentForm);
        let comment = this.commentForm.value.comment;
        console.log(comment);
        if (comment && comment.length > 0) {
            this.eventCommentService.addComment(this.event.id, comment, 0).subscribe(
                response => {
                    console.log(response);
                    // TODO: Remove the text from the input

                    // TODO: Either add the comment to the local array or reload the comments.
                    this.retrieveEventDiscussion();
                }
            );
        }
    }

    // navigatePreviousPhoto(event: Event) {
    //     let prevPointer = this.selectedPhotoPointer;
    //     if (prevPointer == 0) {
    //         prevPointer = this.eventPhotos.length - 1;
    //     } else {
    //         prevPointer--;
    //     }
    //     this.selectedPhotoPointer = prevPointer;
    //     this.selectedPhoto = this.eventPhotos[prevPointer];
    // }

    // navigateNextPhoto(event: Event) {
    //     let nextPointer = this.selectedPhotoPointer;
    //     if (nextPointer == (this.eventPhotos.length - 1)) {
    //         nextPointer = 0;
    //     } else if (nextPointer == (this.eventPhotos.length - 3)) {
    //         // Start pre-loading next images
    //         this.retrieveEventPhotos(this.eventPhotos.length, this.shareCode1, this.shareCode2);

    //         nextPointer++;
    //     } else {
    //         nextPointer++;
    //     }

    //     this.selectedPhotoPointer = nextPointer;
    //     this.selectedPhoto = this.eventPhotos[nextPointer];
    // }

    /**
     * BANNER FUNCTIONS
     */

    updateSubtitleDisplay(type: string) {
        if (type !== this.event.bannerSubtitleDisplay) {
            this.bannerSubtitleDisplayTemp = type;
        } else {
            this.bannerSubtitleDisplayTemp = '';
        }
    }

    saveSubtitleDisplay() {
        if (this.bannerSubtitleDisplayTemp !== '') {
            let event = new Event;
            event.id = this.event.id;
            event.bannerSubtitleDisplay = this.bannerSubtitleDisplayTemp;

            this.eventService.updateEvent(event).subscribe(
                response => {
                    this.event.bannerSubtitleDisplay = response.body.bannerSubtitleDisplay;

                    this.bannerSubtitleDisplayTemp = '';
                    this.bannerImageShowSubtitleSelectors = false;
                }
            );
        } else {
            this.bannerImageShowSubtitleSelectors = false;
        }
    }

    updateBannerSize(size: string) {
        if (size !== this.event.bannerSize) {
            this.bannerSizeTemp = size;
        } else {
            this.bannerSizeTemp = '';
        }
    }

    saveBannerSize() {
        if (this.bannerSizeTemp !== '') {
            let event = new Event;
            event.id = this.event.id;
            event.bannerSize = this.bannerSizeTemp;

            this.eventService.updateEvent(event).subscribe(
                response => {
                    this.event.bannerSize = response.body.bannerSize;

                    this.bannerSizeTemp = '';
                    this.bannerImageShowSizeSelectors = false;
                }
            );
        } else {
            this.bannerImageShowSizeSelectors = false;
        }
    }

    /**
     * First retrieve a signed URL from cache or server, then upload the image.
     */
    uploadBannerImageStep1(e: any) {
        this.loadingBannerImageUpload = true;
        if (this.photoService.signedUploadUrl1) {
            this.uploadBannerImageStep2(this.photoService.signedUploadUrl1, e);
        } else {
            this.photoService.getSignedUploadUrl().subscribe(
                response => {
                    this.photoService.signedUploadUrl1 = response.body;
                    this.uploadBannerImageStep2(this.photoService.signedUploadUrl1, e);
                }
            );
        }
    }

    uploadBannerImageStep2(signedUploadUrl, e) {
        // TODO: Either compress the image to a certain size or impose a file size limit.

        let file = e.target.files[0];
        let _this = this;
        const reader = new FileReader();
        reader.onload = function (e) {
            const checksum = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(reader.result)) + '';
            const contentLength = file.size;

            let fileName = file.name; // IMG_2146.JPG
            let regexPattern = /(?:\.([^.]+))?$/;
            let fileExtension = regexPattern.exec(fileName)[1]; // "JPEG"
            if (fileExtension.toUpperCase() == 'JPEG') {
                fileExtension = 'JPG';
            }

            // 'user_uuid/event_share_code_1/banner.extension'
            let filePath = _this.currentUser.uuid + '/' + _this.event.shareCode1 + '/banner.' + fileExtension;
            filePath = filePath.toUpperCase();

            _this.photoService.addImageBySignedUploadUrl(file, signedUploadUrl.uploadUrl, signedUploadUrl.authToken, contentLength, filePath, checksum.toString()).subscribe(
                async response => {
                    if (response.type === HttpEventType.UploadProgress) {
                        // Uploading, do nothing.
                    }
                    if (response.type === HttpEventType.Response) {
                        let updatedEvent: Event = new Event();
                        updatedEvent.id = _this.event.id;
                        updatedEvent.bannerImageExternalId = response.body.fileId;
                        updatedEvent.bannerImageExternalUrl = response.body.fileName;

                        // TODO: Do I need to create a ImageTransactionIn entry? There is no corresponding photo
                        // object, so that table would need modified to support this.  But I also don't want to store
                        // all Event banner images for free.

                        _this.eventService.updateEvent(updatedEvent).subscribe(
                            response => {


                                _this.setBannerImageSafeUrl(response.body);
                            }
                        );
                    }
                }
            );
        };
        reader.readAsBinaryString(file);
    }

    setBannerImageSafeUrl(event) {
        let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + event.bannerImageExternalUrl + "?Authorization=" + this.eventSignedDownloadToken.token;
        event.bannerImageSafeUrl = url;

        this.event = event;
        this.loadingBannerImageUpload = false;
    }

    clearBannerImage() {
        this.loadingBannerImageUpload = true;

        this.eventService.deleteEventBannerImage(this.event.id).subscribe(
            response => {
                if (response) {
                    // true
                    this.event.bannerImageExternalId = null;
                    this.event.bannerImageExternalUrl = null;
                    this.event.bannerImageSafeUrl = null;
                } else {
                    // false
                }

                this.loadingBannerImageUpload = false;
            }
        );
    }

    /** 
     * UPDATE EVENT FUNCTIONS
     */

    // Date Functions
    increaseEndHour() {
        if (this.settingsForm.value.endTimeHour == '01') {
            let value = { 'endTimeHour': '02' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '02') {
            let value = { 'endTimeHour': '03' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '03') {
            let value = { 'endTimeHour': '04' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '04') {
            let value = { 'endTimeHour': '05' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '05') {
            let value = { 'endTimeHour': '06' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '06') {
            let value = { 'endTimeHour': '07' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '07') {
            let value = { 'endTimeHour': '08' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '08') {
            let value = { 'endTimeHour': '09' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '09') {
            let value = { 'endTimeHour': '10' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '10') {
            let value = { 'endTimeHour': '11' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '11') {
            let value = { 'endTimeHour': '12' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '12') {
            let value = { 'endTimeHour': '01' };
            this.settingsForm.patchValue(value);
        }
    }

    decreaseEndHour() {
        if (this.settingsForm.value.endTimeHour == '01') {
            let value = { 'endTimeHour': '12' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '02') {
            let value = { 'endTimeHour': '01' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '03') {
            let value = { 'endTimeHour': '02' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '04') {
            let value = { 'endTimeHour': '03' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '05') {
            let value = { 'endTimeHour': '04' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '06') {
            let value = { 'endTimeHour': '05' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '07') {
            let value = { 'endTimeHour': '06' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '08') {
            let value = { 'endTimeHour': '07' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '09') {
            let value = { 'endTimeHour': '08' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '10') {
            let value = { 'endTimeHour': '09' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '11') {
            let value = { 'endTimeHour': '10' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeHour == '12') {
            let value = { 'endTimeHour': '11' };
            this.settingsForm.patchValue(value);
        }
    }

    increaseEndMinute() {
        if (this.settingsForm.value.endTimeMinute == '00') {
            let value = { 'endTimeMinute': '15' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '15') {
            let value = { 'endTimeMinute': '30' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '30') {
            let value = { 'endTimeMinute': '45' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '45') {
            let value = { 'endTimeMinute': '00' };
            this.settingsForm.patchValue(value);
        }
    }

    decreaseEndMinute() {
        if (this.settingsForm.value.endTimeMinute == '00') {
            let value = { 'endTimeMinute': '45' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '15') {
            let value = { 'endTimeMinute': '00' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '30') {
            let value = { 'endTimeMinute': '15' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.endTimeMinute == '45') {
            let value = { 'endTimeMinute': '30' };
            this.settingsForm.patchValue(value);
        }
    }

    increaseStartHour() {
        if (this.settingsForm.value.startTimeHour == '01') {
            let value = { 'startTimeHour': '02' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '02') {
            let value = { 'startTimeHour': '03' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '03') {
            let value = { 'startTimeHour': '04' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '04') {
            let value = { 'startTimeHour': '05' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '05') {
            let value = { 'startTimeHour': '06' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '06') {
            let value = { 'startTimeHour': '07' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '07') {
            let value = { 'startTimeHour': '08' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '08') {
            let value = { 'startTimeHour': '09' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '09') {
            let value = { 'startTimeHour': '10' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '10') {
            let value = { 'startTimeHour': '11' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '11') {
            let value = { 'startTimeHour': '12' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '12') {
            let value = { 'startTimeHour': '01' };
            this.settingsForm.patchValue(value);
        }
    }

    decreaseStartHour() {
        if (this.settingsForm.value.startTimeHour == '01') {
            let value = { 'startTimeHour': '12' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '02') {
            let value = { 'startTimeHour': '01' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '03') {
            let value = { 'startTimeHour': '02' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '04') {
            let value = { 'startTimeHour': '03' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '05') {
            let value = { 'startTimeHour': '04' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '06') {
            let value = { 'startTimeHour': '05' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '07') {
            let value = { 'startTimeHour': '06' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '08') {
            let value = { 'startTimeHour': '07' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '09') {
            let value = { 'startTimeHour': '08' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '10') {
            let value = { 'startTimeHour': '09' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '11') {
            let value = { 'startTimeHour': '10' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeHour == '12') {
            let value = { 'startTimeHour': '11' };
            this.settingsForm.patchValue(value);
        }
    }

    increaseStartMinute() {
        if (this.settingsForm.value.startTimeMinute == '00') {
            let value = { 'startTimeMinute': '15' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '15') {
            let value = { 'startTimeMinute': '30' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '30') {
            let value = { 'startTimeMinute': '45' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '45') {
            let value = { 'startTimeMinute': '00' };
            this.settingsForm.patchValue(value);
        }
    }

    decreaseStartMinute() {
        if (this.settingsForm.value.startTimeMinute == '00') {
            let value = { 'startTimeMinute': '45' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '15') {
            let value = { 'startTimeMinute': '00' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '30') {
            let value = { 'startTimeMinute': '15' };
            this.settingsForm.patchValue(value);
        } else if (this.settingsForm.value.startTimeMinute == '45') {
            let value = { 'startTimeMinute': '30' };
            this.settingsForm.patchValue(value);
        }
    }

    changeStartMeridiem() {
        let value;
        if (this.settingsForm.value.startTimeMeridiem == 'PM') {

            value = { 'startTimeMeridiem': 'AM' };


        } else {
            value = { 'startTimeMeridiem': 'PM' };
        }
        this.settingsForm.patchValue(value);
    }

    changeEndMeridiem() {
        let value;
        if (this.settingsForm.value.endTimeMeridiem == 'PM') {

            value = { 'endTimeMeridiem': 'AM' };


        } else {
            value = { 'endTimeMeridiem': 'PM' };
        }
        this.settingsForm.patchValue(value);
    }

    setStartTimeToNoon() {
        let valueHour = { 'startTimeHour': '12' };
        this.settingsForm.patchValue(valueHour);

        let valueMinutes = { 'startTimeMinutes': '00' };
        this.settingsForm.patchValue(valueMinutes);

        let valueMeridiem = { 'startTimeMeridiem': 'PM' };
        this.settingsForm.patchValue(valueMeridiem);
    }

    setStartTimeToMidnight() {
        let valueHour = { 'startTimeHour': '12' };
        this.settingsForm.patchValue(valueHour);

        let valueMinutes = { 'startTimeMinutes': '00' };
        this.settingsForm.patchValue(valueMinutes);

        let valueMeridiem = { 'startTimeMeridiem': 'AM' };
        this.settingsForm.patchValue(valueMeridiem);
    }

    setEndTimeToNoon() {
        let valueHour = { 'endTimeHour': '12' };
        this.settingsForm.patchValue(valueHour);

        let valueMinutes = { 'endTimeMinutes': '00' };
        this.settingsForm.patchValue(valueMinutes);

        let valueMeridiem = { 'endTimeMeridiem': 'PM' };
        this.settingsForm.patchValue(valueMeridiem);
    }

    setEndTimeToMidnight() {
        let valueHour = { 'endTimeHour': '12' };
        this.settingsForm.patchValue(valueHour);

        let valueMinutes = { 'endTimeMinutes': '00' };
        this.settingsForm.patchValue(valueMinutes);

        let valueMeridiem = { 'endTimeMeridiem': 'AM' };
        this.settingsForm.patchValue(valueMeridiem);
    }

    // Location Functions
    newLocationKeyup() {
        if (this.settingsForm.value.location.length > 3) {
            this.userService.getLocationSuggestions(this.settingsForm.value.location).subscribe(
                response => {
                    this.locationSuggestions = response.body.items;
                }
            );
        }
    }

    selectNewLocation(location: string) {
        this.newLocation = location;
        this.locationSuggestions = [];

        let url = 'https://www.google.com/maps/embed/v1/place?&q=' + location + '&key=AIzaSyBaIc0xglS3_DQ0S5h8B-nnnm1ohED0BsI'
        url = url.replace(/\s/g, "+");

        this.newLocationEmbedUrl = url;
    }

    clearNewLocation() {
        this.newLocation = '';
        this.locationSuggestions = [];
        this.newLocationEmbedUrl = '';

        this.settingsForm.controls['location'].setValue('');
    }

    // Delete Functions
    deleteEvent() {
        this.displayDeleteEventConfirm = true;
    }

    deleteEventConfirm() {
        this.loadingDeleteEvent = true;
        this.eventService.deleteEvent(this.event.id).subscribe(
            response => {
                // Delete from the cache.
                const itemCache = this.eventService.events.find(d => d.id === this.event.id);
                this.eventService.events.splice(this.eventService.events.indexOf(itemCache), 1);

                // Navigate to Events
                this.navigate('/events');

                // Announce?
                // this.emitEventDelete.emit(this.event);

                this.closeSettingsModal();
            });
    }

    // Remove Photo Functions
    removeAllPhotos(setToPrivate: boolean) {
        this.loadingRemovePhotos = true;

        // NEED TO FIRST MAKE SURE ALL THE PHOTOS ARE LOADED
        if (this.eventPhotos.length < this.event.photosCount) {
            // LOAD MORE PHOTOS
            this.lastStartPosition = this.eventPhotosPointer;
            this.eventService.retrieveAllEventPhotosByShareCode1AndShareCode2(this.lastStartPosition, this.shareCode1, this.shareCode2).subscribe(
                response => {
                    let photos = response.body;
                    if (photos.length > 0) {
                        let index = 0;
                        let _this = this;
                        (function repeat() {
                            setTimeout(function () {
                                _this.firstEventPhotoLoaded = true;

                                if (!photos[index].imageSafeUrl) {
                                    let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photos[index].imageUrlMed + "?Authorization=" + _this.eventSignedDownloadToken.token;
                                    photos[index].imageSafeUrl = url;

                                    _this.eventPhotos.push(photos[index]);
                                }

                                _this.eventPhotosPointer++;

                                index++;
                                if (index == photos.length) {
                                    // Last photo is processed from this group, see if we need to load another group
                                    // use _this.eventPhotosPointer or events.length?
                                    console.log(_this.eventPhotos.length + " == " + _this.event.photosCount);

                                    if (_this.eventPhotos.length == _this.event.photosCount) {
                                        console.log('loaded them all');
                                        _this.removeAllPhotosStart(setToPrivate);
                                    } else {
                                        console.log('need m0ar photos');
                                        _this.removeAllPhotos(setToPrivate);
                                    }


                                } else {
                                    console.log('process another photo');
                                    repeat();
                                }
                            }, 10);
                        })();
                    }
                });
        } else {
            // NO MORE PHOTOS TO LOAD, START REMOVING PHOTOS
            console.log('no more to load');
            this.removeAllPhotosStart(setToPrivate);
        }
    }

    // All the photos have been loaded, start removing them.
    removeAllPhotosStart(setToPrivate: boolean) {
        this.loadingRemovePhotos = true;

        // ALL PHOTOS 
        if (this.eventPhotos.length > 0) {
            for (let i = 0; i < this.eventPhotos.length; i++) {
                // Sets the event id before announcing the photo
                this.eventPhotos[i].addToEvent = this.event;

                let eventPhotoRemove: EventPhotoRemove = new EventPhotoRemove();
                eventPhotoRemove.photo = this.eventPhotos[i];
                eventPhotoRemove.setToPrivate = setToPrivate;

                this.layoutService.announceRemoveEventPhoto(eventPhotoRemove);
            }
        } else {
            this.modalRef.hide();
        }
    }

    deleteAllPhotos() {
        this.loadingRemovePhotos = true;

        this.eventService.deleteEventPhotos(this.event.id).subscribe(
            response => {
                this.deleteEventConfirm();
            }
        );
    }

    // Update Functions
    updateEvent() {
        this.loadingUpdateEvent = true;

        // 1
        let event = new Event();
        event.id = this.event.id;
        event.name = this.settingsForm.value.name;
        event.details = this.settingsForm.value.details;
        event.location = this.settingsForm.value.location;
        event.locationShowMap = this.settingsForm.value.locationShowMap;
        event.canConnectionsView = this.settingsForm.value.canConnectionsView;
        event.canConnectionsContribute = this.settingsForm.value.canConnectionsContribute;
        event.canConnectionsDiscuss = this.settingsForm.value.canConnectionsDiscuss;
        event.canConnectionsShare = this.settingsForm.value.canConnectionsShare;
        event.canPublicView = this.settingsForm.value.canPublicView;
        event.canPublicContribute = this.settingsForm.value.canPublicContribute;
        event.canPublicDiscuss = this.settingsForm.value.canPublicDiscuss;
        event.canPublicShare = this.settingsForm.value.canPublicShare;
        event.addToOwnerTimeline = this.settingsForm.value.addToMyTimeline;

        // 2. Handle start date and time
        let startDate = new Date(this.settingsForm.value.startDate);

        // TODO: Handle unselected date field
        //			Wed Dec 31 1969 00:00:00 GMT-0500 (Eastern Standard Time)

        if (this.showStartTime) {
            // Date + time
            event.startDateIncludeTime = true;

            let startTimeHour = this.settingsForm.value.startTimeHour;
            let startTimeMinute = this.settingsForm.value.startTimeMinute;
            let startTimeMeridiem = this.settingsForm.value.startTimeMeridiem;

            // 12:00 AM = MIDNIGHT		// 0
            // 01:00 AM					// 1
            // 02:00 AM					// 2
            // 03:00 AM					// 3
            // 04:00 AM					// 4
            // 05:00 AM					// 5
            // 06:00 AM					// 6
            // 07:00 AM					// 7
            // 08:00 AM					// 8
            // 09:00 AM					// 9
            // 10:00 AM					// 10
            // 11:00 AM					// 11

            // 12:00 PM = NOON			// 12
            // 01:00 PM					// 13
            // 02:00 PM					// 14
            // 03:00 PM					// 15
            // 04:00 PM					// 16
            // 05:00 PM					// 17
            // 06:00 PM					// 18
            // 07:00 PM					// 19
            // 08:00 PM					// 20
            // 09:00 PM					// 21
            // 10:00 PM					// 22
            // 11:00 PM					// 23

            if (startTimeMeridiem == 'AM') {
                if (startTimeHour == '12') {
                    startTimeHour = 0;
                } else if (startTimeHour == '01') {
                    startTimeHour = 1;
                } else if (startTimeHour == '02') {
                    startTimeHour = 2;
                } else if (startTimeHour == '03') {
                    startTimeHour = 3;
                } else if (startTimeHour == '04') {
                    startTimeHour = 4;
                } else if (startTimeHour == '05') {
                    startTimeHour = 5;
                } else if (startTimeHour == '06') {
                    startTimeHour = 6;
                } else if (startTimeHour == '07') {
                    startTimeHour = 7;
                } else if (startTimeHour == '08') {
                    startTimeHour = 8;
                } else if (startTimeHour == '09') {
                    startTimeHour = 9;
                } else if (startTimeHour == '10') {
                    startTimeHour = 10;
                } else if (startTimeHour == '11') {
                    startTimeHour = 11;
                }
            } else {
                if (startTimeHour == '12') {
                    startTimeHour = 12;
                } else if (startTimeHour == '01') {
                    startTimeHour = 13;
                } else if (startTimeHour == '02') {
                    startTimeHour = 14;
                } else if (startTimeHour == '03') {
                    startTimeHour = 15;
                } else if (startTimeHour == '04') {
                    startTimeHour = 16;
                } else if (startTimeHour == '05') {
                    startTimeHour = 17;
                } else if (startTimeHour == '06') {
                    startTimeHour = 18;
                } else if (startTimeHour == '07') {
                    startTimeHour = 19;
                } else if (startTimeHour == '08') {
                    startTimeHour = 20;
                } else if (startTimeHour == '09') {
                    startTimeHour = 21;
                } else if (startTimeHour == '10') {
                    startTimeHour = 22;
                } else if (startTimeHour == '11') {
                    startTimeHour = 23;
                }
            }

            if (startTimeMinute == '00') {
                startTimeMinute = 0;
            } else if (startTimeMinute == '15') {
                startTimeMinute = 15;
            } else if (startTimeMinute == '30') {
                startTimeMinute = 30;
            } else if (startTimeMinute == '45') {
                startTimeMinute = 45;
            }

            startDate.setHours(startTimeHour, startTimeMinute, 0, 0);
        } else {
            // Date only
            event.startDateIncludeTime = false;

            startDate.setHours(0, 0, 0, 0);
        }
        event.startDateTime = startDate;

        // 3. Handle end date and time
        if (this.showEndDate) {
            let endDate = new Date(this.settingsForm.value.endDate);

            // TODO: Handle unselected date field
            //			Wed Dec 31 1969 00:00:00 GMT-0500 (Eastern Standard Time)

            if (this.showEndTime) {
                // Date + time
                event.endDateIncludeTime = true;

                let endTimeHour = this.settingsForm.value.endTimeHour;
                let endTimeMinute = this.settingsForm.value.endTimeMinute;
                let endTimeMeridiem = this.settingsForm.value.endTimeMeridiem;

                if (endTimeMeridiem == 'AM') {
                    if (endTimeHour == '12') {
                        endTimeHour = 0;
                    } else if (endTimeHour == '01') {
                        endTimeHour = 1;
                    } else if (endTimeHour == '02') {
                        endTimeHour = 2;
                    } else if (endTimeHour == '03') {
                        endTimeHour = 3;
                    } else if (endTimeHour == '04') {
                        endTimeHour = 4;
                    } else if (endTimeHour == '05') {
                        endTimeHour = 5;
                    } else if (endTimeHour == '06') {
                        endTimeHour = 6;
                    } else if (endTimeHour == '07') {
                        endTimeHour = 7;
                    } else if (endTimeHour == '08') {
                        endTimeHour = 8;
                    } else if (endTimeHour == '09') {
                        endTimeHour = 9;
                    } else if (endTimeHour == '10') {
                        endTimeHour = 10;
                    } else if (endTimeHour == '11') {
                        endTimeHour = 11;
                    }
                } else {
                    if (endTimeHour == '12') {
                        endTimeHour = 12;
                    } else if (endTimeHour == '01') {
                        endTimeHour = 13;
                    } else if (endTimeHour == '02') {
                        endTimeHour = 14;
                    } else if (endTimeHour == '03') {
                        endTimeHour = 15;
                    } else if (endTimeHour == '04') {
                        endTimeHour = 16;
                    } else if (endTimeHour == '05') {
                        endTimeHour = 17;
                    } else if (endTimeHour == '06') {
                        endTimeHour = 18;
                    } else if (endTimeHour == '07') {
                        endTimeHour = 19;
                    } else if (endTimeHour == '08') {
                        endTimeHour = 20;
                    } else if (endTimeHour == '09') {
                        endTimeHour = 21;
                    } else if (endTimeHour == '10') {
                        endTimeHour = 22;
                    } else if (endTimeHour == '11') {
                        endTimeHour = 23;
                    }
                }

                if (endTimeMinute == '00') {
                    endTimeMinute = 0;
                } else if (endTimeMinute == '15') {
                    endTimeMinute = 15;
                } else if (endTimeMinute == '30') {
                    endTimeMinute = 30;
                } else if (endTimeMinute == '45') {
                    endTimeMinute = 45;
                }

                endDate.setHours(endTimeHour, endTimeMinute, 0, 0);
            } else {
                // Date only
                event.endDateIncludeTime = false;

                endDate.setHours(0, 0, 0, 0);
            }
            event.endDateTime = endDate;
        } else {
            event.endDateTime = null;
            event.endDateIncludeTime = false;
        }

        // 4
        this.eventService.updateEvent(event).subscribe(
            response => {
                let event: Event = response.body;

                this.event = event;

                // Add the event to the page and service caches.
                //this.addedEvent = event;
                //this.eventService.events.push(event);

                this.loadingUpdateEvent = false;

                this.closeSettingsModal();
            },
            err => {
                this.error = err.error.message;
                this.loadingUpdateEvent = false;
            }
        );
    }

    /** MODAL FUNCTIONS */

    openFindModal() {
        this.modalRef = this.modalService.show(
            this.findDialog,
            Object.assign({}, { class: 'modal-lg event' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
        );
    }

    openShareModal() {
        this.modalRef = this.modalService.show(
            this.shareDialog,
            Object.assign({}, { class: 'modal-lg event' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
        );
    }

    openSettingsModal() {
        this.initSettingsForm();

        this.modalRef = this.modalService.show(
            this.settingsDialog,
            Object.assign({}, { class: 'modal-lg event' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
        );
    }

    closeSettingsModal() {
        this.modalRef.hide();
        this.displayDeleteEventConfirm = false;
    }

    openUploadModal() {
        this.modalRef = this.modalService.show(
            this.uploadDialog,
            Object.assign({}, { class: 'modal-lg event' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
        );
    }

    closeUploadModal() {
        this.modalRef.hide();
        this.filesToUpload = [];
        this.showInvalidDateMessage = false;
    }

    openApprovalModal() {
        this.modalRef = this.modalService.show(
            this.approvalDialog,
            Object.assign({}, { class: 'modal-lg event' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
        );
    }

    /** 
     * UPLOAD FUNCTIONS 
     **/

    onSelect(item) {
        let eventStartDate = new Date(this.event.startDateTime);
        let eventEndDate = new Date(this.event.startDateTime);

        if (this.event.endDateTime) {
            eventEndDate = this.event.endDateTime;
        } else {
            eventEndDate.setHours(23);
            eventEndDate.setMinutes(59);
            eventEndDate.setSeconds(59);
        }

        for (let file of item.addedFiles) {
            let _this = this;
            let _myCallback;
            EXIF.getData(file, _myCallback = function () {

                let exifDate = EXIF.getTag(this, 'DateTimeOriginal');
                console.log(exifDate);

                if (exifDate) {
                    let photoDate = _this.parseExifDate(exifDate);

                    if (photoDate < eventStartDate || photoDate > eventEndDate) {
                        file.error = 'Date does not match';
                        _this.showInvalidDateMessage = true;
                    }
                }

                _this.filesToUpload.push(file);
            });
        }
    }

    onRemove(item) {
        this.filesToUpload.splice(this.filesToUpload.indexOf(item), 1);
    }

    uploadPhotos() {
        if (!this.currentUser) {
            this.uploadAsAnonymous();
        } else if (this.event.owner == this.currentUser.id) {
            this.uploadAsOwner();
        } else {
            this.uploadAsMember();
        }
    }

    uploadAsOwner() {
        let index = this.filesToUpload.length;
        while (index--) {
            let photoUpload: PhotoUpload = new PhotoUpload();
            photoUpload.file = this.filesToUpload[index];
            photoUpload.event = this.event;
            if (this.event.location) {

                console.log('adding the locaiton');
                console.log(this.event.location);
                photoUpload.form.value.location = this.event.location;
            }

            this.layoutService.announceUploadPhoto(photoUpload);

            this.filesToUpload.splice(index, 1);

            if (index == 0) {
                this.closeUploadModal();
            }
        }
    }

    uploadAsMember() {

    }

    uploadAsAnonymous() {

    }

    /**
     * HELPER FUNCTIONS
     */

    onOuterScroll(scrollEvent) {
        console.log(scrollEvent);
        let height = 156;
        if (this.event?.bannerSize == 'lg') {
            height = 236;
        } else if (this.event?.bannerSize == 'md') {
            height = 206;
        }

        if (scrollEvent.srcElement.scrollTop > height) {
            this.compressHeader = true;
        } else {
            this.compressHeader = false;
        }
    }

    datesAreOnSameDay(first, second) {
        let firstDate = new Date(first);
        let secondDate = new Date(second);

        return firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getDate() === secondDate.getDate();
    }

    parseExifDate(s) {
        var b = s.split(/\D/);
        return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }

    navigateToLogin() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                eventCode1: this.shareCode1,
                eventCode2: this.shareCode2
            }
        };
        this.router.navigate(['/login'], navigationExtras);

    }
}
