<div class="page-title center"><img src="/assets/images/logos/instagram.png" class="logo" /> Sync Instagram Photos <i class="fas fa-question-circle" title="Show Help" (click)="showHelp = !showHelp" [ngClass]="{'active': showHelp }"></i></div>
<div *ngIf="showHelp" class="help-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</div>

<div class="col-md-12" [ngClass]="{'loading in': loading }">
    <div class="row">
        <div class="col-md-12 right">
            <span class="button" (click)="syncAll()"><i class="fas fa-sync-alt" title="Sync"></i> Start Sync</span> <span class="button" (click)="navigate('photo/add')">Back to Syncs</span>
        </div>
    </div>
    <div class="row album-row">
        <div class="col-md-4 title">Name</div>
        <div class="col-md-4 title">Photos</div>
        <div class="col-md-4 title right">Created</div>
        <!--
        <div class="col-md-2 title">Status</div>
        <div class="col-md-1 title right">Actions</div>
        -->
    </div>
    <div *ngFor="let album of albums" class="row album-row">
        <div class="col-md-4">{{ album.name }}</div>
        <div class="col-md-4">{{ album.count }}</div>
        <div class="col-md-4 right">{{ album.created_time | date:'longDate' }}</div>
        <!--
        <div class="col-md-2"><span class="red">Sync disabled</span></div>
        <div class="col-md-1 right"><i class="fas fa-sync-alt" title="Sync"></i></div>
        -->
    </div>
    <div class="row album-row last">
        <div class="col-md-4 title">&nbsp;</div>
        <div class="col-md-4 title">{{ totalPhotos }}</div>
        <div class="col-md-4 title">&nbsp;</div>
        <!--
        <div class="col-md-2 title">&nbsp;</div>
        <div class="col-md-1 title">&nbsp;</div>
        -->
    </div>
</div>

<ng-template #confirmSyncAllDialog>
    <div class="modal-header">
        <h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
    </div>
    <div class="modal-body">
        <p class="center">The time to complete a full sync depends on the number of photos stored within Facebook.</p>
        <p class="center">Photos will become available to update and classify as they are imported.</p>
    </div>
    <div class="modal-footer no-bg">
        <button type="button" class="btn btn-primary btn-md" (click)="syncAllConfirm()">Confirm</button>
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>