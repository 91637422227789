<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introduction</a></div>
		<div class="orange active"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. Getting started</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance</a></div>
		<div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div>
	</div>
	<div>
		<div class="banner orange">
			<div class="title">Photonomy Thoughts and Insights</div>
		</div>
		<div class="text-container">
			<p>One night, during a lively conversation with friends and family, we found ourselves lamenting the state of social media. We were unanimous in our frustration: it had become overly commercialized, with intrusive ads and algorithms dictating the experience. That night, an idea was born. What if we could strip away all the commercial aspects and create a platform that offered a more meaningful experience? We envisioned a space focused on preserving and sharing photos, particularly those cherished family photos that tell our personal histories and need to be stored and passed down through generations.</p>
			<p>This wasn't just a whimsical thought. The need was real and pressing among my immediate circle. Our discussions revealed a common pain point: the challenge of organizing, storing, and sharing family photos in a way that felt secure and personal. This highlighted a crucial principle in application development: identifying and solving a real problem. Too often, ideas are forced, seeking to create demand rather than addressing existing needs. Our approach was different. We wanted to create something that would genuinely improve our lives and the lives of others.</p>
			<p>We embarked on this journey fully aware of the formidable giants in the field—Facebook, Instagram, iCloud, Google Photos. Competing directly with these behemoths seemed impractical, if not impossible. Instead, we chose a more collaborative approach. Our vision was to develop a service that could seamlessly plug into these existing platforms, acting as a companion rather than a competitor. By doing so, we could leverage the strengths of established services while offering a unique, focused experience centered on the preservation and sharing of family photos.</p>
			<p>In this way, our platform aims to fill a niche that has been overlooked. It's not about disrupting the industry but enhancing it, providing a dedicated space for the memories that matter most.</p>
			<p>That night, Photonomy was born. On x/xx/xxx the initial code base was created under the code name apv (ancestry photo vault).</p>
		</div>
	</div>
</div>
<app-footer></app-footer>