import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Gallery } from '../models/gallery.model';
import { Photo } from '../models/photo.model';

@Injectable()
export class GalleryService {

	public galleriesChanged = new Subject();
	public galleries: Array<Gallery>;

	public galleriesPinned: Array<Gallery>;

	public galleryDeleted = new Subject();

	constructor(
		private http: HttpClient
	) { }

	// Return a list of all user galleries
	retrieveAllGalleries() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/galleries';

		return this.http.get<Array<Gallery>>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Return a list of all user galleries
	retrieveGalleriesByDevice(device: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/galleries/' + device;

		return this.http.get<Array<Gallery>>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Retrieve a gallery
	retrieveGallery(galleryId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery/' + galleryId.toString();

		return this.http.get<Gallery>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Retrieve a gallery by shareCode1 and shareCode2
	retrieveGalleryByShareCode1AndShareCode2(shareCode1: string, shareCode2: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery/' + shareCode1 + '/' + shareCode2;

		return this.http.get<Gallery>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Retrieve a gallery by shareCode1 and shareCode2
	retrieveGalleryPhotosByShareCode1AndShareCode2(startPosition: number, shareCode1: string, shareCode2: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('startPosition', startPosition.toString());

		const requestUrl = '/api/gallery/' + shareCode1 + '/' + shareCode2 + '/photos';

		return this.http.get<Array<Photo>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	// Retrieve a gallery image by externalId, shareCode1, and shareCode2
	retrieveGalleryImageByExternalIdShareCode1AndShareCode2(externalId: string, shareCode1: string, shareCode2: string): Observable<any> {
		const requestUrl = '/api/gallery/' + shareCode1 + '/' + shareCode2 + '/' + externalId + '/image';

		return this.http.get<any>(requestUrl,
			{
				responseType: 'blob' as 'json',
				observe: 'response'
			});
	}

	// Create a gallery
	createGallery(gallery: Gallery) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery';

		return this.http.post<Gallery>(requestUrl, gallery, { headers: headers, observe: 'response' });
	}

	// Update a gallery
	updateGallery(gallery: Gallery) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery';

		return this.http.put<Gallery>(requestUrl, gallery, { headers: headers, observe: 'response' });
	}

	// Delete a gallery
	deleteGallery(galleryId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery/' + galleryId.toString();

		return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Pin a gallery
	pinGallery(galleryId: number, device: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery/' + galleryId.toString() + '/pin/' + device;

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	// Unpin a gallery
	unpinGallery(galleryId: number, device: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/gallery/' + galleryId.toString() + '/unpin/' + device;

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	getGalleries() {
		return this.galleries;
	}
	setGalleries(galleries: Array<Gallery>) {
		galleries.sort((a, b) => a.name.localeCompare(b.name));

		this.galleries = galleries;
		this.galleriesChanged.next(galleries);
	}

	getGalleriesPinned() {
		return this.galleriesPinned;
	}
	setGalleriesPinned(galleriesPinned: Array<Gallery>) {
		this.galleriesPinned = galleriesPinned;
	}

	announceGalleryDeleted(gallery: Gallery) {
		this.galleryDeleted.next(gallery);
	}
}
