import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
	selector: 'app-card-start-here',
	templateUrl: './card-start-here.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardStartHereComponent implements OnInit, OnDestroy {
	@Input() selectedFilter: string;
	@Input() filters = [];
	@Output() emitFilterChanged = new EventEmitter<string>();

	@ViewChild('card') cardRef: ElementRef;
	@ViewChild('filterSelect') filterSelect;

	form: UntypedFormGroup;

	constructor(
		private router: Router,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit() {
		this.initForm();
	}

	ngAfterViewInit() {
		// FUTURE: This should not need a setTimeout to not throw an error.
		const _this = this;
		setTimeout(function () {
			let item = _this.filterSelect.itemsList.findItem(_this.selectedFilter);
			if (item) {
				_this.filterSelect.select(item);
			}
		}, 100);
	}

	ngOnDestroy() { }

	initForm() {
		this.form = this.formBuilder.group({
			'filter': new UntypedFormControl(null, []),
		});
	}

	filterChanged() {
		this.emitFilterChanged.emit(this.form.value.filter);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}
