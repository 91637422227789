import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'value'
})
export class ValuePipe implements PipeTransform {

	//transform(input:Array<any>):string {
	//   return input.join(', ');
	//}

	transform(input: string): [] {
		if (input) {
			return JSON.parse(input);
		}
	}
}
