<div class="outer-container" (scroll)="onOuterScroll($event)">
    <!-- HEADER -->
    <div class="header-container" [ngClass]="{'compress': compressHeader}">
        <div class="logo-container">
            <img class="logo default" src="/assets/images/logo.jpg" (click)="navigate('/events')">
        </div>
        <div class="icon-container" *ngIf="!loadingEvent && !error && currentUser">
            <div class="user-initials" (click)="showUserMenu = !showUserMenu">{{ currentUser?.initials }}</div>
            <ul *ngIf="showUserMenu" class="dropdown-menu">
                <li><a href="javascript:void(0)" (click)="navigate('/events')"><i class="fas fa-angle-double-left"></i>Return to Photonomy</a></li>
            </ul>
        </div>
    </div>

    <!-- BANNER -->
    <div *ngIf="!loadingEvent && !loadingUser && !error && loadingBannerImageUpload" class="banner-container">
        <div class="height-container loading in" [ngClass]="bannerSizeTemp || event?.bannerSize"></div>
    </div>
    <div *ngIf="!loadingEvent && !loadingUser && !error && !loadingBannerImageUpload" class="banner-container" [ngClass]="bannerSizeTemp || event?.bannerSize" [ngStyle]="{'background-image': event?.bannerImageSafeUrl ? 'url(' + event.bannerImageSafeUrl + ')' : 'none'}">
        <div class="height-container" [ngClass]="bannerSizeTemp || event?.bannerSize">
            <div class="text-container">
                <div class="banner-title">{{ event?.name }}</div>
                <div class="banner-subtitle" *ngIf="bannerSubtitleDisplayTemp == 'count' || (bannerSubtitleDisplayTemp == '' && event?.bannerSubtitleDisplay == 'count')">{{ event?.photosCount }} photos</div>
                <div class="banner-subtitle" *ngIf="bannerSubtitleDisplayTemp == 'dates' || (bannerSubtitleDisplayTemp == '' && event?.bannerSubtitleDisplay == 'dates')">
                    <ng-container *ngIf="!event?.startDateIncludeTime">
                        {{ event?.startDateTime | date:'longDate' }}
                        <ng-container *ngIf="event?.endDateTime && !event.endDateIncludeTime">- {{ event?.endDateTime | date:'longDate' }}</ng-container>
                        <ng-container *ngIf="event?.endDateTime && event.endDateIncludeTime && datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">- {{ event?.endDateTime | date:'h:mm a' }}</ng-container>
                        <ng-container *ngIf="event?.endDateTime && event.endDateIncludeTime && !datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">- {{ event?.endDateTime | date:'MMMM d, y, h:mm a' }}</ng-container>
                    </ng-container>
                    <ng-container *ngIf="event?.startDateIncludeTime">
                        {{ event?.startDateTime | date:'MMMM d, y, h:mm a' }}
                        <ng-container *ngIf="event?.endDateTime && !event.endDateIncludeTime">- {{ event?.endDateTime | date:'longDate' }}</ng-container>
                        <ng-container *ngIf="event?.endDateTime && event.endDateIncludeTime && datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">- {{ event?.endDateTime | date:'h:mm a' }}</ng-container>
                        <ng-container *ngIf="event?.endDateTime && event.endDateIncludeTime && !datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">- {{ event?.endDateTime | date:'MMMM d, y, h:mm a' }}</ng-container>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="currentUserType == 'owner'" class="banner-icon-containers">
                <span class="hover-text">{{ bannerImageHoverText }}</span>

                <!-- SUBTEXT -->
                <i class="far fa-calendar div-icon lg" [ngClass]="{'selected': bannerSubtitleDisplayTemp == 'dates' || (bannerSubtitleDisplayTemp == '' && event?.bannerSubtitleDisplay == 'dates') }" *ngIf="bannerImageShowSubtitleSelectors" (click)="updateSubtitleDisplay('dates')" (mouseover)="bannerImageHoverText = 'Show event dates'" (mouseout)="bannerImageHoverText = ''"></i>
                <div class="div-icon md" [ngClass]="{'selected': bannerSubtitleDisplayTemp == 'count' || (bannerSubtitleDisplayTemp == '' && event?.bannerSubtitleDisplay == 'count') }" *ngIf="bannerImageShowSubtitleSelectors" (click)="updateSubtitleDisplay('count')" (mouseover)="bannerImageHoverText = 'Show number of photos'" (mouseout)="bannerImageHoverText = ''">#</div>
                <i class="fas fa-remove-format div-icon sm" [ngClass]="{'selected': bannerSubtitleDisplayTemp == 'hide' || (bannerSubtitleDisplayTemp == '' && event?.bannerSubtitleDisplay == 'hide') }" *ngIf="bannerImageShowSubtitleSelectors" (click)="updateSubtitleDisplay('hide')" (mouseover)="bannerImageHoverText = 'Hide subtitle'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-check" *ngIf="!bannerImageLocked && bannerImageShowSubtitleSelectors" (click)="saveSubtitleDisplay()" (mouseover)="bannerImageHoverText = 'Save subtitle settings'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-subscript" *ngIf="!bannerImageLocked && !bannerImageShowSubtitleSelectors && !bannerImageShowUploadSelectors && !bannerImageShowSizeSelectors" (click)="bannerImageShowSubtitleSelectors = !bannerImageShowSubtitleSelectors" (mouseover)="bannerImageHoverText = 'Manage subtitle'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-file-upload disabled" *ngIf="!bannerImageLocked && bannerImageShowSubtitleSelectors"></i>
                <i class="fas fa-expand-alt disabled" *ngIf="!bannerImageLocked && bannerImageShowSubtitleSelectors"></i>
                <i class="fas fa-unlock disabled" *ngIf="!bannerImageLocked && bannerImageShowSubtitleSelectors"></i>

                <!-- UPLOAD IMAGE -->
                <i class="fas fa-subscript disabled" *ngIf="!bannerImageLocked && bannerImageShowUploadSelectors"></i>
                <ng-container *ngIf="bannerImageShowUploadSelectors">
                    <input id="file-userImage" class="hidden" type="file" (change)="uploadBannerImageStep1($event)" />
                    <label for="file-userImage"><i class="fas fa-image div-icon md" [ngClass]="{'selected': event?.bannerImageExternalId }" (mouseover)="bannerImageHoverText = 'Upload banner image'" (mouseout)="bannerImageHoverText = ''"></i></label>
                </ng-container>
                <i class="fas fa-ban div-icon sm" [ngClass]="{'selected': !event?.bannerImageExternalId || event?.bannerImageExternalId == ''}" *ngIf="bannerImageShowUploadSelectors" (click)="clearBannerImage()" (mouseover)="bannerImageHoverText = 'Remove banner image'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-check" *ngIf="!bannerImageLocked && bannerImageShowUploadSelectors" (click)="bannerImageShowUploadSelectors = !bannerImageShowUploadSelectors" (mouseover)="bannerImageHoverText = 'Save banner image'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-file-upload" *ngIf="!bannerImageLocked && !bannerImageShowUploadSelectors && !bannerImageShowSubtitleSelectors && !bannerImageShowSizeSelectors" (click)="bannerImageShowUploadSelectors = !bannerImageShowUploadSelectors" (mouseover)="bannerImageHoverText = 'Upload banner image'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-expand-alt disabled" *ngIf="!bannerImageLocked && bannerImageShowUploadSelectors"></i>
                <i class="fas fa-unlock disabled" *ngIf="!bannerImageLocked && bannerImageShowUploadSelectors"></i>

                <!-- IMAGE SIZE -->
                <i class="fas fa-subscript disabled" *ngIf="!bannerImageLocked && bannerImageShowSizeSelectors"></i>
                <i class="fas fa-file-upload disabled" *ngIf="!bannerImageLocked && bannerImageShowSizeSelectors"></i>
                <div class="div-icon sm" [ngClass]="{'selected': bannerSizeTemp == 'lg' || (bannerSizeTemp == '' && event?.bannerSize == 'lg') }" *ngIf="bannerImageShowSizeSelectors" (click)="updateBannerSize('lg')" (mouseover)="bannerImageHoverText = 'Large'" (mouseout)="bannerImageHoverText = ''">L</div>
                <div class="div-icon md" [ngClass]="{'selected': bannerSizeTemp == 'md' || (bannerSizeTemp == '' && event?.bannerSize == 'md')}" *ngIf="bannerImageShowSizeSelectors" (click)="updateBannerSize('md')" (mouseover)="bannerImageHoverText = 'Medium'" (mouseout)="bannerImageHoverText = ''">M</div>
                <div class="div-icon lg" [ngClass]="{'selected': bannerSizeTemp == 'sm' || (bannerSizeTemp == '' && event?.bannerSize == 'sm')}" *ngIf="bannerImageShowSizeSelectors" (click)="updateBannerSize('sm')" (mouseover)="bannerImageHoverText = 'Small'" (mouseout)="bannerImageHoverText = ''">S</div>
                <i class="fas fa-check" *ngIf="!bannerImageLocked && bannerImageShowSizeSelectors" (click)="saveBannerSize()" (mouseover)="bannerImageHoverText = 'Save image size'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-expand-alt" *ngIf="!bannerImageLocked && !bannerImageShowSizeSelectors && !bannerImageShowSubtitleSelectors && !bannerImageShowUploadSelectors" (click)="bannerImageShowSizeSelectors = !bannerImageShowSizeSelectors" (mouseover)="bannerImageHoverText = 'Change image size'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-unlock disabled" *ngIf="!bannerImageLocked && bannerImageShowSizeSelectors"></i>

                <!-- LOCK / UNLOCK -->
                <i class="fas fa-lock" *ngIf="bannerImageLocked" (click)="bannerImageLocked = false" (mouseover)="bannerImageHoverText = 'Unlock banner settings'" (mouseout)="bannerImageHoverText = ''"></i>
                <i class="fas fa-unlock" *ngIf="!bannerImageLocked && !bannerImageShowSubtitleSelectors && !bannerImageShowUploadSelectors && !bannerImageShowSizeSelectors" (click)="bannerImageLocked = true" (mouseover)="bannerImageHoverText = 'Lock banner'" (mouseout)="bannerImageHoverText = ''"></i>
            </div>
        </div>
    </div>

    <!-- CONTENT AREA -->
    <div class="content-container" *ngIf="!loadingEvent && !loadingUser && !error">
        <div class="left-column" [ngClass]="{'expand': (currentUser && event.canConnectionsDiscuss) || (!currentUser && event.canPublicDiscuss) || (!currentUser && event.canConnectionsDiscuss)}">
            <div class="actions-container">
                <!-- ACTION ICONS -->
                <div class="icon-column">
                    <div class="icon-cell" *ngIf="(currentUser && event.canConnectionsDiscuss) || (!currentUser && event.canPublicDiscuss) || (!currentUser && event.canConnectionsDiscuss)">
                        <div class="action-icon-container discussion"><i class="fas fa-comment"></i>Discuss Event</div>
                    </div>
                    <div class="icon-cell" *ngIf="(currentUser && event.canConnectionsContribute) || (!currentUser && event.canPublicContribute)">
                        <div class="action-icon-container" (click)="openUploadModal()"><i class="fas fa-upload"></i>Upload Photos</div>
                    </div>
                    <div class="icon-cell" *ngIf="currentUser">
                        <div class="action-icon-container" (click)="findEventPhotos()"><i class="fas fa-search"></i>Find Photos</div>
                    </div>
                    <div class="icon-cell" *ngIf="eventIsInFuture">
                        <div class="action-icon-container"><i class="fas fa-envelope-open-text"></i>Send RSVP</div>
                    </div>
                    <div class="icon-cell" *ngIf="(currentUser && event.canConnectionsShare) || (!currentUser && event.canPublicShare)">
                        <div class="action-icon-container" (click)="openShareModal()"><i class="fas fa-share-alt"></i>Share Event</div>
                    </div>
                    <div class="icon-cell" *ngIf="currentUserType == 'owner'">
                        <div class="action-icon-container" (click)="openSettingsModal()"><i class="fas fa-cog"></i>Edit Event</div>
                    </div>
                    <div class="icon-cell" *ngIf="currentUserType == 'owner'">
                        <div class="action-icon-container" (click)="openApprovalModal()"><i class="far fa-images"></i>View Photo Requests</div>
                    </div>
                    <div class="icon-cell filler">
                        <div class="filler-container"></div>
                    </div>
                </div>
                <!-- DISCUSSION -->
                <div class="discussion-column" *ngIf="(currentUser && event.canConnectionsDiscuss) || (!currentUser && event.canPublicDiscuss) || (!currentUser && event.canConnectionsDiscuss)">
                    <ng-container *ngIf="currentUser">
                        <div *ngIf="eventComments.length == 0" class="login-message-container">
                            <i class="fas fa-comments"></i>
                            Be the first to discuss this event
                        </div>
                        <div class="comments-scroll-outer-container" *ngIf="eventComments.length > 0">
                            <div class="comments-scroll-inner-container">
                                <div>
                                    <ng-container *ngFor="let eventComment of eventComments">
                                        <app-event-comment [eventComment]="eventComment" [showReplies]="false"></app-event-comment>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="chat-submit-container">
                            <form #c="ngForm" [formGroup]="commentForm" class="form">
                                <i class="fas fa-comment" (click)="addDiscussionComment()"></i>
                                <input id="comment" type="text" class="form-control comment-input" formControlName="comment" maxlength="500" placeholder="Submit a comment" />
                            </form>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!currentUser">
                        <div class="login-message-container">
                            <i class="fas fa-comments"></i>
                            <a href="javascript:void(0)" (click)="navigate('')">Sign-up</a> or <a href="javascript:void(0)" (click)="navigateToLogin()">login</a> to join the discussion
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="right-column">
            <infinite-scroll (scrolled)="onScroll()">
                <div class="details-container" [ngClass]="{'map': event?.location && event.locationShowMap }">

                    <div class="event-map-container" *ngIf="event?.location && event.locationShowMap">
                        <div *ngIf="locationEmbedUrl">
                            <iframe [src]="locationEmbedUrl | safe: 'resourceUrl'" width="300" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                    <div class="detail-rows-container">
                        <div class="detail-row">
                            <div class="icon"><i class="fas fa-user"></i></div>
                            <i class="fas fa-circle-notch fa-spin" *ngIf="loadingHost"></i>
                            <span *ngIf="!loadingHost">
                                Hosted by <img *ngIf="hostUser?.imageSafeUrl" [src]="hostUser.imageSafeUrl" class="image" /> {{ hostUser.firstName }} {{ hostUser.lastName }}
                            </span>
                        </div>

                        <div class="detail-row" *ngIf="event?.startDateTime && !event?.endDateTime">
                            <div class="icon"><i class="far fa-clock"></i></div>
                            <span>
                                <ng-container *ngIf="!event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'longDate' }}
                                </ng-container>
                                <ng-container *ngIf="event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'MMMM d, y, h:mm a' }}
                                </ng-container>
                            </span>
                        </div>
                        <div class="detail-row" *ngIf="event?.startDateTime && event?.endDateTime && datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">
                            <div class="icon"><i class="far fa-clock"></i></div>
                            <span>
                                <ng-container *ngIf="!event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'longDate' }}
                                </ng-container>
                                <ng-container *ngIf="event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'MMMM d, y, h:mm a' }}
                                </ng-container>

                                <!-- <ng-container *ngIf="!event?.endDateIncludeTime">
                                {{ event?.endDateTime | date:'longDate' }}
                            </ng-container> -->
                                <ng-container *ngIf="event?.endDateIncludeTime">
                                    - {{ event?.endDateTime | date:'h:mm a' }}
                                </ng-container>
                            </span>
                        </div>
                        <div class="detail-row" *ngIf="event?.startDateTime && event?.endDateTime && !datesAreOnSameDay(event?.startDateTime, event?.endDateTime)">
                            <div class="icon"><i class="far fa-clock"></i></div>
                            <span>
                                <ng-container *ngIf="!event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'longDate' }}
                                </ng-container>
                                <ng-container *ngIf="event?.startDateIncludeTime">
                                    {{ event?.startDateTime | date:'MMMM d, y, h:mm a' }}
                                </ng-container>
                                <ng-container *ngIf="!event?.endDateIncludeTime">
                                    - {{ event?.endDateTime | date:'longDate' }}
                                </ng-container>
                                <ng-container *ngIf="event?.endDateIncludeTime">
                                    - {{ event?.endDateTime | date:'MMMM d, y, h:mm a' }}
                                </ng-container>
                            </span>
                        </div>

                        <div class="detail-row" *ngIf="event?.location && !event.locationShowMap">
                            <div class="icon"><i class="fas fa-location-arrow"></i></div>
                            <span>
                                {{ event?.location }}
                            </span>
                        </div>

                        <div class="detail-row details" *ngIf="event?.details">
                            <div class="icon" *ngIf="!event?.location || !event.locationShowMap"><i class="fas fa-info"></i></div>
                            <span class="details">{{ event.details }}</span>
                        </div>
                    </div>
                </div>

                <!-- PHOTOS -->
                <div class="photo-container">
                    <div *ngIf="!error && loadingPhotos" class="loading in"></div>
                    <div *ngIf="!error && !loadingEvent && !loadingPhotos && eventPhotos.length == 0" class="no-photos-message">
                        <div *ngIf="!loadingFindPhotos">No photos have been added to this event</div>
                        <div *ngIf="!loadingFindPhotos && currentUser"><i class="fas fa-search"></i> <a href="javascript:void(0)" (click)="findEventPhotos()">Search your Photonomy network for photos</a></div>
                        <div *ngIf="loadingFindPhotos" class="loading in loadingFindPhotos"></div>
                    </div>

                    <ngx-masonry>
                        <div ngxMasonryItem class="masonry-item" id="{{'masonry-item-'+i}}" *ngFor="let photo of eventPhotos; let i = index">
                            <img *ngIf="photo.imageSafeUrl" [src]="photo.imageSafeUrl" />
                        </div>
                    </ngx-masonry>
                    <div class="scrollbar" *ngIf="showScrollBar && eventPhotos.length > 15">
                        <div>Loading more photos...</div>
                    </div>

                </div>
            </infinite-scroll>
        </div>
    </div>

    <!-- LOADING INDICATOR -->
    <div class="loading-container" *ngIf="(loadingEvent || loadingUser) && !error">
        <div class="loading in"></div>
    </div>

    <!-- ERROR MESSAGE -->
    <div class="error-container" *ngIf="!loadingEvent && error">
        <div><i class="fas fa-car-crash"></i></div>
        <div>Something went wrong loading the event</div>
        <div *ngIf="currentUser"><a href="javascript:void(0)" (click)="navigate('/events')">Return to Photonomy</a></div>
        <div *ngIf="!currentUser"><a href="javascript:void(0)" (click)="navigate('/login')">Login to Photonomy</a></div>
    </div>
</div>

<ng-template #uploadDialog>
    <div class="modal-header">
        <h5 class="modal-title">Upload Photos</h5>
    </div>
    <div class="modal-body">
        <div *ngIf="showInvalidDateMessage" class="invalid-date-message"><i class="fas fa-exclamation"></i>WARNING: The date on one or more photos does not match the event date. Remove any unwanted photos and confirm.</div>
        <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)" [expandable]="false" accept="image/jpeg,image/jpg,image/png,image/gif" [maxFileSize]="10485760">
            <ngx-dropzone-label class="full">
                <div class="upload-text">
                    <div><i class="fas fa-upload"></i></div>
                    <div class="text">Drop images here or click to browse</div>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesToUpload; let i = index" class="upload-row" [id]="i" [file]="f" [removable]="!loadingPhotos" (removed)="onRemove(f)" [ngClass]="{'error': f.error, 'success': f.success, 'finishing': f.finishing }">
                <ngx-dropzone-label class="photo-details">
                    <span *ngIf="!f.error">
                        {{ f.name }}
                        <br />{{ f.size/1024/1024 | number:'.2' }} MB
                    </span>
                    <span *ngIf="f.error">
                        {{ f.error }}
                    </span>
                </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </div>
    </div>
    <div class="modal-footer no-bg">
        <button type="button" class="btn btn-success btn-md" (click)="uploadPhotos()" [disabled]="filesToUpload.length == 0">Upload</button>
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="closeUploadModal()">Cancel</button>
    </div>
</ng-template>

<ng-template #findDialog>
    <div class="modal-header">
        <h5 class="modal-title">
            <span *ngIf="loadingFindPhotos">Searching for photos</span>
            <span *ngIf="myPreExistingPhotos.length == 0 && connectionsPreExistingPhotos.length == 0 && !loadingEvent && !loadingFindPhotos">
                No photos found
            </span>
            <span *ngIf="(myPreExistingPhotos.length + connectionsPreExistingPhotos.length) > 1 && !loadingEvent && !loadingFindPhotos">
                Select which photos you would like to add to the event
            </span>
        </h5>
    </div>
    <div class="modal-body">
        <div *ngIf="loadingFindPhotos" class="loading-find-photos">
            <div class="loading in"></div>
        </div>
        <div class="confirm-container" *ngIf="myPreExistingPhotos.length == 0 && connectionsPreExistingPhotos.length == 0 && !loadingEvent && !loadingFindPhotos">
            <div class="warning-message-center">
                <div><i class="fas fa-search"></i></div>
                <div>There are no photos that match the event dates in your Photonomy network</div>
            </div>
        </div>
        <div *ngIf="myPreExistingPhotos.length > 0 && !loadingFindPhotos" class="preexisting-message">
            <div class="options-right" *ngIf="!loadingFindPhotos"><a href="javascript:void(0)" (click)="unSelectAllPhotos()">unselect all</a> | <a href="javascript:void(0)" (click)="selectAllPhotos()">select all</a></div>
            <div>My Photos ({{ myPreExistingPhotos.length }})</div>
            <div class="private-note">Private photos that are added to the event will become public.</div>
        </div>
        <div *ngIf="myPreExistingPhotos.length > 0 && !loadingFindPhotos" class="photos-container">
            <div *ngFor="let photo of myPreExistingPhotos; let i = index" class="photo-container">
                <div *ngIf="photo.imageLoading" class="bg loading in">
                    &nbsp;
                </div>
                <div *ngIf="!photo.imageLoading" class="bg">
                    <img *ngIf="photo.imageSafeUrl" [src]="photo.imageSafeUrl" />
                    <input type="checkbox" id="preExistingPhoto{{ i }}" name="preExistingPhoto{{ i }}" value="add" [checked]="photo.connectionsCanView">
                    <i *ngIf="!photo.connectionsCanView" class="fas fa-lock" [tooltip]="privateTemplate" placement="left"></i>
                    <ng-template #privateTemplate>
                        This photo is currently private to you.
                    </ng-template>
                </div>
            </div>
        </div>

        <div *ngIf="connectionsPreExistingPhotos.length > 0 && !loadingFindPhotos" class="preexisting-message">
            <div class="options-right" *ngIf="!loadingFindPhotos"><a href="javascript:void(0)" (click)="unSelectAllPhotos()">unselect all</a> | <a href="javascript:void(0)" (click)="selectAllPhotos()">select all</a></div>
            <div>Connections Photos ({{ connectionsPreExistingPhotos.length }})</div>
            <div class="private-note">
                A request will be sent to add a copy of the photo to the event.
            </div>
        </div>
        <div *ngIf="connectionsPreExistingPhotos.length > 0 && !loadingFindPhotos" class="photos-container">
            <div *ngFor="let photo of connectionsPreExistingPhotos; let i = index" class="photo-container">
                <div *ngIf="photo.imageLoading" class="bg loading in">
                    &nbsp;
                </div>
                <div *ngIf="!photo.imageLoading" class="bg">
                    <img *ngIf="photo.imageSafeUrl" [src]="photo.imageSafeUrl" />
                    <input type="checkbox" id="connectionsPreExistingPhoto{{ i }}" name="connectionsPreExistingPhoto{{ i }}" value="add" [checked]="photo.connectionsCanView">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer no-bg">
        <button *ngIf="myPreExistingPhotos.length > 0" type="button" class="btn btn-success btn-md" (click)="addSelectedPhotos()">Add selected photos</button>
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>

<ng-template #settingsDialog>
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!displayDeleteEventConfirm">Edit Event</ng-container>
            <ng-container *ngIf="displayDeleteEventConfirm">Delete Event</ng-container>
        </h5>
    </div>
    <div class="modal-body">
        <div [ngClass]="{'hidden': displayDeleteEventConfirm}">
            <form #f="ngForm" [formGroup]="settingsForm" class="form">
                <!-- EVENT NAME -->
                <div class="row g-0">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name">*Event name:</label>
                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Example: High School Graduation">
                        </div>
                    </div>
                </div>
                <!-- EVENT DETAILS -->
                <div class="row g-0">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name">Event details:</label>
                            <textarea id="details" formControlName="details" class="form-control" maxlength="750"></textarea>
                            <div *ngIf="settingsForm.value.details" class="characters-remaining">{{ 750 - settingsForm.value.details.length }} characters remaining</div>
                        </div>
                    </div>
                </div>
                <!-- START DATE -->
                <div class="row g-0">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="startDate">*Event start date:</label>
                            <label *ngIf="!showStartTime" class="start-time-link">(<a (click)="showStartTime = true" href="javascript:void(0)">add start time</a>)</label>
                            <label *ngIf="showStartTime" class="start-time-link">(<a (click)="showStartTime = false" href="javascript:void(0)">remove start time</a>)</label>
                            <div class="flex">
                                <div class="date-container">
                                    <div *ngIf="showStartTime" class="noon-controls"><a href="javascript:void(0)" (click)="setStartTimeToNoon()">noon</a> / <a href="javascript:void(0)" (click)="setStartTimeToMidnight()">midnight</a></div>
                                    <input bsDatepicker type="text" value="{{ settingsForm.value.startDate | date:'EEEE, MMMM d, y' }}" [disabled]="loading" id="startDate" formControlName="startDate" class="form-control date" placement="top middle" autocomplete="off">
                                    <!-- <div *ngIf="form && form.controls.startDate.invalid && form.controls.startDate.touched" class="error-message">Enter the start date when the event occurred</div> -->
                                </div>
                                <div class="time-container" [ngClass]="{'hidden': !showStartTime}">
                                    <div class="input-cell">
                                        <i class="fas fa-caret-up" (click)="increaseStartHour()"></i>
                                        <input type="text" class="form-control hour" id="startTimeHour" formControlName="startTimeHour" disabled="true" />
                                        <i class="fas fa-caret-down" (click)="decreaseStartHour()"></i>
                                    </div>
                                    <span class="colon">:</span>
                                    <div class="input-cell">
                                        <i class="fas fa-caret-up" (click)="increaseStartMinute()"></i>
                                        <input type="text" class="form-control minutes" id="startTimeMinute" formControlName="startTimeMinute" disabled="true" />
                                        <i class="fas fa-caret-down" (click)="decreaseStartMinute()"></i>
                                    </div>
                                    <input type="button" class="btn btn-meridiem" id="startTimeMeridiem" formControlName="startTimeMeridiem" (click)="changeStartMeridiem()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0" *ngIf="!showEndDate">
                    <div class="col-md-12">
                        <div class="form-group"><a (click)="showEndDate = true" href="javascript:void(0)">Add event end date</a></div>
                    </div>
                </div>
                <!-- END DATE -->
                <div class="row g-0" [ngClass]="{'hidden': !showEndDate}">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="endDate">Event end date:</label>
                            <label *ngIf="!showEndTime" class="start-time-link">(<a (click)="showEndTime = true" href="javascript:void(0)">add end time</a>)</label>
                            <label *ngIf="showEndTime" class="start-time-link">(<a (click)="showEndTime = false" href="javascript:void(0)">remove end time</a>)</label>
                            <div class="flex">
                                <div class="date-container">
                                    <div *ngIf="showEndTime" class="noon-controls"><a href="javascript:void(0)" (click)="setEndTimeToNoon()">noon</a> / <a href="javascript:void(0)" (click)="setEndTimeToMidnight()">midnight</a></div>
                                    <input bsDatepicker type="text" value="{{ settingsForm.value.endDate | date:'EEEE, MMMM d, y' }}" [disabled]="loading" id="endDate" formControlName="endDate" class="form-control date" placement="top middle" autocomplete="off">
                                    <div *ngIf="settingsForm && settingsForm.controls.endDate.invalid && settingsForm.controls.endDate.touched" class="error-message">Enter the end date of the event</div>
                                </div>
                                <div class="time-container" [ngClass]="{'hidden': !showEndTime}">
                                    <div class="input-cell">
                                        <i class="fas fa-caret-up" (click)="increaseEndHour()"></i>
                                        <input type="text" class="form-control hour" id="endTimeHour" formControlName="endTimeHour" disabled="true" />
                                        <i class="fas fa-caret-down" (click)="decreaseEndHour()"></i>
                                    </div>
                                    <span class="colon">:</span>
                                    <div class="input-cell">
                                        <i class="fas fa-caret-up" (click)="increaseEndMinute()"></i>
                                        <input type="text" class="form-control minutes" id="endTimeMinute" formControlName="endTimeMinute" disabled="true" />
                                        <i class="fas fa-caret-down" (click)="decreaseEndMinute()"></i>
                                    </div>
                                    <input type="button" class="btn btn-meridiem" id="endTimeMeridiem" formControlName="endTimeMeridiem" (click)="changeEndMeridiem()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0" *ngIf="showEndDate">
                    <div class="col-md-12">
                        <div class="form-group"><a (click)="showEndDate = false" href="javascript:void(0)">Remove event end date</a></div>
                    </div>
                </div>

                <!-- LOCATION -->
                <div class="row g-0">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name">Event location:</label>
                            <input [ngClass]="{'hidden': newLocation !== '' }" type="text" id="location" formControlName="location" class="form-control" (keyup)="newLocationKeyup()">
                            <div class="location-suggestion-container" *ngIf="locationSuggestions.length > 0">
                                <div *ngFor="let suggestion of locationSuggestions" (click)="selectNewLocation(suggestion.title)">
                                    {{ suggestion.title }}
                                </div>
                            </div>
                            <div *ngIf="newLocation !== ''">{{ newLocation }} (<a (click)="clearNewLocation()" href="javascript:void(0)">clear location</a>)</div>
                            <div *ngIf="newLocationEmbedUrl" class="location-map-container">
                                <iframe [src]="newLocationEmbedUrl | safe: 'resourceUrl'" width="400" height="275" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0" [ngClass]="{'hidden': newLocation == '' }">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="locationShowMap">Show map on the event page?</label>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="locationShowMapSwitch" type="checkbox" class="switch-input" formControlName="locationShowMap" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PERMISSIONS -->
                <div class="row g-0">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="canConnectionsView">Allow your connections to:</label>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canConnectionsViewSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsView" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">View the event</label>
                            </div>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canConnectionsContributeSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsContribute" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Contribute photos to the event</label>
                            </div>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canConnectionsShareSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsShare" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Share the event</label>
                            </div>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canConnectionsDiscussSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsDiscuss" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Discuss the event</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="canConnectionsView">Allow anonymous users to:</label>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canPublicViewSwitch" type="checkbox" class="switch-input" formControlName="canPublicView" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">View the event</label>
                            </div>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canPublicContributeSwitch" type="checkbox" class="switch-input" formControlName="canPublicContribute" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Contribute photos to the event</label>
                            </div>
                            <!-- <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canPublicDiscussSwitch" type="checkbox" class="switch-input" formControlName="canPublicDiscuss" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Discuss the event</label>
                            </div> -->
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="canPublicShareSwitch" type="checkbox" class="switch-input" formControlName="canPublicShare" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label for="keywords" class="option-label">Share the event</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ADD TO TIMELINE -->
                <div class="row g-0">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="addToMyTimeline">*Add this event to my timeline?</label>
                            <div *ngIf="showHelpText" class="help-text">Decide if moderator approval is required when people add photos to the vault.</div>
                            <div class="option">
                                <div class="slider-container">
                                    <label class="switch">
                                        <input id="addToMyTimelineSwitch" type="checkbox" class="switch-input" formControlName="addToMyTimeline" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row g-0">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="addToMyTimeline">Delete the event:</label>
                        <div class="button-container">
                            <div class="button btn btn-danger delete" (click)="deleteEvent()" title="Delete Event"><em class="fas fa-trash"></em></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="confirm-container" *ngIf="displayDeleteEventConfirm && eventPhotos.length == 0">
            <div class="warning-message-center" *ngIf="!loadingDeleteEvent">Are you sure you want to delete this event?</div>
            <div class="warning-message-center" *ngIf="loadingDeleteEvent">
                <div class="loading in"></div>
            </div>
        </div>
        <div class="confirm-container" *ngIf="displayDeleteEventConfirm && eventPhotos.length > 0">
            <div class="warning-message-center" *ngIf="!loadingRemovePhotos">
                <div><i class="fas fa-exclamation-triangle"></i></div>
                <div>All photos must be removed from the event before deleting</div>
            </div>
            <div class="warning-message-center" *ngIf="loadingRemovePhotos">
                <div class="loading in"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer no-bg">
        <div *ngIf="loadingRemovePhotos">Please wait...</div>
        <button type="button" class="btn btn-danger btn-md" (click)="deleteAllPhotos()" *ngIf="displayDeleteEventConfirm && eventPhotos.length > 0 && !loadingRemovePhotos">Permanently delete photos</button>
        <button type="button" class="btn btn-blue btn-md" (click)="removeAllPhotos(false)" *ngIf="displayDeleteEventConfirm && eventPhotos.length > 0 && !loadingRemovePhotos">Move photos to public</button>
        <button type="button" class="btn btn-blue btn-md" (click)="removeAllPhotos(true)" *ngIf="displayDeleteEventConfirm && eventPhotos.length > 0 && !loadingRemovePhotos">Move photos to private</button>
        <button type="button" class="btn btn-default btn-md" (click)="displayDeleteEventConfirm = false" *ngIf="displayDeleteEventConfirm && eventPhotos.length > 0 && !loadingRemovePhotos">Cancel</button>

        <button type="button" class="btn btn-danger btn-md" (click)="deleteEventConfirm()" [disabled]="loadingDeleteEvent" *ngIf="displayDeleteEventConfirm && eventPhotos == 0">Confirm delete</button>
        <button type="button" class="btn btn-default btn-md" (click)="displayDeleteEventConfirm = false" [disabled]="loadingDeleteEvent" *ngIf="displayDeleteEventConfirm && eventPhotos == 0">Cancel</button>

        <button type="button" class="btn btn-success btn-md" (click)="updateEvent()" [disabled]="loadingUpdateEvent" *ngIf="!displayDeleteEventConfirm">Update</button>
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="closeSettingsModal()" [disabled]="loadingUpdateEvent" *ngIf="!displayDeleteEventConfirm">Cancel</button>
    </div>
</ng-template>

<ng-template #shareDialog>
    <div class="modal-header">
        <h5 class="modal-title">Share Event</h5>
    </div>
    <div class="modal-body">
        <div class="share-container">
            <div>
                <div class="share-content">
                    <div>
                        <i class="fas fa-at"></i>
                        <input class="form-control" id="email" />
                    </div>
                </div>
                <div class="share-title">E-mail</div>
                <div class="share-text">Invite existing connections to the event.</div>
            </div>
            <div>
                <div class="share-content">
                    <div>
                        <i class="fas fa-users"></i>
                        <input class="form-control" id="connection" />
                    </div>
                </div>
                <div class="share-title">Connections</div>
                <div class="share-text">Invite existing connections to the event.</div>
            </div>
            <div>
                <div class="share-content">
                    <div *ngIf="qrEventPageURL">
                        <qrcode [qrdata]="qrEventPageURL" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                </div>
                <div class="share-title">QR Code</div>
                <div class="share-text">Scan or save the QR code to share the event.</div>
            </div>
        </div>
    </div>
    <div class="modal-footer no-bg">
        <!-- <button type="button" class="btn btn-danger btn-md" (click)="deleteNetworkConfirm( network.id )">Delete</button> -->
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>

<ng-template #approvalDialog>
    <div class="modal-header">
        <h5 class="modal-title">View Photo Requests</h5>
    </div>
    <div class="modal-body">
        APPROVAL
    </div>
    <div class="modal-footer no-bg">
        <!-- <button type="button" class="btn btn-danger btn-md" (click)="deleteNetworkConfirm( network.id )">Delete</button> -->
        <button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>