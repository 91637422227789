import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Photo } from 'src/app/models/photo.model';
import { FacebookService } from 'src/app/services/facebook.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';
import { SyncService } from 'src/app/services/sync.service';

declare const FB: any;

@Component({
	selector: 'app-photos-add-facebook',
	templateUrl: './facebook.component.html',
	styleUrls: ['./facebook.component.css']
})
export class PhotosAddFacebookComponent implements OnInit, OnDestroy {
	@ViewChild('confirmSyncAllDialog', { static: true }) confirmSyncAllDialog: TemplateRef<any>;
	@ViewChild('confirmSyncAlbumDialog', { static: true }) confirmSyncAlbumDialog: TemplateRef<any>;
	@ViewChild('confirmUnsyncPhotoDialog', { static: true }) confirmUnsyncPhotoDialog: TemplateRef<any>;

	modalRef: BsModalRef;

	loading = true;
	showHelp = false;
	uploadMessage = "";

	albums: any[] = [];
	photos: any[] = [];

	album: any;
	albumSynced = false;

	totalPhotos: number = 0;
	totalPhotosProcessed: number = 0;

	formSingle: UntypedFormGroup;

	photoUnsync: Photo;

	constructor(
		private router: Router,
		private facebookService: FacebookService,
		private layoutService: LayoutService,
		private modalService: BsModalService,
		private photoService: PhotoService,
		private syncService: SyncService
	) { }

	ngOnInit() {
		this.loadFacebookAlbums();
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	/**
	 * LOAD FACEBOOK ALBUMS
	 * 
	 * Load all Facebook albums. Triggered by onInit.
	 * 
	 * 1. Authenticate against Facebook to retrieve a fresh token.
	 * 2. Retrieve the users Facebook albums.
	 * 3. Loop the albums, calculate the total photos, and display the albums on the UI.
	 * 
	 * TODO: Cache the Facebook Authentication.
	 * TODO: Potentially cache the users Facebook albums.
	 * TODO: Display an error if something fails instead of simply hiding the loading indicator.
	 */
	loadFacebookAlbums() {
		let _this = this;
		// TODO: Cache the Facebook Authentication.
		this.facebookService.authenticate(function (model) {
			_this.facebookService.setToken(model.authResponse.accessToken);
			_this.facebookService.setUserId(model.authResponse.userID);

			// TODO: Potentially cache the users Facebook albums.
			_this.facebookService.retrieveAllAlbums(function (response) {
				if (response.data) {
					for (const album of response.data) {
						_this.totalPhotos += album.count;
						_this.albums.push(album);
					}
					_this.loading = false;
				} else {
					_this.loading = false;
				}
			});
		});
	}

	/**
	 * LOAD FACEBOOK ALBUM
	 * 
	 * Load a specific Facebook album. This occurs when a user clicks on an album name on the UI.
	 * 
	 * @param album 
	 * 
	 * 1. Set global variables.
	 * 2. Check if there are any paging parameters.
	 * 3. If yes, load the next page of photos.
	 * 4. If not, load the first page of photos.
	 * 5. Load the images on the UI.
	 * 6. If another page exists, repeat the function again and keep loading photos.
	 * 7. After all phots are loaded, load the photo statuses.
	 */
	loadFacebookAlbum(album) {
		this.album = album;
		this.photos = [];
		let _this = this;
		(function repeat(pagingNextUrl) {
			setTimeout(function () {
				if (pagingNextUrl && pagingNextUrl.length > 0) {
					_this.facebookService.retrieveAlbumPhotosNextPage(pagingNextUrl, function (response) {
						if (response.data) {
							for (const fbPhoto of response.data) {
								_this.photos.push(fbPhoto);
							}
							if (response.paging && response.paging.next) {
								repeat(response.paging.next);
							} else {
								_this.loadPhotoStatuses(album.id);
							}
						}
					});
				} else {
					_this.facebookService.retrieveAlbumPhotos(album.id, function (response) {
						if (response.data) {
							for (const fbPhoto of response.data) {
								_this.photos.push(fbPhoto);
							}
							if (response.paging && response.paging.next) {
								repeat(response.paging.next);
							} else {
								_this.loadPhotoStatuses(album.id);
							}
						}
					});
				}
			});
		})();
	}

	/**
	 * LOAD PHOTO STATUSES
	 * 
	 * Determines if each album photo is synced or not on the UI. This happens 
	 * 
	 * @param albumId
	 * 
	 * 1. Retrieve all photos from database by Facebook album id.
	 * 2. If there are no photos, set albumSynced to false which updates the UI.
	 * 3. If there are photos, set albumSynced to true.
	 * 4. Loop the photos currently displayed on the UI.
	 * 5. Find the corresponding photo from the database response.
	 * 6. Set the proper status on the photo.
	 */
	loadPhotoStatuses(albumId: string) {
		this.photoService.getPhotosByFacebookAlbumId(albumId).subscribe(
			response => {
				if (response.body.length > 0) {
					this.albumSynced = true;

					for (let photo of this.photos) {
						const item = response.body.find(d => d.externalId === photo.id);

						if (item) {
							photo.photoId = item.id;

							// if (item.doNotSync) {
							// 	photo.status = 'Do not sync';
							// } else {
							// 	photo.status = 'Synced';
							// }
						}
					}
				} else {
					this.albumSynced = false;
				}
			}
		);
	}

	/**
	 * SYNC ALL
	 * 
	 * Open the confirm all sync modal dialog.
	 */
	syncAll() {
		this.openModal(this.confirmSyncAllDialog);
	}

	/**
	 * SYNC ALL
	 * 
	 * Open the confirm album sync modal dialog.
	 */
	syncAlbum() {
		this.openModal(this.confirmSyncAlbumDialog);
	}

	/**
	 * SYNC ALL CONFIRM
	 * 
	 * ...
	 * 
	 * 1. Enable sync in progress on the UI.
	 * 2. Enable the sync within the database.
	 * 3. Check to see if the sync should be stopped (the user pressed the cancel button or we are out of albums/photos to sync).
	 * 4. Loop the albums.
	 * 5. Send array of photos to see if they already exist.
	 * 6. Update the UI with the amount of photos added.
	 * 
	 * TODO: This logic shouldnt even belong here.  It should be within the main component where the "Sync active" is located
	 *       This is so we can manage the sync globally so it doesn't matter where you refresh the page from it will always restart
	 */
	syncAllConfirm() {
		this.syncService.setSyncInProgress(true);

		this.syncService.enableSync('facebook').subscribe(
			response => {
				let i = 0;
				let _this = this;
				(function repeat(pagingNextUrl) {
					if (i > _this.albums.length - 1 || !_this.syncService.getSyncInProgress()) {
						_this.syncService.setSyncInProgress(false);
						return;
					}

					setTimeout(function () {

						if (pagingNextUrl && pagingNextUrl.length > 0) { // paging, load next page
							_this.facebookService.retrieveAlbumPhotosNextPage(pagingNextUrl, function (response) {
								// TODO: Does this need an else if?  What happens when albums don't load?
								if (response.data) {
									let photos: Photo[] = [];
									for (const fbPhoto of response.data) {
										let photo: Photo = new Photo();
										photo.type = 'facebook';
										photo.externalParentId = _this.albums[i].id;
										photo.externalId = fbPhoto.id;
										photo.externalUrl = fbPhoto.link;
										photo.imageUrl = fbPhoto.images[0].source;
										photos.push(photo);
									}

									_this.photoService.addPhotos(photos).subscribe(
										addPhotoResponse => {
											let userPhotoCount = _this.photoService.getUserPhotoCount();
											userPhotoCount.totalPhotos = userPhotoCount.totalPhotos + addPhotoResponse.body;
											_this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);

											if (response.paging && response.paging.next) {
												repeat(response.paging.next);
											} else {
												// No paging, go to next 
												i++;
												repeat('');
											}
										}
									);
								}
							});
						} else { // No paging, load next album
							_this.facebookService.retrieveAlbumPhotos(_this.albums[i].id, function (response) {
								// TODO: Does this need an else if?  What happens when albums don't load?
								if (response.data) {
									let photos: Photo[] = [];
									for (const fbPhoto of response.data) {
										let photo: Photo = new Photo();
										photo.type = 'facebook';
										photo.externalParentId = _this.albums[i].id;
										photo.externalId = fbPhoto.id;
										photo.externalUrl = fbPhoto.link;
										photo.imageUrl = fbPhoto.images[0].source;
										photos.push(photo);
									}

									_this.photoService.addPhotos(photos).subscribe(
										addPhotoResponse => {
											let userPhotoCount = _this.photoService.getUserPhotoCount();
											userPhotoCount.totalPhotos = userPhotoCount.totalPhotos + addPhotoResponse.body;
											_this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);

											if (response.paging && response.paging.next) {
												repeat(response.paging.next);
											} else {
												// No paging, go to next 
												i++;
												repeat('');
											}
										}
									);
								}
							});
						}
					}, 5000);
				})();

				this.modalRef.hide();
			},
			err => {
				this.syncService.setSyncInProgress(false);
			}
		);
	}

	/**
	 * SYNC ALBUM CONFIRM
	 * 
	 * ...
	 * 
	 * 1. Enable sync in progress on the UI.
	 * 2. Enable the sync within the database.
	 * 3. Check to see if the sync should be stopped (the user pressed the cancel button or we are out of albums/photos to sync).
	 * 4. Send array of photos to see if they already exist.
	 * 5. Update the UI with the amount of photos added.
	 * 6. Load photo statuses.
	 * 
	 * TODO: This logic shouldnt even belong here.  It should be within the main component where the "Sync active" is located
	 *       This is so we can manage the sync globally so it doesn't matter where you refresh the page from it will always restart
	 */
	syncAlbumConfirm() {
		this.syncService.setSyncInProgress(true);

		this.syncService.enableSync('facebook').subscribe(
			response => {

				let _this = this;
				(function repeat(pagingNextUrl) {
					if (!_this.syncService.getSyncInProgress()) {
						_this.syncService.setSyncInProgress(false);
						return;
					}

					setTimeout(function () {
						if (pagingNextUrl && pagingNextUrl.length > 0) { // paging, load next page
							_this.facebookService.retrieveAlbumPhotosNextPage(pagingNextUrl, function (response) {
								// TODO: Does this need an else if?  What happens when albums don't load?
								if (response.data) {
									let photos: Photo[] = [];
									for (const fbPhoto of response.data) {
										let photo: Photo = new Photo();
										photo.type = 'facebook';
										photo.externalParentId = _this.album.id;
										photo.externalId = fbPhoto.id;
										photo.externalUrl = fbPhoto.link;
										photo.imageUrl = fbPhoto.images[0].source;
										photos.push(photo);
									}

									_this.photoService.addPhotos(photos).subscribe(
										addPhotoResponse => {
											let userPhotoCount = _this.photoService.getUserPhotoCount();
											userPhotoCount.totalPhotos = userPhotoCount.totalPhotos + addPhotoResponse.body;
											_this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);

											if (response.paging && response.paging.next) {
												repeat(response.paging.next);
											} else {
												_this.syncService.setSyncInProgress(false);
												_this.loadPhotoStatuses(_this.album.id);
											}
										}
									);
								}
							});
						} else { // No paging, load next album
							_this.facebookService.retrieveAlbumPhotos(_this.album.id, function (response) {
								// TODO: Does this need an else if?  What happens when albums don't load?
								if (response.data) {
									let photos: Photo[] = [];
									for (const fbPhoto of response.data) {
										let photo: Photo = new Photo();
										photo.type = 'facebook';
										photo.externalParentId = _this.album.id;
										photo.externalId = fbPhoto.id;
										photo.externalUrl = fbPhoto.link;
										photo.imageUrl = fbPhoto.images[0].source;
										photos.push(photo);
									}

									_this.photoService.addPhotos(photos).subscribe(
										addPhotoResponse => {
											let userPhotoCount = _this.photoService.getUserPhotoCount();
											userPhotoCount.totalPhotos = userPhotoCount.totalPhotos + addPhotoResponse.body;
											_this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);

											if (response.paging && response.paging.next) {
												repeat(response.paging.next);
											} else {
												_this.syncService.setSyncInProgress(false);
												_this.loadPhotoStatuses(_this.album.id);
											}
										}
									);
								}
							});
						}
					}, 5000);
				})();

				this.modalRef.hide();
			},
			err => {
				this.syncService.setSyncInProgress(false);
			}
		);
	}

	/**
	 * HANDLE SYNC EVENT
	 * 
	 * Either sync or unsync the photo, based on the state of the checkbox. Happens when a user clicks the checkbox
	 * next to a photo on the album page.
	 * 
	 * @param event
	 * 
	 * 1. If the checkbox is checked, sync the photo.
	 * 2. If the checkbox is unchecked, display a confirm dialog.
	 * 3. Update the UI.
	 */
	handlePhotoSyncEvent(event) {
		this.loading = true;

		let photo = new Photo();
		photo.id = event.target.value;

		if (event.target.checked) {
			// photo.doNotSync = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					const item = this.photos.find(d => d.photoId === response.body.id);
					if (item) {
						item.status = 'Synced';
						let userPhotoCount = this.photoService.getUserPhotoCount();
						userPhotoCount.totalPhotos = userPhotoCount.totalPhotos + 1;
						this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);
					}
					this.loading = false;
				}
			);

		} else {
			// photo.doNotSync = true;
			this.unsyncPhoto(photo);

			this.loading = false;
		}
	}

	/**
	 * UNSYNC PHOTO
	 * 
	 * Displays a confirmation dialog.
	 * 
	 * @param photo 
	 */
	unsyncPhoto(photo) {
		this.photoUnsync = photo;

		this.modalRef = this.modalService.show(
			this.confirmUnsyncPhotoDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: true })
		);
	}

	/**
	 * UNSYNC PHOTO CONFIRM
	 * 
	 * Unsync the photo. Triggered by pressing the Confirm button on the unsync confirmation dialog.
	 * 
	 * 1. Unsync the photo, which will also delete all photo networks in the backend.
	 * 2. Update the UI.
	 * 3. Hide the modal.
	 */
	unsyncPhotoConfirm() {
		this.loading = true;
		this.photoService.updatePhoto(this.photoUnsync, false).subscribe(
			response => {
				const item = this.photos.find(d => d.photoId === response.body.id);
				if (item) {
					item.status = 'Do not sync';
					let userPhotoCount = this.photoService.getUserPhotoCount();
					userPhotoCount.totalPhotos = userPhotoCount.totalPhotos - 1;
					this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);
				}
				this.loading = false;

				this.modalRef.hide();
			}
		);
	}

	/**
	 * UNSYNC PHOTO CANCEL
	 * 
	 * Triggered by pressing the Cancel button on the unsync confirmation dialog.
	 * 
	 * 1. Find the photo in the photo array.
	 * 2. Set the checkbox back to checked.
	 * 3. Hide the modal.
	 */
	unsyncPhotoCancel() {
		const item = this.photos.find(d => d.photoId == this.photoUnsync.id);  //  == 386
		if (item) {
			const ele = document.getElementById(item.id) as HTMLInputElement;
			ele.checked = true;
		}
		this.modalRef.hide();
	}

	/**
	 * NAVIGATE
	 * 
	 * Navigate to the specified path.
	 * 
	 * @param path
	 */
	navigate(path) {
		this.router.navigate([path]);
	}

	/**
	 * OPEN MODAL
	 * 
	 * Open the specified modal.
	 * 
	 * @param template
	 */
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'modal-xl' })
		);
	}
}
