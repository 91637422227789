<div class="background">
	<div class="center-graph" [ngClass]="{'loading in': loading }">
		<div class="pieContainer uncategorized" *ngIf="!loading">
			<div class="pieBackground"></div>
			<div id="pieSlice1" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform ' + pieSliceUncategorized1Seconds + 's linear .6s' , 'transform' : 'rotate(' + pieSliceUncategorized1Degrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice1_filler" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform .5s linear .6s' , 'transform' : 'rotate(' + pieSliceUncategorizedFillerDegrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice2" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform ' + pieSliceUncategorized2Seconds + 's linear 1.1s' , 'transform' : 'rotate(' + pieSliceUncategorized2Degrees + 'deg)'}"></div>
			</div>
			<div id="pieSlice2_filler" class="hold">
				<div class="pie" [ngStyle]="{'transition' : 'transform .5s linear 1.1s' , 'transform' : 'rotate(' + pieSliceUncategorizedFillerDegrees + 'deg)'}"></div>
			</div>
			<div class="innerCircle">
				<div class="content">{{ userPhotoCount?.myUncategorizedPhotos }}</div>
			</div>
		</div>
		<div class="text" *ngIf="!loading">My Uncategorized Photos</div>
	</div>
</div>