<div *ngIf="photo.connectionsCanReact" class="reactions-container">
	<span *ngIf="photo?.reactionsLike && photo.reactionsLike.length > 0" class="reaction-container" [ngClass]="{'active': hasLikeReaction }">
		<i class="fas fa-thumbs-up"></i> <span class="number">{{ photo.reactionsLike.length }}</span>
	</span>
	<span *ngIf="photo?.reactionsLove && photo.reactionsLove.length > 0" class="reaction-container" [ngClass]="{'active': hasLoveReaction }">
		<i class="fas fa-heart"></i> <span class="number">{{ photo.reactionsLove.length }}</span>
	</span>
	<span *ngIf="photo?.reactionsSad && photo.reactionsSad.length > 0" class="reaction-container" [ngClass]="{'active': hasSadReaction }">
		<i class="fas fa-heart-broken"></i> <span class="number">{{ photo.reactionsSad.length }}</span>
	</span>
	<span *ngIf="photo?.reactionsLaugh && photo.reactionsLaugh.length > 0" class="reaction-container" [ngClass]="{'active': hasLaughReaction }">
		<i class="fas fa-laugh-beam"></i> <span class="number">{{ photo.reactionsLaugh.length }}</span>
	</span>
	<span *ngIf="photo?.reactionsAngry && photo.reactionsAngry.length > 0" class="reaction-container" [ngClass]="{'active': hasAngryReaction }">
		<i class="fas fa-angry"></i> <span class="number">{{ photo.reactionsAngry.length }}</span>
	</span>
</div>
<div class="edit-icon-container" *ngIf="photo.status !== 'deleted' && photo.vaultPhotoStatus !== 'rejected'">
	<div class="actions-container" *ngIf="showActionsContainer">
		<!-- Edit icon - The current user is the owner or the photo allows suggestions -->
		<div *ngIf="(photo.userId == userService.getLocalUserId(0) || photo.connectionsCanSuggest)">
			<i class="fas fa-pen" [ngClass]="{'faded': showReactionIcons}" (click)="openPhotoDetailsModalClassify(photo, i)" (mouseenter)="showEditPhotoDetailsMessage = true" (mouseleave)="showEditPhotoDetailsMessage = false"></i> <span *ngIf="showEditPhotoDetailsMessage">Edit photo details</span>
		</div>
		<!-- View icon - The current user is not the owner and the photo does not allow suggestions -->
		<div *ngIf="photo.userId !== userService.getLocalUserId(0) && !photo.connectionsCanSuggest">
			<i class="fas fa-list-alt" [ngClass]="{'faded': showReactionIcons}" (click)="openPhotoDetailsModalClassify(photo, i)" (mouseenter)="showViewPhotoDetailsMessage = true" (mouseleave)="showViewPhotoDetailsMessage = false"></i> <span *ngIf="showViewPhotoDetailsMessage">View photo details</span>
		</div>
		<!-- Comment icon - If discussions allowed -->
		<div *ngIf="photo.allowDiscussion && !photo.lockDiscussion">
			<i class="fas fa-comment" [ngClass]="{'faded': showReactionIcons}" (click)="openPhotoDetailsModalDiscuss(photo, i)" (mouseenter)="showDiscussPhotoMessage = true" (mouseleave)="showDiscussPhotoMessage = false"></i> <span *ngIf="showDiscussPhotoMessage">Discuss photo</span>
		</div>
		<!-- Reaction Icons-->
		<div *ngIf="photo.connectionsCanReact" class="reaction-icons-container">
			<!-- React to photo -->
			<i *ngIf="!hasLikeReaction && !hasLoveReaction && !hasSadReaction && !hasLaughReaction && !hasAngryReaction" class="fas fa-laugh" [ngClass]="{'faded': showReactionIcons}" (click)="showReactionIcons = !showReactionIcons" (mouseenter)="showReactToPhotoMessage = true" (mouseleave)="showReactToPhotoMessage = false"></i>
			<span *ngIf="showReactToPhotoMessage && !showReactionIcons">React to photo</span>
			<i *ngIf="showReactionIcons" class="fas fa-thumbs-up blue" (click)="addReactionLike()"></i>
			<i *ngIf="showReactionIcons" class="fas fa-heart blue" (click)="addReactionLove()"></i>
			<i *ngIf="showReactionIcons" class="fas fa-heart-broken blue" (click)="addReactionSad()"></i>
			<i *ngIf="showReactionIcons" class="fas fa-laugh-beam blue" (click)="addReactionLaugh()"></i>
			<i *ngIf="showReactionIcons" class="fas fa-angry blue" (click)="addReactionAngry()"></i>

			<!-- Unreact to photo -->
			<i *ngIf="hasLikeReaction" class="fas fa-thumbs-up remove" (click)="removeReactionLike()" (mouseenter)="showUnreactToPhotoMessage = true" (mouseleave)="showUnreactToPhotoMessage = false"></i>
			<i *ngIf="hasLoveReaction" class="fas fa-heart remove" (click)="removeReactionLove()" (mouseenter)="showUnreactToPhotoMessage = true" (mouseleave)="showUnreactToPhotoMessage = false"></i>
			<i *ngIf="hasSadReaction" class="fas fa-heart-broken remove" (click)="removeReactionSad()" (mouseenter)="showUnreactToPhotoMessage = true" (mouseleave)="showUnreactToPhotoMessage = false"></i>
			<i *ngIf="hasLaughReaction" class="fas fa-laugh-beam remove" (click)="removeReactionLaugh()" (mouseenter)="showUnreactToPhotoMessage = true" (mouseleave)="showUnreactToPhotoMessage = false"></i>
			<i *ngIf="hasAngryReaction" class="fas fa-angry remove" (click)="removeReactionAngry()" (mouseenter)="showUnreactToPhotoMessage = true" (mouseleave)="showUnreactToPhotoMessage = false"></i>
			<span *ngIf="showUnreactToPhotoMessage">Remove photo reaction</span>
		</div>
	</div>
	<div class="user-actions-container" *ngIf="showUserActionsContainer">
		<!-- TODO: Send connection request 
		<i *ngIf="!photoOwnerConnection" class="fas fa-pen" (click)="sendConnectionRequest()" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Send connection request</span>-->

		<!-- TODO: Cancel connection request 
		<i *ngIf="photoOwnerConnection && photoOwnerConnectionStatus == 'pending'" class="fas fa-pen" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Cancel connection request</span>-->

		<!-- TODO: Cancel connection 
		<i *ngIf="photoOwnerConnection && photoOwnerConnectionStatus == 'waiting'" class="fas fa-pen" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Reject connection request</span>-->

		<!-- TODO: Approve connection request
		<i *ngIf="photoOwnerConnection && photoOwnerConnectionStatus == 'waiting'" class="fas fa-pen" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Approve connection request</span>-->

		<!-- TODO: Remove connection
		<i *ngIf="photoOwnerConnection && photoOwnerConnectionStatus == 'active'" class="fas fa-pen" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Remove connection request</span>-->

		<!-- TODO: Search for photos by user 
		<i *ngIf="photoOwner?.id == " class="fas fa-pen" (mouseenter)="showSendRequestMessage = true" (mouseleave)="showSendRequestMessage = false"></i>
		<span *ngIf="showSendRequestMessage">Send connection request</span>-->
	</div>
	<div class="icon-row">
		<!-- Ellipsis Icon -->
		<i *ngIf="loading" class="fas fa-circle-notch fa-spin"></i>
		<i *ngIf="!loading" class="fas fa-ellipsis-h" [ngClass]="{'faded': showReactionIcons}" (click)="showActionsContainer = !showActionsContainer; showUserActionsContainer = false;"></i>
		<i *ngIf="!loading && !photo.connectionsCanView" class="fas fa-lock" [tooltip]="privateTemplate" placement="left"></i>
		<ng-template #privateTemplate>
			This photo is private to you
		</ng-template>


		<!-- User Image - DISABLED FOR NOW BECAUSE I DONT LIKE HOW IT LOOKS -->
		<!-- <img *ngIf="!loading && photoOwner?.id !== photoUserId && photoOwner?.mageSafeUrl.length > 0" [src]="photoOwner.imageSafeUrl" (mouseenter)="photo.photoIconHoverMessage = photoOwner?.firstName + ' ' + photoOwner?.lastName" (mouseleave)="photo.photoIconHoverMessage = ''" /> -->
		<!-- Image Icon -->
		<i *ngIf="!loading && myUserId !== photoOwner?.id && photoOwner?.initials.length == 0" class="fas fa-user" [ngClass]="{'faded': showReactionIcons}" (click)="showUserActionsContainer = !showUserActionsContainer; showActionsContainer = false;" (mouseenter)="photo.photoIconHoverMessage = photoOwner?.firstName + ' ' + photoOwner?.lastName" (mouseleave)="photo.photoIconHoverMessage = ''"></i>
		<!-- User Initials -->
		<div *ngIf="!loading && myUserId !== photoOwner?.id && photoOwner?.initials.length > 0" class="user-initials" [ngClass]="{'faded': showReactionIcons}" (click)="showUserActionsContainer = !showUserActionsContainer; showActionsContainer = false;" (mouseenter)="photo.photoIconHoverMessage = photoOwner?.firstName + ' ' + photoOwner?.lastName" (mouseleave)="photo.photoIconHoverMessage = ''">{{ photoOwner?.initials }}</div>
		<!-- Hover Icon -->
		<span class="icon-hover-message">{{ photo.photoIconHoverMessage }}</span>
	</div>
</div>