<div class="card" #card>
	<div class="face front">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<!--
		<i class="fas fa-images"></i>
		-->

		<div class="add-gallery-container" title="Pin a Gallery" *ngIf="!displayAddGalleryDialog">
			<svg class="circle" xmlns="http://www.w3.org/2000/svg" (click)="showAddGalleryDialog()">
				<g>
					<ellipse class="background" ry="60" rx="60" cy="62.5" cx="62.5" stroke-width="2" />
					<ellipse class="foreground" ry="60" rx="60" cy="62.5" cx="62.5" stroke-width="2" />
					<line class="line line1" x1="52" y1="62" x2="74" y2="62" />
					<line class="line line2" x1="52" y1="62" x2="74" y2="62" />
				</g>
			</svg>
		</div>

		<ng-container *ngIf="displayAddGalleryDialog">
			<div class="images">
				<div class="scroll-container">
					<div *ngFor="let gallery of galleriesUnpinned" class="item" (click)="pinGallery( gallery )">{{ gallery.name }}</div>
					<div *ngIf="galleriesUnpinned && galleriesUnpinned.length == 0" class="no-galleries">
						No galleries are available
					</div>


				</div>
			</div>
			<div class="text">
				Pin a Gallery <br />
			</div>
			<div class="button-container" *ngIf="displayAddGalleryDialog">
				<div class="button" (click)="displayAddGalleryDialog=!displayAddGalleryDialog">Cancel</div>
			</div>
		</ng-container>

	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Pin a Gallery</div>
		<div class="help-text">
			...
		</div>
	</div>
</div>