<div class="background" [ngClass]="{'loading in': loading }">
    <ng-image-slider
        [images]="photos"
        [infinite]="true"
        [autoSlide]="{interval: 5, stopOnHover: false}"
        [showArrow]="false"
        [imageSize]="{width: '100%', height: '100%'}"
        [manageImageRatio]="true"
        [imagePopup]="false"></ng-image-slider>
</div>
