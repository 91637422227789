import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventPhotoRemove } from 'src/app/models/event-photo-remove.model';
import { EventService } from 'src/app/services/event.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-remove-photo-from-event-progress',
	templateUrl: './remove-photo-from-event.component.html',
	styleUrls: ['./remove-photo-from-event.component.css']
})
export class RemovePhotoFromEventProgressComponent implements OnInit {
	@Input() eventPhotoRemove: EventPhotoRemove;
	@Output() finished = new EventEmitter<EventPhotoRemove>();

	loading = true;
	removingPhoto = false;

	error = false; // ?

	eventPhotoRemoveNextQueueSubscription: Subscription;

	constructor(
		private eventService: EventService,
		private layoutService: LayoutService,
		private photoService: PhotoService
	) { }

	ngOnInit() {
		// If the incoming photo has the status of inprogress, process it right away. 
		// Otherwise, wait for the queueNumber to be announced within the subscription.
		if (this.eventPhotoRemove.status == 'inprogress') {
			this.removePhotoFromEvent();
		} else if (this.eventPhotoRemove.status == 'queued') {
			this.subscribeToNextQueueNumberAnnounced();
		}
	}

	ngOnDestroy() {
		if (this.eventPhotoRemoveNextQueueSubscription) {
			this.eventPhotoRemoveNextQueueSubscription.unsubscribe();
		}
	}

	subscribeToNextQueueNumberAnnounced() {
		this.eventPhotoRemoveNextQueueSubscription = this.layoutService.callNextRemoveEventPhotoQueueNumber
			.subscribe((queueNumber: number) => {
				if (queueNumber == this.eventPhotoRemove.queueNumber) {
					this.eventPhotoRemove.status == 'inprogress';
					this.removePhotoFromEvent();
				}
			});
	}

	removePhotoFromEvent() {
		this.removingPhoto = true;
		this.eventService.removeEventPhoto(this.eventPhotoRemove.photo.addToEvent.id, this.eventPhotoRemove.photo.id, this.eventPhotoRemove.setToPrivate).subscribe(
			(response) => {
				let addToEvent = this.eventPhotoRemove.photo.addToEvent;
				this.eventPhotoRemove.photo = response.body;
				this.eventPhotoRemove.photo.addToEvent = addToEvent;
				this.eventPhotoRemove.status = 'success';
				this.loading = false;
				this.removingPhoto = false;
				this.eventPhotoRemove.photo.imageSafeUrl = null;
				this.updateImageSafeUrl();
			}, err => {
				this.error = true;
				this.eventPhotoRemove.status = 'error';
				this.loading = false;
				this.removingPhoto = false;
			}
		);
	}

	updateImageSafeUrl() {
		let token;
		for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
			if (signedDownloadToken.userId == this.eventPhotoRemove.photo.userId) {
				if (signedDownloadToken.type == 'userPublic' && !this.eventPhotoRemove.setToPrivate) {
					token = signedDownloadToken;
					break;
				}
				if (signedDownloadToken.type == 'userPrivate' && this.eventPhotoRemove.setToPrivate) {
					token = signedDownloadToken;
					break;
				}
			}
		}

		console.log(token);

		if (token) {
			this.eventPhotoRemove.photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + this.eventPhotoRemove.photo.imageUrlMed + "?Authorization=" + token.token;

			this.finish();
		} else {
			let userTokenType = 'userPublic';
			if (this.eventPhotoRemove.setToPrivate) {
				userTokenType = 'userPrivate';
			}
			this.photoService.getSignedDownloadToken(this.eventPhotoRemove.photo.userId, userTokenType).subscribe(
				response => {
					let responseToken = response.body;
					responseToken.userId = this.eventPhotoRemove.photo.userId;

					// Ensure the token doesn't exist before adding to cache
					let token2;
					for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
						if (signedDownloadToken.userId == this.eventPhotoRemove.photo.userId) {
							if (signedDownloadToken.type == 'userPublic' && !this.eventPhotoRemove.setToPrivate) {
								token2 = signedDownloadToken;
								break;
							}
							if (signedDownloadToken.type == 'userPrivate' && this.eventPhotoRemove.setToPrivate) {
								token2 = signedDownloadToken;
								break;
							}
						}
					}

					if (!token2) {
						this.photoService.signedDownloadTokens.push(responseToken);
						token = responseToken;
					} else {
						token = token2;
					}

					this.eventPhotoRemove.photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + this.eventPhotoRemove.photo.imageUrlMed + "?Authorization=" + token.token;

					this.finish();
				}
			);
		}
	}

	finish() {
		this.finished.emit(this.eventPhotoRemove);
	}
}
