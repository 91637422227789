<span *ngIf="loading" class="connection-select">
	<i class="fas fa-circle-notch fa-spin"></i>
</span>
<span *ngIf="user.isSearchDefaultOwner && !loading" class="connection-select user selected" (click)="removeSearchDefaultOwner(user)" [tooltip]="'Unselect ' + user.firstName + ' ' + user.lastName" placement="bottom">
	<div *ngIf="!user?.imageSafeUrl">{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0)}}</div>
	<div *ngIf="user?.imageSafeUrl"><img [src]="user.imageSafeUrl" class="image" /></div>
</span>
<span *ngIf="!user.isSearchDefaultOwner && !loading" class="connection-select user" (click)="addSearchDefaultOwner(user)" [tooltip]="'Select ' + user.firstName + ' ' + user.lastName" placement="bottom">
	<div *ngIf="!user?.imageSafeUrl">{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0)}}</div>
	<div *ngIf="user?.imageSafeUrl"><img [src]="user.imageSafeUrl" class="image" /></div>
</span>