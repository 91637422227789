<div class="row justify-content-center g-0">
	<div class="col-md-11">
		<div class="quick-links row">
			<div class="container col-sm-6 col-lg-4 col-xl-3">
				<app-card-galleries-title></app-card-galleries-title>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3">
				<app-card-gallery-uncategorized-photos></app-card-gallery-uncategorized-photos>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let gallery of galleries">
				<app-card-gallery-pinned [gallery]="gallery"></app-card-gallery-pinned>
			</div>
		</div>
		<div *ngIf="galleryExpanded">
			<app-expanded-photo-gallery [gallery]="galleryExpanded"></app-expanded-photo-gallery>
			<span class="close-icon" (click)="closeGallery()">×</span>
		</div>
	</div>
</div>