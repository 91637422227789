import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PhotoComment } from 'src/app/models/photo-comment.model';
import { PhotoHistory } from 'src/app/models/photo-history.model';
import { PhotoSuggestion } from 'src/app/models/photo-suggestion.model';
import { Photo } from 'src/app/models/photo.model';
import { PhotoCommentService } from 'src/app/services/photo-comment.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';

@Component({
	selector: 'app-photo-view',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.css']
})
export class PhotoViewComponent implements OnInit, OnDestroy {
	@Input() modalRef: BsModalRef;
	@Input() modalTab: string;

	@ViewChild('keywordsSelect') keywordsSelect;
	@ViewChild('locationsSelect') locationsSelect;
	@ViewChild('peopleSelect') peopleSelect;

	// Subscriptions
	activePhotoChangedSubscription: Subscription;

	// Global Page
	editMode: boolean = false;
	tab: string = '';
	maxDate: Date;

	// Loading indicators
	loading = false;
	loadingKeywords = true;
	loadingPeople = true;
	loadingLocations = true;
	loadingOptionUpdate = false;

	// UI
	actionsRolloverText = '';
	saveRolloverText = '';

	// Photo
	photo: Photo;
	photoHistory: PhotoHistory[] = [];
	photoKeywords: any[] = []; 				// Used to store keywords from photo + suggestions + dropdown data
	photoPeople: any[] = [];				// Used to store people from photo + suggestions + dropdown data
	photoLocations: any[] = []; 			// Used to store locations from photo + suggestions

	selectedKeywordsLength: number = 0;	 	// Includes photo keywords + suggestions, and is used to for the 'No data entered' message
	selectedPeopleLength: number = 0;
	selectedLocationsLength: number = 0;

	locations: any[] = [];	 				// Used to store locations for dropdown data
	locationSuggestions: any[] = [];
	locationShowSuggestions: boolean = false;
	userLocationSuggestions: any[] = [];
	selectedLocation;						// This is the selected location, either from the db or a suggestion
	private locationSearchSubject = new Subject<string>();
	private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

	// Discussion
	commentForm: UntypedFormGroup;
	photoComments: PhotoComment[] = [];

	// Edit Metadata
	classifyForm: UntypedFormGroup;
	tempPerson = '';
	tempLocation = '';
	tempKeyword = '';

	lastRemovedPerson = ''; 				// Used to fix a recursion issue when removing metadata via the X icon on the metadata
	lastRemovedLocation = '';
	lastRemovedKeyword = '';

	// Delete
	deleteConfirm = false;
	photoDeleted = false;

	constructor(
		public authService: AuthService,
		private bsDatepickerConfig: BsDatepickerConfig,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private toastr: ToastrService,
		private connectionService: UserConnectionService,
		private photoService: PhotoService,
		private photoCommentService: PhotoCommentService,
		public userService: UserService
	) {
		this.maxDate = new Date();
	}

	// 1. Subscribe to activePhotoChanged to know if prev or next button was pressed
	// 2. Initialie the update metadata form since we are hiding it instead of using an ngIf
	// 3. Load the photo from this.photoService.getActivePhoto()
	// 4. Retrieve suggestions and add them to photo metadata
	// 5. Retrieve the photo history if the current user is the photo owner
	// 6. Retrieve custom dropdown data if the user has the ability to edit metadata
	// 7. Set the correct tab if one passed in querystring

	ngOnInit() {
		this.subscribeToActivePhotoChanged();
		this.subscribeToLocationInputChanged();

		this.photo = this.photoService.getActivePhoto();

		this.initClassifyForm();
		this.initCommentForm();
		this.populatePhotoMetadata();

		if (this.photo.connectionsCanSuggest) {
			this.retrievePhotoSuggestions(this.photo.id);
		} else {
			if (this.photo.userId == this.userService.getLocalUserId(0) || this.photo.connectionsCanSuggest) {
				this.retrieveDropdownData();
			}
			this.loadingKeywords = false;
			this.loadingLocations = false;
			this.loadingPeople = false;
		}

		if (this.photo.userId == this.userService.getLocalUserId(0)) {
			this.retrievePhotoHistory();
		}

		if (this.modalTab == 'classifyTab') {
			this.tab = 'classifyTab';
		} else {
			this.tab = 'discussTab';
		}

		// TODO: Only load comments if discussions are enabled for the photo.
		this.loadDiscussion();

		this.route.queryParams.subscribe(params => {
			if (params['tab']) {
				this.tab = params['tab'];
			}
		});

		this.bsDatepickerConfig.dateInputFormat = 'MMMM DD, YYYY';
	}

	ngOnDestroy() {
		if (this.activePhotoChangedSubscription) {
			this.activePhotoChangedSubscription.unsubscribe();
		}
	}

	/**
	 * INIT FORMS
	 */
	initClassifyForm() {
		this.classifyForm = this.formBuilder.group({
			'description': new UntypedFormControl(),
			'enableComments': new UntypedFormControl(),
			'storeInPhotonomy': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'capturedDate': new UntypedFormControl(),
			'location': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'localEditMode': new UntypedFormControl(),
			'connectionsCanView': this.photo.connectionsCanView,
			'connectionsCanReact': this.photo.connectionsCanReact,
			'connectionsCanDiscuss': this.photo.connectionsCanDiscuss,
			'lockDiscussion': this.photo.lockDiscussion,
			'connectionsCanSuggest': this.photo.connectionsCanSuggest,
			'connectionsCanSeeExif': this.photo.connectionsCanSeeExif,
		});
	}

	initCommentForm() {
		this.commentForm = this.formBuilder.group({
			'addComment': new UntypedFormControl(),
		});
	}

	/**
	 * SUBSCRIPTIONS
	 */

	subscribeToLocationInputChanged() {
		this.locationSearchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
			this.locationKeyupSearch();
		});
	}

	subscribeToActivePhotoChanged() {
		this.activePhotoChangedSubscription = this.photoService.activePhotoChanged
			.subscribe((photo: Photo) => {
				// Clear page data
				this.editMode = false;
				this.updateSaveRolloverText('');
				this.selectedKeywordsLength = 0;
				this.selectedPeopleLength = 0;
				this.selectedLocationsLength = 0;
				this.lastRemovedPerson = '';
				this.lastRemovedLocation = '';
				this.lastRemovedKeyword = '';
				this.loadingKeywords = true;
				this.loadingPeople = true;
				this.loadingLocations = true;
				this.locationSuggestions = [];
				this.userLocationSuggestions = [];
				this.locationShowSuggestions = false;

				// Update local photo object
				this.photo = photo;

				this.initClassifyForm();
				this.initCommentForm();
				this.populatePhotoMetadata();

				// Retrieve suggestions
				if (this.photo.connectionsCanSuggest) {
					this.retrievePhotoSuggestions(this.photo.id);
				} else {
					if (this.photo.userId == this.userService.getLocalUserId(0)) {
						this.retrieveDropdownData();
					}
					this.loadingKeywords = false;
					this.loadingLocations = false;
					this.loadingPeople = false;
				}

				if (this.photo.userId == this.userService.getLocalUserId(0)) {
					this.retrievePhotoHistory();
				}

				this.loadDiscussion();

				// Set delete params
				this.deleteConfirm = false;
				if (photo.status == 'deleted') {
					this.photoDeleted = true;
				} else {
					this.photoDeleted = false;
				}

				this.loading = false;
			});
	}

	/**
	 * TABS
	 */

	tabSelect(tab: string) {
		this.tab = tab;
	}

	/**
	 * DISCUSSION FUNCTIONS
	 */
	loadDiscussion() {
		this.photoCommentService.getDiscussion(this.photo.id).subscribe(
			response => {
				this.photoComments = response.body;
			}
		);
	}

	addComment() {
		let comment = this.commentForm.value.addComment;

		this.photoCommentService.addComment(this.photo.id, comment, 0).subscribe(
			response => {
				console.log(response);
				// TODO: Remove the text from the input

				// TODO: Either add the comment to the local array or reload the comments.
				this.loadDiscussion();
			}
		);

	}

	/**
	 * POPULATE PHOTO METADATA
	 * 
	 * Loads the photo metadata into the photo metadata arrays. This is the first data that gets
	 * loaded into the arrays.
	 */
	populatePhotoMetadata() {
		this.photoKeywords = [];
		if (this.photo.keywords) {
			for (let keyword of JSON.parse(this.photo.keywords)) {
				const photoUserId = this.photo.userId;
				this.photoKeywords.push({ label: keyword, type: 'photo', action: '', userId: photoUserId, suggestionId: '' });
				this.selectedKeywordsLength++;
			}
		}

		this.photoPeople = [];
		if (this.photo.people) {
			for (let person of JSON.parse(this.photo.people)) {
				const photoUserId = this.photo.userId;
				this.photoPeople.push({ label: person, type: 'photo', action: '', userId: photoUserId, suggestionId: '' });
				this.selectedPeopleLength++;
			}
		}

		this.photoLocations = [];
		if (this.photo.location) {
			const photoUserId = this.photo.userId;

			// TODO: ADD SUGGESTIONS HERE
			let photoEntry = { label: this.photo.location, type: 'photo', action: '', userId: photoUserId, suggestionId: '' };
			this.photoLocations.push(photoEntry);
			this.selectedLocationsLength++;

			this.selectedLocation = photoEntry;
		} else {
			this.selectedLocation = null;
		}
	}

	/**
	 * LOAD PHOTO SUGGESTIONS
	 * 
	 * Load suggestions for a photo from the database. Even though this function is called at the same time
	 * as populatePhotoMetadata, this is guaranteed to be the second data added to the arrays, since it 
	 * pulls it from the database.  After this is finished the rest of the dropdown data is loaded.
	 */
	retrievePhotoSuggestions(photoId: number) {
		this.photoService.getPhotoSuggestions(photoId).subscribe(
			response => {
				// Loop suggestions
				for (let suggestion of response.body) {
					if (suggestion.suggestionType == 'keyword' && suggestion.suggestionAction == 'add') {
						// Suggestion type = Keyword Add
						// Check if the keyword already exists
						const item = this.photoKeywords.find(d => d.label === suggestion.suggestionValue);
						if (item) {
							// Keyword already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'keyword');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'keyword');
							}
						} else {
							// Keyword doesn't exist, add it
							this.photoKeywords.push({ label: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId });
							this.selectedKeywordsLength++;
						}
					} else if (suggestion.suggestionType == 'keyword' && suggestion.suggestionAction == 'remove') {
						// Suggestion type = Remove Keyword
						// Check if the keyword already exists
						const item = this.photoKeywords.find(d => d.label === suggestion.suggestionValue);
						if (item) {
							// Keyword already exists, update it to type suggestion with an action remove
							item.type = 'suggestion';
							item.action = suggestion.suggestionAction;
							item.suggestionId = suggestion.suggestionId;
							item.userId = suggestion.userId;
						} else {
							// Item doesn't exist, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'keyword');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'keyword');
							}
						}
					} else if (suggestion.suggestionType == 'person' && suggestion.suggestionAction == 'add') {
						// Suggestion type = Person Add
						// Check if the person already exists
						const item = this.photoKeywords.find(d => d.label === suggestion.suggestionValue);
						if (item) {
							// Person already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'person');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'person');
							}
						} else {
							// Person doesn't exist, add it
							this.photoPeople.push({ label: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId });
							this.selectedPeopleLength++;
						}
					} else if (suggestion.suggestionType == 'person' && suggestion.suggestionAction == 'remove') {
						// Suggestion type = Person Remove
						// Check if the person already exists
						const item = this.photoPeople.find(d => d.label === suggestion.suggestionValue);
						if (item) {
							// Person already exists, update it to type suggestion with an action remove
							item.type = 'suggestion';
							item.action = suggestion.suggestionAction;
							item.suggestionId = suggestion.suggestionId;
							item.userId = suggestion.userId;
						} else {
							// Item doesn't exist, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'person');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'person');
							}
						}
					} else if (suggestion.suggestionType == 'location' && suggestion.suggestionAction == 'add') {
						// Suggestion type = Location Add
						// Check if the location already exists
						const item = this.photoLocations.find(d => d.label === suggestion.suggestionValue);
						if (item) {
							// Item already exists, delete the suggestion
							// Location already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'location');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'location');
							}
						} else {
							// Location doesn't exist, add it
							this.photoLocations.push({ label: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId });
							this.selectedLocationsLength++;
						}
					}
				}

				// We want to load the dropdown data after we have finished loading both the photo metadata
				// as well as suggestions into the photo arrays. This way we can do a check to perform the
				// value doesn't already exist before adding it to prevent duplicates.
				if (this.photo.userId == this.userService.getLocalUserId(0) || this.photo.connectionsCanSuggest) { // TODO: this.photo.allowConnectionsToEdit will always be true here, so shouldn't we always load this data?
					this.retrieveDropdownData();
				}
			});
	}

	/**
	 * RETRIEVE PHOTO HISTORY
	 * 
	 * Retrieve the photo history from the database. This occurs every time a photo is loaded if
	 * the current user is the owner of the photo.
	 */
	retrievePhotoHistory() {
		this.photoService.getPhotoHistory(this.photo.id).subscribe(
			response => {
				this.photoHistory = response.body;
			}
		);
	}

	/**
	 * RETRIEVE DROPDOWN DATA
	 * 
	 * Triggers the functions to retrieve the final data for the dropdowns.
	 */
	retrieveDropdownData() {
		this.loadUserKeywords();
		this.loadUserLocations();
		this.loadPeople();
	}

	/**
	 * LOAD USER KEYWORDS
	 * 
	 * Load the list of user keywords for the multi-select field. 
	 */
	loadUserKeywords() {
		let type = 'dropdown';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}

		if (this.userService.userKeywords) {
			for (const keyword of this.userService.userKeywords) {
				let item = this.photoKeywords.find(d => d.label === keyword.label);
				if (!item) {
					// We set this in case the user just added this as a new keyword.
					keyword.type = type;
					this.photoKeywords.push(keyword);
				}
			}
			this.photoKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
			this.photoKeywords = this.photoKeywords.slice();

			this.selectKeywordsDropdownValues();

			this.loadingKeywords = false;
		} else {
			this.userService.getUserKeywords().subscribe(
				response => {
					let userKeywords = [];
					for (const keyword of response) {
						let item = { label: keyword, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };

						let photoKeywordsItem = this.photoKeywords.find(d => d.label === keyword);
						if (!photoKeywordsItem) {
							this.photoKeywords.push(item);
						}

						userKeywords.push(item);
					}
					this.photoKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
					this.photoKeywords = this.photoKeywords.slice();

					this.userService.userKeywords = userKeywords;

					this.selectKeywordsDropdownValues();

					this.loadingKeywords = false;
				}
			);
		}
	}

	/**
	   * LOAD PEOPLE
	   * 
	   * Load the list of users active connection for the People dropdown.
	   */
	loadPeople() {
		let type = 'dropdown';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}

		if (this.userService.userPeople) {
			for (const person of this.userService.userPeople) {
				let item = this.photoPeople.find(d => d.label === person.label);
				if (!item) {
					this.photoPeople.push(person);
				}
			}
			this.photoPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
			this.photoPeople = this.photoPeople.slice();

			this.selectPeopleDropdownValues();

			this.loadingPeople = false;
		} else {
			let userPeople = [];
			let user = this.userService.getLocalUser(0);
			let item = { label: user.firstName + " " + user.lastName, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };
			this.photoPeople.push(item);
			userPeople.push(item);

			this.connectionService.getUserConnectionsActive().subscribe(
				response => {
					for (const person of response.body) {
						let item = { label: person.firstName + " " + person.lastName, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };

						let photoPeopleItem = this.photoPeople.find(d => d.label === person);
						if (!photoPeopleItem) {
							this.photoPeople.push(item);
						}

						userPeople.push(item);
					}
					this.photoPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
					this.photoPeople = this.photoPeople.slice();

					this.userService.userPeople = userPeople;

					this.loadUserPeople();
				}
			);
		}
	}

	/**
	 * LOAD USER PEOPLE
	 * 
	 * Load the list of non-users for the People dropdown.
	 * 
	 * 0. Get this from cache.
	 * 1. Retrieve user people.
	 * 2. Add to the array. 
	 * 3. Select items.
	 */
	loadUserPeople() {
		this.userService.getUserPeople().subscribe(
			response => {
				let type = 'dropdown';
				if (this.photo.userId !== this.userService.getLocalUserId(0)) {
					type = 'suggestion-new';
				}

				let userPeople = this.userService.userPeople;
				for (const person of response) {
					let item = { label: person, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' }

					let photoPeopleItem = this.photoPeople.find(d => d.label === person);
					if (!photoPeopleItem) {
						this.photoPeople.push(item);
					}

					userPeople.push(item);
				}
				this.photoPeople.sort((a, b) => a.label.localeCompare(b.label));
				this.photoPeople = this.photoPeople.slice();

				this.userService.userPeople = userPeople;

				this.selectPeopleDropdownValues();

				this.loadingPeople = false;
			}
		);
	}

	/**
	 * LOAD USER LOCATIONS
	 * 
	 * Load list of user locations for the locations dropdown.
	 */
	loadUserLocations() {
		let type = 'dropdown';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}

		if (this.userService.userLocations) {
			for (const location of this.userService.userLocations) {
				let item = { label: location, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };

				let photoLocationsItem = this.locations.find(d => d.label === location);
				if (!photoLocationsItem) {
					this.locations.push(item);
				}
			}

			this.loadingLocations = false;
		} else {
			this.userService.getUserLocations().subscribe(
				response => {
					let userLocations = [];

					for (const location of response) {
						let item = { label: location, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };

						let photoLocationsItem = this.locations.find(d => d.label === location);
						if (!photoLocationsItem) {
							this.locations.push(item);
						}

						userLocations.push(item);
					}
					this.locations.sort((a, b) => a.label.localeCompare(b.label));
					this.locations = this.locations.slice();

					this.userService.userLocations = userLocations;

					this.loadingLocations = false;
				}
			);
		}
	}

	/**
	 * SELECT KEYWORDS DROPDOWN VALUES
	 * 
	 * Loads the photos keywords into the select field which is displayed in edit mode.
	 */
	selectKeywordsDropdownValues() {
		// Set the keywords from the photo
		if (this.photo?.keywords) {
			// TODO: There has to be a better way than a setTimeout for this. Is this even needed any more?
			const _this = this;
			setTimeout(function () {
				for (const keyword of JSON.parse(_this.photo.keywords)) {
					let item = _this.keywordsSelect.itemsList.findByLabel(keyword);
					if (item) {
						_this.keywordsSelect.select(item);
					}
				}
			}, 100);
		}

		// Set the keywords from add suggestions
		if (this.photoKeywords) {
			// TODO: There has to be a better way than a setTimeout for this. Is this even needed any more?
			const _this = this;
			setTimeout(function () {
				for (const keyword of _this.photoKeywords) {
					if (keyword.type == 'suggestion' && keyword.action == 'add') {
						let item = _this.keywordsSelect.itemsList.findByLabel(keyword.label);
						if (item) {
							_this.keywordsSelect.select(item);
						}
					}
				}
			}, 100);
		}
	}

	/**
	 * SELECT PEOPLE DROPDOWN VALUES
	 * 
	 * Loads the photos people into the select field which is displayed in edit mode.
	 */
	selectPeopleDropdownValues() {
		if (this.photo?.people) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				for (const person of JSON.parse(_this.photo.people)) {
					let item = _this.peopleSelect.itemsList.findItem(person);
					if (item) {
						_this.peopleSelect.select(item);
					}
				}
			}, 100);
		}

		// Set the people from add suggestions
		if (this.photoPeople) {
			// TODO: There has to be a better way than a setTimeout for this. Is this even needed any more?
			const _this = this;
			setTimeout(function () {
				for (const person of _this.photoPeople) {
					if (person.type == 'suggestion' && person.action == 'add') {
						let item = _this.peopleSelect.itemsList.findByLabel(person.label);
						if (item) {
							_this.peopleSelect.select(item);
						}
					}
				}
			}, 100);
		}
	}

	/**
	 * DELETE PHOTO CONFIRM
	 */
	deletePhotoConfirm() {
		this.loading = true;

		this.photoService.deleteUploadedPhoto(this.photo.id).subscribe(
			response => {
				if (response.status === 200) {
					// Annouce the photo has been deleted.
					this.photoService.announcePhotoDeleted(this.photo.id);

					// Update the photo count.
					let userPhotoCount = this.photoService.getUserPhotoCount();

					if (userPhotoCount) {
						userPhotoCount.totalPhotos = userPhotoCount.totalPhotos - 1;
						userPhotoCount.myUploadedPhotos = userPhotoCount.myUploadedPhotos - 1;
						userPhotoCount.myPhotos = userPhotoCount.myPhotos - 1;

						this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);
					} else {
						this.photoService.getTotalPhotoCount().subscribe(
							response => {
								this.photoService.setAndAnnounceUserPhotoCount(response.body);
							});

					}

					// Show a deleted message.
					this.photoDeleted = true;

					// TODO: Update the photo object so if you navigate away and back, it still shows as deleted.
					this.photo.status = 'deleted'

					this.deleteConfirm = false;
					this.loading = false;
				}
			});
	}

	/**
	 * UPDATE PHOTO FUNCTIONS
	 */
	updatePhoto() {
		this.loading = true;

		if (this.classifyForm.value.description) {
			this.photo.description = this.classifyForm.value.description;
		}

		if (this.classifyForm.value.capturedDate) {
			this.photo.capturedType = "date";
			this.photo.capturedDate = this.classifyForm.value.capturedDate;
		}

		if (this.classifyForm.value.keywords) {
			this.photo.keywords = JSON.stringify(this.classifyForm.value.keywords);
		}

		if (this.selectedLocation) {
			this.photo.location = this.selectedLocation.label;
		} else {
			this.photo.location = '';
		}

		if (this.classifyForm.value.people) {
			this.photo.people = JSON.stringify(this.classifyForm.value.people);
		}

		this.photoService.updatePhoto(this.photo, true).subscribe(
			response => {
				if (response.status === 200) {
					// Add new people to the user people table
					if (this.classifyForm.value.people) {
						for (const item of this.peopleSelect.itemsList.items) { // TODO: This causes issues. I don't know why I wrote this.
							if (item.value.type == 'new') {
								let person = item.value.label;
								this.userService.addUserPerson(person).subscribe(
									response => {
										// TODO: Error validation.

										// Add the person to local cache
										let newItem = { label: person, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
										this.userService.userPeople.push(newItem);
										this.userService.userPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
										this.userService.userPeople = this.userService.userPeople.slice();
									});
							}
						}
					}

					// Add new keywords to the user keyword table
					if (this.classifyForm.value.keywords) {
						for (const item of this.keywordsSelect.itemsList.items) { // TODO: This causes issues. I don't know why I wrote this.
							if (item.value.type == 'new') {
								let keyword = item.value.label;
								this.userService.addUserKeyword(keyword).subscribe(
									response => {
										// TODO: Error validation.

										// Add the keyword to local cache
										let newItem = { label: keyword, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
										this.userService.userKeywords.push(newItem);
										this.userService.userKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
										this.userService.userKeywords = this.userService.userKeywords.slice();
									});
							}
						}
					}

					// Add new location to the user location table
					if (this.tempLocation.length > 0) {
						this.userService.addUserLocation(this.tempLocation).subscribe(
							response => {
								// TODO: Error validation.

								// Add the location to local cache
								let newItem = { label: location, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
								this.userService.userLocations.push(newItem);
								this.userService.userLocations.sort((a, b) => a.label.toString().localeCompare(b.label));
								this.userService.userLocations = this.userService.userLocations.slice();
							});
					}

					// Announce the photo has been updated so it gets removed from the unclassified 
					// listing if they are looking at that page
					this.photoService.setPhotoCategorized(response.body);

					// Set the active photo which will trigger the subscription.
					// TODO: Instead of triggering the subscription this should really update the local
					//       object and cache, which would be more efficient.
					this.photoService.setActivePhoto(this.photo);

					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	updatePhotoForReview() {
		console.log('updatePhotoForReview');
		this.loading = true;

		let keywordsComplete = false;
		let peopleComplete = false;
		let locationsComplete = false;
		let photoHasLoaded = false;

		/** NOT HANDLING DATE FOR NOW
		if (this.classifyForm.value.capturedDate) {
			this.photo.capturedType = "date";
			this.photoReview.capturedDate = this.classifyForm.value.capturedDate;
		} 
		*/

		if (this.classifyForm.value.keywords) {
			for (let i = 0; i < this.classifyForm.value.keywords.length; i++) { // ['Bowling','Vacation']
				// Find item in the keywords array
				const item = this.photoKeywords.find(d => d.label === this.classifyForm.value.keywords[i]);
				if (item) {
					// Get the type
					if (item.type == 'suggestion-new') {
						let photoSuggestion = new PhotoSuggestion();
						photoSuggestion.photoId = this.photo.id;
						photoSuggestion.suggestionAction = 'add';
						photoSuggestion.suggestionType = 'keyword';
						photoSuggestion.suggestionValue = this.classifyForm.value.keywords[i];

						this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
							response => {
								// Check if this is the last suggestion to add
								if (i == (this.classifyForm.value.keywords.length - 1)) {
									keywordsComplete = true;
									if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
										photoHasLoaded = true;
										this.photoService.setActivePhoto(this.photo);
									}
								}
							});

						// Add new keyword to the user keyword table if one doesn't already exist.
						let item = this.userService.userKeywords.find(d => d.label === this.classifyForm.value.keywords[i]);
						if (!item) {
							this.userService.addUserKeyword(this.classifyForm.value.keywords[i]).subscribe(
								response => {
									// Add the keyword to local cache
									let newItem = { label: this.classifyForm.value.keywords[i], type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
									this.userService.userKeywords.push(newItem);
									this.userService.userKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
									this.userService.userKeywords = this.userService.userKeywords.slice();
								});
						}
					} else if (item.type == 'suggestion-remove') {
						let photoSuggestion = new PhotoSuggestion();
						photoSuggestion.photoId = this.photo.id;
						photoSuggestion.suggestionAction = 'remove';
						photoSuggestion.suggestionType = 'keyword';
						photoSuggestion.suggestionValue = this.classifyForm.value.keywords[i];

						this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
							response => {
								// Check if this is the last suggestion to add
								if (i == (this.classifyForm.value.keywords.length - 1)) {
									keywordsComplete = true;
									if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
										photoHasLoaded = true;
										this.photoService.setActivePhoto(this.photo);
									}
								}
							});
					} else {
						// Check if this is the last suggestion to add
						if (i == (this.classifyForm.value.keywords.length - 1)) {
							keywordsComplete = true;
							if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
								photoHasLoaded = true;
								this.photoService.setActivePhoto(this.photo);
								break;
							}
						}
					}
				}
			}
		} else {
			keywordsComplete = true;

			if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
				photoHasLoaded = true;
				this.photoService.setActivePhoto(this.photo);
			}
		}

		if (this.selectedLocation) {
			// Get the type
			if (this.selectedLocation.type == 'suggestion-new') {

				let photoSuggestion = new PhotoSuggestion();
				photoSuggestion.photoId = this.photo.id;
				photoSuggestion.suggestionAction = 'add';
				photoSuggestion.suggestionType = 'location';
				photoSuggestion.suggestionValue = this.selectedLocation.label;

				this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
					response => {
						locationsComplete = true;
						if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
							photoHasLoaded = true;
							this.photoService.setActivePhoto(this.photo);
						}
					});

				// Add new location to the user location table if one doesn't already exist.
				let item = this.userService.userLocations.find(d => d.label === this.selectedLocation.label);
				if (!item) {
					this.userService.addUserLocation(this.selectedLocation.label).subscribe(
						response => {
							// Add the location to local cache
							let newItem = { label: this.selectedLocation.label, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
							this.userService.userLocations.push(newItem);
							this.userService.userLocations.sort((a, b) => a.label.toString().localeCompare(b.label));
							this.userService.userLocations = this.userService.userLocations.slice();
						});
				}



			} else {
				locationsComplete = true;
				if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
					photoHasLoaded = true;
					this.photoService.setActivePhoto(this.photo);
				}
			}


			//for (let i = 0; i < this.classifyForm.value.locations.length; i++) {
			// Find this in the locations array
			// const item = this.photoLocations.find(d => d.label === this.classifyForm.value.locations[i]);
			// if (item) {
			// 	// Get the type
			// 	if (item.type == 'suggestion-new') {
			// 		let photoSuggestion = new PhotoSuggestion();
			// 		photoSuggestion.photoId = this.photo.id;
			// 		photoSuggestion.suggestionAction = 'add';
			// 		photoSuggestion.suggestionType = 'location';
			// 		photoSuggestion.suggestionValue = this.classifyForm.value.locations[i];

			// 		this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
			// 			response => {
			// 				// Check if this is the last suggestion to add
			// 				if (i == (this.classifyForm.value.locations.length - 1)) {
			// 					locationsComplete = true;
			// 					if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
			// 						photoHasLoaded = true;
			// 						this.photoService.setActivePhoto(this.photo);
			// 					}
			// 				}
			// 			});

			// 		// Add new location to the user location table if one doesn't already exist.
			// 		let item = this.userService.userLocations.find(d => d.label === this.classifyForm.value.locations[i]);
			// 		if (!item) {
			// 			this.userService.addUserLocation(this.classifyForm.value.locations[i]).subscribe(
			// 				response => {
			// 					// Add the location to local cache
			// 					let newItem = { label: this.classifyForm.value.locations[i], type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
			// 					this.userService.userLocations.push(newItem);
			// 					this.userService.userLocations.sort((a, b) => a.label.toString().localeCompare(b.label));
			// 					this.userService.userLocations = this.userService.userLocations.slice();
			// 				});
			// 		}
			// 	} else if (item.type == 'suggestion-remove') {
			// 		let photoSuggestion = new PhotoSuggestion();
			// 		photoSuggestion.photoId = this.photo.id;
			// 		photoSuggestion.suggestionAction = 'remove';
			// 		photoSuggestion.suggestionType = 'location';
			// 		photoSuggestion.suggestionValue = this.classifyForm.value.locations[i];

			// 		this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
			// 			response => {
			// 				// Check if this is the last suggestion to add
			// 				if (i == (this.classifyForm.value.locations.length - 1)) {
			// 					locationsComplete = true;
			// 					if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
			// 						photoHasLoaded = true;
			// 						this.photoService.setActivePhoto(this.photo);
			// 					}
			// 				}
			// 			});
			// 	} else {
			// 		// Check if this is the last suggestion to add
			// 		if (i == (this.classifyForm.value.locations.length - 1)) {
			// 			locationsComplete = true;
			// 			if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
			// 				photoHasLoaded = true;
			// 				this.photoService.setActivePhoto(this.photo);
			// 				break;
			// 			}
			// 		}
			// 	}
			// }
			//}
		} else {
			locationsComplete = true;
			if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
				photoHasLoaded = true;
				this.photoService.setActivePhoto(this.photo);
			}
		}

		if (this.classifyForm.value.people && this.classifyForm.value.people != null) {
			for (let i = 0; i < this.classifyForm.value.people.length; i++) {
				// Find this in the people array
				const item = this.photoPeople.find(d => d.label === this.classifyForm.value.people[i]);
				if (item) {
					// Get the type
					if (item.type == 'suggestion-new') {
						let personLabel = this.classifyForm.value.people[i]
						let photoSuggestion = new PhotoSuggestion();
						photoSuggestion.photoId = this.photo.id;
						photoSuggestion.suggestionAction = 'add';
						photoSuggestion.suggestionType = 'person';
						photoSuggestion.suggestionValue = personLabel;

						this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
							response => {
								// Check if this is the last suggestion to add
								if (i == (this.classifyForm.value.people.length - 1)) {
									peopleComplete = true;
									if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
										photoHasLoaded = true;
										this.photoService.setActivePhoto(this.photo);
									}
								}
							});

						// Add new person to the user person table if one doesn't already exist.
						let item = this.userService.userPeople.find(d => d.label === personLabel);
						if (!item) {
							this.userService.addUserPerson(personLabel).subscribe(
								response => {
									// Add the person to local cache
									// TODO: Getting an error on this.classifyForm.value.people[i] because _this_1.form.value.people is null
									// 
									let newItem = { label: personLabel, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
									this.userService.userPeople.push(newItem);
									this.userService.userPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
									this.userService.userPeople = this.userService.userPeople.slice();
								});
						}
					} else if (item.type == 'suggestion-remove') {
						let photoSuggestion = new PhotoSuggestion();
						photoSuggestion.photoId = this.photo.id;
						photoSuggestion.suggestionAction = 'remove';
						photoSuggestion.suggestionType = 'person';
						photoSuggestion.suggestionValue = this.classifyForm.value.people[i];

						this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
							response => {
								// Check if this is the last suggestion to add
								if (i == (this.classifyForm.value.people.length - 1)) {
									peopleComplete = true;
									if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
										photoHasLoaded = true;
										this.photoService.setActivePhoto(this.photo);
									}
								}
							});
					} else {
						// Check if this is the last suggestion to add
						if (i == (this.classifyForm.value.people.length - 1)) {
							peopleComplete = true;
							if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
								photoHasLoaded = true;
								this.photoService.setActivePhoto(this.photo);
								break;
							}
						}
					}
				}
			}
		} else {
			peopleComplete = true;
			if (keywordsComplete && peopleComplete && locationsComplete && !photoHasLoaded) {
				photoHasLoaded = true;
				this.photoService.setActivePhoto(this.photo);
			}
		}
	}

	/**
	 * SUGGESTION FUNCTIONS
	 */
	acceptSuggestion(suggestion, field) {
		console.log('acceptSuggestion');
		this.photoService.acceptPhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				// Check if the suggestion action is 'add' or 'remove'
				if (suggestion.action == 'add') {
					console.log('add');
					// Change the suggestion from type 'suggestion' to 'photo' in the photoKeywords array
					suggestion.type = 'photo';

					if (field == 'keyword') {
						console.log('keyword');
						// Add the suggestion to the photo.keywords json string
						let keywordsArray = JSON.parse(this.photo.keywords);
						keywordsArray.push(suggestion.label);
						this.photo.keywords = JSON.stringify(keywordsArray);

						// Add new keyword to the user keyword table if one doesn't already exist.
						let item = this.userService.userKeywords.find(d => d.label === suggestion.label);
						if (!item) {
							this.userService.addUserKeyword(suggestion.label).subscribe(
								response => {
									// Add the keyword to local cache
									let newItem = { label: suggestion.label, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
									this.userService.userKeywords.push(newItem);
									this.userService.userKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
									this.userService.userKeywords = this.userService.userKeywords.slice();
								});
						}
					} else if (field == 'location') {
						console.log('location');
						// Add the suggestion to photo.location
						this.photo.location = suggestion.label;

						// Add new location to the user location table if one doesn't already exist.
						let item = this.userService.userLocations.find(d => d.label === suggestion.label);
						if (!item) {
							console.log('if 1');
							this.userService.addUserLocation(suggestion.label).subscribe(
								response => {
									// Add the location to local cache
									let newItem = { label: suggestion.label, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
									this.userService.userLocations.push(newItem);
									this.userService.userLocations.sort((a, b) => a.label.toString().localeCompare(b.label));
									this.userService.userLocations = this.userService.userLocations.slice();
								});
						} else {
							console.log('else 1');
						}
					} else if (field == 'person') {
						console.log('person');
						// Add the suggestion to the photo.people json string
						let peopleArray = JSON.parse(this.photo.people);
						peopleArray.push(suggestion.label);
						this.photo.people = JSON.stringify(peopleArray);

						// Add new person to the user person table if one doesn't already exist.
						let item = this.userService.userPeople.find(d => d.label === suggestion.label);
						if (!item) {
							console.log('if 2');
							this.userService.addUserPerson(suggestion.label).subscribe(
								response => {
									// Add the person to local cache
									let newItem = { label: suggestion.label, type: 'dropdown', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '' }
									this.userService.userPeople.push(newItem);
									this.userService.userPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
									this.userService.userPeople = this.userService.userPeople.slice();
								});
						} else {
							console.log('else 2');
						}
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						// Remove the suggestion from the photo.keywords json string
						let keywordsArray = JSON.parse(this.photo.keywords);
						const item = keywordsArray.find(d => d === suggestion.label);
						if (item) {
							keywordsArray.splice(keywordsArray.indexOf(item), 1);
						}
						this.photo.keywords = JSON.stringify(keywordsArray);

						// Remove the keyword from the photoKeywords array
						this.photoKeywords.splice(this.photoKeywords.indexOf(suggestion), 1);
						this.selectedKeywordsLength--;
					} else if (field == 'location') {
						// NOTE: Not currently supporting the suggesting of removing locations

						// // Remove the suggestion from the photo.locations json string
						// let locationsArray = JSON.parse(this.photo.location);
						// const item = locationsArray.find(d => d === suggestion.label);
						// if (item) {
						// 	locationsArray.splice(locationsArray.indexOf(item), 1);
						// }
						// this.photo.location = JSON.stringify(locationsArray);

						// // Remove the keyword from the photoKeywords array
						// this.photoLocations.splice(this.photoLocations.indexOf(suggestion), 1);
						// this.selectedLocationsLength--;
					} else if (field == 'person') {
						// Remove the suggestion from the photo.people json string
						let peopleArray = JSON.parse(this.photo.people);
						const item = peopleArray.find(d => d === suggestion.label);
						if (item) {
							peopleArray.splice(peopleArray.indexOf(item), 1);
						}
						this.photo.people = JSON.stringify(peopleArray);

						// Remove the keyword from the photoKeywords array
						this.photoPeople.splice(this.photoPeople.indexOf(suggestion), 1);
						this.selectedPeopleLength--;
					}
				}
			});
	}

	declineSuggestion(suggestion, field) {
		this.photoService.declinePhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				// Check if the suggestion action is 'add' or 'remove'
				if (suggestion.action == 'add') {
					if (field == 'keyword') {
						// Remove the keyword from the photoKeywords array
						this.photoKeywords.splice(this.photoKeywords.indexOf(suggestion), 1);
						this.selectedKeywordsLength--;
					} else if (field == 'location') {
						// Remove the location from the photoLocations array
						// this.photoLocations.splice(this.photoLocations.indexOf(suggestion), 1);
						// this.selectedLocationsLength--;
					} else if (field == 'person') {
						// Remove the person from the photoPeople array
						this.photoPeople.splice(this.photoPeople.indexOf(suggestion), 1);
						this.selectedPeopleLength--;
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						const item = this.photoKeywords.find(d => d.label === suggestion.label);
						if (item) {
							item.type = 'photo';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'location') {
						// const item = this.photoLocations.find(d => d.label === suggestion.label);
						// if (item) {
						// 	item.type = 'photo';
						// 	item.action = '';
						// 	item.suggestionId = '';
						// 	item.userId = this.photo.userId;
						// }
					} else if (field == 'person') {
						const item = this.photoPeople.find(d => d.label === suggestion.label);
						if (item) {
							item.type = 'photo';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					}
				}
			});
	}

	cancelSuggestion(suggestion, field) {
		this.photoService.cancelPhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				// Check if the suggestion action is 'add' or 'remove'
				if (suggestion.action == 'add') {
					if (field == 'keyword') {
						// Remove the keyword from the photoKeywords array
						this.photoKeywords.splice(this.photoKeywords.indexOf(suggestion), 1);
						this.selectedKeywordsLength--;
					} else if (field == 'location') {
						// Remove the temp location
						this.selectedLocation = null;
						this.photoLocations = [];
						this.selectedLocationsLength = 0;
					} else if (field == 'person') {
						// Remove the person from the photoPeople array
						this.photoPeople.splice(this.photoPeople.indexOf(suggestion), 1);
						this.selectedPeopleLength--;
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						const item = this.photoKeywords.find(d => d.label === suggestion.label);
						if (item) {
							item.type = 'photo';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'location') {
						// const item = this.photoLocations.find(d => d.label === suggestion.label);
						// if (item) {
						// 	item.type = 'photo';
						// 	item.action = '';
						// 	item.suggestionId = '';
						// 	item.userId = this.photo.userId;
						// }
					} else if (field == 'person') {
						const item = this.photoPeople.find(d => d.label === suggestion.label);
						if (item) {
							item.type = 'photo';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					}
				}
			});
	}

	/**
	 * UI / HELPER FUNCTIONS
	 */
	enterEditMode() {
		this.editMode = true;
		this.updateSaveRolloverText('');
	}

	cancelUpdatePhoto() {
		// Reload the photo so it clears any modified metadata
		this.photoService.setActivePhoto(this.photo);
	}

	updateActionsRolloverText(text: string) {
		this.actionsRolloverText = text;
	}

	updateSaveRolloverText(text: string) {
		this.saveRolloverText = text;
	}

	closeModal() {
		// If we don't do this multiple subsriptions get created each time they view a photo.
		if (this.activePhotoChangedSubscription) {
			this.activePhotoChangedSubscription.unsubscribe();
		}

		this.photoService.triggerModalClosed();
		this.modalRef.hide();
	}

	/**
	 * EDIT METADATA FUNCTIONS TIED TO THE DROPDOWN FIELDS
	 */

	/**
	 * PEOPLE KEYUP
	 * 
	 * This occurs every time a user types a character in the people field.
	 */
	peopleKeyup() {
		this.tempPerson = this.peopleSelect.searchTerm;
	}

	/**
	 * PEOPLE BLUR
	 * 
	 * This occurs when the user tabs or clicks away from the field after entering a new person.
	 */
	peopleBlur() {
		if (this.tempPerson?.length > 0) {
			// Set the proper photo type
			let type = 'new';
			if (this.photo.userId !== this.userService.getLocalUserId(0)) {
				type = 'suggestion-new';
			}

			// Add the person
			this.photoPeople.push({ label: this.tempPerson, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' });
			this.photoPeople.sort((a, b) => a.label.toString().localeCompare(b.label));
			this.photoPeople = this.photoPeople.slice();

			// Select the person
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.peopleSelect.itemsList.findByLabel(_this.tempPerson);
				if (item) {
					_this.peopleSelect.select(item);
				}
				_this.tempPerson = '';
			}, 100);
		}
	}

	/**
	 * PEOPLE CHANGE
	 * 
	 * Set the temporary person to blank. This happens when a user selects a person so a new one is not created.
	 */
	peopleChange() {
		this.tempPerson = '';

		// TODO: Apply sorting to ng-select.
	}

	/**
	 * PEOPLE UNSELECT
	 * 
	 * @param $event
	 */
	peopleUnselect(person) {
		if (this.lastRemovedPerson !== person.label) { // Fix for 'too much recursion' issue
			if (this.photo.userId !== this.userService.getLocalUserId(0)) {
				this.lastRemovedPerson = person.label;

				// Not my Photo, they are either canceling a suggestion or suggesting to remove a person
				if (person.type == 'suggestion-new') {
					// Cancelling a suggestion-new
					// Unselect the person
					let dropdownItem = this.peopleSelect.itemsList.findByLabel(person.label);
					if (dropdownItem) {
						this.peopleSelect.unselect(dropdownItem);
					}
				} else if (person.type == 'suggestion-remove') {
					this.lastRemovedPerson = '';
					// Cancelling a suggestion-remove
					// Change the type to 'photo'
					const item = this.photoPeople.find(d => d.label === person.label);
					if (item) {
						item.userId = this.photo.userId;
						item.type = 'photo';
					}
				} else {
					// Suggesting a remove, either a person or a suggestion
					this.lastRemovedPerson = '';

					// Suggesting to remove a suggestion, which we won't allow for now for now.
					if (person.type == 'suggestion' || person.type == undefined) {
						// Do nothing for now.
					} else {
						// Suggesting to remove a person, update the person type from 'photo' to 'suggestion-remove'
						const item = this.photoPeople.find(d => d.label === person.label);
						if (item) {
							item.userId = this.userService.getLocalUserId(0);
							item.type = 'suggestion-remove';
						}
					}

					// Detect if the person is still selected, if not, add it back
					let dropdownItem = this.peopleSelect.itemsList.findByLabel(person.label);
					if (dropdownItem) {
						this.peopleSelect.select(dropdownItem);
					}
				}
			} else {
				this.lastRemovedPerson = person.label;

				// My Photo, unselect the person
				let dropdownItem = this.peopleSelect.itemsList.findByLabel(person.label);
				if (dropdownItem) {
					this.peopleSelect.unselect(dropdownItem);
				}

				// Change the type to 'dropdown'
				const item = this.photoPeople.find(d => d.label === person.label);
				if (item) {
					item.type = 'dropdown';
				}
			}
		}
	}



	/** LOCATION FUNCTIONS */

	locationKeyup() {
		this.locationSearchSubject.next(null);
	}

	locationKeyupSearch() {
		if (this.classifyForm.value.location.length > 3) {

			if (this.locationShowSuggestions) {
				this.locationShowSuggestions = true;

				this.getLocationSuggestions();
			} else {
				let locations = this.locations.filter((location) => location.label.toString().toLowerCase().includes(this.classifyForm.value.location.toLowerCase()));
				if (locations.length > 0) {
					this.locationShowSuggestions = false;
					this.locationSuggestions = [];

					this.userLocationSuggestions = locations;
				} else {
					this.userLocationSuggestions = [];

					this.locationShowSuggestions = true;

					this.getLocationSuggestions();
				}
			}
		} else if (this.classifyForm.value.location.length == 0) {
			this.userLocationSuggestions = [];
			this.locationSuggestions = [];
			this.locationShowSuggestions = false;
		}
	}

	loadMoreSuggestions() {
		this.locationShowSuggestions = true;
		this.userLocationSuggestions = [];

		this.getLocationSuggestions();
	}

	getLocationSuggestions() {
		this.userLocationSuggestions = [];

		this.userService.getLocationSuggestions(this.classifyForm.value.location).subscribe(
			response => {
				this.locationSuggestions = response.body.items;
			}
		);
	}

	addSuggestion() {
		// TODO: ADD HERE
		this.selectedLocation = this.classifyForm.value.location;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = this.selectedLocation;
	}

	selectLocation(location) {
		// TODO: ADD HERE
		this.selectedLocation = location;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = '';
	}

	selectLocationSuggestion(locationLabel: string) {
		// ADD HERE
		let type = 'dropdown';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}
		console.log('type: ' + type);
		let item = { label: locationLabel, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' };


		this.selectedLocation = item;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = item.label;
	}

	clearLocation() {
		this.selectedLocation = null;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = '';

		this.classifyForm.controls['location'].setValue('');
	}

	locationsUnselect(location) {
		if (this.lastRemovedLocation !== location.label) { // Fix for 'too much recursion' issue
			if (this.photo.userId !== this.userService.getLocalUserId(0)) {
				this.lastRemovedLocation = location.label;

				// Not my Photo, they are either canceling a suggestion or suggesting to remove a keyword
				if (location.type == 'suggestion-new') {
					// Cancelling a suggestion-new
					// Unselect the keyword
					this.clearLocation();

				} else {
					// Suggesting a remove, either a keyword or a suggestion
					this.lastRemovedLocation = '';

					// Suggesting to remove a suggestion, which we won't allow for now
					if (location.type == 'suggestion' || location.type == undefined) {
						// Do nothing for now.
					} else {
						// Suggesting to remove a keyword, update the keyword type from 'photo' to 'suggestion-remove'
						// const item = this.photoKeywords.find(d => d.label === keyword.label);
						// if (item) {
						// 	item.userId = this.userService.getLocalUserId(0);
						// 	item.type = 'suggestion-remove';
						// }
					}

					// Detect if the keyword is still selected, if not, add it back
					// let dropdownItem = this.keywordsSelect.itemsList.findByLabel(keyword.label);
					// if (dropdownItem) {
					// 	this.keywordsSelect.select(dropdownItem);
					// }
				}
			} else {
				this.lastRemovedLocation = location.label;

				// My Photo, unselect the keyword
				// let dropdownItem = this.keywordsSelect.itemsList.findByLabel(keyword.label);
				// if (dropdownItem) {
				// 	this.keywordsSelect.unselect(dropdownItem);
				// }

				// Change the type to 'dropdown'
				// const item = this.photoKeywords.find(d => d.label === keyword.label);
				// if (item) {
				// 	item.type = 'dropdown';
				// }
			}
		}
	}


	/**
	 * KEYWORDS KEYUP
	 * 
	 * This occurs every time a user types a character in the keywords field.
	 */
	keywordsKeyup() {
		this.tempKeyword = this.keywordsSelect.searchTerm;
	}

	/**
	 * KEYWORDS BLUR
	 * 
	 * This occurs when the user tabs or clicks away from the field after entering a new keyword.
	 */
	keywordsBlur() {
		if (this.tempKeyword?.length > 0) {
			// Set the proper photo type
			let type = 'new';
			if (this.photo.userId !== this.userService.getLocalUserId(0)) {
				type = 'suggestion-new';
			}

			// Add the keyword
			this.photoKeywords.push({ label: this.tempKeyword, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' });
			this.photoKeywords.sort((a, b) => a.label.toString().localeCompare(b.label));
			this.photoKeywords = this.photoKeywords.slice();

			// Select the keyword
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.keywordsSelect.itemsList.findByLabel(_this.tempKeyword);
				if (item) {
					_this.keywordsSelect.select(item);
				}
				_this.tempKeyword = '';
			}, 100);
		}
	}

	/**
	 * KEYWORDS CHANGE
	 * 
	 * Set the temporary keyword to blank. This occurs when a user selects a keyword so a new one is not created.
	 */
	keywordsChange(keywords) {
		this.tempKeyword = '';
	}

	/**
	 * KEYWORDS UNSELECT
	 * 
	 * This occurs when a user removes a keyword.
	 */
	keywordsUnselect(keyword) {
		if (this.lastRemovedKeyword !== keyword.label) { // Fix for 'too much recursion' issue
			if (this.photo.userId !== this.userService.getLocalUserId(0)) {
				this.lastRemovedKeyword = keyword.label;

				// Not my Photo, they are either canceling a suggestion or suggesting to remove a keyword
				if (keyword.type == 'suggestion-new') {
					// Cancelling a suggestion-new
					// Unselect the keyword
					let dropdownItem = this.keywordsSelect.itemsList.findByLabel(keyword.label);
					if (dropdownItem) {
						this.keywordsSelect.unselect(dropdownItem);
					}
				} else if (keyword.type == 'suggestion-remove') {
					this.lastRemovedKeyword = '';
					// Cancelling a suggestion-remove
					// Change the type to 'photo'
					const item = this.photoKeywords.find(d => d.label === keyword.label);
					if (item) {
						item.userId = this.photo.userId;
						item.type = 'photo';
					}
				} else {
					// Suggesting a remove, either a keyword or a suggestion
					this.lastRemovedKeyword = '';

					// Suggesting to remove a suggestion, which we won't allow for now
					if (keyword.type == 'suggestion' || keyword.type == undefined) {
						// Do nothing for now.
					} else {
						// Suggesting to remove a keyword, update the keyword type from 'photo' to 'suggestion-remove'
						const item = this.photoKeywords.find(d => d.label === keyword.label);
						if (item) {
							item.userId = this.userService.getLocalUserId(0);
							item.type = 'suggestion-remove';
						}
					}

					// Detect if the keyword is still selected, if not, add it back
					let dropdownItem = this.keywordsSelect.itemsList.findByLabel(keyword.label);
					if (dropdownItem) {
						this.keywordsSelect.select(dropdownItem);
					}
				}
			} else {
				this.lastRemovedKeyword = keyword.label;

				// My Photo, unselect the keyword
				let dropdownItem = this.keywordsSelect.itemsList.findByLabel(keyword.label);
				if (dropdownItem) {
					this.keywordsSelect.unselect(dropdownItem);
				}

				// Change the type to 'dropdown'
				const item = this.photoKeywords.find(d => d.label === keyword.label);
				if (item) {
					item.type = 'dropdown';
				}
			}
		}
	}

	/**
	 * PHOTO OPTIONS FUNCTIONS
	 */

	connectionsCanViewSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanView = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanView = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can view the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanView = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanView = false;
					this.photo.connectionsCanReact = false;
					this.photo.connectionsCanDiscuss = false;
					this.photo.lockDiscussion = false;
					this.photo.connectionsCanSeeExif = false;
					this.photo.connectionsCanSuggest = false;

					this.initClassifyForm();

					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot view the photo');
				}
			);
		}
	}

	connectionsCanReactSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanReact = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanReact = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can react to the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanReact = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanReact = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot react to the photo');
				}
			);
		}
	}

	connectionsCanDiscussSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanDiscuss = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanDiscuss = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can discuss the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanDiscuss = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanDiscuss = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot discuss the photo');
				}
			);
		}
	}

	connectionsCanSuggestSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSuggest = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanSuggest = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can suggest photo metadata');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSuggest = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanSuggest = false;

					// Remove all suggestions from photoKeywords, photoUsers, photoLocations
					for (let i = 0; i < this.photoKeywords.length; i++) {
						if (this.photoKeywords[i].type == 'suggestion') {
							this.photoKeywords.splice(i, 1);
						}
					}
					// for (let i = 0; i < this.photoLocations.length; i++) {
					// 	if (this.photoLocations[i].type == 'suggestion') {
					// 		this.photoLocations.splice(i, 1);
					// 	}
					// }
					for (let i = 0; i < this.photoPeople.length; i++) {
						if (this.photoPeople[i].type == 'suggestion') {
							this.photoPeople.splice(i, 1);
						}
					}

					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot suggest photo metadata');
				}
			);
		}
	}

	connectionsCanSeeExifSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSeeExif = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanSeeExif = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can see photo EXIF data');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSeeExif = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.connectionsCanSeeExif = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot photo EXIF data');
				}
			);
		}
	}

	lockDiscussionSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.lockDiscussion = true;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.lockDiscussion = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Photo discussion is locked');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.lockDiscussion = false;

			this.photoService.updatePhoto(photo, false).subscribe(
				response => {
					this.photo.lockDiscussion = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Photo discussion is unlocked');
				}
			);
		}
	}
}
