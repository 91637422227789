<div class="loading in" *ngIf="loadingConnections || loadingUserPaymentMethods">
	&nbsp;
</div>

<div class="scroll" *ngIf="!userPaymentMethodsExist && (!loadingConnections && !loadingUserPaymentMethods)">
	<div class="welcome-title">
		<div class="title">Create Event</div>
	</div>
	<div class="welcome-message">
		To create events, you must have a valid payment method added to your account...
	</div>
	<div class="welcome-content">
		<div class="column">
			<div class="icon-cell">
				<i class="far fa-credit-card"></i>
			</div>
			<div class="border-cell">
				<div class="content-cell">
					<div class="content-title">Payment Methods</div>
					<div class="content-text">
						<p>Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigateToPaymentMethods()">Create a Payment Method</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row justify-content-center g-0" *ngIf="userPaymentMethodsExist && (!loadingConnections && !loadingUserPaymentMethods)">
	<div class="col-md-11">
		<div class="quick-links row">
			<!-- Filter / Title Card -->
			<div class="container col-12  col-md-4 col-lg-3">
				<app-card-event-create-title></app-card-event-create-title>
			</div>

			<div class="container col-12 col-md-8 col-lg-9">
				<div class="content-container" [ngClass]="{'loading in': loading }">
					<form #f="ngForm" [formGroup]="form" class="form">
						<!-- EVENT NAME -->
						<div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="name">*Event name:</label>
									<input type="text" id="name" formControlName="name" class="form-control" placeholder="Example: High School Graduation">
								</div>
							</div>
						</div>
						<!-- EVENT DETAILS -->
						<div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="name">Event details:</label>
									<textarea id="details" formControlName="details" class="form-control" maxlength="750"></textarea>
									<div *ngIf="form.value.details" class="characters-remaining">{{ 750 - form.value.details.length }} characters remaining</div>
								</div>
							</div>
						</div>
						<!-- START DATE -->
						<div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="startDate">*Event start date:</label>
									<label *ngIf="!showStartTime" class="start-time-link">(<a (click)="showStartTime = true" href="javascript:void(0)">add start time</a>)</label>
									<label *ngIf="showStartTime" class="start-time-link">(<a (click)="showStartTime = false" href="javascript:void(0)">remove start time</a>)</label>
									<div class="flex">
										<div class="date-container">
											<div *ngIf="showStartTime" class="noon-controls"><a href="javascript:void(0)" (click)="setStartTimeToNoon()">noon</a> / <a href="javascript:void(0)" (click)="setStartTimeToMidnight()">midnight</a></div>
											<input bsDatepicker type="text" value="{{ form.value.startDate | date:'EEEE, MMMM d, y' }}" [disabled]="loading" id="startDate" formControlName="startDate" class="form-control date" placement="top middle" autocomplete="off">
											<!-- <div *ngIf="form && form.controls.startDate.invalid && form.controls.startDate.touched" class="error-message">Enter the start date when the event occurred</div> -->
										</div>
										<div class="time-container" [ngClass]="{'hidden': !showStartTime}">
											<div class="input-cell">
												<i class="fas fa-caret-up" (click)="increaseStartHour()"></i>
												<input type="text" class="form-control hour" id="startTimeHour" formControlName="startTimeHour" disabled="true" />
												<i class="fas fa-caret-down" (click)="decreaseStartHour()"></i>
											</div>
											<span class="colon">:</span>
											<div class="input-cell">
												<i class="fas fa-caret-up" (click)="increaseStartMinute()"></i>
												<input type="text" class="form-control minutes" id="startTimeMinute" formControlName="startTimeMinute" disabled="true" />
												<i class="fas fa-caret-down" (click)="decreaseStartMinute()"></i>
											</div>
											<input type="button" class="btn btn-meridiem" id="startTimeMeridiem" formControlName="startTimeMeridiem" (click)="changeStartMeridiem()" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row g-0" *ngIf="!showEndDate">
							<div class="col-md-12">
								<div class="form-group"><a (click)="showEndDate = true" href="javascript:void(0)">Add event end date</a></div>
							</div>
						</div>
						<!-- END DATE -->
						<div class="row g-0" [ngClass]="{'hidden': !showEndDate}">
							<div class="col-md-12">
								<div class="form-group">
									<label for="endDate">Event end date:</label>
									<label *ngIf="!showEndTime" class="start-time-link">(<a (click)="showEndTime = true" href="javascript:void(0)">add end time</a>)</label>
									<label *ngIf="showEndTime" class="start-time-link">(<a (click)="showEndTime = false" href="javascript:void(0)">remove end time</a>)</label>
									<div class="flex">
										<div class="date-container">
											<div *ngIf="showEndTime" class="noon-controls"><a href="javascript:void(0)" (click)="setEndTimeToNoon()">noon</a> / <a href="javascript:void(0)" (click)="setEndTimeToMidnight()">midnight</a></div>
											<input bsDatepicker type="text" value="{{ form.value.endDate | date:'EEEE, MMMM d, y' }}" [disabled]="loading" id="endDate" formControlName="endDate" class="form-control date" placement="top middle" autocomplete="off">
											<div *ngIf="form && form.controls.endDate.invalid && form.controls.endDate.touched" class="error-message">Enter the end date of the event</div>
										</div>
										<div class="time-container" [ngClass]="{'hidden': !showEndTime}">
											<div class="input-cell">
												<i class="fas fa-caret-up" (click)="increaseEndHour()"></i>
												<input type="text" class="form-control hour" id="endTimeHour" formControlName="endTimeHour" disabled="true" />
												<i class="fas fa-caret-down" (click)="decreaseEndHour()"></i>
											</div>
											<span class="colon">:</span>
											<div class="input-cell">
												<i class="fas fa-caret-up" (click)="increaseEndMinute()"></i>
												<input type="text" class="form-control minutes" id="endTimeMinute" formControlName="endTimeMinute" disabled="true" />
												<i class="fas fa-caret-down" (click)="decreaseEndMinute()"></i>
											</div>
											<input type="button" class="btn btn-meridiem" id="endTimeMeridiem" formControlName="endTimeMeridiem" (click)="changeEndMeridiem()" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row g-0" *ngIf="showEndDate">
							<div class="col-md-12">
								<div class="form-group"><a (click)="showEndDate = false" href="javascript:void(0)">Remove event end date</a></div>
							</div>
						</div>

						<!-- LOCATION -->
						<div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="name">Event location:</label>
									<input [ngClass]="{'hidden': location !== '' }" type="text" id="location" formControlName="location" class="form-control" (keyup)="locationKeyup()" autocomplete="off">
									<div class="location-suggestion-container" *ngIf="locationSuggestions.length > 0">
										<div *ngFor="let suggestion of locationSuggestions" (click)="selectLocation(suggestion.title)">
											{{ suggestion.title }}
										</div>
									</div>
									<div *ngIf="location !== ''">{{ location }} (<a (click)="clearLocation()" href="javascript:void(0)">clear location</a>)</div>
									<div *ngIf="locationEmbedUrl" class="location-map-container">
										<iframe [src]="locationEmbedUrl | safe: 'resourceUrl'" width="400" height="275" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
									</div>
								</div>
							</div>
						</div>

						<div class="row g-0" [ngClass]="{'hidden': location == '' }">
							<div class="col-md-12">
								<div class="form-group">
									<label for="locationShowMap">Show map on the event page?</label>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="locationShowMapSwitch" type="checkbox" class="switch-input" formControlName="locationShowMap" />
												<span class="slider round"></span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- PERMISSIONS -->
						<div class="row g-0">
							<div class="col-md-6">
								<div class="form-group">
									<label for="canConnectionsView">Allow your connections to:</label>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canConnectionsViewSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsView" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">View the event</label>
									</div>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canConnectionsContributeSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsContribute" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Contribute photos to the event</label>
									</div>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canConnectionsShareSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsShare" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Share the event</label>
									</div>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canConnectionsDiscussSwitch" type="checkbox" class="switch-input" formControlName="canConnectionsDiscuss" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Discuss the event</label>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="canConnectionsView">Allow anonymous users to:</label>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canPublicViewSwitch" type="checkbox" class="switch-input" formControlName="canPublicView" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">View the event</label>
									</div>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canPublicContributeSwitch" type="checkbox" class="switch-input" formControlName="canPublicContribute" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Contribute photos to the event</label>
									</div>
									<!-- <div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canPublicDiscussSwitch" type="checkbox" class="switch-input" formControlName="canPublicDiscuss" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Discuss the event</label>
									</div> -->
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="canPublicShareSwitch" type="checkbox" class="switch-input" formControlName="canPublicShare" />
												<span class="slider round"></span>
											</label>
										</div>
										<label for="keywords" class="option-label">Share the event</label>
									</div>
								</div>
							</div>
						</div>

						<!-- ADD TO TIMELINE -->
						<div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="addToMyTimeline">Add this event to my timeline?</label>
									<div *ngIf="showHelpText" class="help-text">Decide if moderator approval is required when people add photos to the vault.</div>
									<div class="option">
										<div class="slider-container">
											<label class="switch">
												<input id="addToMyTimelineSwitch" type="checkbox" class="switch-input" formControlName="addToMyTimeline" />
												<span class="slider round"></span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- SELECT CONNECTIONS -->
						<!-- <div class="row g-0">
							<div class="col-md-12">
								<div class="form-group">
									<label for="users">Invite members <span class="optional">(select a connection or enter an e-mail)</span></label>
									<div *ngIf="showHelpText" class="help-text">Vault members can view photos, add photos, and invite other users to the vault. Select a connection from the list or enter an e-mail address and we will send an invitation.</div>
									<ng-select #memberUsersOrEmailsSelect class="typeahead" [items]="users" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="id" id="memberUsersOrEmails" formControlName="memberUsersOrEmails" (change)="memberUsersOrEmailsChange($event)" (keyup)="memberUsersOrEmailsKeyup()" (blur)="memberUsersOrEmailsBlur()" (remove)="memberUsersOrEmailsUnselect($event)" (add)="memberUsersOrEmailsAdd($event)">
									</ng-select>
								</div>
							</div>
						</div> -->

						<div *ngIf="error.length > 0" class="date-error-message">{{ error }}</div>

						<div class="row justify-content-center g-0 last">
							<div class="col-12 col-lg-4">
								<div class="form-group center">
									<button class="btn btn-success form-button-submit" type="submit" (click)="createEvent()" [disabled]="!f.valid">Create</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>