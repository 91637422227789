import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserMessage } from 'src/app/models/user-message';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-user-message',
	templateUrl: './user-message.component.html',
	styleUrls: ['./user-message.component.css']
})
export class UserMessageComponent implements OnInit, OnDestroy {
	@Input() message: UserMessage;

	userFoundSubscription: Subscription;
	user: User;

	loadingUser = true;

	constructor(
		private router: Router,
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		console.log(this.message);
		if (this.message.messageUserId) {
			console.log('yes messageUserId');
			this.userService.getUserByUserId(this.message.messageUserId, null);
		} else {
			this.loadingUser = false;
		}
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				console.log('subscribeToUserChanged');
				if (user.id == this.message.messageUserId) {
					console.log('yes');
					this.user = user;
					this.loadingUser = false;
				}
			}
		);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}
}
