<div class="card opposite-colors" #card>
	<div class="face front">
		<i class="fas fa-question" (click)="flip()"></i>
		<div class="title-container">
			<div class="title">Add<br />Photos</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Sync Photos</div>
		<div class="help-text">With Photonomy, you can sync photos from external sources such as Facebook and OneDrive. You can choose to import the photo into Photonomy or leave it in the external source.</div>
	</div>
</div>