import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserConnection } from 'src/app/models/user-connection.model';
import { User } from 'src/app/models/user.model';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-card-connection-send-request',
	templateUrl: './card-connection-send-request.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardConnectionSendRequestComponent implements OnInit, OnDestroy {
	@Output() emitConnectionRequested = new EventEmitter<User>();

	@ViewChild('card') cardRef: ElementRef;

	myUserUuid: string;
	userChangedSubscription: Subscription;
	loading = false;
	requestSent = false;
	formCode: UntypedFormGroup;

	constructor(
		private connectionService: UserConnectionService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private toastr: ToastrService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.initFormCode();
		this.subscribeToUserChanged();
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				this.myUserUuid = user.uuid;
			});
		if (this.userService.users[0]) {
			this.myUserUuid = this.userService.users[0].uuid;
		}
	}

	initFormCode() {
		this.formCode = this.formBuilder.group({
			'code': new UntypedFormControl(null, [
				Validators.required
			])
		});
	}

	/**
	 * SUBMIT CONNECTION CODE
	 */
	submitUuid() {
		this.loading = true;
		this.requestSent = false;

		let connection = new UserConnection();
		connection.userUuid1 = this.myUserUuid;
		connection.userUuid2 = this.formCode.value.code;

		this.connectionService.addUserConnection(connection).subscribe(
			response => {
				if (response.status === 200) {
					this.requestSent = true;
					this.initFormCode();
					this.toastr.success('The request has been sent.');
					this.emitConnectionRequested.emit();

					// TODO: I only want to increment this if the connection request was legit.
					this.connectionService.connectionsCount = this.connectionService.connectionsCount + 1;
					this.loading = false;
				} else {
					this.toastr.error('Invalid connection code.');
					this.loading = false;
				}
			},
			err => {
				this.toastr.error(err.error);
				this.loading = false;
			}
		);
	}

	/**
	 * Helper Methods
	 **/
	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}
