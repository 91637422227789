import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-widget-welcome-message',
	templateUrl: './widget-welcome-message.component.html',
	styleUrls: ['./widget-welcome-message.component.css']
})
export class WidgetWelcomeMessageComponent implements OnInit, OnDestroy {
	loading = true;

	timeOfDay = '';
	fadeText = false;

	user: User;
	userImageSafeUrlChangedSubscription: Subscription;

	constructor(
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		this.setTimeOfDay();

		if (!this.userService.users[0] || this.userService.users[0] == null) {
			this.retrieveCurrentUser();
		} else {
			this.retrieveCurrentUserCached();
		}

		let _this = this;
		setTimeout(function () {
			_this.fadeText = true;
		}, 5000);
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userImageSafeUrlChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user.imageSafeUrl = user.imageSafeUrl;
					this.loading = false;
				}
			}
		);
	}

	retrieveCurrentUser() {
		this.userService.retrieveCurrentUser().subscribe(
			response => {
				this.user = response.body;

				if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
					this.userService.retrieveUserImageSafeUrl(this.user, null);
				} else {
					this.loading = false;
				}
			}
		);
	}

	retrieveCurrentUserCached() {
		this.user = this.userService.users[0];

		if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
			this.userService.retrieveUserImageSafeUrl(this.user, null);
		} else {
			this.loading = false;
		}
	}

	setTimeOfDay() {
		let today = new Date();
		let curHr = today.getHours();

		if (curHr < 5) {
			this.timeOfDay = 'night'; // night
		} else if (curHr < 12) {
			this.timeOfDay = 'morning'; // morning
		} else if (curHr < 18) {
			this.timeOfDay = 'afternoon'; // afternoon
		} else if (curHr < 23) {
			this.timeOfDay = 'evening'; // evening
		} else {
			this.timeOfDay = 'night'; // night
		}
	}
}
