import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventComment } from '../models/event-comment.model';

@Injectable()
export class EventCommentService {

    constructor(
        private http: HttpClient
    ) { }

    retrieveDiscussionByShareCode1AndShareCode2(shareCode1: string, shareCode2: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/event/discussion/' + shareCode1 + '/' + shareCode2;

        return this.http.get<Array<EventComment>>(requestUrl, { headers: headers, observe: 'response' });
    }

    retrieveComments(eventId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);

        const requestUrl = '/api/event/comments';

        return this.http.get<Array<EventComment>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
    }

    addComment(eventId: number, comment: string, parentCommentId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'text/html');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('parentCommentId', parentCommentId);

        const requestUrl = '/api/event/comment';

        return this.http.post<EventComment>(requestUrl, comment, { headers: headers, observe: 'response', params: queryParameters });
    }

    updateComment(eventId: number, commentId: number, comment: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment';

        return this.http.put<EventComment>(requestUrl, comment, { headers: headers, observe: 'response', params: queryParameters });
    }

    deleteComment(commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    angryComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/angry';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unangryComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/angry';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    laughComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/laugh';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unlaughComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/laugh';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    likeComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/like';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unlikeComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/like';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    loveComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/love';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unloveComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/love';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    sadComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/sad';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unsadComment(eventId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('eventId', eventId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/event/comment/sad';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }
}
