import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-profile-connection-icon',
	templateUrl: './profile-connection-icon.component.html',
	styleUrls: ['./profile-connection-icon.component.css']
})
export class ProfileConnectionIconComponent implements OnInit {
	@Input() user: User;
	@Input() defaultPhotoOwnerUserIdsArray;

	loading = true;

	userFoundSubscription: Subscription;

	constructor(
		private userService: UserService,
		private toastr: ToastrService
	) { }

	ngOnInit() {
		console.log('photo-connection-icon init');

		console.log(this.user);

		this.subscribeToUserChanged();

		this.userService.getUserByUserId(this.user.id, null);
	}

	ngOnDestroy() { }


	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.user.id) {
					const item = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
					if (item) {
						user.isSearchDefaultOwner = true;
					} else {
						user.isSearchDefaultOwner = false;
					}

					this.user = user;

					this.loading = false;
				}
			}
		);
	}

	addSearchDefaultOwner(user) {
		this.defaultPhotoOwnerUserIdsArray.push(user.id);

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.defaultPhotoOwnerUserIds = JSON.stringify(this.defaultPhotoOwnerUserIdsArray);

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				//this.userService.users[0].defaultPhotoOwnerUserIds = this.defaultPhotoOwnerUserIdsArray;
				this.userService.users[0].defaultPhotoOwnerUserIds = updateUser.defaultPhotoOwnerUserIds;

				//this.userService.updateLocalUser(response.body);

				user.isSearchDefaultOwner = true;

				this.toastr.success('Default setting updated');
			}
		);
	}

	removeSearchDefaultOwner(user) {
		const item = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
		if (item) {
			this.defaultPhotoOwnerUserIdsArray.splice(this.defaultPhotoOwnerUserIdsArray.indexOf(item), 1);
		}

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.defaultPhotoOwnerUserIds = JSON.stringify(this.defaultPhotoOwnerUserIdsArray);

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.users[0].defaultPhotoOwnerUserIds = updateUser.defaultPhotoOwnerUserIds;
				//this.userService.updateLocalUser(response.body);

				user.isSearchDefaultOwner = false;

				this.toastr.success('Default setting updated');
			}
		);
	}
}
