import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventPhotoAdd } from 'src/app/models/event-photo-add.model';
import { EventService } from 'src/app/services/event.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-add-photo-to-event-progress',
	templateUrl: './add-photo-to-event.component.html',
	styleUrls: ['./add-photo-to-event.component.css']
})
export class AddPhotoToEventProgressComponent implements OnInit {
	@Input() eventPhotoAdd: EventPhotoAdd;
	@Output() finished = new EventEmitter<EventPhotoAdd>();

	loading = true;
	addingPhoto = false;

	error = false; // ?

	eventPhotoAddNextQueueSubscription: Subscription;

	constructor(
		private eventService: EventService,
		private layoutService: LayoutService,
		private photoService: PhotoService
	) { }

	ngOnInit() {
		// If the incoming photo has the status of inprogress, process it right away. 
		// Otherwise, wait for the queueNumber to be announced within the subscription.
		if (this.eventPhotoAdd.status == 'inprogress') {
			this.addPhotoToEvent();
		} else if (this.eventPhotoAdd.status == 'queued') {
			this.subscribeToNextQueueNumberAnnounced();
		}
	}

	ngOnDestroy() {
		if (this.eventPhotoAddNextQueueSubscription) {
			this.eventPhotoAddNextQueueSubscription.unsubscribe();
		}
	}

	subscribeToNextQueueNumberAnnounced() {
		this.eventPhotoAddNextQueueSubscription = this.layoutService.callNextAddEventPhotoQueueNumber
			.subscribe((queueNumber: number) => {
				if (queueNumber == this.eventPhotoAdd.queueNumber) {
					this.eventPhotoAdd.status == 'inprogress';
					this.addPhotoToEvent();
				}
			});
	}

	addPhotoToEvent() {
		this.addingPhoto = true;
		this.eventService.addEventPhoto(this.eventPhotoAdd.photo.addToEvent.id, this.eventPhotoAdd.photo.id).subscribe(
			(response) => {
				let addToEvent = this.eventPhotoAdd.photo.addToEvent;
				this.eventPhotoAdd.photo = response.body;
				this.eventPhotoAdd.photo.addToEvent = addToEvent;
				this.eventPhotoAdd.status = 'success';
				this.loading = false;
				this.addingPhoto = false;
				this.updateImageSafeUrl();
			}, err => {
				this.error = true;
				this.eventPhotoAdd.status = 'error';
				this.loading = false;
				this.addingPhoto = false;
			}
		);
	}

	updateImageSafeUrl() {
		let token;
		for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
			if (signedDownloadToken.eventId == this.eventPhotoAdd.photo.addToEvent.id) {
				token = signedDownloadToken;
				break;
			}
		}

		if (token) {
			this.eventPhotoAdd.photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + this.eventPhotoAdd.photo.imageUrlMed + "?Authorization=" + token.token;

			this.finish();
		} else {
			this.photoService.getSignedDownloadToken(this.eventPhotoAdd.photo.addToEvent.id, 'event').subscribe(
				response => {
					let responseToken = response.body;
					responseToken.eventId = this.eventPhotoAdd.photo.addToEvent.id;

					// Ensure the token doesn't exist before adding to cache
					let token2;
					for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
						if (signedDownloadToken.eventId == this.eventPhotoAdd.photo.addToEvent.id) {
							token2 = signedDownloadToken;
							break;
						}
					}

					if (!token2) {
						this.photoService.signedDownloadTokens.push(responseToken);
						token = responseToken;
					} else {
						token = token2;
					}

					this.eventPhotoAdd.photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + this.eventPhotoAdd.photo.imageUrlMed + "?Authorization=" + token.token;

					this.finish();
				}
			);
		}
	}

	finish() {
		this.finished.emit(this.eventPhotoAdd);
	}
}
