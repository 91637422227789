import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Subject } from 'rxjs';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { PhotoService } from './photo.service';
import { UserConnectionService } from './user-connection.service';

@Injectable()
export class AuthService {
	public loading = new Subject<boolean>(); // Is this needed?

	public announceLogout = new Subject<null>();

	constructor(
		private userIdle: UserIdleService,
		private http: HttpClient,
		private connectionService: UserConnectionService,
		private photoService: PhotoService,
		private userService: UserService
	) { }

	/**
	 * Registration
	 */
	registerEmail(email: string, registerCode: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('email', email);
		queryParameters = queryParameters.set('registerCode', registerCode);

		const requestUrl = '/api/user/register/email';

		return this.http.post<any>(requestUrl, '', { observe: 'response', params: queryParameters });
	}

	verifyRegisterEmail(userUuid: string, emailVerifyCode: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('userUuid', userUuid);
		queryParameters = queryParameters.set('emailVerifyCode', emailVerifyCode);

		const requestUrl = '/api/user/register/email/verify';

		return this.http.post(requestUrl, '', { responseType: 'text', observe: 'response', params: queryParameters, });
	}

	registerUser(user: User) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('Auth', `Basic ${btoa(user.password)}`);

		user.password = '';

		// TODO: This code takes the page.widgets GristerItem[] and converts it into a string before
		//       sending to server. I know there is a better way to handle this.
		const tempUser = JSON.parse(JSON.stringify(user));
		tempUser.widgets = JSON.stringify(user.widgets);

		const requestUrl = '/api/user/register';

		return this.http.post<any>(requestUrl, tempUser, { headers: headers, observe: 'response' });
	}

	/**
	 * Password Reset
	 */
	requestPasswordReset(email: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('email', email);

		const requestUrl = '/api/user/password/reset';

		return this.http.post<any>(requestUrl, '', { observe: 'response', params: queryParameters });
	}

	validateRequestPasswordReset(email: string, resetPasswordCode: string) {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('email', email);
		queryParameters = queryParameters.set('resetPasswordCode', resetPasswordCode);

		const requestUrl = '/api/user/password/reset/confirm';

		return this.http.get<any>(requestUrl, { observe: 'response', params: queryParameters });
	}

	updatePassword(email: string, resetPasswordCode: string, password: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('Auth', `Basic ${btoa(email + ':' + password)}`);

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('resetPasswordCode', resetPasswordCode);

		const requestUrl = '/api/user/password/reset/update';

		return this.http.put<any>(requestUrl, null, { headers: headers, observe: 'response', params: queryParameters });
	}

	/**
	 * Login / Logout
	 */
	signInUser(email: string, password: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Auth', `Basic ${btoa(email + ':' + password)}`);

		const requestUrl = '/api/user/login';

		return this.http.post<User>(requestUrl, '', { headers: headers, observe: 'response' });
	}

	signOutUser() {
		this.userIdle.stopTimer();

		this.announceLogout.next(null);

		localStorage.removeItem('token');
		localStorage.removeItem('jwt');
		localStorage.removeItem('userId');

		this.clearCache();
	}

	/**
	 * Tokens
	 */
	getToken(name: string) {
		let token = localStorage.getItem(name);
		if (token === null) {
			token = '';
		}
		return token;
	}

	setToken(name: string, token: string) {
		localStorage.setItem(name, token);
	}

	/**
	 * Helper Utils
	 */
	isAuthenticated() {
		let userId = null;
		if (!this.userService.users[0] || this.userService.users[0] == null) {
			userId = parseInt(localStorage.getItem('userId'));
		} else {
			userId = this.userService.users[0].id;
		}
		return userId;
	}

	setUserData(user: User) {
		this.clearCache();

		localStorage.setItem('userId', user.id.toString());
		this.userService.addLocalUser(user);
	}

	updateLoading(loading: boolean) {
		this.loading.next(loading);
	}

	// ATTENTION: This should only happen at login or logout and not during a 
	//            session without re-initializing the data after.
	clearCache() {
		this.connectionService.connections = [];

		this.userService.users = [];
		this.userService.policiesVerified = false;
		this.userService.userKeywords = undefined;
		this.userService.userLocations = undefined;
		this.userService.userPeople = undefined;
		this.photoService.signedDownloadTokens = [];
		this.photoService.signedUploadUrl1 = null;
		this.photoService.signedUploadUrl2 = null;
	}
}
