import { Photo } from "./photo.model";

export class PhotoUpload {
	public file: any;
	public photo: Photo;
	public form: any;
	public formLocation: any;
	public event: any;

	public status;
	public errorMessage;
	public queueNumber;
	public signedUploadUrlNumber;
}