import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'src/app/models/gallery.model';
import { AuthService } from 'src/app/services/auth.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
	selector: 'app-card-gallery-pinned',
	templateUrl: './card-gallery-pinned.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardGalleryPinnedComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;
	@ViewChild('galleryDeleteDialog', { static: true }) galleryDeleteDialog: TemplateRef<any>;

	@Input() gallery: Gallery;
	@Output() emitDeleteGallery = new EventEmitter<Gallery>();

	modalRef: BsModalRef;
	loading = false;

	displayDeleteConfirm = false;

	constructor(
		private router: Router,
		public authService: AuthService,
		public galleryService: GalleryService,
		private toastr: ToastrService
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}

	search() {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				galleryId: this.gallery.id
			}
		};
		this.router.navigate(['/explore'], navigationExtras);
	}

	delete() {
		this.displayDeleteConfirm = true;
	}

	deleteConfirm() {
		/** DELETE GALLERY
		 * 
		 * Delete a gallery.
		 * 
		 * 1. Delete the gallery from the database.
		 * 2. Announce the deletion.
		 */
		this.galleryService.deleteGallery(this.gallery.id).subscribe(
			response => {
				if (response) {
					this.galleryService.announceGalleryDeleted(this.gallery);
				}
			});
	}

	openGallery() {
		this.router.navigate(['gallery/' + this.gallery.shareCode1 + '/' + this.gallery.shareCode2]);
	}

	copyShareLinkToClipboard() {
		// TODO: Generate the URL
		let item = 'https://www.photonomy.com/#/gallery/' + this.gallery.shareCode1 + '/' + this.gallery.shareCode2;

		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
		this.toastr.success('Successfully copied to clipboard');
	}
}
