import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import CryptoJS from 'crypto-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { User } from '../../../models/user.model';
import { PhotoService } from '../../../services/photo.service';
import { UserService } from '../../../services/user.service';

//declare var Stripe;

@Component({
	selector: 'app-user-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {
	@ViewChild('confirmDeleteKeywordDialog', { static: true }) confirmDeleteKeywordDialog: TemplateRef<any>;
	@ViewChild('confirmDeleteLocationDialog', { static: true }) confirmDeleteLocationDialog: TemplateRef<any>;
	@ViewChild('confirmDeletePersonDialog', { static: true }) confirmDeletePersonDialog: TemplateRef<any>;

	modalRef: BsModalRef;

	loading = true;
	loadingKeywords = false;
	loadingLocations = false;
	loadingPeople = false;
	loadingImage = true;
	loadingFirstNameSave = false;
	loadingLastNameSave = false;
	loadingPasswordSave = false;

	tab: string = '';

	user: User;
	userChangedSubscription: Subscription;

	formFirstName: UntypedFormGroup;
	formLastName: UntypedFormGroup;
	formPassword: UntypedFormGroup;
	formPhotoSettings: UntypedFormGroup;

	editFirstName = false;
	editFirstNameTemp: string = "";
	editFirstNameSuccess = false;

	editLastName = false;
	editLastNameTemp: string = "";
	editLastNameSuccess = false;

	editEmail = false;

	editPassword = false;
	editPasswordSuccess = false;
	editPasswordFailure = false;

	keywords: any[] = [];
	keywordDelete: string = "";

	locations: any[] = [];
	locationDelete: string = "";

	people: any[] = [];
	personDelete: string = "";

	userPhotoCount: any;
	userPhotoSize = 0;

	expandPhotoReactionHelp = false;

	connectionsActive = [];
	defaultPhotoOwnerUserIdsArray;
	processingConnectionUserId;

	availableFilters = [];
	selectedFilter = '';

	constructor(
		private route: ActivatedRoute,
		private photoService: PhotoService,
		private userService: UserService,
		private userConnectionService: UserConnectionService,
		private formBuilder: UntypedFormBuilder,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private router: Router
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if (params['tab']) {
				this.tab = params['tab'];
			}
		});

		this.subscribeToUserChanged();

		if (!this.userService.users[0] || this.userService.users[0] == null) {
			this.user = new User();

			this.retrieveCurrentUser();
		} else {
			this.retrieveCurrentUserCached();
		}

		//this.photoService.getTotalPhotoSize().subscribe(
		//  response => {
		//    this.userPhotoSize = response.body;
		//  }
		//);

		if (this.tab == '' || this.tab == 'general') {
			this.initFormFirstName();
			this.initFormLastName();
			this.initFormPassword();
		}

		this.setAvailableFilters();
		this.setSelectedFilter();
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				console.log('user subbed: ' + user.firstName + ' ' + user.lastName);
				if (user.id == this.userService.users[0].id) {
					this.user = user;
					this.user.imageSafeUrl = user.imageSafeUrl;
					this.loadingImage = false;
				} else if (user.id == this.processingConnectionUserId) {

					const item = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
					if (item) {
						console.log('item');
						user.isSearchDefaultOwner = true;
					} else {
						console.log('no item');
						user.isSearchDefaultOwner = false;
					}

					this.connectionsActive.push(user);


				}
			}
		);
	}

	initFormFirstName() {
		console.log('initFormFirstName');
		this.formFirstName = this.formBuilder.group({
			'firstName': new UntypedFormControl(this.user?.firstName || null, [
				Validators.required,
			])
		});
	}

	initFormLastName() {
		this.formLastName = this.formBuilder.group({
			'lastName': new UntypedFormControl(this.user?.lastName || null, [
				Validators.required,
			])
		});
	}

	initFormPassword() {
		this.formPassword = this.formBuilder.group({
			'currentPassword': new UntypedFormControl(null, [
				Validators.required,
			]),
			'password': new UntypedFormControl(null, [
				Validators.required,
			]),
			'password2': new UntypedFormControl(null, [
				Validators.required,
			])
		});
	}

	initFormPhotoSettings() {
		this.formPhotoSettings = this.formBuilder.group({
			'connectionsCanView': new UntypedFormControl(this.user.connectionsCanViewByDefault, []),
			'connectionsCanReact': new UntypedFormControl(this.user.connectionsCanReactByDefault, []),
			'connectionsCanSuggest': new UntypedFormControl(this.user.connectionsCanSuggestByDefault, []),
			'connectionsCanDiscuss': new UntypedFormControl(this.user.connectionsCanDiscussByDefault, []),
			'connectionsCanSeeExif': new UntypedFormControl(this.user.connectionsCanSeeExifByDefault, [])
		});
	}

	retrieveCurrentUser() {
		this.loading = true;
		this.userService.retrieveCurrentUser().subscribe(
			response => {
				this.user = response.body;

				if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
					this.userService.retrieveUserImageSafeUrl(this.user, null);
				} else {
					this.loadingImage = false;
				}

				this.loading = false;
			},
			err => {
				this.loading = false;
				this.loadingImage = false;
			}
		);
	}

	retrieveCurrentUserCached() {
		this.user = this.userService.users[0];

		if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
			this.userService.retrieveUserImageSafeUrl(this.user, null);
		} else {
			this.loadingImage = false;
		}

		this.loading = false;
	}

	/**
	 * First retrieve a signed URL from cache or server, then upload the image.
	 */
	uploadProfileImageStep1(e: any) {
		this.loadingImage = true;
		if (this.photoService.signedUploadUrl1) {
			this.uploadProfileImageStep2(this.photoService.signedUploadUrl1, e);
		} else {
			this.photoService.getSignedUploadUrl().subscribe(
				response => {
					this.photoService.signedUploadUrl1 = response.body;
					this.uploadProfileImageStep2(this.photoService.signedUploadUrl1, e);
				}
			);
		}
	}

	uploadProfileImageStep2(signedUploadUrl, e) {
		// TODO: Size check. If over 300KB, reduce to 300KB.

		let file = e.target.files[0];
		let _this = this;
		const reader = new FileReader();
		reader.onload = function (e) {
			const checksum = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(reader.result)) + '';
			const contentLength = file.size;

			let fileName = file.name; // IMG_2146.JPG
			let regexPattern = /(?:\.([^.]+))?$/;
			let fileExtension = regexPattern.exec(fileName)[1]; // "JPEG"
			if (fileExtension.toUpperCase() == 'JPEG') {
				fileExtension = 'JPG';
			}

			let filePath = 'profile/' + _this.userService.getLocalUserUuid(0) + '.' + fileExtension;
			filePath = filePath.toUpperCase();

			_this.photoService.addImageBySignedUploadUrl(file, signedUploadUrl.uploadUrl, signedUploadUrl.authToken, contentLength, filePath, checksum.toString()).subscribe(
				async response => {
					if (response.type === HttpEventType.UploadProgress) {
						// Uploading, do nothing.
					}
					if (response.type === HttpEventType.Response) {
						let updatedUser: User = new User();
						updatedUser.id = _this.user.id;
						updatedUser.imageExternalId = response.body.fileId;
						updatedUser.imageExternalUrl = response.body.fileName;

						_this.userService.updateCurrentUser(updatedUser).subscribe(
							response => {
								_this.userService.users[0] = response.body;
								_this.userService.retrieveUserImageSafeUrl(response.body, null);

								_this.loadingImage = false;
							}
						);
					}
				}
			);
		};
		reader.readAsBinaryString(file);
	}

	clearImage() {
		this.loadingImage = true;
		this.userService.deleteProfileImage().subscribe(
			response => {
				this.user.imageSafeUrl = null;
				this.user.imageExternalId
				this.user.imageExternalUrl

				this.userService.users[0].imageSafeUrl = null;
				this.userService.users[0].imageExternalId = null;
				this.userService.users[0].imageExternalUrl = null;

				//this.userService.announceCurrentUserImageChanged(null);

				this.loadingImage = false;
			}
		);
	}

	setAvailableFilters() {
		this.availableFilters.push({ id: 'general', name: 'General' });
		this.availableFilters.push({ id: 'defaults', name: 'Default Settings' });
		this.availableFilters.push({ id: 'statistics', name: 'Account Statistics' });
		this.availableFilters.push({ id: 'payment', name: 'Manage Payment Method' });
		this.availableFilters.push({ id: 'keywords', name: 'Manage Keywords' });
		this.availableFilters.push({ id: 'locations', name: 'Manage Locations' });
		this.availableFilters.push({ id: 'people', name: 'Manage People' });
	}

	setSelectedFilter() {
		if (this.tab !== '') {
			this.selectedFilter = this.tab;
		} else {
			this.selectedFilter = 'general';
		}

		this.loading = false;
	}

	loadAccountDetails() {
		// TODO: Do this lazily when the Account Details tab is selected and not during init.
		// TODO: If this is 0, get it from the server. (this only happens when the page is refreshed on this page)
		this.userPhotoCount = this.photoService.getUserPhotoCount();

		this.loadUserLocations();
		this.loadUserKeywords();
	}

	handleFilterChanged(filter) {
		this.selectedFilter = filter;
		if (filter == 'general') {
			this.initFormFirstName();
			this.initFormLastName();
			this.initFormPassword();
		} else if (filter == 'statistics') {
			this.loadAccountDetails();
		} else if (filter == 'keywords') {
			this.loadUserKeywords();
		} else if (filter == 'locations') {
			this.loadUserLocations();
		} else if (filter == 'people') {
			this.loadUserPeople();
		} else if (filter == 'defaults') {
			this.initFormPhotoSettings();
			this.loadConnectionsActive();
		}
	}

	loadUserLocations() {
		if (this.locations.length == 0) {
			this.loadingLocations = true;

			this.userService.getUserLocations().subscribe(
				response => {
					for (const location of response) {
						this.locations.push({ name: location });
					}
					this.locations.sort((a, b) => a.name.localeCompare(b.name));
					// this.locations = this.locations.slice();
					this.loadingLocations = false;
				}
			);
		}
	}

	loadUserKeywords() {
		if (this.keywords.length == 0) {
			this.loadingKeywords = true;
			this.userService.getUserKeywords().subscribe(
				response => {
					for (const keyword of response) {
						this.keywords.push({ name: keyword });
					}
					this.keywords.sort((a, b) => a.name.localeCompare(b.name));
					// this.keywords = this.keywords.slice();
					this.loadingKeywords = false;
				}
			);
		}
	}

	loadUserPeople() {
		if (this.people.length == 0) {
			this.loadingPeople = true;
			this.userService.getUserPeople().subscribe(
				response => {
					for (const person of response) {
						this.people.push({ name: person });
					}
					this.people.sort((a, b) => a.name.localeCompare(b.name));
					// this.people = this.people.slice();
					this.loadingPeople = false;
				}
			);
		}
	}

	loadConnectionsActive() {
		if (this.connectionsActive.length == 0) {
			this.userConnectionService.getUserConnectionsActive().subscribe(
				response => {

					this.defaultPhotoOwnerUserIdsArray = JSON.parse(this.user.defaultPhotoOwnerUserIds);
					console.log(this.defaultPhotoOwnerUserIdsArray);

					this.connectionsActive = response.body;
				}
			);
		}
	}

	deleteLocation(location) {
		this.locationDelete = location;
		this.openModal(this.confirmDeleteLocationDialog);
	}

	deleteLocationConfirm() {
		this.loading = true;
		this.userService.deleteUserLocation(this.locationDelete).subscribe(
			response => {
				if (response == true) {
					const item = this.locations.find(d => d.name === this.locationDelete);
					if (item) {
						this.locations.splice(this.locations.indexOf(item), 1);
					}

					this.modalRef.hide();

					this.loading = false;
				} else {
					// TODO: Handle error.
					this.loading = false;
				}
			}
		);
	}

	deleteKeyword(keyword) {
		this.keywordDelete = keyword;
		this.openModal(this.confirmDeleteKeywordDialog);
	}

	deleteKeywordConfirm() {
		this.loading = true;
		this.userService.deleteUserKeyword(this.keywordDelete).subscribe(
			response => {
				if (response == true) {
					const item = this.keywords.find(d => d.name === this.keywordDelete);
					if (item) {
						this.keywords.splice(this.keywords.indexOf(item), 1);
					}

					this.modalRef.hide();

					this.loading = false;
				} else {
					// TODO: Handle error.
					this.loading = false;
				}
			}
		);
	}

	deletePerson(person) {
		this.personDelete = person;
		this.openModal(this.confirmDeletePersonDialog);
	}

	deletePersonConfirm() {
		this.loading = true;
		this.userService.deleteUserPerson(this.personDelete).subscribe(
			response => {
				if (response == true) {
					const item = this.people.find(d => d.name === this.personDelete);
					if (item) {
						this.people.splice(this.people.indexOf(item), 1);
					}

					this.modalRef.hide();

					this.loading = false;
				} else {
					// TODO: Handle error.
					this.loading = false;
				}
			}
		);
	}

	/**
	 * OPEN MODAL
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'modal-xl' })
		);
	}

	// When the user clicks the edit button next to user
	editFirstNameStart() {
		this.editFirstNameSuccess = false;
		this.editFirstNameTemp = this.user.firstName;
		this.editFirstName = true;
	}
	editFirstNameSave() {
		this.loadingFirstNameSave = true;

		let user = new User()
		user.firstName = this.formFirstName.value.firstName;

		this.userService.updateCurrentUser(user).subscribe(
			response => {
				this.toastr.success("Your first name has been updated");
				this.user = response.body;
				this.userService.users[0].firstName = user.firstName;
				this.editFirstNameTemp = '';
				this.editFirstName = false;
				this.editFirstNameSuccess = true;

				this.loadingFirstNameSave = false;
			},
			error => {
				this.editFirstNameTemp = '';
				this.editFirstName = false;

				this.loadingFirstNameSave = false;
			}
		);
	}
	editFirstNameCancel() {
		this.formFirstName.controls['firstName'].setValue(this.editFirstNameTemp);
		this.user.firstName = this.editFirstNameTemp;
		this.editFirstNameTemp = '';

		this.editFirstName = false;
	}

	// When the user clicks the edit button next to user
	editLastNameStart() {
		this.editLastNameSuccess = false;
		this.editLastNameTemp = this.user.lastName;
		this.editLastName = true;
	}
	editLastNameSave() {
		this.loadingLastNameSave = true;

		let user = new User()
		user.lastName = this.formLastName.value.lastName;

		this.userService.updateCurrentUser(user).subscribe(
			response => {
				this.toastr.success("Your last name has been updated");
				this.user = response.body;
				this.userService.users[0].lastName = user.lastName;
				this.editLastNameTemp = '';
				this.editLastName = false;
				this.editLastNameSuccess = true;

				this.loadingLastNameSave = false;
			},
			error => {
				this.editLastNameTemp = '';
				this.editLastName = false;

				this.loadingLastNameSave = false;
			}
		);
	}
	editLastNameCancel() {
		this.formLastName.controls['lastName'].setValue(this.editLastNameTemp);
		this.user.lastName = this.editLastNameTemp;
		this.editLastNameTemp = '';

		this.editLastName = false;
	}

	// When the user clicks the edit button next to user
	editPasswordStart() {
		this.editPasswordSuccess = false;
		this.editPasswordFailure = false;
		this.editPassword = true;
	}
	editPasswordSave() {
		this.loadingPasswordSave = true;
		if (this.formPassword.value.password !== this.formPassword.value.password2) {
			this.toastr.error("Passwords do not match");
			this.loadingPasswordSave = false;
		} else {
			this.userService.updateCurrentUserPassword(this.formPassword.value.currentPassword, this.formPassword.value.password).subscribe(
				response => {
					this.toastr.success("Your password has been updated");
					this.formPassword.controls['currentPassword'].setValue('');
					this.formPassword.controls['password'].setValue('');
					this.formPassword.controls['password2'].setValue('');
					this.editPassword = false;
					this.editPasswordSuccess = true;
					this.editPasswordFailure = false;

					this.loadingPasswordSave = false;
				},
				error => {
					this.toastr.error(error.error);
					this.formPassword.controls['currentPassword'].setValue('');
					this.formPassword.controls['password'].setValue('');
					this.formPassword.controls['password2'].setValue('');
					this.editPassword = false;
					this.editPasswordSuccess = false;
					this.editPasswordFailure = true;

					this.loadingPasswordSave = false;
				}
			);
		}
	}
	editPasswordCancel() {
		this.formPassword.controls['currentPassword'].setValue('');
		this.formPassword.controls['password'].setValue('');
		this.formPassword.controls['password2'].setValue('');

		this.editPassword = false;
	}

	searchLocation(location: string) {
		this.router.navigate(['/explore'], { queryParams: { location: location } });
	}
	searchKeyword(keyword: string) {
		this.router.navigate(['/explore'], { queryParams: { keyword: keyword } });
	}
	searchPerson(person: string) {
		this.router.navigate(['/explore'], { queryParams: { person: person } });
	}

	connectionsCanViewSwitchChanged(event) {
		this.user.connectionsCanViewByDefault = event.srcElement.checked;

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.connectionsCanViewByDefault = event.srcElement.checked;

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);

				this.initFormPhotoSettings();

				this.toastr.success('Default setting updated');
			}
		);
	}

	connectionsCanReactSwitchChanged(event) {
		this.user.connectionsCanReactByDefault = event.srcElement.checked;

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.connectionsCanReactByDefault = event.srcElement.checked;

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);

				this.toastr.success('Default setting updated');
			}
		);
	}

	connectionsCanDiscussSwitchChanged(event) {
		this.user.connectionsCanDiscussByDefault = event.srcElement.checked;

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.connectionsCanDiscussByDefault = event.srcElement.checked;

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);

				this.toastr.success('Default setting updated');
			}
		);
	}

	connectionsCanSuggestSwitchChanged(event) {
		this.user.connectionsCanSuggestByDefault = event.srcElement.checked;

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.connectionsCanSuggestByDefault = event.srcElement.checked;

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);

				this.toastr.success('Default setting updated');
			}
		);
	}

	connectionsCanSeeExifSwitchChanged(event) {
		this.user.connectionsCanSeeExifByDefault = event.srcElement.checked;

		let updateUser = new User;
		updateUser.id = this.user.id;
		updateUser.connectionsCanSeeExifByDefault = event.srcElement.checked;

		this.userService.updateCurrentUser(updateUser).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);

				this.toastr.success('Default setting updated');
			}
		);
	}
}
