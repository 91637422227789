import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-payment-method-manage-nav',
	templateUrl: './payment-method-manage-nav.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardPaymentMethodManageNavComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;

	constructor(
		private router: Router
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}
