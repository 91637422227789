import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LayoutService } from 'src/app/services/layout.service';
import { OnedriveService } from 'src/app/services/onedrive.service';
import { PhotoService } from 'src/app/services/photo.service';
import { SyncService } from 'src/app/services/sync.service';

@Component({
	selector: 'app-photos-add-onedrive',
	templateUrl: './onedrive.component.html',
	styleUrls: ['./onedrive.component.css']
})
export class PhotosAddOnedriveComponent implements OnInit, OnDestroy {
	//subscriptions: Subscription[] = [];

	modalRef: BsModalRef;

	loading = true;
	showHelp = false;
	//uploadMessage = "";

	//albums:any[] = [];
	//photos:any[] = [];

	//totalPhotos: number = 0;
	//totalPhotosProcessed: number = 0;

	//formSingle: FormGroup;

	isAuthenticated = false;
	token = "";
	username = "";

	constructor(
		private router: Router,
		private layoutService: LayoutService,
		private modalService: BsModalService,
		private photoService: PhotoService,
		private syncService: SyncService,
		//private msAuthService: MsalService,
		//private broadcastService: BroadcastService,
		private onedriveService: OnedriveService,
		private http: HttpClient
	) { }

	ngOnInit() {
		/**
		let loginSuccessSubscription: Subscription;
		let loginFailureSubscription: Subscription;

		this.isAuthenticated = this.onedriveService.isAuthenticated();

		loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
			this.isAuthenticated = this.onedriveService.isAuthenticated();
			console.log("Success");
		});

		loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
			console.log('Login Fails:', error);
		}); */


		/**
		this.msAuthService.handleRedirectCallback((authError, response) => {
		  if (authError) {
			console.error('Redirect Error: ', authError.errorMessage);
			return;
		  }
	
		  console.log('Redirect Success: ', response.accessToken);
		});
	 */
		/**
			this.msAuthService.setLogger(new Logger((logLevel, message, piiEnabled) => {
				console.log('MSAL Logging: ', message);
			}, {
				correlationId: CryptoUtils.createNewGuid(),
				piiLoggingEnabled: false
			})); */
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}
	/**
	login() {
		const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

		if (isIE) {
			this.msAuthService.loginRedirect();
		} else {
			this.msAuthService.loginPopup();
		}
	} */

	/**
	 * NAVIGATE
	 * @param path
	 */
	navigate(path) {
		this.router.navigate([path]);
	}

	/**
	 * OPEN MODAL
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'modal-xl' })
		);
	}
	/** 
		getToken() {
			const accessTokenRequest = {
				scopes: ["user.read"]
			}
			let _this = this;
			this.msAuthService.acquireTokenSilent(accessTokenRequest)
				.then(function (accessTokenResponse) {
					// Acquire token silent success
					// Call API with token
					let accessToken = accessTokenResponse.accessToken;
					console.log(accessToken);
					_this.token = accessToken;
					_this.onedriveService.setToken(accessToken);
				})
				.catch(function (error) {
					//Acquire token silent failure, and send an interactive request
					if (error.errorMessage.indexOf("interaction_required") !== -1) {
						this.msAuthService.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse) {
							// Acquire token interactive success
							let accessToken = accessTokenResponse.accessToken;
							console.log(accessToken);
							_this.token = accessToken;
							_this.onedriveService.setToken(accessToken);
	
						}).catch(function (error) {
							// Acquire token interactive failure
							console.log(error);
						});
					}
					console.log(error);
				});
		}
	
		getUsername() {
			this.username = this.msAuthService.getAccount().userName;
		}
	
		getProfile() {
			this.onedriveService.retrieveProfile();
		}
	
		getDrives() {
			this.onedriveService.retrieveDrives();
		}*/

}
