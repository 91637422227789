<div class="photo-row">
	<!-- <img *ngIf="imgSafeUrl" [src]="imgSafeUrl" /> -->
	<div class="details-container">
		<div class="event-name">
			{{ photoUpload.file.name }}
			<span *ngIf="photoUpload.status == 'skipped'">(Photo already exists)</span>
			<span *ngIf="photoUpload.errorMessage">({{ photoUpload.errorMessage }})</span>
		</div>
		<div class="progress-outer-container">
			<div *ngIf="uploadProgress > 1" class="progress-inner-container" [ngClass]="photoUpload.status" [ngStyle]="{ 'width': uploadProgress + '%' }"></div>
		</div>
	</div>
	<div class="close-container">
		<i *ngIf="photoUpload.status == 'inprogress' || photoUpload.status == 'queued' || photoUpload.status == 'finishing'" class="fas fa-circle-notch fa-spin"></i>
		<i *ngIf="photoUpload.status !== 'inprogress' && photoUpload.status !== 'queued' && photoUpload.status !== 'finishing'" class="fas fa-check" [ngClass]="photoUpload.status"></i>
	</div>
</div>