<div class="card opposite-colors" #card>
	<div class="face front">
		<!--
		<i class="fas fa-question" (click)="flip()"></i>
		<div class="help-title">My Galleries</div>
		-->
		<div class="help-text full">
			<p>In Photonomy, your photos must be stored either in a shared vault or in your private vault.</p>
			<p>Shared vaults can be used to split storage costs across multiple members as well
				as set ownership heirarchies to ensure long term ownership of your assets.</p>
			<p>To use your private vault, a valid <a (click)="navigateToPaymentMethods()">payment method</a> must be associated with
				your account.</p>

		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">My Galleries</div>
		<div class="help-text">...</div>
	</div>
</div>