import { PhotoCommentReactionAngry } from "./photo-comment-reaction-angry.model";
import { PhotoCommentReactionLaugh } from "./photo-comment-reaction-laugh.model";
import { PhotoCommentReactionLike } from "./photo-comment-reaction-like.model";
import { PhotoCommentReactionLove } from "./photo-comment-reaction-love.model";
import { PhotoCommentReactionSad } from "./photo-comment-reaction-sad.model";

export class PhotoComment {
    public commentId: number;
    public parentCommentId: number;
    public photoId: number;
    public userId: number;
    public comment: string;
    public date: Date;
    public modified: boolean;
    public lastModifiedDate: Date;
    public removed: boolean;
    public removedDate: Date;
    public removedByUserId: number;

    public reactionsAngry: PhotoCommentReactionAngry[] = [];
    public reactionsLaugh: PhotoCommentReactionLaugh[] = [];
    public reactionsLike: PhotoCommentReactionLike[] = [];
    public reactionsLove: PhotoCommentReactionLove[] = [];
    public reactionsSad: PhotoCommentReactionSad[] = [];

    public replies: PhotoComment[] = [];
}