import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-terms-of-service',
	templateUrl: './terms-of-service.component.html',
	styleUrls: ['./terms-of-service.component.css']
})
export class TermsOfServiceComponent implements OnInit {
	@Input() showFooter: boolean = true;
	loading = true;

	constructor(
		public router: Router
	) { }

	ngOnInit() {
		this.loading = false;
	}

	navigate(path,) {
		this.router.navigate([path]);
	}
}
