import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout.service';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('headerItems', { read: ElementRef }) public headerItems: ElementRef<any>;
	@ViewChildren('headerItems') public headerChildren: QueryList<any>;

	// User Session Timeout Variables
	timerRunning = false;
	idleTimer = 900;
	idleGracePeriod = 120;
	timeoutTime: number;
	pingSubscription: Subscription;
	timerSubscription: Subscription;
	timeoutSubscription: Subscription;

	user: User;
	userChangedSubscription: Subscription;
	userImageSafeUrlChangedSubscription: Subscription;

	userInitials: string = '';

	dashboardEditMode = false;
	iconHoverText: string = '';
	showWidgetsMenu = false;
	showUserMenu = false;
	showMenuGalleries = false;
	showMenuGraphs = false;
	showMenuQuickLinks = false;

	privateModeEnabled = true;

	showUserWelcomeMessage = true;
	showUserWelcomeMessageSubscription: Subscription;

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		public router: Router,
		private userIdle: UserIdleService,
		private userService: UserService,
		private layoutService: LayoutService
	) { }

	ngOnInit() {
		this.initializeIdleTimer();

		if (this.authService.isAuthenticated()) {
			this.subscribeToUserChanged();
			this.subscribeToShowUserWelcomeMessageChanged();

			if (!this.userService.users[0] || this.userService.users[0] == null) {
				this.retrieveCurrentUser();
			} else {
				this.retrieveCurrentUserCached();
			}
		}

		this.route.queryParams.subscribe(params => {
			if (params['action'] && params['action'] === 'edit' && this.router.url.startsWith('/dashboard')) {
				this.dashboardEditMode = true;
			}
		});
	}

	ngOnDestroy() {
		if (this.userChangedSubscription) {
			this.userChangedSubscription.unsubscribe;
		}

		if (this.userService.users[0]) {
			this.userService.users[0] = null;
		}

		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}

		if (this.timeoutSubscription) {
			this.timeoutSubscription.unsubscribe();
		}

		this.userIdle.stopTimer();
		this.userIdle.stopWatching();
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.getLocalUserId(0)) {
					this.user = user;
					this.userInitials = user.firstName.charAt(0) + user.lastName.charAt(0);

					if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
						this.userService.retrieveUserImageSafeUrl(this.user, null);
					}
				}
			}
		);
	}

	subscribeToShowUserWelcomeMessageChanged() {
		this.showUserWelcomeMessageSubscription = this.layoutService.showUserWelcomeMessageChanged
			.subscribe((value: boolean) => {
				this.showUserWelcomeMessage = value;
			});
	}

	initializeIdleTimer() {
		if (this.authService.isAuthenticated()) {
			this.timerRunning = true;
			this.userIdle.startWatching();
		}

		this.timerSubscription = this.userIdle.onTimerStart().subscribe((count) => {
			this.timeoutTime = count;
		});

		this.timeoutSubscription = this.userIdle.onTimeout().subscribe(() => {
			if (this.authService.isAuthenticated()) {
				this.timerRunning = false;
				this.logout();
			}
		});
	}

	retrieveCurrentUser() {
		this.userService.retrieveCurrentUser().subscribe(
			response => {
				this.userService.addLocalUser(response.body); // TODO: We have to make sure this is index 0

				if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
					this.userService.retrieveUserImageSafeUrl(this.user, null);
				}

				this.userInitials = response.body.firstName.charAt(0) + response.body.lastName.charAt(0);
			}
		);
	}

	retrieveCurrentUserCached() {
		this.user = this.userService.users[0];

		if (this.user.imageExternalUrl && !this.user.imageSafeUrl) {
			this.userService.retrieveUserImageSafeUrl(this.user, null);
		}

		this.userInitials = this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
	}

	switchPrivateMode() {
		this.privateModeEnabled = !this.privateModeEnabled;
		this.layoutService.setPrivateModeEnabled(this.privateModeEnabled);
	}

	logout() {
		this.authService.signOutUser();
		this.router.navigate(['/login']);
	}

	navigate(event: Event, path: string) {
		this.showWidgetsMenu = false;
		this.showUserMenu = false;

		if (!path.startsWith('/dashboard')) {
			this.dashboardEditMode = false;
			this.updateIconHoverText('');
		}

		//event.stopPropagation();
		this.router.navigate([path]);
	}

	search() {
		this.router.navigate(['/explore']);
	}

	lockDashboard() {
		this.dashboardEditMode = false;
		this.showWidgetsMenu = false;
		this.showMenuQuickLinks = false;
		this.showMenuGraphs = false;
		this.updateIconHoverText('');
		this.layoutService.announceLockDashboardClicked();
	}

	unlockDashboard() {
		this.dashboardEditMode = true;
		this.showMenuQuickLinks = false;
		this.showMenuGraphs = false;
		this.updateIconHoverText('');
		this.layoutService.announceUnlockDashboardClicked();
	}

	addWidget(type: string) {
		this.showWidgetsMenu = false;
		this.showMenuQuickLinks = false;
		this.showMenuGraphs = false;
		this.updateIconHoverText('');
		this.layoutService.announceAddWidgetClicked(type);
	}

	resetWidgets() {
		this.dashboardEditMode = false;
		this.showWidgetsMenu = false;
		this.showMenuQuickLinks = false;
		this.showMenuGraphs = false;
		this.updateIconHoverText('');
		this.layoutService.announceResetWidgetsClicked();
		this.layoutService.announceLockDashboardClicked();
	}

	displayWidgetMenu() {
		this.showUserMenu = false;
		this.showWidgetsMenu = !this.showWidgetsMenu;
	}

	displayUserMenu() {
		this.showWidgetsMenu = false;
		this.showUserMenu = !this.showUserMenu;
	}

	updateIconHoverText(text: string) {
		this.iconHoverText = text;
	}

	triggerShowGalleries(e) {
		this.showMenuGalleries = !this.showMenuGalleries;
		this.showMenuQuickLinks = false;
		this.showMenuGraphs = false;
		e.stopPropagation();
		e.preventDefault();
	}

	triggerShowGraphs(e) {
		this.showMenuGraphs = !this.showMenuGraphs;
		this.showMenuGalleries = false;
		this.showMenuQuickLinks = false;
		e.stopPropagation();
		e.preventDefault();
	}

	triggerShowQuickLinks(e) {
		this.showMenuQuickLinks = !this.showMenuQuickLinks;
		this.showMenuGalleries = false;
		this.showMenuGraphs = false;
		e.stopPropagation();
		e.preventDefault();
	}
}
