<div class="card opposite-colors" #card>
	<div class="face front">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<div class="flex-container">
			<div class="title-container">
				<div class="title">User<br />Settings</div>
			</div>
			<div class="dropdown-container">
				<form #f="ngForm" [formGroup]="form" class="form">
					<ng-select #filterSelect class="typeahead" appendTo="body" [items]="filters" [clearable]="false" [multiple]="false" bindLabel="name" bindValue="id" id="filter" formControlName="filter" (change)="filterChanged()"></ng-select>
				</form>
			</div>
		</div>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">User Settings</div>
		<div class="help-text tall">
			<p>...</p>
		</div>
	</div>
</div>