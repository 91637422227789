import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
	selector: 'app-widget-photos-quick-links',
	templateUrl: './widget-photos-quick-links.component.html',
	styleUrls: ['./widget-photos-quick-links.component.css']
})
export class WidgetPhotosQuickLinksComponent implements OnInit, OnDestroy {
	loading = true;

	userPhotoCount: any;
	userPhotoCountChangedSubscription: Subscription;

	constructor(
		private router: Router,
		private photoService: PhotoService
	) { }

	ngOnInit() {
		this.subscribeToPhotoCountChanged();

		if (this.photoService.loadingUserPhotoCount) {
			// Another component is already loading the count, do nothing and wait for subscription.
		} else {
			// No component is already loading the count, trigger it and wait for subscription.
			this.retrievePhotoCount();
		}
	}

	ngOnDestroy() { }

	subscribeToPhotoCountChanged() {
		this.userPhotoCountChangedSubscription = this.photoService.userPhotoCountChanged
			.subscribe((value: any) => {
				this.userPhotoCount = value;
				this.loading = false;
			});
	}

	retrievePhotoCount() {
		this.loading = true;
		this.photoService.loadingUserPhotoCount = true;

		this.photoService.getTotalPhotoCount().subscribe(
			response => {
				if (response.status === 200) {
					this.photoService.setAndAnnounceUserPhotoCount(response.body);
				}
			}
		);
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
