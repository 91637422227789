<div class="outer">
	<div class="center-container">
		<!-- Logo - Always visible -->
		<div class="logo-outer-container">
			<div class="logo-inner-container" [ngClass]="{'crop': loginSuccess}"><img src="/assets/images/logo-loginto.png" /></div>
		</div>

		<!-- Loading Indicator -->
		<div *ngIf="loading">
			<div class="loading in loading-container" [ngClass]="{'fade': loginSuccess}">&nbsp;</div>
		</div>

		<!-- Login Form -->
		<div *ngIf="!loading">
			<div class="row">
				<div class="col-md-4 offset-md-4 form-container">
					<form #f="ngForm" [formGroup]="form" class="form">
						<div class="form-group">
							<label>E-mail Address</label>
							<i class="fas fa-envelope"></i>
							<input type="text" id="email" formControlName="email" class="form-control" placeholder="E-mail address">
						</div>
						<div class="form-group">
							<label>Password</label>
							<i class="fas fa-passport"></i>
							<input type="password" id="password" formControlName="password" class="form-control" placeholder="Password">
						</div>

						<button class="btn btn-success submit" type="submit" (click)="loginSubmit()" [disabled]="!f.valid">Log In</button>
						<button class="btn btn-success navigate" (click)="navigate('')">Cancel</button>

						<div class="forgot-password"><a (click)="navigate('login/forgot-password')">Forgot password?</a></div>
					</form>
				</div>
			</div>
		</div>

	</div>
</div>