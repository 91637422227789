import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhotoComment } from '../models/photo-comment.model';

@Injectable()
export class PhotoCommentService {

    constructor(
        private http: HttpClient
    ) { }

    getDiscussion(photoId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);

        const requestUrl = '/api/photo/discussion';

        return this.http.get<Array<PhotoComment>>(requestUrl, { observe: 'response', params: queryParameters });
    }

    addComment(photoId: number, comment: string, parentCommentId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'text/html');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('parentCommentId', parentCommentId);

        const requestUrl = '/api/photo/comment';

        return this.http.post<PhotoComment>(requestUrl, comment, { headers: headers, observe: 'response', params: queryParameters });
    }

    updateComment(photoId: number, commentId: number, comment: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment';

        return this.http.put<PhotoComment>(requestUrl, comment, { headers: headers, observe: 'response', params: queryParameters });
    }

    deleteComment(commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    angryComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/angry';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unangryComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/angry';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    laughComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/laugh';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unlaughComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/laugh';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    likeComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/like';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unlikeComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/like';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    loveComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/love';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unloveComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/love';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }

    sadComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/sad';

        return this.http.post<boolean>(requestUrl, null, { observe: 'response', params: queryParameters });
    }

    unsadComment(photoId: number, commentId: number) {
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('photoId', photoId);
        queryParameters = queryParameters.set('commentId', commentId);

        const requestUrl = '/api/photo/comment/sad';

        return this.http.delete<boolean>(requestUrl, { observe: 'response', params: queryParameters });
    }
}
