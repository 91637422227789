import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
    constructor(
        public router: Router
    ) { }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    navigate(path,) {
        this.router.navigate([path]);
    }

}
