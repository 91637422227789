<div class="card" #card>
	<div class="face front" [ngClass]="{'loading in': loading }">
		<!--<i class="fas fa-question" (click)="flip()"></i>-->
		<form #f="ngForm" [formGroup]="formCode" class="form">
			<input type="text" id="code" formControlName="code" class="form-control">
			<div class="text">Enter an existing users connection code<br />to initiate a connection request</div>
			<div class="button-container">
				<button class="button" type="submit" (click)="submitUuid()" [disabled]="!f.valid || loading">Send Connection Request</button>
			</div>
		</form>
	</div>
	<div class="face back">
		<i class="fas fa-reply" (click)="flip()"></i>
		<div class="help-title">Connection Codes</div>
		<div class="help-text">With Photonomy, you can sync photos from external sources such as Facebook and OneDrive. You can choose to import the photo into Photonomy or leave it in the external source.</div>
	</div>
</div>