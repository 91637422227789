import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import { Subscription } from 'rxjs';
import { Gallery } from '../../../models/gallery.model';
import { GalleryService } from '../../../services/gallery.service';

@Component({
	selector: 'app-photos',
	templateUrl: './photos.component.html',
	styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
	@ViewChild('sliderUncategorized') sliderUncategorized: NgImageSliderComponent;

	loadingPage = true;

	galleryDeletedSubscription: Subscription;

	galleries: Array<Gallery> = [];
	galleryExpanded: Gallery;

	constructor(
		private galleryService: GalleryService,
		private router: Router
	) { }

	ngOnInit() {
		this.subscribeToGalleryDeleted();
		this.retrieveGalleries();
	}

	/**
	 * SUBSCRIBE TO GALLERY DELETED
	 * 
	 * If a gallery is deleted via a card, remove it from the array.
	 * 
	 * 1.
	 */
	subscribeToGalleryDeleted() {
		this.galleryDeletedSubscription = this.galleryService.galleryDeleted
			.subscribe((gallery: Gallery) => {
				const item = this.galleries.find(d => d.id === gallery.id);
				if (item) {
					this.galleries.splice(this.galleries.indexOf(item), 1);
				}
			});
	}

	retrieveGalleries() {
		this.galleryService.retrieveAllGalleries().subscribe(
			response => {
				this.galleries = response.body;
			});
	}

	showGallery(gallery: Gallery) {
		this.galleryExpanded = gallery;
	}

	closeGallery() {
		this.galleryExpanded = null;
	}

	navigate(route: string) {
		this.router.navigate(['/' + route]);
	}
}
