import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { UserConnectionService } from 'src/app/services/user-connection.service';

@Component({
	selector: 'app-card-connection-invite',
	templateUrl: './card-connection-invite.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardConnectionInviteComponent implements OnInit {
	@Output() emitConnectionRequested = new EventEmitter<User>();

	loading = false;

	formEmail: UntypedFormGroup;

	constructor(
		private connectionService: UserConnectionService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private toastr: ToastrService
	) { }

	ngOnInit() {
		this.initFormEmail();
	}

	initFormEmail() {
		this.formEmail = this.formBuilder.group({
			'email': new UntypedFormControl(null, [
				Validators.required
			])
		});
	}

	submitFormEmail() {
		this.loading = true;

		let newUserEmail = this.formEmail.value.email;

		this.connectionService.inviteUserConnection(newUserEmail).subscribe(
			response => {
				if (response.status === 200) {
					this.initFormEmail();
					this.toastr.success('The invitation has been sent.');
					this.emitConnectionRequested.emit();
					this.connectionService.connectionsCount = this.connectionService.connectionsCount + 1;
					this.loading = false;
				} else {
					this.toastr.error('Invalid email.');
					this.loading = false;
				}
			},
			err => {
				this.toastr.error('Invalid email.');
				this.loading = false;
			}
		);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}
}
