import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join'
})
export class ArrayPipe implements PipeTransform {

	//transform(input:Array<any>):string {
	//   return input.join(', ');
	//}

	transform(input: string): string {
		if (input) {
			var array = JSON.parse(input);
			return array.join(', ');
		}
	}
}
