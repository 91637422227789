import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare const FB: any;

@Injectable()
export class InstagramService {

	private token: any;
	private userId: any;

	constructor(
		private http: HttpClient
	) { }

	authenticate(callback) {
		FB.login(function (response) {
			if (response.authResponse) {
				callback(response);
			} else {
				console.log('User cancelled login or did not fully authorize.');
			}
		}, { scope: 'user_photos' });
	}

	getUser() {
		//let headers = new HttpHeaders();
		//headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters.set('fields', 'id,username');
		queryParameters.set('access_token', this.token);

		const requestUrl = 'https://graph.instagram.com/me';

		return this.http.get<any>(requestUrl, { observe: 'response', params: queryParameters });
	}

	/**
	retrieveAllAlbums(callback) {
	  FB.api('/' + this.userId + '/albums?fields=count,name,created_time', function(response) {  // do we need photos?
		  callback(response);
	  }, {access_token: this.token});
	}
  
	retrieveAlbumPhotos(albumId, callback) {
	  FB.api('/' + albumId + '/photos?fields=images,link', function(response) {
		  callback(response);
	  }, {access_token: this.token});
	}
  
	retrieveAlbumPhotosNextPage(pagingNextUrl, callback) {
	  FB.api(pagingNextUrl, function(response) {
		  callback(response);
	  }, {access_token: this.token});
	}
  
	retrieveAllPhotos(callback) {
	  FB.api('/' + this.userId + '/photos', function(response) {
		  callback(response);
	  }, {access_token: this.token});
	}
    
	retrievePhoto(photoId, callback) {
	  FB.api('/' + photoId + '?fields=images', function(response) {
		  callback(response);
	  }, {access_token: this.token});
	}
	  */

	getToken() {
		return this.token;
	}
	setToken(token: any) {
		this.token = token;
	}

	getUserId() {
		return this.userId;
	}
	setUserId(userId: any) {
		this.userId = userId;
	}
}
