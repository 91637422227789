<div class="container" [ngClass]="{'loading in': loading }">
	<div class="middle">
		<div class="total-storage">{{ userImageSize?.totalSize | number : '1.2-2' }} MB TOTAL STORAGE USED</div>
		<ng-container>
			<div class="storage-graph-container">
				<div class="empty"></div>
				<div class="private" [ngStyle]="{'transition' : 'width ' + privateWidthSeconds + 's linear', 'width' : 'calc(' + privateWidth + '% - 2rem)'}"></div>
				<div class="shared" [ngStyle]="{'width' : 'calc(' + sharedWidth + '% - 2rem)'}"></div>
			</div>
			<div class="storage-info-container">
				<div class="private">
					<div class="title">Private</div>
					{{ userImageSize?.privateSize | number : '1.2-2' }} MB
				</div>
				<div class="shared" *ngIf="sharedWidth > 0">
					<div class="title">Shared</div>
					{{ userImageSize?.sharedSize | number : '1.2-2' }} MB
				</div>
			</div>
		</ng-container>
	</div>
</div>