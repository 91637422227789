<div class="loading in" *ngIf="loadingEvents || loadingUserPaymentMethods">
	&nbsp;
</div>

<div class="scroll" *ngIf="!paymentMethodsExist && (!loadingEvents && !loadingUserPaymentMethods)">
	<div class="welcome-title">
		<div class="title">My Events</div>
	</div>
	<div class="welcome-message">
		To create events, you must have a valid payment method added to your account...
	</div>
	<div class="welcome-content">
		<div class="column">
			<div class="icon-cell">
				<i class="far fa-credit-card"></i>
			</div>
			<div class="border-cell">
				<div class="content-cell">
					<div class="content-title">Payment Methods</div>
					<div class="content-text">
						<p>Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigateToPaymentMethods()">Create a Payment Method</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row justify-content-center g-0" *ngIf="paymentMethodsExist && (!loadingEvents && !loadingUserPaymentMethods)">
	<div class="col-md-11" [ngClass]="{'loading in': loading }">
		<div class="quick-links row">
			<!-- Filter / Title Card -->
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-events-title [selectedFilter]="selectedFilter" [filters]="availableFilters" (emitFilterChanged)="handleFilterChanged($event)"></app-card-events-title>
			</div>

			<!-- Add -->
			<ng-container *ngIf="selectedFilter == 'add'">
				<div class="container col-sm-6 col-lg-4 col-xl-3">
					<app-card-events-add-nav></app-card-events-add-nav>
				</div>
			</ng-container>

			<!-- All -->
			<ng-container *ngIf="selectedFilter == 'all'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let event of eventsAll">
					<app-card-event-view [event]="event" (emitEventAcceptInvite)="acceptEventInvite($event)" (emitEventDeclineInvite)="declineEventInvite($event)" (emitEventDelete)="deleteEvent($event)" (emitEventLeave)="leaveEvent($event)"></app-card-event-view>
				</div>
			</ng-container>

			<!-- Past -->
			<ng-container *ngIf="selectedFilter == 'past'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let event of eventsPast">
					<app-card-event-view [event]="event" (emitEventAcceptInvite)="acceptEventInvite($event)" (emitEventDeclineInvite)="declineEventInvite($event)" (emitEventDelete)="deleteEvent($event)" (emitEventLeave)="leaveEvent($event)"></app-card-event-view>
				</div>
			</ng-container>

			<!-- Future -->
			<ng-container *ngIf="selectedFilter == 'future'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let event of eventsFuture">
					<app-card-event-view [event]="event" (emitEventAcceptInvite)="acceptEventInvite($event)" (emitEventDeclineInvite)="declineEventInvite($event)" (emitEventDelete)="deleteEvent($event)" (emitEventLeave)="leaveEvent($event)"></app-card-event-view>
				</div>
			</ng-container>

			<!-- Member -->
			<!-- <ng-container *ngIf="selectedFilter == 'member'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let event of eventsMember">
					<app-card-event-view [event]="event" (emitEventAcceptInvite)="acceptEventInvite($event)" (emitEventDeclineInvite)="declineEventInvite($event)" (emitEventDelete)="deleteEvent($event)" (emitEventLeave)="leaveEvent($event)"></app-card-event-view>
				</div>
				<div *ngIf="eventsMember && eventsMember.length < 1" class="pending-message">You are not a member of any events.</div>
			</ng-container> -->

			<!-- Pending -->
			<!-- <ng-container *ngIf="selectedFilter == 'pending'">
				<div class="container col-sm-6 col-lg-4 col-xl-3" *ngFor="let event of eventsPending">
					<app-card-event-view [event]="event" (emitEventAcceptInvite)="acceptEventInvite($event)" (emitEventDeclineInvite)="declineEventInvite($event)" (emitEventDelete)="deleteEvent($event)" (emitEventLeave)="leaveEvent($event)"></app-card-event-view>
				</div>
				<div *ngIf="eventsPending && eventsPending.length < 1" class="pending-message">You have no events pending your approval.</div>
			</ng-container> -->
		</div>
	</div>
</div>