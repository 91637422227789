import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-card-galleries-title',
	templateUrl: './card-galleries-title.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardGalleriesTitleComponent implements OnInit, OnDestroy {
	@ViewChild('card') cardRef: ElementRef;

	constructor(
		private router: Router
	) { }

	ngOnInit() { }

	ngOnDestroy() { }

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}
