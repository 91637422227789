<div class="footer">
    <div class="logo-container">
        <div class="left">
            <hr />
        </div>
        <div class="middle"><img src="assets/images/logo-text-white.png" (click)="navigate('')" /></div>
        <div class="right">
            <hr />
        </div>
    </div>
    <div class="link-container">
        <div class="left">
            <a (click)="navigate('privacy')">Privacy Policy</a>
        </div>
        <!--
		<div class="middle">-->
        <!--
			<a href="javascript:void(0)" (click)="navigate('privacy')">Privacy Policy</a>
			
            <li><a target="_blank" href="https://www.facebook.com/PhotonomyInc">Facebook</a></li>
            <li><a target="_blank" href="https://twitter.com/PhotonomyInc">Twitter</a></li>
            -->
        <!--</div>
		-->
        <div class="right">
            <a (click)="navigate('terms')">Terms and Conditions</a>
        </div>
    </div>
    <div class="copyright">
        &copy; 2023 &nbsp;&nbsp; &middot; &nbsp;&nbsp; Photonomy &nbsp;&nbsp; &middot; &nbsp;&nbsp; All rights reserved.
    </div>
</div>