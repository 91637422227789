<div class="container loading in" *ngIf="loadingCount || loadingConnections">
	&nbsp;
</div>

<div class="container" *ngIf="!loadingCount && !loadingConnections && connectionsPending.length == 0">
	<div class="center">
		<div class="left">
			<i class="fas fa-users"></i>
		</div>
		<div class="right">
			<div class="top">{{ connectionsCount }} Connections</div>
			<div class="bottom"><a (click)="navigate('connections')">Manage connections</a> <a (click)="navigate('connections')">Add a connection</a></div>
		</div>
	</div>
</div>

<div class="container pending" *ngIf="!loadingCount && !loadingConnections && connectionsPending.length > 0">
	<div class="center">
		<div class="left">
			<i class="fas fa-users"></i>
		</div>
		<div class="right">
			<div class="top">Connection request from <strong>{{ connectionsPending[0].firstName }} {{ connectionsPending[0].lastName }}</strong></div>
			<div class="bottom"><a (click)="approveConnectionPending( connectionsPending[0] )" class="accept">Accept</a> <a (click)="declineConnectionInvite( connectionsPending[0] )" class="decline">Decline</a></div>
		</div>
	</div>
</div>