<div class="row justify-content-center g-0">
	<div class="col-md-11" [ngClass]="{'loading in': loading }">
		<div class="quick-links row">
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-title></app-card-photos-sync-title>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-upload-nav></app-card-photos-upload-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-facebook-nav></app-card-photos-sync-facebook-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-instagram-nav></app-card-photos-sync-instagram-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-google-photos-nav></app-card-photos-sync-google-photos-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-microsoft-onedrive-nav></app-card-photos-sync-microsoft-onedrive-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-apple-icloud-nav></app-card-photos-sync-apple-icloud-nav>
			</div>
			<!--
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-dropbox-nav></app-card-photos-sync-dropbox-nav>
			</div>
			<div class="container col-sm-6 col-lg-4 col-xl-3" *ngIf="!loading">
				<app-card-photos-sync-microsoft-onedrive-nav></app-card-photos-sync-microsoft-onedrive-nav>
			</div>
			-->
		</div>
	</div>
</div>