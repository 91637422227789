import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { SyncService } from 'src/app/services/sync.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-photos-add',
	templateUrl: './add.component.html',
	styleUrls: ['./add.component.css']
})
export class PhotosAddComponent implements OnInit {
	loading = true;

	user: User;
	userChangedSubscription: Subscription;

	syncFacebookEnabled = false;
	syncInstagramEnabled = false;
	syncOnedriveEnabled = false;
	syncIcloudEnabled = false;
	syncGoogleEnabled = false;

	showHelp = false;

	constructor(
		public router: Router,
		private syncService: SyncService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		// TODO: Get this from cache.
		this.loadUserSyncs();
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				this.user = user;
			});

		if (this.userService.getLocalUser(0)) {
			this.user = this.userService.getLocalUser(0);
		}
	}

	loadUserSyncs() {
		this.loading = true;

		this.syncService.getSyncs().subscribe(
			response => {
				if (response.status === 200) {
					for (let sync of response.body) {
						if (sync.type === 'facebook' && sync.status === 'enabled') {
							this.syncFacebookEnabled = true;
						} else if (sync.type === 'instagram' && sync.status === 'enabled') {
							this.syncInstagramEnabled = true;
						} else if (sync.type === 'onedrive' && sync.status === 'enabled') {
							this.syncOnedriveEnabled = true;
						} else if (sync.type === 'icloud' && sync.status === 'enabled') {
							this.syncIcloudEnabled = true;
						} else if (sync.type === 'google' && sync.status === 'enabled') {
							this.syncGoogleEnabled = true;
						}
					}
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
