import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PhotoComment } from 'src/app/models/photo-comment.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { PhotoCommentService } from 'src/app/services/photo-comment.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-photo-comment',
	templateUrl: './photo-comment.component.html',
	styleUrls: ['./photo-comment.component.css']
})
export class PhotoCommentComponent implements OnInit {
	@Input() photoComment: PhotoComment;
	@Input() showReplies: boolean;
	@Input() allowReplies: boolean;

	loading = true;
	loadingUser = true;

	userFoundSubscription: Subscription;
	user: User;

	showReactionContainer = false;
	showReplyContainer = false;

	hasLikeReaction = false;
	hasLoveReaction = false;
	hasSadReaction = false;
	hasAngryReaction = false;
	hasLaughReaction = false;

	replyForm: UntypedFormGroup;

	timeInPast = '';

	constructor(
		private authService: AuthService,
		private photoCommentService: PhotoCommentService,
		private userService: UserService,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit() {
		this.initReplyForm();
		this.subscribeToUserChanged();

		this.setReactionFlags();

		// TODO: If this comment has been modified, use the date modified use that instead
		this.setTimeInPast(this.photoComment.date);

		this.userService.getUserByUserId(this.photoComment.userId, null);
	}

	ngOnDestroy() { }

	initReplyForm() {
		this.replyForm = this.formBuilder.group({
			'reply': new UntypedFormControl()
		});
	}

	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.photoComment.userId) {
					this.user = user;
					this.loadingUser = false;
				}
			}
		);
	}

	setTimeInPast(date: Date) {
		let timeInPast = '';

		let endTime = new Date();
		let startTime = new Date(date);

		let differenceInDays = this.calculateDaysDiff(startTime, endTime);
		if (differenceInDays == 0) {
			let differenceInHours = this.calculateHoursDiff(startTime, endTime);
			if (differenceInHours > 1) {
				// 2-23 hours ago
				timeInPast = differenceInHours + ' hours ago'
			} else if (differenceInHours == 1) {
				// 1 hour ago
				timeInPast = differenceInHours + ' hour ago'
			} else {
				let differenceInMinutes = this.calculateMinutesDiff(startTime, endTime);

				if (differenceInMinutes > 1) {
					// 2-59 minutes ago
					timeInPast = differenceInMinutes + ' minutes ago'
				} else if (differenceInMinutes == 1) {
					// 1 minute ago
					timeInPast = differenceInMinutes + ' minute ago'
				} else {
					// Now
					timeInPast = 'Now'
				}
			}
		} else if (differenceInDays == 1) {
			// 1 day ago
			timeInPast = differenceInDays + ' day ago';
		} else if (differenceInDays > 1 && differenceInDays < 28) {
			// 2-28 days ago
			timeInPast = differenceInDays + ' days ago';
		} else if (differenceInDays > 27 && differenceInDays < 32) {
			// About a month ago
			timeInPast = 'About a month ago';
		} else {
			let differenceInMonths = this.calculateMonthDiff(startTime, endTime);
			if (differenceInMonths == '1') {
				// 1 month ago
				timeInPast = differenceInMonths + ' month ago'
			} else if (differenceInMonths > 1 && differenceInMonths < 12) {
				// 2-11 months ago
				timeInPast = differenceInMonths + ' months ago'
			} else {
				let differenceInYears = this.calculateYearDiff(startTime, endTime);
				if (differenceInYears == 1) {
					// 1 year ago
					timeInPast = differenceInYears + ' year ago'
				} else {
					// 2+ years ago
					timeInPast = differenceInYears + ' years ago'
				}
			}
		}

		this.timeInPast = timeInPast;
	}

	/**
	 * Reactions
	 */

	setReactionFlags() {
		if (this.photoComment.reactionsLike.length > 0) {
			for (let photoReactionCommentLike of this.photoComment.reactionsLike) {
				if (photoReactionCommentLike.userId == this.userService.getLocalUserId(0)) {
					this.hasLikeReaction = true;
					break;
				}
			}
		}
		if (this.photoComment.reactionsLove.length > 0) {
			for (let photoReactionCommentLove of this.photoComment.reactionsLove) {
				if (photoReactionCommentLove.userId == this.userService.getLocalUserId(0)) {
					this.hasLoveReaction = true;
					break;
				}
			}
		}
		if (this.photoComment.reactionsAngry.length > 0) {
			for (let photoReactionCommentAngry of this.photoComment.reactionsAngry) {
				if (photoReactionCommentAngry.userId == this.userService.getLocalUserId(0)) {
					this.hasAngryReaction = true;
					break;
				}
			}
		}
		if (this.photoComment.reactionsSad.length > 0) {
			for (let photoReactionCommentSad of this.photoComment.reactionsSad) {
				if (photoReactionCommentSad.userId == this.userService.getLocalUserId(0)) {
					this.hasSadReaction = true;
					break;
				}
			}
		}
		if (this.photoComment.reactionsLaugh.length > 0) {
			for (let photoReactionCommentLaugh of this.photoComment.reactionsLaugh) {
				if (photoReactionCommentLaugh.userId == this.userService.getLocalUserId(0)) {
					this.hasLaughReaction = true;
					break;
				}
			}
		}
	}

	addReaction() {
		this.showReactionContainer = !this.showReactionContainer;
	}

	addReactionLike() {
		this.photoCommentService.likeComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLikeReaction = true;

				let item = { commentId: this.photoComment.commentId, userId: this.userService.getLocalUserId(0) };
				this.photoComment.reactionsLike.push(item);

				this.showReactionContainer = false;
			}
		);
	}

	addReactionLove() {
		this.photoCommentService.loveComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLoveReaction = true;

				let item = { commentId: this.photoComment.commentId, userId: this.userService.getLocalUserId(0) };
				this.photoComment.reactionsLove.push(item);

				this.showReactionContainer = false;
			}
		);
	}

	addReactionSad() {
		this.photoCommentService.sadComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasSadReaction = true;

				let item = { commentId: this.photoComment.commentId, userId: this.userService.getLocalUserId(0) };
				this.photoComment.reactionsSad.push(item);

				this.showReactionContainer = false;
			}
		);
	}

	addReactionLaugh() {
		this.photoCommentService.laughComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLaughReaction = true;

				let item = { commentId: this.photoComment.commentId, userId: this.userService.getLocalUserId(0) };
				this.photoComment.reactionsLaugh.push(item);

				this.showReactionContainer = false;
			}
		);
	}

	addReactionAngry() {
		this.photoCommentService.angryComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasAngryReaction = true;

				let item = { commentId: this.photoComment.commentId, userId: this.userService.getLocalUserId(0) };
				this.photoComment.reactionsAngry.push(item);

				this.showReactionContainer = false;
			}
		);
	}

	removeReactionLike() {
		this.photoCommentService.unlikeComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLikeReaction = false;

				const item = this.photoComment.reactionsLike.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photoComment.reactionsLike.splice(this.photoComment.reactionsLike.indexOf(item), 1);
			}
		);
	}

	removeReactionLove() {
		this.photoCommentService.unloveComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLoveReaction = false;

				const item = this.photoComment.reactionsLove.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photoComment.reactionsLove.splice(this.photoComment.reactionsLove.indexOf(item), 1);
			}
		);
	}

	removeReactionAngry() {
		this.photoCommentService.unangryComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasAngryReaction = false;

				const item = this.photoComment.reactionsAngry.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photoComment.reactionsAngry.splice(this.photoComment.reactionsAngry.indexOf(item), 1);
			}
		);
	}

	removeReactionSad() {
		this.photoCommentService.unsadComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasSadReaction = false;

				const item = this.photoComment.reactionsSad.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photoComment.reactionsSad.splice(this.photoComment.reactionsSad.indexOf(item), 1);
			}
		);
	}

	removeReactionLaugh() {
		this.photoCommentService.unlaughComment(this.photoComment.photoId, this.photoComment.commentId).subscribe(
			response => {
				this.hasLaughReaction = false;

				const item = this.photoComment.reactionsLaugh.find(d => d.userId === this.userService.getLocalUserId(0));
				this.photoComment.reactionsLaugh.splice(this.photoComment.reactionsLaugh.indexOf(item), 1);
			}
		);
	}

	editComment() {

	}

	addReply() {
		this.showReplyContainer = true;
	}

	addReplySubmit() {
		let reply = this.replyForm.value.reply;

		this.photoCommentService.addComment(this.photoComment.photoId, reply, this.photoComment.commentId).subscribe(
			response => {
				console.log(response);

				this.showReplyContainer = true;

				this.initReplyForm();

				// TODO: Announce to parent component to reload discussion
			}
		);
	}

	cancelReply() {
		this.showReplyContainer = false;

		// TODO: Reset the reply form.
		this.initReplyForm();
	}

	/** Helper Functions */
	secondsDiff(d1, d2) {
		let millisecondDiff = d2 - d1;
		let secDiff = Math.floor((d2 - d1) / 1000);
		return secDiff;
	}

	calculateMinutesDiff(d1, d2) {
		let seconds = this.secondsDiff(d1, d2);
		let minutesDiff = Math.floor(seconds / 60);
		return minutesDiff;
	}

	calculateHoursDiff(d1, d2) {
		let minutes = this.calculateMinutesDiff(d1, d2);
		let hoursDiff = Math.floor(minutes / 60);
		return hoursDiff;
	}

	calculateDaysDiff(d1, d2) {
		let hours = this.calculateHoursDiff(d1, d2);
		let daysDiff = Math.floor(hours / 24);
		return daysDiff;
	}

	calculateMonthDiff(d1, d2) {
		let months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	calculateYearDiff(d1, d2) {
		let date1 = new Date(d1);
		let date2 = new Date(d2);
		let yearsDiff = date2.getFullYear() - date1.getFullYear();
		return yearsDiff;
	}
}
