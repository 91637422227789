import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare const FB: any;

@Injectable()
export class OnedriveService {

	private token: any;
	private userId: any;

	constructor(
		private http: HttpClient,
		//private msAuthService: MsalService
	) { }

	/**
	authenticate() {
		const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

		if (isIE) {
			this.msAuthService.loginRedirect();
		} else {
			this.msAuthService.loginPopup.then();
		}
	}


	retrieveChildren() {

	}
/
	retrieveProfile() {
		let headers = new HttpHeaders();
		let tokenString = 'bearer ' + this.getToken();
		console.log(tokenString);
		headers = headers.set('Authorization', '`' + tokenString + '`');

		this.http.get('https://graph.microsoft.com/v1.0/me', { headers: headers }).subscribe({
			next: (profile) => {
				console.log(profile);
			},
			error: (err: AuthError) => {
				console.log('err');
				// If there is an interaction required error,
				// call one of the interactive methods and then make the request again.
				if (InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)) {
					this.msAuthService.acquireTokenPopup({
						scopes: this.msAuthService.getScopesForEndpoint('https://graph.microsoft.com/v1.0/me')
					})
						.then(() => {
							this.http.get('https://graph.microsoft.com/v1.0/me')
								.toPromise()
								.then(profile => {
									console.log(profile);
								});
						});
				}
			}
		});
	}

	retrieveDrives() {
		let headers = new HttpHeaders();
		let tokenString = 'bearer ' + this.getToken();
		console.log(tokenString);
		headers = headers.set('Authorization', '`' + tokenString + '`');

		this.http.get('https://graph.microsoft.com/v1.0/me/drives', { headers: headers }).subscribe({
			next: (response) => {
				console.log(response);
			},
			error: (err: AuthError) => {
				console.log('error getting drives');
				// If there is an interaction required error,
				// call one of the interactive methods and then make the request again.
				if (InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)) {
					this.msAuthService.acquireTokenPopup({
						scopes: this.msAuthService.getScopesForEndpoint('https://graph.microsoft.com/v1.0/me')
					})
						.then(() => {
							this.http.get('https://graph.microsoft.com/v1.0/me/drives')
								.toPromise()
								.then(profile => {
									console.log(profile);
								});
						});
				} else {
					console.log('logging you out');
				}
			}
		});
	}

	getToken() {
		return this.token;
	}
	setToken(token: any) {
		this.token = token;
	}

	getUserId() {
		return this.userId;
	}
	setUserId(userId: any) {
		this.userId = userId;
	}

	isAuthenticated() {
		return !!this.msAuthService.getAccount();
	}
 */
}
