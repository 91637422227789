import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-payment-method-manage',
	templateUrl: './payment-method-manage.component.html',
	styleUrls: ['./payment-method-manage.component.css']
})
export class PaymentMethodManageComponent implements OnInit, OnDestroy {
	@ViewChild('deletePaymentMethodDialog', { static: true }) managePaymentMethodDialog: TemplateRef<any>;

	modalRef: BsModalRef;
	loading = true;

	showAddPaymentMethod = false;
	canDeletePaymentMethod = false;

	card: any;
	paymentHandler = this.onChange.bind(this);
	error: string;

	user: User;
	userChangedSubscription: Subscription;

	paymentMethodExpiring = false;

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private paymentService: PaymentService,
		private userService: UserService,
		private cd: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		if (!this.userService.users[0] || this.userService.users[0] == null) {
			this.user = new User();

			this.retrieveCurrentUser();
		} else {
			this.retrieveCurrentUserCached();
		}

		this.card = elements.create('card');
		this.card.mount('#card-info');
		this.card.addEventListener('change', this.paymentHandler);
	}

	ngOnDestroy() {
		if (this.card) {
			this.card.removeEventListener('change', this.paymentHandler);
			this.card.destroy();
		}

		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user = user;

					let now = new Date(); // 5/13/22
					let expireDate = new Date(user.paymentMethodCcExpirationYear, user.paymentMethodCcExpirationMonth, 0);

					if (this.numDaysBetween(now, expireDate) < 60) {
						this.paymentMethodExpiring = true;
					} else {
						this.paymentMethodExpiring = false;
					}
				}
			}
		);
	}

	retrieveCurrentUser() {
		this.loading = true;
		this.userService.retrieveCurrentUser().subscribe(
			response => {
				this.userService.updateLocalUser(response.body);
				this.loading = false;
			},
			err => {
				this.loading = false;
			}
		);
	}

	retrieveCurrentUserCached() {
		this.userService.updateLocalUser(this.userService.users[0]);
		this.loading = false;
	}

	showAddPayment() {
		this.showAddPaymentMethod = true;
	}

	cancelAddPayment() {
		this.showAddPaymentMethod = false;
	}

	showDeletePaymentMethodDialog() {
		this.loading = true;

		this.modalRef = this.modalService.show(
			this.managePaymentMethodDialog,
			Object.assign({}, { class: 'modal-md' }, { backdrop: true, ignoreBackdropClick: true })
		);

		this.paymentService.isPaymentMethodUsed(this.user.paymentMethodId).subscribe(
			response => {
				this.canDeletePaymentMethod = !response;
				this.loading = false;
			});
	}

	deletePaymentMethodConfirm() {
		this.loading = true;
		this.paymentService.deleteUserPaymentMethod(this.user.paymentMethodId).subscribe(
			response => {
				this.userService.updateLocalUser(response.body);
				this.modalRef.hide();
				this.loading = false;
			});
	}

	onChange({ error }) {
		if (error) {
			this.error = error.message;
		} else {
			this.error = null;
		}
		this.cd.detectChanges();
	}

	// https://stripe.com/docs/payments/save-and-reuse
	// https://stripe.com/docs/payments/setup-intents
	// FUTURE: https://support.stripe.com/questions/check-if-a-card-is-valid-without-a-charge
	// https://stripe.com/docs/stripe-js/elements/payment-request-button?platform=html-js-testing-apple-pay
	createSetupIntent() {
		this.loading = true;
		this.paymentService.getSetupIntent().subscribe(
			response => {
				var stripeResponse = this.confirmCardSetup(response.body, this.card);
				console.log('stripeResponse');
				console.log(stripeResponse);
			},
			err => {
				console.log(err);
				this.loading = false;
				// TODO: Proper error handling.
			}
		);
	}

	confirmCardSetup(intent, cardElement) {
		return stripe
			.confirmCardSetup(
				intent,
				{
					payment_method: {
						card: cardElement,
						billing_details: {
							email: this.userService.getLocalUser(0).email,
							name: this.userService.getLocalUserFullName(0),
						},
					},
				},
				{
					handleActions: false
				}
			)
			.then((result) => {
				if (result.error) {
					this.loading = false;
					// TODO: Show error message with back button
				} else {
					let updatedUser = new User();
					updatedUser.id = this.user.id;

					this.paymentService.updateUserPaymentMethod(result.setupIntent.payment_method).subscribe(
						response => {
							this.userService.updateLocalUser(response.body);

							this.card.clear();

							this.showAddPaymentMethod = false;
							this.loading = false;
						},
						err => {
							this.loading = false;
							console.log(err);
							// TODO: Proper error handling.
						});
				}
			});
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}

	numDaysBetween(d1, d2) {
		var diff = Math.abs(d1.getTime() - d2.getTime());
		return diff / (1000 * 60 * 60 * 24);
	}
}
